import { foodsActions as actions, colors } from '@/entities/foods';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Select } from '@/shared/ui/Select/Select';

export const SelectColor = () => {
  const dispatch = useAppDispatch();
  const { color } = useAppSelector((state) => state.foods.form.main);
  const selected = colors.find((c) => c.hex === color?.id);
  return (
    <Select
      name={'color'}
      data-testid={'select-color'}
      id={'color'}
      selected={
        selected
          ? {
              id: selected.hex,
              title: selected.name,
            }
          : null
      }
      required
      values={colors.map((color) => ({
        id: color.hex,
        title: color.name,
      }))}
      handleSelect={(value) =>
        dispatch(
          actions.changeRecipesForm({
            key: 'color',
            value: value,
          })
        )
      }
      label={'Цвет'}
      placeholderClassName={color?.title ? '' : 'text-gray-300'}
      containerClassName='w-[428px]'
      placeholder={color ? selected?.name : 'Выберите'}
    />
  );
};
