import { fetchCategoryById } from '@/entities/categories/model/categoriesSlice';
import { isEmptyObj } from '@/shared/lib/isEmptyObj';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SingleCategoryView from './SingleCategory-View';

import './SingleCategory.css';

const SingleCategoryContainer = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const category = useAppSelector((state) => state.categories.category);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  useEffect(() => {
    (async () => {
      await dispatch(fetchCategoryById(+id));
      setLoading(false);
    })();
  }, []);
  if (loading || isEmptyObj(category)) {
    return <CircularProgress />;
  }

  return (
    <SingleCategoryView
      category={category}
      editOpen={editOpen}
      setEditOpen={setEditOpen}
      setDeleteOpen={setDeleteOpen}
      deleteOpen={deleteOpen}
    />
  );
};

export default SingleCategoryContainer;
