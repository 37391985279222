import { articlesActions } from '@/entities/articles/model/articleSlice';
import {
  addArticlesSources,
  addAuthor,
  addTopics,
  changePublicationType,
  deleteSources,
  editAuthor,
  editSingleArticle,
  fetchSingleArticle,
  getArticleSources,
  IArticleAction,
  removeTopics,
} from '@/entities/articles/model/articleThunks';
import {
  AuthorData,
  EditArticleObject,
} from '@/entities/articles/types/IArticle';
import { isEdOrCr } from '@/pages/Articles/ui';
import { uploadImg } from '@/shared/lib/uploadImg';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { formatISO, isDate } from 'date-fns';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const useArticle = (watch?: any) => {
  const dispatch = useAppDispatch();
  const article = useAppSelector((state) => state.articles.articlesForm);

  const authorId = useAppSelector(
    (state) => state.articles.articlesForm.author?.id
  );
  const sources = useAppSelector((state) => state.articles.articlesForm.source);
  const sourcesInit = useAppSelector((state) => state.articles.sourcesInit);
  const topicInitIds = useAppSelector(
    (state) => state.articles.topicsInitIds.ids
  );

  const { pathname } = useLocation();
  const formatOptions = useAppSelector((state) => state.articles.formatOptions);

  const [isCreatingOrEditing, setIsCreatingOrEditing] = useState<isEdOrCr>({
    id: null,
    authorId: null,
    edit: false,
  });
  const superOrder = useAppSelector(
    (state) => state.articles.articlesForm.main.superOrder
  );
  const handleChangeTab = (item: any) => {
    dispatch(articlesActions.changeActiveTab(item));
    window.scrollTo(0, 0);
  };
  const { id } = useParams();
  const [isZakrep, setIsImportant] = useState(false);
  const [changeDate, setChangeDate] = useState(false);
  useEffect(() => {
    if (superOrder && superOrder > 0) {
      setIsImportant(true);
    }
  }, [superOrder]);

  useEffect(() => {
    if (Number(id)) {
      dispatch(getArticleSources(Number(id)));
      dispatch(fetchSingleArticle(Number(id)));
      setIsCreatingOrEditing({
        id: Number(id),
        authorId: article.author?.id,
        edit: true,
      });
    } else {
      setIsCreatingOrEditing({ id: null, authorId: null, edit: false });
    }
    return () => {
      dispatch(articlesActions.resetForm());
      dispatch(articlesActions.changeActiveTab('main'));
      setIsCreatingOrEditing({ id: null, authorId: null, edit: false });
    };
  }, []);

  const handleFinalSubmitEditArticleForm = async (
    data: any,
    withPublication?: any
  ) => {
    if (!data) return;
    try {
      const date = data?.publishedAt || Date.now();

      const finalObjectMain: EditArticleObject = {
        title: data?.title,
        body: data?.body,
        image: data?.image,
        authorId: data?.authorId ? data.authorId : authorId ? authorId : null,
        publishedAt: isDate(date) ? formatISO(date) : formatISO(new Date(date)),
        superOrder: isZakrep ? 1 : 0,
        formatId: formatOptions?.find((item) => item?.id === data?.format.id)
          ?.id,
      };
      finalObjectMain.image = (await uploadImg([data?.image]))[0];

      if (
        isCreatingOrEditing &&
        isCreatingOrEditing.edit &&
        !pathname.includes('create-article')
      ) {
        await dispatch(
          editSingleArticle({
            id: Number(isCreatingOrEditing.id),
            formData: finalObjectMain,
          })
        );
      }

      const finalObjectAuthor: AuthorData = {
        name: data.name || '',
        positionAtWork: data.positionAtWork || '',
        avatar: null,
      };

      if (data?.avatar?.[0]) {
        finalObjectAuthor.avatar = (await uploadImg([data?.avatar?.[0]]))[0];
      }

      const formData: IArticleAction = { sources };
      if (sourcesInit.length > 0) {
        dispatch(
          deleteSources({
            articleId: Number(isCreatingOrEditing.id) || Number(id),
            //@ts-ignore
            sources: { ids: sourcesInit.map((item) => item.id) },
          })
        );
      }

      if (
        sources.length > 0 &&
        !(
          sources.length === 1 &&
          sources[0].title === '' &&
          sources[0].link === ''
        )
      ) {
        await dispatch(addArticlesSources({ articleId: Number(id), formData }));
      }
      const topicsToDelete = topicInitIds.filter((v) =>
        data?.topics?.map((t: any) => t.id).includes(v)
      );
      if (topicsToDelete?.length) {
        await dispatch(
          removeTopics({
            articleId: Number(id),
            data: {
              ids: topicsToDelete,
            },
          })
        );
      }

      const topicsToAdd = data?.topics?.map((item: any) => item.id);
      if (topicsToAdd?.length) {
        await dispatch(
          addTopics({
            articleId: Number(id),
            data: { ids: topicsToAdd },
          })
        );
      }

      if (withPublication) {
        await dispatch(
          changePublicationType({
            articleId: Number(isCreatingOrEditing.id) || Number(id),
            type: 'publish',
            status: 'first',
          })
        );
      }

      if (
        !authorId &&
        finalObjectAuthor.name.length > 0 &&
        finalObjectAuthor.positionAtWork.length > 0
      ) {
        await dispatch(addAuthor({ formData: finalObjectAuthor })).then(
          (res) => {
            //@ts-ignore
            dispatch(
              editSingleArticle({
                //@ts-ignore
                id: Number(id),
                formData: {
                  ...finalObjectMain,
                  //@ts-ignore
                  authorId: res.payload.id,
                },
              })
            );
          }
        );
      }

      if (authorId) {
        await dispatch(
          editAuthor({
            id: Number(data.authorId) || Number(authorId),
            formData: finalObjectAuthor,
          })
        );
      }

      if (
        authorId &&
        watch('name').length === 0 &&
        watch('positionAtWork').length === 0 &&
        !watch('avatar')
      ) {
        await dispatch(
          editSingleArticle({
            id: Number(id),
            formData: {
              ...finalObjectMain,
              authorId: null,
            },
          })
        );
      }

      if (
        isCreatingOrEditing.edit &&
        ((watch('name').length > 0 && !watch('positionAtWork').length) ||
          (!watch('name').length && watch('positionAtWork').length > 0))
      ) {
        dispatch(
          setNotification({
            value: 'Отсутвует должность/имя автора',
            variant: 'error',
          })
        );
        return;
      }

      window.open('/articles', '_self');
    } catch (error) {
      dispatch(
        setNotification({
          value: 'Ошибка при сохранении',
          variant: 'error',
        })
      );
      console.error(error);
    } finally {
      setChangeDate(() => false);
    }
  };

  return {
    handleFinalSubmitEditArticleForm,
    isZakrep,
    setIsImportant,
    changeDate,
    setChangeDate,
    handleChangeTab,
    isCreatingOrEditing,
  };
};
