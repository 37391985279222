import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const AuthCodePageView = ({
  handleSubmit,
  handleFormSubmit,
  register,
  setCode,
  errors,
  setEmail,
}: any) => {
  return (
    <>
      <div>Подтверждение кода</div>
      <div className='login-container wrapper'>
        <Container maxWidth='sm'>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box mb={2}>
              <TextField
                className='input-value'
                variant='outlined'
                label='Email'
                fullWidth
                autoFocus
                data-testid={'input-email'}
                autoComplete='Email'
                {...register('email', {
                  onChange: (e: any) => {
                    setEmail(e.target.value);
                  },
                  require: 'Введите Email',
                })}
                error={!!errors?.email}
              />
            </Box>
            <Box mb={2}>
              <TextField
                className='input-value'
                variant='outlined'
                label='Код'
                data-testid={'input-code'}
                fullWidth
                autoFocus
                autoComplete='Код'
                type='password'
                {...register('code', {
                  onChange: (e: any) => {
                    setCode(e.target.value);
                  },
                  required: 'Введите код',
                })}
                error={!!errors?.code}
              />
            </Box>
            <Button
              data-testid={'button-login'}
              type='submit'
              variant='contained'
              color='primary'
            >
              Войти
            </Button>
          </form>
        </Container>
      </div>
    </>
  );
};

export default AuthCodePageView;
