import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import DropInput from '@/shared/ui/DropInput/DropInput';

export const AttachmentInput = () => {
  const dispatch = useAppDispatch();
  const { attachments } = useAppSelector(
    (state) => state.marketplace.courseForm.form
  );
  const onChange = async ([files]: File[][]) => {
    const filesToAdd = files.map((file) => ({ file: file, name: file.name }));
    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'attachments',
        value: [...(attachments || []), ...filesToAdd],
      })
    );
  };
  const onDelete = (index: number) => {
    const sliced = attachments
      ?.filter((_, i) => i !== index)
      .map((file, index) => ({
        file: file.file,
        name: file.name,
        order: index,
      }));
    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'attachments',
        value: sliced,
      })
    );
  };

  return (
    <DropInput
      label='attachment'
      htmlFor={'attachment'}
      format='dropzone'
      title={'Вложение'}
      sizes=''
      data-testid={'dropzone-attachment'}
      required
      name='attachment'
      id='attachment'
      config={{
        accept: {
          'application/pdf': ['.pdf'],
        },
      }}
      files={attachments?.map((v) => v.file)}
      names={attachments?.map((v) => v.name.trim().substring(0, 35))}
      setFiles={onChange}
      withIcon
      onDelete={onDelete}
    />
  );
};
