import { reviewsActions } from '@/entities/reviews/model/reviewsSlice';
import { statisticsActions } from '@/entities/statistics/model/statisticsSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import * as UI from '@/shared/ui';
import cn from 'classnames';
import { useRef } from 'react';
import ReviewCard from 'src/pages/Review/sub-pages/views/ReviewCard';
import Loading from 'src/pages/Review/views/Loading/Loading';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as ArrowLeftEnd } from '../../../assets/images/ArrowSwiperLeftEnd.svg';
import { ReactComponent as ArrowRight } from '../../../assets/images/ArrowSwiperRight.svg';
import StatCards from './StatCards/StatCards';

// eslint-disable-next-line import-helpers/order-imports
import {
  deleteReview,
  publicateReview,
  sendOnModerationReview,
} from '@/entities/reviews/model/reviewsThunk';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

SwiperCore.use([Autoplay]);
export const TopBar = () => {
  const isLoading = useAppSelector((state) => state.reviews.isLoading);
  const dispatch = useAppDispatch();
  const swiperRef = useRef(null);
  const reviews = useAppSelector((state) => state.reviews.reviews);
  const isLoadingStat = useAppSelector((state) => state.statistics.loading);
  const handleDeleteReview = (id: number) => {
    dispatch(deleteReview(id)).then(() => {
      dispatch(setNotification({ value: 'Отзыв удалён', variant: 'success' }));
      dispatch(reviewsActions.closeDeleteModal());
    });
  };

  const handleSendOnModeration = (id: number) => {
    dispatch(sendOnModerationReview(id)).then(() => {
      dispatch(
        setNotification({
          value: 'Отзыв отправлен на модерацию',
          variant: 'success',
        })
      );
    });
  };

  const handlePublicateReview = (id: number) => {
    dispatch(publicateReview(id)).then(() => {
      dispatch(
        setNotification({ value: 'Отзыв опубликован', variant: 'success' })
      );
    });
  };

  const sortValues = useAppSelector((state) => state.statistics.sortInit);
  const activeSortType = useAppSelector((state) => state.statistics.sort);
  return (
    <>
      <div className='mb-[40px] flex h-[56px] items-center justify-between'>
        <div className='flex h-full items-center justify-center gap-8'>
          <UI.SectionTitle title='Статистика' />
        </div>
      </div>
      <div className='mb-[20px] flex gap-[4px]'>
        {sortValues.map((item) => (
          <div
            data-testid={`button-change_sort-${item.value}`}
            key={item.value}
            className={cn(
              'flex cursor-pointer items-center justify-center gap-2 rounded-[1000px] border border-[black] py-[7px] pl-4 pr-3.5',
              item.type === activeSortType.type
                ? 'bg-[black] text-[white]'
                : 'bg-white text-black'
            )}
            onClick={() => dispatch(statisticsActions.changeSort(item))}
          >
            <p className='text-base font-normal not-italic leading-[22px]'>
              {item.secondLabel}
            </p>
          </div>
        ))}
      </div>
      <div
        className={`flex flex-col ${
          isLoadingStat ? 'gap-[40px]' : 'gap-[80px]'
        }`}
      >
        <StatCards />
        {isLoading && (
          <div>
            <div className='mb-[40px] flex items-center justify-between gap-0'>
              <UI.SectionTitle title='Отзывы' />
            </div>
            <Loading isSwiper={true} />
          </div>
        )}
        <div className=''>
          {reviews.length > 1 && (
            <div className='mb-[40px] flex items-center justify-between gap-0'>
              <UI.SectionTitle title='Отзывы' />
              <div>
                <button
                  data-testid={'button-slide_prev'}
                  onClick={() => swiperRef.current.slidePrev()}
                >
                  <ArrowLeftEnd />
                </button>
                <button
                  data-testid={'button-slide_next'}
                  onClick={() => swiperRef.current.slideNext()}
                >
                  <ArrowRight />
                </button>
              </div>
            </div>
          )}
          {Array.isArray(reviews) && reviews.length !== 0 ? (
            <Swiper
              slidesPerView={3}
              spaceBetween={24}
              loop={true}
              speed={150}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              className={cn('mySwiper')}
            >
              {reviews?.map((elem) => (
                <SwiperSlide key={elem.id}>
                  <ReviewCard
                    id={elem.id}
                    isModerated={elem.isModerated}
                    comment={elem.comment}
                    key={elem.id}
                    name={elem.name ? elem.name : '-'}
                    date={elem.createdAt}
                    company={elem.company}
                    product={elem.product}
                    onDelete={handleDeleteReview}
                    onModeration={handleSendOnModeration}
                    onPublicate={handlePublicateReview}
                    rate={elem.rate}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default TopBar;
