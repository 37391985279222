import React, { ReactNode } from 'react';

type Props = {
  htmlFor: string;
  title?: string;
  children?: ReactNode;
  required?: boolean;
  action?: ReactNode;
  className?: string;
};

const FormLabel = ({
  htmlFor,
  title,
  children,
  required,
  action,
  className,
}: Props) => {
  return (
    <label
      htmlFor={htmlFor}
      className={`relative flex w-[100%] justify-between text-xl font-medium not-italic leading-[26px] text-[color:var(--Simple-Night,#000)] ${className}`}
    >
      {(title || children) && (
        <div>
          {title || children}&nbsp;
          {required && (
            <span className='text-xl font-medium not-italic leading-[26px] text-[color:var(--Simple-Red-Day,#EC3333)]'>
              *
            </span>
          )}
        </div>
      )}
      {action && action}
    </label>
  );
};

export default FormLabel;
