export function generateRandomString(
  length: number,
  characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
): string {
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}
