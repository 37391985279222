import sx from 'classnames';
import React from 'react';
import '../some.css';

type Props = {
  expanded: boolean;
  truncatedComment: string[];
  fadedWord: string;
  comment: string;
  notFadedText: string;
  isTrunc: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
};

const Comment = ({
  expanded,
  truncatedComment,
  comment,
  notFadedText,
  fadedWord,
  setExpanded,
  isTrunc,
}: Props) => {
  return (
    <div
      className={sx(
        'mb-6 max-w-[100%] overflow-hidden break-words text-lg font-normal not-italic leading-6'
      )}
    >
      {expanded || !isTrunc ? (
        <div className='relative'>{comment}</div>
      ) : (
        <div>
          <p>
            {notFadedText}
            <span className={sx('lastWord')}>
              {fadedWord.split('').reverse().join('')}
            </span>
            {!expanded && isTrunc && (
              <span
                data-testid={'button-show_more'}
                onClick={() => setExpanded(true)}
                className={`relative cursor-pointer text-lg font-normal not-italic leading-6 text-[#337CEC] ${sx(
                  'expandButton'
                )}`}
                style={{ zIndex: 0 }}
              >
                Еще
              </span>
            )}
          </p>
        </div>
      )}
    </div>
  );
};

export default Comment;
