import { useCheckImagesSizes } from '@/shared/lib/hooks/useCheckImageSizes';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import DropInput from '@/shared/ui/DropInput/DropInput';
import { Select } from '@/shared/ui/Select/Select';
import { TextArea } from '@/shared/ui/TextArea/TextArea';
import FormCard from '@/widgets/FormCard';

export const SingleRule = ({
  name,
  img,
  ageId,
  index,
  itemsCount,
  onChange,
  onSwap,
  onDelete,
  id,
}: any) => {
  const checkImages = useCheckImagesSizes();
  const dispatch = useAppDispatch();
  const { ages } = useAppSelector((state) => state.foods);
  return (
    <FormCard
      index={index}
      itemsCount={itemsCount}
      key={id}
      onDelete={() => onDelete(id)}
      onSwap={onSwap}
    >
      <div className='flex flex-col gap-[32px]'>
        <DropInput
          files={img}
          setFiles={async ([files]: any) => {
            const err = await checkImages({
              files: files,
              minHeight: 750,
              minWidth: 750,
            });
            if (err) {
              dispatch(
                setNotification({
                  variant: 'error',
                  value: 'Ошибка при загрузке (маленький размер)',
                })
              );
              return;
            }
            onChange({
              value: files,
              index,
              key: 'img',
            });
          }}
          withIcon
          sizes={'(750x750 px)'}
          // onDelete={() => onClear('image')}
          label='image'
          htmlFor={'image'}
          format='dropzone'
          title={'Фото'}
          // setValue={setValue}
          required
          name='image'
          id='image'
          onDelete={onDelete}
        />
        <Select
          name={'age'}
          data-testid={'select-age'}
          id={'age'}
          selected={ages.find((a) => a.id === ageId)}
          values={ages}
          handleSelect={
            (value) => {
              onChange({
                value: value.id,
                index,
                key: 'ageId',
              });
            }
            // dispatch(
            // //   productsActions.changeProducts({
            // //     key: 'formats',
            // //     value: [value],
            // //   })
            // )
          }
          label={'Возраст'}
          placeholderClassName={ageId ? '' : 'text-gray-300'}
          containerClassName='w-[428px]'
          placeholder={
            ageId ? ages.find((a) => a.id === ageId)?.title : 'Выберите'
          }
        />
        <TextArea
          data-testid={'textarea-description'}
          label={'Описание'}
          name={'description'}
          id={'description'}
          value={name}
          required={false}
          length={0}
          onChange={(e) =>
            onChange({
              value: e.target.value,
              index,
              key: 'name',
            })
          }
        />
      </div>
    </FormCard>
  );
};
