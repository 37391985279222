import React from 'react';
export const useOutsideClick = (
  callback: () => void,
  excludedIds: string[] = []
) => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !excludedIds.includes(event.target.id)
      ) {
        callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};
