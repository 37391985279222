import React from 'react';

type Props = {
  status: Variant;
  className?: string;
};

export type Variant = { id: number; title: string };

const variantStyles: Record<Variant['id'], { style: string; text: string }> = {
  3: { style: 'bg-[#EDF7D1] text-[#1BC47D]', text: 'Опубликовано' },
  1: { style: 'bg-[#F1EEEA] text-[#515151]', text: 'Черновик' },
  2: { style: 'bg-[#FFEFE2] text-[#FF9800]', text: 'Скрыто' },
};

const Status = ({ status, className }: Props) => {
  return (
    <span
      className={`flex items-start gap-2.5 rounded-[8px] px-3 pt-1 pb-1.5 text-base font-normal not-italic leading-[22px] ${
        variantStyles[status.id].style
      } ${className && className}`}
    >
      {variantStyles[status.id].text}
    </span>
  );
};

export default Status;
