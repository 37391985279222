import { ChangeFrequentQuestions } from '@/entities/marketplace/model/marketplaceSlice';
import { IFormCardProps } from '@/entities/marketplace/types/IFormCardProps';
import { FC } from 'react';
import FormCard from 'src/widgets/FormCard';

import { Input } from '../../../../../shared/ui/Input/SecondInput';
import { TextArea } from '../../../../../shared/ui/TextArea/TextArea';

interface Props extends IFormCardProps<ChangeFrequentQuestions> {
  question: string;
  answer: string;
}

export const SingleQuestion: FC<Props> = ({
  onChange,
  onDelete,
  onSwap,
  question,
  id,
  index,
  itemsCount,
  answer,
}) => {
  return (
    <FormCard
      index={index}
      itemsCount={itemsCount}
      key={id}
      onDelete={() => onDelete(id)}
      onSwap={onSwap}
    >
      <Input
        data-testid={`input-question-${index}`}
        label='Вопрос'
        value={question}
        onChange={(e) =>
          onChange({
            value: e.target.value,
            index,
            key: 'question',
          })
        }
      />
      <TextArea
        data-testid={`textarea-answer-${index}`}
        label='Ответ'
        value={answer}
        onChange={(e) =>
          onChange({
            value: e.target.value,
            index,
            key: 'answer',
          })
        }
      />
    </FormCard>
  );
};
