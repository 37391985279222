import promocodesService from '@/entities/promocodes/api';
import { IFormPromocode } from '@/entities/promocodes/types/IPromocodeForm';
import { convertDateFormat } from '@/shared/lib/formatDateAndTime';
import { tryCatch } from '@/shared/lib/tryCatch';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getAllPromocodes = createAsyncThunk(
  'promocodes/getAllPromocodes',
  async (
    {
      search,
      endDate,
      usageCounter,
      limit,
      page,
    }: {
      search?: string;
      endDate?: 'ASC' | 'DESC';
      usageCounter?: 'ASC' | 'DESC';
      limit?: number;
      page: number;
    },
    { rejectWithValue }
  ) => {
    const [data, err] = await tryCatch(
      promocodesService.getAllPromocodes(
        search,
        endDate,
        usageCounter,
        limit,
        page
      )
    );
    if (err)
      throw rejectWithValue(`Ошибка в получении промокодов ${err.status}`);
    return { data, page };
  }
);

export const getAllPromocodesTypes = createAsyncThunk(
  'promocodes/getAllPromocodesTypes',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch(
      promocodesService.getAllPromocodeTypes()
    );
    if (err)
      throw rejectWithValue(
        `Ошибка в получении типов промокодов ${err.status}`
      );
    return data;
  }
);

export const getAllPromocodesAccessTypes = createAsyncThunk(
  'promocodes/getAllPromocodesAccessTypes',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch(
      promocodesService.getAllPromocodeAccessTypes()
    );
    if (err)
      throw rejectWithValue(
        `Ошибка в получении типов доступов промокодов ${err.status}`
      );
    return data;
  }
);

export const createOrEditPromocode = createAsyncThunk(
  'promocodes/createPromocode',
  async (
    {
      formData,
      isEdit,
      id,
    }: { formData: IFormPromocode; isEdit?: boolean; id?: number },
    { rejectWithValue }
  ) => {
    const endDate = formData.endDate as Date;
    const fd = {
      ...formData,
      productsId: formData.productsId.length ? formData.productsId : null,
      endDate: endDate
        ? convertDateFormat(
            new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
          )
        : null,
    };
    const [data, err] = await tryCatch(
      isEdit
        ? promocodesService.editPromococode(fd, id)
        : promocodesService.createPromocode(fd)
    );
    if (err) {
      console.log(err);
      throw rejectWithValue(
        `Ошибка в ${isEdit ? 'изменении' : 'создании'} промокода ${err.status}`
      );
    }

    return data;
  }
);

export const editPromocode = createAsyncThunk(
  'promocodes/editPromocode',
  async (
    { formData, id }: { formData: IFormPromocode; id: number },
    { rejectWithValue }
  ) => {
    const endDate = formData.endDate as Date;
    const fd = {
      ...formData,
      productsId: formData.productsId.length ? formData.productsId : null,
      endDate: convertDateFormat(
        new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000)
      ),
    };
    const [data, err] = await tryCatch(
      promocodesService.editPromococode(fd, id)
    );
    if (err)
      throw rejectWithValue(`Ошибка в изменении промокода ${err.status}`);
    return data;
  }
);

export const deletePromocode = createAsyncThunk(
  'promocodes/deletePromocode',
  async ({ id }: { id: number }, { rejectWithValue }) => {
    const [data, err] = await tryCatch(promocodesService.deletePromococode(id));
    if (err) throw rejectWithValue(`Ошибка в удалении промокода ${err.status}`);
    return data;
  }
);
