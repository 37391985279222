import { reviewsActions } from '@/entities/reviews/model/reviewsSlice';
import useFilterVisibility from '@/shared/lib/hooks/useFilterVisibility';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import ActiveFilters from '@/shared/ui/ActiveFilters/ActiveFilters';
import { FilterSelect, FilterSelectType } from '@/shared/ui/Filters';
import Status from '@/shared/ui/Filters/Status';
import { Search } from '@/shared/ui/Search/Search';
import SortSelect from '@/shared/ui/Sort/SortSelect';
import { SortValues } from '@/shared/ui/Sort/SortValues';
import cx from 'classnames';

export const Areas = () => {
  const {
    isFilterVisible,
    filterSelectOptions,
    handleMouseEnter,
    handleMouseLeave,
  } = useFilterVisibility();

  const {
    isFilterVisible: isSortVisible,
    filterSelectOptions: isFilterSelectOptions,
    handleMouseEnter: handleOptionsMouseEnter,
    handleMouseLeave: handleOptionsMouseLeave,
  } = useFilterVisibility();
  const filterValues = useAppSelector((state) => state.reviews.filtersInit);
  const sortValues = useAppSelector((state) => state.reviews.sortInit);
  const filters = useAppSelector((state) => state.reviews.filters);
  const activeSort = useAppSelector((state) => state.reviews.sort);
  const dispatch = useAppDispatch();
  const handleAddFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    v: { label: string; value: number; param: boolean }
  ) => {
    dispatch(reviewsActions.addFilter(v));
    handleMouseLeave();
  };
  const handleRemoveFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    v: FilterSelectType['value']
  ) => {
    event.stopPropagation();
    dispatch(reviewsActions.removeFilter(v));
  };

  const handleRemoveAllFilters = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    dispatch(reviewsActions.removeAllFilter());
  };

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    { label, value }: { label: string; value: number | string }
  ) => {
    dispatch(reviewsActions.changeSort({ label, value }));
    handleOptionsMouseLeave();
  };

  return (
    <div className='mb-[24px] flex flex-col'>
      <div className='flex items-center gap-1'>
        <div
          className='relative flex flex-col'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Status onClick={() => {}} ref={filterSelectOptions} />
          {isFilterVisible && (
            <FilterSelect
              ref={filterSelectOptions}
              data={filterValues}
              handleAddFilter={handleAddFilter}
            />
          )}
        </div>
        <div className='mr-[24px] w-[100%]'>
          <Search />
        </div>
        <div
          className='relative flex flex-col'
          onMouseEnter={handleOptionsMouseEnter}
          onMouseLeave={handleOptionsMouseLeave}
        >
          <SortSelect
            onClick={() => {}}
            ref={isFilterSelectOptions}
            isSortVisible={isSortVisible}
            activeSort={activeSort}
          />
          {isSortVisible && (
            <SortValues
              data={sortValues}
              ref={isFilterSelectOptions}
              handleClick={handleClick}
            />
          )}
        </div>
      </div>
      <div className={cx('flex gap-1', filters.length > 0 && 'mt-[24px]')}>
        <ActiveFilters
          activeFilters={filters}
          handleRemoveFilter={handleRemoveFilter}
          handleRemoveAllFilters={handleRemoveAllFilters}
        />
      </div>
    </div>
  );
};
