import { editStory } from '@/entities/categories/model/categoriesSlice';
import { IStory } from '@/entities/stories/types/IStory';
import { UISelect } from '@/pages/Banners/components/UISelect';
import { setState } from '@/shared/interfaces/common/setState';
import { toDateInputValue } from '@/shared/lib/toDateInputValue';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { ShowUntil } from '@/shared/ui/ShowUntil/ShowUntil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  modalOpen: boolean;
  setModalOpen: setState<boolean>;
  story: IStory;
}

interface Form {
  title: string;
  link: string;
  image?: FileList;
  categoryId: number;
  articleId: number;
  showUntil: string;
}

export const EditStoryModal: FC<Props> = ({
  modalOpen,
  setModalOpen,
  story,
}) => {
  const selectCategories = useAppSelector(
    (state) => state.categories.selectCategories
  );
  const selectArticles = useAppSelector(
    (state) => state.articles.selectArticles
  );
  const [loading, setLoading] = useState(false);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<Form>({
    values: {
      articleId: story.articleId,
      categoryId: story.categoryId,
      link: story.outer_resource_link,
      showUntil: toDateInputValue(new Date(story.showUntil)),
      title: story.title,
    },
  });
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('articleId', data.articleId + '');
    formData.append('title', data.title);
    formData.append('image', data.image[0] || story.image);
    formData.append('outer_resource_link', data.link);
    formData.append('categoryId', data.categoryId + '');
    formData.append('showUntil', data.showUntil);
    await dispatch(editStory({ id: story.id, formData }));
    setLoading(false);
    setModalOpen(false);
    reset();
  });
  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth>
      <DialogTitle>Редактировать историю</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          onSubmit={onSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, py: 1 }}
        >
          <TextField
            data-testid={'input-title'}
            label='Название'
            error={errors.title !== undefined}
            {...register('title', { required: 'Введите название' })}
            helperText={errors.title?.message}
          />
          <TextField
            data-testid={'input-link'}
            label='Внешняя ссылка'
            {...register('link')}
          />
          <Controller
            control={control}
            name='categoryId'
            render={({ field }) => (
              <UISelect
                data-testid={'select-category'}
                label={'Выберите категорию'}
                {...field}
                items={selectCategories}
              />
            )}
          />
          <Controller
            control={control}
            name='articleId'
            render={({ field }) => (
              <UISelect
                data-testid={'select-article'}
                label={'Выберите статью'}
                {...field}
                items={selectArticles}
              />
            )}
          />
          <ShowUntil
            data-testid={'input-show_until'}
            label={'Показывать до'}
            registerProps={register('showUntil')}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} />}
        <Button
          type='submit'
          data-testid={'button-edit'}
          variant='contained'
          disabled={loading}
          onClick={onSubmit}
        >
          Редактировать
        </Button>
        <Button
          data-testid={'button-cancel'}
          onClick={() => setModalOpen(false)}
          color='warning'
          variant='outlined'
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
