import { reviewsActions } from '@/entities/reviews/model/reviewsSlice';
import { deleteReview } from '@/entities/reviews/model/reviewsThunk';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { useState } from 'react';
import CloseDelete from 'src/assets/svg/closeDelete';

const DeleteModal = () => {
  const dispatch = useAppDispatch();
  const [isHovered, setIsHovered] = useState(false);

  const id = useAppSelector((state) => state.reviews.isDeleteModal.id);

  const deletingReview = (id: number) => {
    dispatch(deleteReview(id));
    dispatch(setNotification({ value: 'Отзыв удалён', variant: 'success' }));
    dispatch(reviewsActions.closeDeleteModal());
  };

  return (
    <>
      <div className='fixed top-0 left-0 z-40 h-screen w-screen bg-black opacity-30'></div>
      <div className='fixed top-1/2 left-1/2 z-40 flex w-[704px] -translate-x-1/2 -translate-y-1/2 transform flex-col items-center gap-8 rounded-2xl bg-white p-14'>
        <h1 className='text-center text-[32px] font-medium not-italic leading-10'>
          Удалить отзыв?
        </h1>

        <div className='flex flex-col items-center'>
          <button
            data-testid={`button-delete-${id}`}
            className='mb-[24px] flex h-[80px] w-[360px] items-center justify-center gap-[10px] rounded-[100px] bg-[#EC3333] px-[48px] pt-[26px] pb-[28px] text-white hover:bg-[#BD2929]'
            onClick={() => deletingReview(id)}
          >
            Удалить
          </button>
          <button
            data-testid={'button-cancel'}
            className='text-center text-base font-normal not-italic leading-5 text-[#337CEC] hover:text-[#1B56B1]'
            onClick={() => dispatch(reviewsActions.closeDeleteModal())}
          >
            Отмена
          </button>
        </div>

        <div
          className='hover:-[#E1E1E1] absolute top-0 right-0 m-4 cursor-pointer'
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => dispatch(reviewsActions.closeDeleteModal())}
        >
          <CloseDelete isHovered={isHovered} />
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
