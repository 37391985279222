import { foodsActions as actions } from '@/entities/foods';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Select } from '@/shared/ui/Select/Select';

export const SelectAge = () => {
  const dispatch = useAppDispatch();
  const { age } = useAppSelector((state) => state.foods.form.main);

  const { ages } = useAppSelector((state) => state.foods);
  return (
    <Select
      name={'age'}
      data-testid={'select-age'}
      id={'age'}
      selected={age}
      required
      values={ages}
      handleSelect={(value) =>
        dispatch(
          actions.changeRecipesForm({
            key: 'age',
            value: value,
          })
        )
      }
      label={'Возраст'}
      placeholderClassName={age ? '' : 'text-gray-300'}
      containerClassName='w-[428px]'
      placeholder={age ? age.title : 'Выберите'}
    />
  );
};
