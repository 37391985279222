import { Change, ChangeForm } from '@/shared/interfaces/types';
import { swapArr } from '@/shared/lib/spawArr';
import { SelectProps } from '@/shared/ui/Select';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IRecommendation, IRule, ISlice } from '../types';
import {
  deleteSingle,
  fetchAll,
  fetchForEdit,
  fetchStatic,
  updateOrCreate,
} from './thunk';

const initRecommendation: IRecommendation = {
  text: '',
  order: 0,
  id: 0,
};
const initRule: IRule = {
  img: undefined,
  ageId: undefined,
  name: '',
  order: 0,
  id: 0,
};

export type ChangeRules = Change<IRule, IRule[keyof IRule]>;
export type ChangeRecommendations = Change<IRecommendation, string>;
const initialState: ISlice = {
  foods: [],
  isInit: {
    cover: false,
  },
  filters: {
    search: '',
    category: null,
    age: null,
    isMarked: null,
  },
  ages: [],
  categories: [],
  measures: [],
  activeTab: 'main',
  form: {
    initRules: [initRule],
    initRecommendations: [initRecommendation],
    isValid: true,
    isLoading: false,
    formDirty: false,
    main: {
      image: undefined,
      color: undefined,
      category: undefined,
      age: undefined,
      measure: undefined,
      title: '',
      description: '',
    },
    recs: [initRecommendation],
    rules: [initRule],
  },
};

type FormType = ISlice['form']['main'];
type FiltersType = ISlice['filters'];

const slice = createSlice({
  name: 'foods',
  initialState,
  reducers: {
    resetForm: (state) => {
      state.form = initialState.form;
      state.activeTab = initialState.activeTab;
    },
    changeRecipesForm(state, action: PayloadAction<ChangeForm<FormType>>) {
      const { key, value } = action.payload;
      // @ts-ignore
      state.form.main[key] = value;
      state.form.formDirty = true;
    },
    changeFilters(state, action: PayloadAction<ChangeForm<FiltersType>>) {
      const { key, value } = action.payload;
      // @ts-ignore
      state.filters[key as keyof FormType] = value;
      state.form.formDirty = true;
    },
    changeActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    deleteRec(state, action: PayloadAction<number>) {
      state.form.recs = state.form.recs.filter(
        (elem) => elem.id !== action.payload
      );
      state.form.formDirty = true;
    },

    onSwapRecs(
      state,
      action: PayloadAction<{ index: number; destIndex: number }>
    ) {
      const { index, destIndex } = action.payload;
      swapArr(index, destIndex, state.form.recs);
      state.form.formDirty = true;
    },

    addRecs(state) {
      const newContent = {
        ...initRecommendation,
        id: state.form.recs.length + 999,
      };
      state.form.formDirty = true;
      state.form.recs.push(newContent);
    },

    changeRecs(state, action: PayloadAction<ChangeRecommendations>) {
      const { index, key, value } = action.payload;
      // @ts-ignore
      state.form.recs[index][key] = value;
      state.form.formDirty = true;
    },

    deleteRule(state, action: PayloadAction<number>) {
      state.form.rules = state.form.rules.filter(
        (elem) => elem.id !== action.payload
      );
      state.form.formDirty = true;
    },

    onSwapRules(
      state,
      action: PayloadAction<{ index: number; destIndex: number }>
    ) {
      const { index, destIndex } = action.payload;
      swapArr(index, destIndex, state.form.rules);
      state.form.formDirty = true;
    },

    addRules(state) {
      const newContent = {
        ...initRule,
        id: state.form.rules.length + 999,
      };
      state.form.formDirty = true;
      state.form.rules.push(newContent);
    },

    changeRules(state, action: PayloadAction<ChangeRules>) {
      const { index, key, value } = action.payload;
      // @ts-ignore
      state.form.rules[index][key] = value;
      state.form.formDirty = true;
    },

    checkCanSubmitForm(state) {
      const {
        form: {
          main: { color, image, title, category, age, measure, description },
          rules,
          recs,
        },
      } = state;
      if (
        color &&
        image?.length &&
        title.trim() &&
        category &&
        age &&
        measure &&
        description.trim() &&
        rules.length > 0 &&
        recs.length > 0
      ) {
        state.form.isValid = true;
        return;
      }

      state.form.isValid = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAll.fulfilled, (state, action) => {
      state.foods = action.payload.map((food) => ({
        img: food.image,
        id: food.id,
        title: food.name,
        category: {
          id: food.category.id,
          title: food.category.name,
        } as SelectProps,
        age: food.fromWhatAge.name,
        isMark: food.possibleAllergy,
      }));
    });
    builder.addCase(fetchStatic.fulfilled, (state, action) => {
      const mapToSelect = (arr: any[]): SelectProps[] => {
        return arr.map((elem) => ({
          title: elem.name,
          id: elem.id,
        }));
      };
      const { ages, measures, categories } = action.payload;

      state.categories = categories.length > 0 ? mapToSelect(categories) : [];
      state.ages = ages.length > 0 ? mapToSelect(ages) : [];
      state.measures = measures.length > 0 ? mapToSelect(measures) : [];
    });
    builder.addCase(deleteSingle.fulfilled, (state, action) => {
      state.foods = state.foods.filter((elem) => elem.id !== action.payload.id);
    });
    builder.addCase(fetchForEdit.fulfilled, (state, action) => {
      const { foods, recommendations, rules } = action.payload;

      state.form.recs =
        recommendations.length > 0 ? recommendations : [initRecommendation];
      state.form.initRecommendations = recommendations;
      state.form.rules =
        rules.length > 0
          ? (rules.map((rule: any) => ({
              img: [rule.image],
              ageId: rule.fromWhatAge.id,
              name: rule.name,
              order: rule.order,
              id: rule.id,
            })) as IRule[])
          : [initRule];
      state.form.initRules = rules;

      state.form.main = {
        image: foods.image ? [foods.image] : [],
        color: {
          id: foods?.backgroundColor,
          title: foods?.backgroundColor,
        },
        isMarked: !!foods?.possibleAllergy,
        category: foods?.category?.id
          ? {
              id: foods?.category?.id,
              title: foods?.category?.name,
            }
          : null,
        age: {
          id: foods?.fromWhatAge?.id,
          title: foods?.fromWhatAge?.name,
        },
        measure: {
          id: foods?.measure.id,
          title: foods?.measure.name,
        },
        title: foods?.name,
        description: foods?.description,
      };
    });
    builder.addCase(updateOrCreate.fulfilled, (state, action) => {});
  },
});
export const { actions, reducer } = slice;
