import deepEqual from 'deep-equal';
interface Obj1 {
  id: number;
  [key: string]: any;
}

interface Obj {
  value: Obj1;
  [key: string]: any;
}

export const getTouchedElemsFromFirstArray = <T extends Obj, R extends Obj1>(
  arr: T[],
  initialArray: R[],
  initialIds?: number[]
) => {
  const initIds = initialIds ?? initialArray.map(({ id }) => id);
  return arr.filter(({ value }) => {
    if (!initIds.includes(value.id)) {
      return false;
    }

    const initElem = initialArray.find((inElem) => inElem.id === value.id);
    return !deepEqual(initElem, value);
  });
};
