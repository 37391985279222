import { IBanner } from '@/entities/banners/types/IBanner';
import { setState } from '@/shared/interfaces/common/setState';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';

import { AddBannerModal } from './components/AddBannerModal';
import BannerItem from './components/BannerItem';

interface Props {
  banners: IBanner[];
  editOpen: boolean;
  setEditOpen: setState<boolean>;
}

const BannersView: FC<Props> = ({ banners, editOpen, setEditOpen }) => {
  return (
    <>
      <AddBannerModal modalVisible={editOpen} setModalVisible={setEditOpen} />
      <div className='container'>
        <div className='other'>
          <div className='buttons-block'>
            <div className='topbar-icons'>
              <Button
                data-testid={'button-open_edit'}
                variant='contained'
                onClick={() => setEditOpen(true)}
              >
                Создать
              </Button>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, cursor: 'pointer' }}
              size='medium'
              aria-label='a dense table'
            >
              <TableHead sx={{ background: '#5d8aa8' }}>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align='right'>Название</TableCell>
                  <TableCell align='right'>Время жизни баннера</TableCell>
                  <TableCell align='right'>Фото</TableCell>
                  <TableCell align='right'>Высота</TableCell>
                  <TableCell align='right'>Ссылка</TableCell>
                  <TableCell align='right'>Дата создания</TableCell>
                  <TableCell align='right'>Дата редактирования</TableCell>
                  {/*<TableCell align="right">Место для баннера</TableCell>*/}
                  <TableCell align='right'>Id Статьи</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {banners?.map((banner: IBanner) => (
                  <BannerItem {...banner} key={banner.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default BannersView;
