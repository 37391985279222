import { api } from '@/shared/api';
import {
  CompanyDto,
  CreationCompanyDto,
  CreationStaffDto,
  ListCompanyDto,
  ListStaffDto,
} from '@/shared/interfaces/generated';

class CompanyApi {
  createCompany(data: CreationCompanyDto) {
    return api.post<CompanyDto>('/companies', data);
  }

  updateCompany(id: number, data: CreationCompanyDto) {
    return api.patch<CompanyDto>(`/companies/${id}`, data);
  }

  publishCompany(id: number | string) {
    return api.post(`/companies/${id}:publish`);
  }

  hideCompany(id: number | string) {
    return api.post(`/companies/${id}:hide`);
  }

  getAllCompanies({
    status,
    search,
    createdAt,
  }: {
    status?: 1 | 2 | 3;
    search?: string;
    createdAt?: 'ASC' | 'DESC';
  }) {
    const params = new URLSearchParams();
    params.append('limit', '10000');
    if (status) {
      params.append('filters[publicationStatusId]', String(status));
    }
    if (search != null) {
      params.append('search', search.trim());
    }
    if (createdAt) {
      params.append('orderBy[createdAt]', createdAt);
    }
    return api.get<ListCompanyDto>('/companies', {
      params,
    });
  }

  getSingleCompany(id: number) {
    return api.get<CompanyDto>(`/companies/${id}`);
  }

  getStaffById(id: number) {
    return api.get<ListStaffDto>(`/companies/${id}/staff`);
  }

  createStaffBatch(id: number, data: CreationStaffDto[]) {
    return api.post(`/companies/${id}/staff:batchCreate`, { staff: data });
  }

  editStaffBatch(id: number, data: CreationStaffDto[]) {
    return api.post(`/companies/${id}/staff:batchUpdate`, { staff: data });
  }

  deleteStaffBatch(id: number, data: number[]) {
    return api.post(`/companies/${id}/staff:batchDelete`, { ids: data });
  }

  softDelete(id: number) {
    return api.delete(`/companies/${id}`);
  }
}

export const companyApi = new CompanyApi();
