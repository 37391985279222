import { fetchAllCourses } from '@/entities/marketplace/model/markeplaceThunks';
import { normalizePromocode } from '@/entities/promocodes/lib/normalizePromocode';
import { promocodesActions } from '@/entities/promocodes/model/promocodesSlice';
import {
  getAllPromocodes,
  getAllPromocodesAccessTypes,
  getAllPromocodesTypes,
} from '@/entities/promocodes/model/promocodesThunks';
import Skeletons from '@/pages/Statistics/Sceletons/Skeletons';
import { usePaginate } from '@/shared/lib/hooks/usePaginate';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import * as UI from '@/shared/ui';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import { useDebounce } from '@uidotdev/usehooks';
import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as Plus } from 'src/assets/svg/Plus.svg';

import { Promocode } from '../Product/Promocode';
import { PromocodeBar } from '../Product/PromocodeBar';
import { Areas } from './Areas';

export const TopBar = () => {
  const basicGrid =
    'flex h-14 items-center justify-start gap-8 self-stretch rounded-[16px] bg-[#FFF] px-6 py-4 border';

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { promocodes } = useAppSelector((state) => state.promocodes.promocodes);
  const isLoading = useAppSelector((state) => state.promocodes.loading.list);
  const search = useAppSelector((state) => state.promocodes.search);
  const filters = useAppSelector((state) => state.promocodes.filters);

  const debouncedSearch = useDebounce(search, 500);
  const { ref, page } = usePaginate(
    () =>
      dispatch(
        getAllPromocodes({
          search: debouncedSearch,
          endDate: filters.endDate,
          usageCounter: filters.usageCounter,
          limit: 40,
          page: page,
        })
      ),
    {
      initialPage: 1,
      deps: [debouncedSearch, filters],
      data: promocodes,
    }
  );

  useEffect(() => {
    dispatch(getAllPromocodesAccessTypes());
    dispatch(getAllPromocodesTypes());
    dispatch(fetchAllCourses());
  }, []);

  const handleOpenEditPromocode = (id: number) => {
    const promocode = promocodes.find((item) => item.id === id);
    dispatch(promocodesActions.setPromocodeForm(normalizePromocode(promocode)));
    navigate(`/promocodes/edit-promocode/${id}`);
  };

  return (
    <>
      <div className='align-center mb-12 flex h-[56px] justify-between'>
        <div className='flex h-full items-center gap-4'>
          <UI.SectionTitle title='Промокоды' />
          <Link to='/promocodes/create-promocode'>
            <div className={'relative'}>
              <Tooltip tooltipClassName='top-[-72px]' text='Добавить промокод'>
                <div className='200ms flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[50%] bg-[#337CEC] transition hover:bg-[#1B56B1]'>
                  <Plus />
                </div>
              </Tooltip>
            </div>
          </Link>
        </div>
      </div>
      <Areas search={search} />
      <PromocodeBar basicGrid={basicGrid} />
      {isLoading && (
        <div className='mt-[15px]'>
          <Skeletons type={1} />
        </div>
      )}
      {promocodes?.map((item, index) => {
        return (
          <Promocode
            onOpen={() => handleOpenEditPromocode(item.id)}
            index={index}
            amountUsers={item?.users?.length}
            key={item?.id}
            id={item?.id}
            length={promocodes?.length}
            discount={item?.discount}
            discountCurrency={item?.discountCurrency}
            products={item?.products}
            promocode={item?.title}
            usageCounter={item?.usageCounter}
            access={item?.accessType}
            activity={item?.endDate}
          />
        );
      })}
      {isLoading && promocodes?.length === 0 && (
        <div className='mt-[15px]'>
          <Skeletons type={1} />
        </div>
      )}
      {promocodes?.length !== 0 && <div ref={ref}></div>}
    </>
  );
};

export default TopBar;
