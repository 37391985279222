import { logout, signIn } from '@/entities/auth/model/authSlice';
import { routes } from '@/shared/consts/consts';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { removeCookie } from 'typescript-cookie';

import { SignUpTypes } from '../SignUpPage/SignUp-Types';
import SignInPageView from './SignInPage-View';

import './SingIn.css';

const SignInPageContainer = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const error = useAppSelector((state) => state.auth.error);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SignUpTypes>({ mode: 'onChange' });
  const onSubmit = async () => {
    dispatch(logout());
    const login = await dispatch(signIn({ email, password }));
    if (typeof login.payload === 'string') return;
    reset();
    // if (roles.includes('User') && !roles.includes('Admin')) {
    //   navigate(`/${routes.userRoutes.noRights}`);
    // } else {
    //   navigate(`/${routes.adminRoutes.statistics}`);
    //   init();
    window.location.reload();
  };

  const handleSignUp = () => {
    removeCookie('jwt');
    navigate(`/${routes.authRoutes.register}`);
  };
  return (
    <SignInPageView
      validationError={error}
      handleSubmit={handleSubmit}
      handleFormSubmit={onSubmit}
      register={register}
      setEmail={setEmail}
      setPassword={setPassword}
      errors={errors}
      handleSignUp={handleSignUp}
    />
  );
};

export default SignInPageContainer;
