// PostInfo.tsx

import { formatDateToDDMMYY } from '@/shared/lib/formatDate';
import React from 'react';

import RatingValue from '../../Rating';

interface PostInfoProps {
  name: string | undefined;
  date: Date;
  rate: number;
}

const PostInfo: React.FC<PostInfoProps> = ({ name, date, rate }) => {
  return (
    <div className='flex w-[101%] items-center justify-between whitespace-nowrap text-base font-normal not-italic leading-[22px] text-[#7D7D7D]'>
      <p>
        {name?.split(' ')[0]}, {formatDateToDDMMYY(date)}
      </p>
      <RatingValue value={rate} />
    </div>
  );
};

export default PostInfo;
