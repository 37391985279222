//@ts-ignore

import './Editor.css';

import FormLabel from '@/shared/ui/FormLabel/FormLabel';
import JoditEditor from 'jodit-react';
import { Config } from 'jodit/types/config';
import { FC } from 'react';
import { RefCallBack } from 'react-hook-form';

import { useJoditConfig } from './useJoditConfig';

interface Props {
  ref: RefCallBack;
  onChange: (arg: any) => void;
  value: string;
  htmlFor?: string;
  title?: string;
  required?: boolean;
  config?: Partial<Config>;
}

export const Editor: FC<Props> = ({
  ref,
  onChange,
  value,
  htmlFor,
  title,
  required,
  config,
}) => {
  const defaultConfig = useJoditConfig('...', config);
  return (
    <span className='flex w-full max-w-[888px] flex-col gap-[16px]'>
      <FormLabel
        htmlFor={htmlFor}
        title={title}
        required={required}
        className='self-start'
      />
      {/* @ts-ignore */}
      <JoditEditor
        data-testid={'jodit-editor'}
        ref={ref}
        value={value}
        // @ts-ignore
        config={defaultConfig}
        onChange={(newContent) => onChange(newContent)}
      />
    </span>
  );
};
