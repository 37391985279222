import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import {
  addYears,
  format,
  isDate,
  isValid,
  parseISO,
  subYears,
} from 'date-fns';

import { DateInput } from '../../../../../shared/ui/DatePicker/DatePicker';

const isIsoString = (str: string) => {
  const parsedDate = parseISO(str);
  return isValid(parsedDate);
};

export const getDate = (date: string | Date): Date => {
  if (isDate(date)) {
    return new Date(date);
  }
  // @ts-ignore
  if (isIsoString(date)) {
    return new Date(parseISO(date as string));
  }

  return date as Date;
};

export const DateBlock = () => {
  const date = useAppSelector(
    (state) => state.marketplace.courseForm.form.date
  );
  const dispatch = useAppDispatch();
  const handleSetDate = (v: Date) => {
    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'date',
        value: v,
      })
    );
  };
  const fixedDate = getDate(date);
  return (
    <div className={'flex flex-col gap-[32px]'}>
      <div className={'grid grid-cols-2 gap-x-[32px]'}>
        <DateInput
          data-testid={'input-date'}
          name={'date'}
          label={'Дата публикации'}
          minDate={subYears(new Date(), 100)}
          maxDate={addYears(new Date(), 100)}
          value={format(fixedDate, 'dd.MM.yyyy')}
          initValue={fixedDate}
          onChange={handleSetDate}
        />
        <div></div>
      </div>
    </div>
  );
};
