import { fetchBannerById } from '@/entities/banners/model/bannerSlice';
import { isEmptyObj } from '@/shared/lib/isEmptyObj';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SingleBannerView from './SingleBanner-View';

const SingleBannerContainer = () => {
  const { id } = useParams();
  const banner = useAppSelector((state) => state.banners.banner);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      await dispatch(fetchBannerById(+id));
      setLoading(false);
    })();
  }, []);
  if (loading || isEmptyObj(banner)) {
    return <CircularProgress />;
  }
  return (
    <SingleBannerView
      banner={banner}
      editModal={editModal}
      setEditModal={setEditModal}
      deleteModal={deleteModal}
      setDeleteModal={setDeleteModal}
    />
  );
};

export default SingleBannerContainer;
