import {
  fetchFormats,
  fetchTopics,
} from '@/entities/articles/model/articleThunks';
import {
  fetchAppPlaces,
  fetchBanners,
} from '@/entities/banners/model/bannerSlice';
import { fetchAllCategories } from '@/entities/categories/model/categoriesSlice';
import { fetchAllReviews } from '@/entities/reviews/model/reviewsThunk';
import {
  fetchAllRoles,
  fetchAllStatistics,
} from '@/entities/statistics/model/statisticsThunks';
import { useAppDispatch } from '@/shared/model';

export const useInitAfterAuth = () => {
  const dispatch = useAppDispatch();
  return () => {
    dispatch(fetchAllCategories());
    dispatch(fetchTopics());
    dispatch(fetchFormats());
    dispatch(fetchBanners());
    dispatch(fetchAllStatistics('day'));
    dispatch(fetchAppPlaces());
    dispatch(fetchAllRoles());
    dispatch(
      fetchAllReviews({
        limit: 10000,
        offset: 0,
        search: '',
        createdAd: 'DESC',
        rate: 'DESC',
      })
    );
  };
};
