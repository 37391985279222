export const routes = {
  adminRoutes: {
    statistics: 'statistics',
    categories: 'categories',
    articles: 'articles',
    stories: 'stories',
    banners: 'banners',
    category: 'categories/:id',
    reviews: 'reviews',
    story: 'stories/:id',
    recipe: 'recipes/:id',
    banner: 'banners/:id',
    directory: 'directory',
    promocodes: 'promocodes',
  },
  articles: {
    articles: 'articles',
    editArticle: 'articles/article/edit-article/:id',
    createArticle: 'articles/article/create-article',
  },
  foods: {
    foods: 'foods',
    editFoods: 'foods/edit-foods/:id',
    createFoods: 'foods/create-foods',
  },
  marketplace: {
    courses: 'marketplace/courses',
    companies: 'marketplace/companies',
    createCompany: 'marketplace/create-company',
    editCompany: 'marketplace/edit-company/:id',
    createCourse: 'marketplace/create-course',
    editCourse: 'marketplace/edit-course/:courseId',
  },
  userRoutes: {
    noRights: 'no-rights',
  },
  authRoutes: {
    login: 'login',
    code: 'register/code',
    register: 'register',
    restore: 'restore',
  },
  errorRoutes: {
    notFound: '404',
  },
};

export const innerUrls = {
  statistics: '/statistics',
  categories: '/categories',
  stories: '/stories',
  banners: '/banners',
  category: (id: string) => `/categories/${id}`,
  reviews: '/reviews',
  story: (id: string) => `/stories/${id}`,
  recipe: (id: string) => `/recipes/${id}`,
  banner: (id: string) => `/banners/${id}`,
  directory: '/directory',
  promocodes: '/promocodes',
  articles: {
    articles: '/articles',
    editArticle: (id: string) => `/articles/article/edit-article/${id}`,
    createArticle: '/articles/article/create-article',
  },
  foods: {
    foods: '/foods',
    editFoods: (id: string) => `/foods/edit-foods/${id}`,
    createFoods: '/foods/create-foods',
  },
  marketplace: {
    courses: '/marketplace/courses',
    companies: '/marketplace/companies',
    createCompany: '/marketplace/create-company',
    editCompany: (id: string) => `/marketplace/edit-company/${id}`,
    createCourse: '/marketplace/create-course',
    editCourse: (id: string) => `/marketplace/edit-course/${id}`,
  },
  userRoutes: {
    noRights: '/no-rights',
  },
  authRoutes: {
    login: '/login',
    code: '/register/code',
    register: '/register',
    restore: '/restore',
  },
  errorRoutes: {
    notFound: '/404',
  },
};
