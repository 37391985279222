import Purchases from './assets/Purchases';
import Regs from './assets/Regs';
import Users from './assets/Users';

type Props = {};

const StatLoader = (props: Props) => {
  return (
    <div className='grid -translate-x-5 -translate-y-3 grid-cols-3 gap-[24px]'>
      <Users />
      <Regs />
      <Purchases />
    </div>
  );
};

export default StatLoader;
