import { FC } from 'react';
import StarRev from 'src/assets/svg/starRev';

interface Props {
  value: number;
}

const ratingValues = [...new Array(5)].map((_, index) => index + 1);

const RatingValue: FC<Props> = ({ value }) => {
  return (
    <div className='flex gap-1'>
      {ratingValues.map((elem) => {
        return (
          <span key={elem}>
            <StarRev selected={elem <= value} />
          </span>
        );
      })}
    </div>
  );
};
export default RatingValue;
