import { addNewStory } from '@/entities/categories/model/categoriesSlice';
import { UISelect } from '@/pages/Banners/components/UISelect';
import { setState } from '@/shared/interfaces/common/setState';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { ShowUntil } from '@/shared/ui/ShowUntil/ShowUntil';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface Props {
  modalOpen: boolean;
  setModalOpen: setState<boolean>;
}

interface Form {
  title: string;
  link: string;
  image: FileList;
  categoryId: string;
  articleId: string;
  showUntil: string;
}

export const AddStoryModal: FC<Props> = ({ modalOpen, setModalOpen }) => {
  const selectCategories = useAppSelector(
    (state) => state.categories.selectCategories
  );
  const selectArticles = useAppSelector(
    (state) => state.articles.selectArticles
  );
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<Form>({ mode: 'all' });
  const dispatch = useAppDispatch();
  const onSubmit = handleSubmit(async (data) => {
    if (!data.image.length) {
      return enqueueSnackbar('Выберите изображение', { variant: 'warning' });
    }
    setLoading(true);
    const formData = new FormData();
    if (data.articleId) formData.append('articleId', data.articleId);
    if (data.link) formData.append('outer_resource_link', data.link);
    formData.append('title', data.title);
    formData.append('image', data.image[0]);
    formData.append('categoryId', data.categoryId);
    formData.append('showUntil', data.showUntil);
    await dispatch(addNewStory(formData));
    setLoading(false);
    setModalOpen(false);
    reset();
  });
  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth>
      <DialogTitle>Создать историю</DialogTitle>
      <DialogContent>
        <Box
          component='form'
          onSubmit={onSubmit}
          sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, py: 1 }}
        >
          <TextField
            data-testid={'input-title'}
            label='Название'
            error={errors.title !== undefined}
            {...register('title', { required: 'Введите название' })}
            helperText={errors.title?.message}
          />
          <TextField label='Внешняя ссылка' {...register('link')} />
          <Controller
            control={control}
            name='categoryId'
            render={({ field }) => (
              <UISelect
                data-testid={'select-category'}
                label={'Выберите категорию'}
                {...field}
                items={selectCategories}
              />
            )}
          />
          <Controller
            control={control}
            name='articleId'
            render={({ field }) => (
              <UISelect
                data-testid={'select-article'}
                label={'Выберите статью'}
                {...field}
                items={selectArticles}
              />
            )}
          />
          <ShowUntil
            data-testid={'input-show_until'}
            label={'Показывать до'}
            registerProps={register('showUntil', { required: 'Выберите дату' })}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress sx={{ mr: 4 }} />}
        <Button
          type='submit'
          data-testid={'button-submit'}
          variant='contained'
          disabled={loading}
          onClick={onSubmit}
        >
          Cоздать
        </Button>
        <Button
          data-testid={'button-cancel'}
          onClick={() => setModalOpen(false)}
          color='warning'
          variant='outlined'
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
