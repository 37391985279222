import { AuthorData } from '@/entities/articles/types/IArticle';
import { api } from '@/shared/api';

class ArticlesService {
  async getAllArticles(
    search?: string,
    sort?: 'ASC' | 'DESC' | null,
    publicationStatusId?: number
  ) {
    const params = new URLSearchParams();
    params.append('limit', '10000');
    if (publicationStatusId) {
      params.append(
        'filters[publicationStatusId]',
        String(publicationStatusId)
      );
    }
    if (search) {
      params.append('search', search);
    }
    if (sort) {
      params.append('orderBy[publishedAt]', sort);
    }
    return api.get('v2/articles', {
      params,
    });
  }

  async getSingleArticle(id: number) {
    return api.get(`v2/articles/${id}`);
  }

  async getTopics() {
    return api.get('/topics');
  }

  async getFormats() {
    return api.get('/articleFormats');
  }

  async addAuthor(data: AuthorData) {
    return api.post('/authors', data);
  }

  async updateAuthor(id: number, data: AuthorData) {
    return api.patch(`/authors/${id}`, data);
  }

  async addTopicsToArticle(
    articleId: number,
    data: {
      ids: number[];
    }
  ) {
    return api.post(`v2/articles/${articleId}/topics:batchAdd`, data);
  }

  async deleteTopicsFromArticle(
    articleId: number,
    data: {
      ids: number[];
    }
  ) {
    return api.post(`v2/articles/${articleId}/topics:batchRemove`, data);
  }

  async getArticlesSources(id: number) {
    return api.get(`/v2/articles/${id}/sources`);
  }

  async createSingleArticle(formData: any) {
    return api.post('/v2/articles', formData);
  }

  async deleteSources(articleId: number, data: any) {
    return api.post(`/v2/articles/${articleId}/sources:batchDelete`, data);
  }

  async addSingleArticlesSource(articleId: number, data: any) {
    return api.post(`v2/articles/${articleId}/sources`, data);
  }

  async addArticlesSources(articleId: number, data: any) {
    return api.post(`v2/articles/${articleId}/sources:batchCreate`, {
      sources: data,
    });
  }

  async updateArticlesSources(articleId: number, data: any) {
    return api.post(`v2/articles/${articleId}/sources:batchUpdate`, data);
  }

  async changePublicationType(articleId: number | string, type: string) {
    return api.post(`/v2/articles/${articleId}:${type}`);
  }

  async deleteArticle(id: number) {
    return api.delete(`v2/articles/${id}`);
  }

  async updateArticle(id: number, data: any) {
    return api.patch(`v2/articles/${id}`, data);
  }
}
const articleService = new ArticlesService();
export default articleService;
