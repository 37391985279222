import { CreateCourseTabs } from '@/entities/marketplace/types/tabs';
import { Contents } from '@/pages/Marketplace/course/ui/Contents';
import { DescriptionCourse } from '@/pages/Marketplace/course/ui/DescriptionCourse';
import { FrequentQuestions } from '@/pages/Marketplace/course/ui/FrequentQuestions';
import React, { ReactNode } from 'react';

const DescriptionIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.17157 1.17157C0 2.34315 0 4.22876 0 8V12C0 15.7712 0 17.6569 1.17157 18.8284C2.34315 20 4.22876 20 8 20H10C13.7712 20 15.6569 20 16.8284 18.8284C18 17.6569 18 15.7712 18 12V8C18 4.22876 18 2.34315 16.8284 1.17157C15.6569 0 13.7712 0 10 0H8C4.22876 0 2.34315 0 1.17157 1.17157ZM5 9C4.44772 9 4 9.44771 4 10C4 10.5523 4.44772 11 5 11H13C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9H5ZM4 6C4 5.44772 4.44772 5 5 5H13C13.5523 5 14 5.44772 14 6C14 6.55228 13.5523 7 13 7H5C4.44772 7 4 6.55228 4 6ZM5 13C4.44772 13 4 13.4477 4 14C4 14.5523 4.44772 15 5 15H10C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13H5Z'
      fill='black'
    />
  </svg>
);

const ContentsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9 0C6.79086 0 5 1.79086 5 4V16C5 18.2091 6.79086 20 9 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0H9ZM10 7C9.44772 7 9 7.44772 9 8C9 8.55228 9.44772 9 10 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H10ZM9 12C9 11.4477 9.44772 11 10 11H15C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13H10C9.44772 13 9 12.5523 9 12ZM0 4.99999C0 2.85797 1.6837 1.10919 3.79985 1.00491C3.2911 1.88632 3 2.90916 3 3.99999V16C3 17.0908 3.2911 18.1137 3.79985 18.9951C1.6837 18.8908 0 17.142 0 15V4.99999Z'
      fill='black'
    />
  </svg>
);
const QuestionsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 20C5.28595 20 2.92893 20 1.46447 18.5355C0 17.0711 0 14.714 0 10C0 5.28595 0 2.92893 1.46447 1.46447C2.92893 0 5.28595 0 10 0C14.714 0 17.0711 0 18.5355 1.46447C20 2.92893 20 5.28595 20 10C20 14.714 20 17.0711 18.5355 18.5355C17.0711 20 14.714 20 10 20ZM10 5.75C9.37868 5.75 8.875 6.25368 8.875 6.875C8.875 7.28921 8.53921 7.625 8.125 7.625C7.71079 7.625 7.375 7.28921 7.375 6.875C7.375 5.42525 8.55025 4.25 10 4.25C11.4497 4.25 12.625 5.42525 12.625 6.875C12.625 7.58584 12.3415 8.23198 11.883 8.70396C11.7907 8.79894 11.7027 8.88687 11.6187 8.97076C11.4029 9.18641 11.2138 9.37533 11.0479 9.5885C10.8289 9.86992 10.75 10.0768 10.75 10.25V11C10.75 11.4142 10.4142 11.75 10 11.75C9.58579 11.75 9.25 11.4142 9.25 11V10.25C9.25 9.59481 9.55501 9.06444 9.86417 8.66721C10.0929 8.37329 10.3804 8.08634 10.6138 7.85346C10.6842 7.78321 10.7496 7.71789 10.807 7.65877C11.0046 7.45543 11.125 7.18004 11.125 6.875C11.125 6.25368 10.6213 5.75 10 5.75ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44771 13 9 13.4477 9 14C9 14.5523 9.44771 15 10 15Z'
      fill='black'
    />
  </svg>
);
export const tabs: {
  value: CreateCourseTabs;
  label: ReactNode;
  tab?: ReactNode;
}[] = [
  {
    value: 'description',
    label: (
      <>
        <DescriptionIcon />
        Описание
      </>
    ),
    tab: <DescriptionCourse />,
  },
  {
    value: 'program',
    label: (
      <>
        <ContentsIcon />
        Содержание
      </>
    ),
    tab: <Contents />,
  },
  {
    value: 'frequent-questions',
    label: (
      <>
        <QuestionsIcon />
        Частые вопросы
      </>
    ),
    tab: <FrequentQuestions />,
  },
];
