import { ICategory } from '@/entities/categories/types/ICategory';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Dispatch, FC, SetStateAction } from 'react';

import { AddCategotyModal } from './components/AddCategotyModal';
import CategoryItem from './components/CategoryItem';

interface Props {
  categories: ICategory[];
  setModalVisible: Dispatch<SetStateAction<boolean>>;
  modalVisible: boolean;
}

const CategoriesView: FC<Props> = ({
  categories,
  setModalVisible,
  modalVisible,
}) => {
  return (
    <>
      <AddCategotyModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
      <div className='container'>
        <div className='other'>
          <div className='buttons-block'>
            <div className='topbar-icons'>
              <Button
                data-testid={'button-add-category'}
                variant='contained'
                onClick={() => setModalVisible(true)}
              >
                Создать
              </Button>
            </div>
          </div>

          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, cursor: 'pointer' }}
              size='medium'
              aria-label='a dense table'
            >
              <TableHead sx={{ background: '#5d8aa8' }}>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align='right'>Название</TableCell>
                  <TableCell align='right'>Фото</TableCell>
                  <TableCell align='right'>Дата создания</TableCell>
                  <TableCell align='right'>Дата редактирования</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories?.map((cat) => (
                  <CategoryItem
                    key={cat.id}
                    id={cat.id}
                    name={cat.name}
                    image={cat.image}
                    createdAt={cat.createdAt}
                    updatedAt={cat.updatedAt}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default CategoriesView;
