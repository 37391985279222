import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import React from 'react';

const SignUpView = ({
  handleSubmit,
  handleFormSubmit,
  register,
  setPassword,
  errors,
  handleEmail,
  handleLogin,
  emailHelperText,
}: any) => {
  return (
    <>
      <div className='register'>Регистрация</div>
      <div className='login-container wrapper'>
        <Container maxWidth='sm'>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box mb={2}>
              <TextField
                data-testid={'input-email'}
                helperText={
                  emailHelperText &&
                  'Пользователь с таким email уже зарегистрирован'
                }
                className='input-value'
                variant='outlined'
                label='Email'
                fullWidth
                autoFocus
                autoComplete='Email'
                {...register('email', {
                  onChange: handleEmail,
                  require: 'Введите Email',
                })}
                error={emailHelperText && true}
              />
            </Box>
            <Box mb={2}>
              <TextField
                data-testid={'input-password'}
                className='input-value'
                variant='outlined'
                label='Пароль'
                fullWidth
                autoFocus
                type='password'
                autoComplete='Пароль'
                {...register('password', {
                  onChange: (e: any) => {
                    setPassword(e.target.value);
                  },
                  required: 'Введите пароль',
                })}
                error={!!errors?.password}
              />
            </Box>
            <Button
              data-testid={'button-register'}
              type='submit'
              variant='contained'
              fullWidth
              color='primary'
            >
              Зарегистрироваться
            </Button>
            <div className='auth-user'>Уже зарегистрированы?</div>
            <Button
              data-testid={'button-login'}
              type='submit'
              variant='contained'
              fullWidth
              color='primary'
              onClick={handleLogin}
              className='button-auth'
            >
              Войти
            </Button>
          </form>
        </Container>
      </div>
    </>
  );
};

export default SignUpView;
