import { tryCatch } from '@/shared/lib/tryCatch';
import { setNotification } from '@/shared/model/appSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { removeCookie, setCookie } from 'typescript-cookie';

import authService from '../api/auth-service';
import { IRole, Roles } from '../types/IAuth';

interface IInitialState {
  isAuth: boolean;
  userId: number;
  roles: Roles[];
  initStatus: 'pending' | 'settled';
  error: string | null;
}

const initialState = {
  isAuth: false,
  userId: 0,
  roles: [],
  initStatus: 'pending',
  error: null,
} as IInitialState;

export const fetchUserRoles = createAsyncThunk(
  'auth/fetchUserRoles',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch<IRole[]>(authService.getUserRoles());
    if (err) {
      throw rejectWithValue(`ERROR in fetchUserRoles >>> ${err.message}`);
    }
    return data;
  }
);

export const fetchIsAuth = createAsyncThunk(
  'auth/fetchIsAuth',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch<{ userId: number }>(
      authService.checkAuth()
    );
    if (err) {
      throw rejectWithValue(`ERROR in fetchIsAuth >>> ${err.message}`);
    }
    return data;
  }
);

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (
    payload: { email: string; password: string },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch<{
      accessToken: string;
      refreshToken: string;
    }>(authService.signIn(payload));
    if (err) {
      const message = err.response.data.message;
      console.log('message', message);
      return rejectWithValue(message);
    }
    dispatch(setNotification({ value: 'Вы успешно вошли' }));
    return data;
  }
);

export const signUp = createAsyncThunk(
  'auth/signUp',
  async (
    payload: { email: string; password: string },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch<{
      code: string;
      email: string;
      isSend: boolean;
    }>(authService.signUp(payload));
    if (err) {
      const message = err.response.data.message;
      return rejectWithValue(message);
    }
    return data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuth = false;
      state.roles = [];
      removeCookie('jwt');
      removeCookie('refresh');
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserRoles.fulfilled, (state, action) => {
      state.roles = action.payload.map((elem) => elem.value);
      state.initStatus = 'settled';
    });
    builder.addCase(fetchUserRoles.rejected, (state, action) => {
      state.initStatus = 'settled';
      console.error(action.payload);
    });

    builder.addCase(fetchIsAuth.fulfilled, (state, action) => {
      state.isAuth = true;
      state.userId = action.payload.userId;
    });
    builder.addCase(fetchIsAuth.rejected, (state, action) => {
      state.isAuth = false;
      console.error(`ERROR IN fetchIsAuth >>> ${action.payload}`);
    });

    builder.addCase(signIn.fulfilled, (state, action) => {
      state.isAuth = true;
      const { accessToken, refreshToken } = action.payload;
      setCookie('jwt', accessToken);
      setCookie('refresh', refreshToken);
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.isAuth = false;
      state.error = action.payload as string;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.isAuth = true;
    });
    builder.addCase(signUp.rejected, (state, action) => {
      state.isAuth = false;
      state.error = action.payload as string;
    });
  },
});

export const {
  actions: { logout, resetError },
  reducer: authReducer,
} = authSlice;
