import NewButton from '@/shared/ui/NewButton/NewButton';
import React from 'react';

type Props = {
  onGenerate: () => void;
};

const GeneratePromo = ({ onGenerate }: Props) => {
  return (
    <NewButton
      data-testid={'button-generate_promocode'}
      variant='blue'
      styles='w-min'
      onClick={() => onGenerate()}
    >
      Сгенерировать
    </NewButton>
  );
};

export default GeneratePromo;
