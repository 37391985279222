import { uploadImg } from '@/shared/lib/uploadImg';
import { useAppDispatch } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';

export const useCheckImagesSizes = () => {
  return async ({
    files,
    minHeight,
    minWidth,
  }: {
    files: File[];
    minHeight?: number;
    minWidth?: number;
  }) => {
    let err = false;
    const promises = (files || []).map((file) => {
      return new Promise<void>((resolve, reject) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = function () {
          if (image.width < minWidth || image.height < minHeight) {
            err = true;
            console.error(image.width, 'x', image.height);
          }
          resolve();
        };
        image.onerror = function () {
          err = true;
          resolve();
        };
      });
    });

    await Promise.all(promises);

    return err;
  };
};

export const useCheckAndUploadImages = () => {
  const dispatch = useAppDispatch();

  return async ({
    files,
    minHeight,
    minWidth,
  }: {
    files: File[];
    minHeight?: number;
    minWidth?: number;
  }) => {
    let err = false;
    const uploadedFiles: string[] = [];

    const promises = files.map((file) => {
      return new Promise<void>((resolve) => {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = async function () {
          if (image.width < minWidth || image.height < minHeight) {
            err = true;
            console.error(image.width, 'x', image.height);
            dispatch(
              setNotification({
                variant: 'error',
                value: 'Ошибка при загрузке (маленький размер)',
              })
            );
            resolve();
          } else {
            try {
              const response = await uploadImg([file]);
              uploadedFiles.push(response[0]);
            } catch (uploadError) {
              err = true;
              dispatch(
                setNotification({
                  variant: 'error',
                  value: 'Ошибка при загрузке',
                })
              );
            }
            resolve();
          }
        };
        image.onerror = function () {
          err = true;
          dispatch(
            setNotification({
              variant: 'error',
              value: 'Ошибка при загрузке изображения',
            })
          );
          resolve();
        };
      });
    });

    await Promise.all(promises);

    return { err, uploadedFiles };
  };
};
