import { routes } from '@/shared/consts/consts';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Link } from 'react-router-dom';

const RestorePageView = ({ handleSubmit, handleFormSubmit, register }: any) => {
  return (
    <>
      <div className='login-container wrapper'>
        <div className='login mb-8'>Восстановить пароль</div>
        <Container maxWidth='sm'>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box mb={2}>
              <TextField
                className='input-value'
                variant='outlined'
                defaultValue={''}
                label='Ваш Email'
                fullWidth
                autoFocus
                {...register('email')}
                autoComplete='Email'
              />
            </Box>

            <Button
              data-testid={'button-restore_password'}
              type='submit'
              variant='contained'
              fullWidth
              color='primary'
            >
              Восстановить
            </Button>
          </form>
          <Link to={`/${routes.authRoutes.login}`} replace={true}>
            <Button
              data-testid={'button-back'}
              type='submit'
              variant='contained'
              fullWidth
              color='primary'
            >
              Вернуться обратно
            </Button>
          </Link>
        </Container>
      </div>
    </>
  );
};

export default RestorePageView;
