import ArrowDown from 'src/assets/svg/arrowDown';

type Props = {
  onClick: () => void;
  ref?: any;
  activeSort: { label: string; value: number | string };
  isSortVisible: boolean;
};

const SortSelect = ({ onClick, activeSort, isSortVisible, ref }: Props) => {
  return (
    <div
      ref={ref}
      className='flex h-min w-full min-w-[107px] flex-1 cursor-pointer items-center justify-end gap-2 pr-0'
      onClick={onClick}
      data-testid={'button-toggle_select'}
    >
      <p className='text-right text-base font-normal not-italic leading-[22px]'>
        {activeSort.label}
      </p>
      <button className={`transition ${isSortVisible ? 'rotate-180' : ''}`}>
        <ArrowDown />
      </button>
    </div>
  );
};

export default SortSelect;
