export function formatPhoneNumber(phoneNumber: string) {
  const cleaned = phoneNumber.replace(/\D/g, '');

  const formatted = cleaned.replace(
    /^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/,
    '+$1 $2 $3 $4 $5'
  );

  return formatted;
}
