import { api } from '../../../shared/api';

class ReviewsService {
  async getAllReviews(
    limit: any,
    offset: any,
    filters: any,
    createdAd: any,
    rate: any,
    search: string
  ) {
    // Формируем строку параметров
    let params = new URLSearchParams({
      limit: limit,
      offset: offset,
      search: search,
      'orderBy[createdAt]': createdAd?.toString(),
      'orderBy[rate]': rate?.toString(),
    });

    // Check if isModerated is provided before adding it to the parameters
    if (filters !== undefined) {
      params.append('filters[isModerated]', filters);
    }

    if (createdAd === undefined) {
      params = new URLSearchParams({
        limit: limit,
        offset: offset,
        search: search,
        'orderBy[rate]': rate.toString(),
      });
      if (filters !== undefined) {
        params.append('filters[isModerated]', filters);
      }
    }

    if (rate === undefined) {
      params = new URLSearchParams({
        limit: limit,
        offset: offset,
        search: search,
        'orderBy[createdAt]': createdAd.toString(),
      });
      if (filters !== undefined) {
        params.append('filters[isModerated]', filters);
      }
    }

    const url = `ratings?${params.toString()}`;
    return api.get(url);
  }

  async publicateReview(id: number) {
    return api.post(`/ratings/${id}:moderate`); // установка отзыва как отмодерированного
  }

  async sendOnModerationReview(id: number) {
    return api.post(`/ratings/${id}:unmoderate`); // установка отзыва как не отмодерированного
  }

  async deleteReview(id: number) {
    return api.delete(`/ratings/${id}`); // удаление отзыва
  }
}
const reviewsService = new ReviewsService();
export default reviewsService;
