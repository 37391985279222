import {
  deleteCompany,
  deleteCourse,
  fetchAllCompanies,
  fetchAllCourses,
  fetchCompanyForEdit,
  fetchCourseForEdit,
  fetchSingleCompany,
  getCourseFormats,
  getTopics,
  publishCourse,
  saveCourseContents,
  saveCourseDescription,
  saveCourseFrequentQuestions,
  submitCompany,
  toggleCompanyVisibility,
  toggleCourseVisibility,
} from '@/entities/marketplace/model/markeplaceThunks';
import { IAttachment, IContent } from '@/entities/marketplace/types/IContent';
import { ICourse } from '@/entities/marketplace/types/ICourse';
import { IQuestion } from '@/entities/marketplace/types/IQuestion';
import { IReview } from '@/entities/marketplace/types/IReview';
import { ISpeakerAction, IStaff } from '@/entities/marketplace/types/IStaff';
import { FilterValue, SortingValue } from '@/entities/marketplace/types/status';
import {
  CreateCompanyTabs,
  CreateCourseTabs,
} from '@/entities/marketplace/types/tabs';
import { CompanyDto, PreviewCourseV2Dto } from '@/shared/interfaces/generated';
import { Change, ChangeForm } from '@/shared/interfaces/types';
import { swapArr } from '@/shared/lib/spawArr';
import { SelectProps } from '@/shared/ui/Select';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IFilter =
  | {
      search: string;
      status: number | null;
      createdAt: 'ASC' | 'DESC';
    }
  | Record<string, any>;
interface IState {
  activeCreateCompanyTab: CreateCompanyTabs;
  activeCreateCourseTab: CreateCourseTabs;
  companyId: number;
  companyName: string;
  courses: PreviewCourseV2Dto[];
  editSpeakersActions: ISpeakerAction[];
  editInitialSpeakers: IStaff[];
  editInitialReviews: IReview[];
  activeEditCompanyTab: CreateCompanyTabs;
  singleCompanySearch: string;
  singleCompanyFilter: FilterValue;
  singleCompany: CompanyDto;
  singleCompanyCourses: ICourse[];
  deletedCourses: ICourse[];
  topics?: SelectProps[];
  courseFormats?: SelectProps[];
  courseFilters: IFilter;
  companyFilters: IFilter;
  companyForm: {
    doneModalOpen: boolean;
    canSubmit: boolean;
    form: {
      title: string;
      description: string;
      logo: File[] | null | string[];
    };
    staff: IStaff[];
    companyId: number;
    publicationStatus?: number;
    unsavedChanges?: boolean;
  };
  courseForm: {
    initQuestions: IQuestion[];
    initContents: IContent[];
    initAttachments: IAttachment[];
    initPreviews: IAttachment[];
    initTopics: SelectProps[];
    initStaff: SelectProps[];

    doneModalOpen: boolean;
    canSubmit: boolean;
    contents?: IContent[];
    frequentQuestions?: IQuestion[];
    unsavedChanges: {
      description: boolean;
      contents: boolean;
      frequentQuestions: boolean;
    };
    courseId?: number;
    productId?: number;
    publicationStatusId?: number;
    form: {
      title: string;
      description: string;
      price: number;
      discountPrice: number | null;
      cover: File[] | null | string[];
      attachments: IAttachment[];
      previews: IAttachment[];
      company?: SelectProps;
      isPinned?: boolean;
      formats?: SelectProps[];
      videoLink: string;

      topics?: SelectProps[];
      date?: string | Date;

      staff: SelectProps[];

      isAdvertising?: boolean;

      ooo?: string;
      inn?: string;
      erid?: string;
    };
  };
  selectedFilter: FilterValue;
  sortingValue: SortingValue;
  searchValue: string;
  companies: CompanyDto[];
  staff: IStaff[];
  isEditSubmitted: boolean;
}
const initFilters: IFilter = {
  search: '',
  status: null,
  createdAt: null,
};
const initStaff: IStaff = { name: '', position: '', photo: null, id: 0 };
const initContent: IContent = {
  description: '',
  title: '',
  id: 0,
  place: '',
};
const initFrequentQuestion: IQuestion = {
  id: 0,
  answer: '',
  question: '',
};
const initChanges = {
  description: false,
  contents: false,
  frequentQuestions: false,
};
const initialState: IState = {
  companyName: '',
  courses: [],
  companyId: 0,
  activeCreateCourseTab: 'description',
  editSpeakersActions: [],
  editInitialSpeakers: [],
  editInitialReviews: [],
  deletedCourses: [],
  singleCompanySearch: '',
  singleCompanyFilter: 'all',
  singleCompanyCourses: [],
  isEditSubmitted: false,
  activeCreateCompanyTab: 'description',
  activeEditCompanyTab: 'description',
  singleCompany: {} as CompanyDto,
  companyFilters: initFilters,
  courseFilters: initFilters,
  companyForm: {
    publicationStatus: undefined,
    doneModalOpen: false,
    canSubmit: false,
    companyId: 0,
    form: {
      title: '',
      description: '',
      logo: null,
    },
    unsavedChanges: false,
    staff: [initStaff],
  },
  courseForm: {
    initContents: [],
    initQuestions: [],
    initAttachments: [],
    initPreviews: [],
    initTopics: [],
    initStaff: [],

    doneModalOpen: false,
    canSubmit: false,
    unsavedChanges: initChanges,
    contents: [initContent],
    frequentQuestions: [initFrequentQuestion],
    courseId: null,
    form: {
      topics: [],
      formats: [],
      isAdvertising: false,
      discountPrice: null,
      title: '',
      date: new Date(),
      description: '',
      cover: null,
      attachments: null,
      previews: null,
      price: 0,
      videoLink: '',
      company: undefined,
      staff: [],
    },
  },
  selectedFilter: 'all',
  sortingValue: 0,
  searchValue: '',
  companies: [],
  staff: [],
};

export type ChangeStaff = Change<IStaff, File | string | null>;

export type ChangeReviews = Change<IReview, string | Date | number>;

export type ChangeContents = Change<IContent, string>;

export type ChangeFrequentQuestions = Change<IQuestion, string>;

export interface ChangeDescription {
  key: keyof Omit<IState['companyForm'], 'reviews' | 'speakers'>;
  value: string | File | null;
}

type CourseFormType = IState['courseForm']['form'];
type CompanyFormType = IState['companyForm']['form'];

export type ChangeCourse = ChangeForm<CourseFormType>;

const marketplaceSlice = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    changeFilters(
      state,
      action: PayloadAction<{
        key: keyof IFilter;
        type: 'courseFilters' | 'companyFilters';
        value: string | number | null;
      }>
    ) {
      const { type, key, value } = action.payload;
      // @ts-ignore
      state[type][key] = value;
    },
    changeSearch(state, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },

    changeFilter(state, action: PayloadAction<FilterValue>) {
      state.selectedFilter = action.payload;
    },

    changeCompanyForm(
      state,
      action: PayloadAction<ChangeForm<CompanyFormType>>
    ) {
      const { key, value } = action.payload;
      //@ts-expect-error
      state.companyForm.form[key] = value;
      state.companyForm.unsavedChanges = true;
    },

    changeSort(state, action: PayloadAction<SortingValue>) {
      state.sortingValue = action.payload;
    },

    changeCreateCompanyTab(state, action: PayloadAction<CreateCompanyTabs>) {
      state.activeCreateCompanyTab = action.payload;
    },

    changeEditCompanyTab(state, action: PayloadAction<CreateCompanyTabs>) {
      state.activeEditCompanyTab = action.payload;
    },

    resetCourseForm(state) {
      state.courseForm = initialState.courseForm;
      state.activeCreateCourseTab = 'description';
    },
    resetCompanyForm(state) {
      state.companyForm = initialState.companyForm;
      state.activeEditCompanyTab = 'description';
    },

    // DESCRIPTION

    changeDescription(state, action: PayloadAction<ChangeDescription>) {
      const { key, value } = action.payload;
      // @ts-expect-error
      state.companyForm[key] = value;
      const { description, logo, title } = state.companyForm.form;
      state.companyForm.canSubmit = !!(description && logo && title);
    },

    // SPEAKERS

    changeStaff(state, action: PayloadAction<ChangeStaff>) {
      const { index, key, value } = action.payload;
      //@ts-ignore
      state.companyForm.staff[index][key] = value;
      state.companyForm.unsavedChanges = true;
    },
    addStaff(state) {
      const newStaff = {
        ...initStaff,
        id: state.companyForm.staff.length + 999,
      };

      state.companyForm.staff.push(newStaff);
      state.companyForm.unsavedChanges = true;
    },
    deleteStaff(state, action: PayloadAction<number>) {
      state.companyForm.staff = state.companyForm.staff.filter(
        ({ id }) => id !== action.payload
      );
      state.companyForm.unsavedChanges = true;
    },
    swapStaff(
      state,
      action: PayloadAction<{ destIndex: number; index: number }>
    ) {
      const { index, destIndex } = action.payload;
      swapArr(index, destIndex, state.companyForm.staff);
      state.companyForm.unsavedChanges = true;
    },

    clearTab(state) {
      state.activeEditCompanyTab = 'description';
    },

    // COMPANY EDIT

    clearForm(state) {
      state.companyForm.staff = [initStaff];
      state.companyForm.canSubmit = false;
      state.companyForm.unsavedChanges = false;
      state.companyForm.form = initialState.companyForm.form;
      state.activeEditCompanyTab = 'description';
    },

    changeEditSubmitted(state) {
      state.isEditSubmitted = !state.isEditSubmitted;
    },

    // COURSES

    checkCanSubmitCourse(state) {
      const {
        courseForm: {
          form: {
            formats,
            topics,
            cover,
            attachments,
            title,
            discountPrice,
            price,
            description,
            company,
            isAdvertising,
            ooo,
            inn,
            erid,
          },
        },
      } = state;
      if (
        !cover?.length ||
        !attachments?.length ||
        title.trim() === '' ||
        (price > 0 && discountPrice >= price) ||
        isNaN(discountPrice) ||
        description.trim() === '' ||
        !topics?.length ||
        !formats?.length ||
        discountPrice < 0 ||
        company?.id == null ||
        (isAdvertising && (!ooo || !erid || !inn))
      ) {
        state.courseForm.canSubmit = false;
        return;
      }

      state.courseForm.canSubmit = true;
    },

    clearCourseForm(state) {
      state.courseForm.form = initialState.courseForm.form;
      state.activeCreateCourseTab = 'description';
      state.courseForm.contents = [initContent];
      state.courseForm.frequentQuestions = [initFrequentQuestion];
    },

    changeCreateCourseTab(state, action: PayloadAction<CreateCourseTabs>) {
      state.activeCreateCourseTab = action.payload;
    },

    changeCoursesForm(state, action: PayloadAction<ChangeCourse>) {
      const { key, value } = action.payload;
      //@ts-expect-error
      state.courseForm.form[key] = value;
      state.courseForm.unsavedChanges.description = true;
    },

    deleteContent(state, action: PayloadAction<number>) {
      state.courseForm.contents = state.courseForm.contents.filter(
        (elem) => elem.id !== action.payload
      );
      state.courseForm.unsavedChanges.contents = true;
    },

    onSwapContents(
      state,
      action: PayloadAction<{ index: number; destIndex: number }>
    ) {
      const { index, destIndex } = action.payload;
      swapArr(index, destIndex, state.courseForm.contents);
      state.courseForm.unsavedChanges.contents = true;
    },

    addContents(state) {
      const newContent = {
        ...initContent,
        id: state.courseForm.contents.length + 999,
      };
      state.courseForm.unsavedChanges.contents = true;
      state.courseForm.contents.push(newContent);
    },

    changeContents(state, action: PayloadAction<ChangeContents>) {
      const { index, key, value } = action.payload;
      //@ts-ignore
      state.courseForm.contents[index][key] = value;
      state.courseForm.unsavedChanges.contents = true;
    },

    changeFrequentQuestions(
      state,
      action: PayloadAction<ChangeFrequentQuestions>
    ) {
      const { index, key, value } = action.payload;
      //@ts-ignore
      state.courseForm.frequentQuestions[index][key] = value;
      state.courseForm.unsavedChanges.frequentQuestions = true;
    },

    deleteFrequentQuestion(state, action: PayloadAction<number>) {
      state.courseForm.frequentQuestions =
        state.courseForm.frequentQuestions.filter(
          (elem) => elem.id !== action.payload
        );
      state.courseForm.unsavedChanges.frequentQuestions = true;
    },

    onSwapFrequentQuestion(
      state,
      action: PayloadAction<{ index: number; destIndex: number }>
    ) {
      const { index, destIndex } = action.payload;
      swapArr(index, destIndex, state.courseForm.frequentQuestions);
      state.courseForm.unsavedChanges.frequentQuestions = true;
    },

    addFrequentQuestion(state) {
      const newQuestion = {
        ...initFrequentQuestion,
        id: state.courseForm.frequentQuestions.length + 999,
      };

      state.courseForm.frequentQuestions.push(newQuestion);
      state.courseForm.unsavedChanges.frequentQuestions = true;
    },

    changeCourseReviews(state, action: PayloadAction<ChangeReviews>) {
      const { index, key, value } = action.payload;
      //@ts-ignore
      state.courseForm.reviews[index][key] = value;
    },

    doneModalToggle(state) {
      state.courseForm.doneModalOpen = !state.courseForm.doneModalOpen;
    },

    doneCompanyModalToggle(state) {
      state.companyForm.doneModalOpen = !state.companyForm.doneModalOpen;
    },

    changeSingleCompanyFilter(state, action: PayloadAction<FilterValue>) {
      state.singleCompanyFilter = action.payload;
    },

    changeSingleCompanySearch(state, action: PayloadAction<string>) {
      state.singleCompanySearch = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCompanies.fulfilled, (state, action) => {
      state.companies = action.payload.companies;
    });

    builder.addCase(submitCompany.fulfilled, (state, action) => {
      state.companyForm.companyId = action.payload.company.id;
      state.companyForm.form.title = action.payload.company.title;
      state.companyForm.form.description = action.payload.company.description;
      state.companyForm.form.logo = [action.payload.company.logo];
      state.companyForm.staff = action.payload.allStaffToSave;
      state.editInitialSpeakers = action.payload.allStaffToSave;
      state.companyForm.unsavedChanges = false;
    });
    builder.addCase(publishCourse.fulfilled, (state) => {
      state.courseForm.form.description = '';
      state.courseForm.form.discountPrice = null;
      state.courseForm.form.cover = null;
      state.courseForm.form.attachments = null;
      state.courseForm.form.previews = null;
      state.courseForm.form.price = 0;
      state.courseForm.form.staff = [];
      state.courseForm.form.title = '';
      state.courseForm.form.videoLink = '';
    });
    builder.addCase(fetchAllCourses.fulfilled, (state, action) => {
      state.courses = action.payload.courses;
    });
    builder.addCase(fetchCourseForEdit.fulfilled, (state, action) => {
      const { contents, attachments, questions, course } = action.payload;
      state.courseForm.unsavedChanges = initChanges;
      state.courseForm.initQuestions = questions;
      state.courseForm.initContents = contents;
      state.courseForm.form.description = course.product.description ?? '';
      state.courseForm.form.discountPrice =
        course.product.discountPrice ?? course.product.price;
      state.courseForm.form.cover = [course.product.image] ?? null;
      state.courseForm.form.date = course.product.publishedAt;
      state.courseForm.form.price =
        course.product.discountPrice === null ? null : course.product.price;
      state.courseForm.form.isAdvertising = false;
      state.courseForm.form.title = course.product.title ?? '';
      state.courseForm.form.formats = [course.format];

      state.courseForm.form.topics = [...course.topics];
      state.courseForm.initTopics = [...course.topics];

      state.courseForm.form.company = course.product.company;
      state.courseForm.form.isPinned = course.product.superOrder > 0;

      const newAttachments = attachments?.length ? attachments : [];
      state.courseForm.form.attachments = [...newAttachments];
      state.courseForm.initAttachments = [...newAttachments];

      const newPreviews = course.product.media?.length
        ? course.product.media.sort((a, b) => a.order - b.order)
        : [];
      state.courseForm.form.previews = [...newPreviews];
      state.courseForm.initPreviews = [...newPreviews];

      state.courseForm.frequentQuestions = questions?.length
        ? questions
        : [initFrequentQuestion];

      state.courseForm.form.videoLink = course.videoLink;

      const newStaff = course.product.staff.map(
        // @ts-ignore
        ({ name, id }: { name: string; id: number }) => ({
          title: name,
          id: id,
        })
      );
      state.courseForm.form.staff = [...newStaff];
      state.courseForm.initStaff = [...newStaff];

      state.courseForm.courseId = course.id;
      state.courseForm.productId = course.product.id;
      state.courseForm.publicationStatusId = course.product.publicationStatusId;
      state.courseForm.contents = contents?.length ? contents : [initContent];
    });
    builder.addCase(saveCourseDescription.rejected, (state, action) => {
      state.courseForm.unsavedChanges.description = false;
      throw Error(action.payload as string);
    });
    builder.addCase(saveCourseDescription.fulfilled, (state, action) => {
      const { course, previews, attachments, topics, staff } = action.payload;
      state.courseForm.initTopics = topics;
      state.courseForm.initStaff = staff;
      state.courseForm.initAttachments = attachments;
      state.courseForm.initPreviews = previews;
      state.courseForm.courseId = course.id;
      state.courseForm.productId = course.product.id;
      state.courseForm.unsavedChanges.description = false;
    });
    builder.addCase(saveCourseFrequentQuestions.rejected, (state, action) => {
      state.courseForm.unsavedChanges.frequentQuestions = false;
      throw Error(action.payload as string);
    });
    builder.addCase(saveCourseFrequentQuestions.fulfilled, (state, action) => {
      state.courseForm.frequentQuestions = action.payload;
      state.courseForm.initQuestions = action.payload;
      state.courseForm.unsavedChanges.frequentQuestions = false;
    });
    builder.addCase(saveCourseContents.rejected, (state, action) => {
      state.courseForm.unsavedChanges.contents = false;
      throw Error(action.payload as string);
    });
    builder.addCase(saveCourseContents.fulfilled, (state, action) => {
      state.courseForm.contents = action.payload;
      state.courseForm.initContents = action.payload;
      state.courseForm.unsavedChanges.contents = false;
    });

    builder.addCase(fetchCompanyForEdit.fulfilled, (state, action) => {
      const { reviews, staff, company } = action.payload;
      state.companyName = company.title;
      state.companyId = company.id;
      state.editInitialSpeakers = staff;
      state.editInitialReviews = reviews;
      state.companyForm.staff = staff?.length ? staff : [initStaff];
      state.companyForm.publicationStatus = company.publicationStatus.id as
        | 1
        | 2
        | 3;
      state.companyForm.form.description = company.description ?? '';
      state.companyForm.form.title = company.title ?? '';
      state.companyForm.form.logo = [company.logo] ?? null;
      state.companyForm.canSubmit = !!(
        company.logo &&
        company.description &&
        company.logo
      );
    });

    builder.addCase(toggleCompanyVisibility.fulfilled, (state, action) => {
      const { companyId: id, publicationStatus } = action.payload;
      const publStatus = publicationStatus as 1 | 2 | 3;
      state.companies = state.companies.map((elem) =>
        elem.id === id
          ? {
              ...elem,
              publicationStatus: { id: publStatus === 3 ? 2 : 3, title: '' },
            }
          : elem
      );
    });

    builder.addCase(toggleCourseVisibility.fulfilled, (state, action) => {
      const { id, publicationStatus } = action.payload;
      const publStatus = publicationStatus as 1 | 2 | 3;
      state.courses = state.courses.map((elem) =>
        elem.product.id === id
          ? {
              ...elem,
              product: {
                ...elem.product,
                publicationStatusId: publStatus === 3 ? 2 : 3,
              },
            }
          : elem
      );
    });

    builder.addCase(deleteCompany.fulfilled, (state, action) => {
      const id = action.payload;
      state.companies = state.companies.filter((elem) => elem.id !== id);
    });
    builder.addCase(
      getTopics.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.topics = action.payload;
      }
    );
    builder.addCase(
      getCourseFormats.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.courseFormats = action.payload;
      }
    );

    builder.addCase(deleteCourse.fulfilled, (state, action) => {
      const id = action.payload;
      state.courses = state.courses?.filter((elem) => elem.id !== id);
    });

    builder.addCase(fetchSingleCompany.fulfilled, (state, action) => {
      state.singleCompany = action.payload;
    });

    // TODO: ADD REJECTED CASE HANDLER ON ALL THUNKS
    builder.addCase(fetchAllCompanies.rejected, (_, action) => {
      console.error(action.payload);
    });
  },
});

export const { actions: marketplaceActions, reducer: marketplaceReducer } =
  marketplaceSlice;
