import { memo, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

const FormWrapper = ({ children, className }: Props) => {
  return (
    <div className={`${className ?? ''} container mt-[32px]`}>
      <div className='flex gap-[32px]'>{children}</div>
    </div>
  );
};

export default memo(FormWrapper);
