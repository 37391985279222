type Props = {};

const PolarBear = (props: Props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='35'
      fill='none'
      viewBox='0 0 62 62'
      {...props}
    >
      <g clipPath='url(#clip0_2758_27554)'>
        <g>
          <path
            fill='#DCDDDF'
            d='M47.314 25.587a8.783 8.783 0 100-17.566 8.783 8.783 0 000 17.566z'
          ></path>
          <path
            fill='url(#paint0_linear_2758_27554)'
            d='M47.314 25.587a8.783 8.783 0 100-17.566 8.783 8.783 0 000 17.566z'
          ></path>
          <path
            fill='url(#paint1_linear_2758_27554)'
            d='M47.314 25.587a8.783 8.783 0 100-17.566 8.783 8.783 0 000 17.566z'
          ></path>
          <path
            fill='url(#paint2_radial_2758_27554)'
            d='M47.314 25.587a8.783 8.783 0 100-17.566 8.783 8.783 0 000 17.566z'
          ></path>
          <path
            fill='url(#paint3_radial_2758_27554)'
            d='M47.314 25.587a8.783 8.783 0 100-17.566 8.783 8.783 0 000 17.566z'
          ></path>
          <path
            fill='url(#paint4_radial_2758_27554)'
            d='M47.314 25.587a8.783 8.783 0 100-17.566 8.783 8.783 0 000 17.566z'
          ></path>
        </g>
        <path
          fill='url(#paint5_linear_2758_27554)'
          d='M47.319 21.94a5.018 5.018 0 100-10.036 5.018 5.018 0 000 10.035z'
        ></path>
        <g>
          <path
            fill='#DCDDDF'
            d='M14.686 25.587a8.783 8.783 0 110-17.565 8.783 8.783 0 010 17.565z'
          ></path>
          <path
            fill='url(#paint6_linear_2758_27554)'
            d='M14.686 25.587a8.783 8.783 0 110-17.565 8.783 8.783 0 010 17.565z'
          ></path>
          <path
            fill='url(#paint7_radial_2758_27554)'
            d='M14.686 25.587a8.783 8.783 0 110-17.565 8.783 8.783 0 010 17.565z'
          ></path>
          <path
            fill='url(#paint8_radial_2758_27554)'
            d='M14.686 25.587a8.783 8.783 0 110-17.565 8.783 8.783 0 010 17.565z'
          ></path>
        </g>
        <g>
          <path
            fill='url(#paint9_radial_2758_27554)'
            d='M14.681 21.94a5.018 5.018 0 110-10.036 5.018 5.018 0 010 10.035z'
          ></path>
          <path
            fill='url(#paint10_radial_2758_27554)'
            d='M14.681 21.94a5.018 5.018 0 110-10.036 5.018 5.018 0 010 10.035z'
          ></path>
        </g>
        <g>
          <path
            fill='url(#paint11_radial_2758_27554)'
            d='M58.543 37.86v-.058c0-1.04-.098-2.059-.295-3.04-.61-3.98-2.48-11.078-8.186-16.81-8.206-8.233-19.068-7.694-19.068-7.694s-10.862-.538-19.068 7.694C6.2 23.684 4.35 30.782 3.74 34.762c-.196.981-.295 2-.295 3.059v.058c0 6.597 3.916 12.31 9.603 15.04 0 0 6.14 3.52 17.946 3.52 11.807 0 17.946-3.52 17.946-3.52 5.687-2.75 9.603-8.462 9.603-15.06z'
          ></path>
          <path
            fill='url(#paint12_radial_2758_27554)'
            d='M58.543 37.86v-.058c0-1.04-.098-2.059-.295-3.04-.61-3.98-2.48-11.078-8.186-16.81-8.206-8.233-19.068-7.694-19.068-7.694s-10.862-.538-19.068 7.694C6.2 23.684 4.35 30.782 3.74 34.762c-.196.981-.295 2-.295 3.059v.058c0 6.597 3.916 12.31 9.603 15.04 0 0 6.14 3.52 17.946 3.52 11.807 0 17.946-3.52 17.946-3.52 5.687-2.75 9.603-8.462 9.603-15.06z'
          ></path>
          <path
            fill='url(#paint13_radial_2758_27554)'
            d='M58.543 37.86v-.058c0-1.04-.098-2.059-.295-3.04-.61-3.98-2.48-11.078-8.186-16.81-8.206-8.233-19.068-7.694-19.068-7.694s-10.862-.538-19.068 7.694C6.2 23.684 4.35 30.782 3.74 34.762c-.196.981-.295 2-.295 3.059v.058c0 6.597 3.916 12.31 9.603 15.04 0 0 6.14 3.52 17.946 3.52 11.807 0 17.946-3.52 17.946-3.52 5.687-2.75 9.603-8.462 9.603-15.06z'
          ></path>
          <path
            fill='url(#paint14_radial_2758_27554)'
            d='M58.543 37.86v-.058c0-1.04-.098-2.059-.295-3.04-.61-3.98-2.48-11.078-8.186-16.81-8.206-8.233-19.068-7.694-19.068-7.694s-10.862-.538-19.068 7.694C6.2 23.684 4.35 30.782 3.74 34.762c-.196.981-.295 2-.295 3.059v.058c0 6.597 3.916 12.31 9.603 15.04 0 0 6.14 3.52 17.946 3.52 11.807 0 17.946-3.52 17.946-3.52 5.687-2.75 9.603-8.462 9.603-15.06z'
          ></path>
          <path
            fill='url(#paint15_radial_2758_27554)'
            d='M58.543 37.86v-.058c0-1.04-.098-2.059-.295-3.04-.61-3.98-2.48-11.078-8.186-16.81-8.206-8.233-19.068-7.694-19.068-7.694s-10.862-.538-19.068 7.694C6.2 23.684 4.35 30.782 3.74 34.762c-.196.981-.295 2-.295 3.059v.058c0 6.597 3.916 12.31 9.603 15.04 0 0 6.14 3.52 17.946 3.52 11.807 0 17.946-3.52 17.946-3.52 5.687-2.75 9.603-8.462 9.603-15.06z'
          ></path>
          <path
            fill='url(#paint16_radial_2758_27554)'
            d='M58.543 37.86v-.058c0-1.04-.098-2.059-.295-3.04-.61-3.98-2.48-11.078-8.186-16.81-8.206-8.233-19.068-7.694-19.068-7.694s-10.862-.538-19.068 7.694C6.2 23.684 4.35 30.782 3.74 34.762c-.196.981-.295 2-.295 3.059v.058c0 6.597 3.916 12.31 9.603 15.04 0 0 6.14 3.52 17.946 3.52 11.807 0 17.946-3.52 17.946-3.52 5.687-2.75 9.603-8.462 9.603-15.06z'
          ></path>
          <path
            fill='url(#paint17_radial_2758_27554)'
            d='M58.543 37.86v-.058c0-1.04-.098-2.059-.295-3.04-.61-3.98-2.48-11.078-8.186-16.81-8.206-8.233-19.068-7.694-19.068-7.694s-10.862-.538-19.068 7.694C6.2 23.684 4.35 30.782 3.74 34.762c-.196.981-.295 2-.295 3.059v.058c0 6.597 3.916 12.31 9.603 15.04 0 0 6.14 3.52 17.946 3.52 11.807 0 17.946-3.52 17.946-3.52 5.687-2.75 9.603-8.462 9.603-15.06z'
          ></path>
        </g>
        <g filter='url(#filter0_f_2758_27554)'>
          <path
            fill='#9E9BA4'
            d='M22.637 33.893a1.464 1.464 0 01-1.453-1.453v-2.88c0-.794.658-1.453 1.453-1.453.794 0 1.453.66 1.453 1.454v2.879c0 .794-.64 1.453-1.453 1.453z'
          ></path>
        </g>
        <g filter='url(#filter1_f_2758_27554)'>
          <path
            fill='#9E9BA4'
            d='M38.137 33.728a1.464 1.464 0 01-1.453-1.453v-2.88c0-.793.658-1.452 1.453-1.452.794 0 1.453.659 1.453 1.453v2.879c0 .794-.64 1.453-1.453 1.453z'
          ></path>
        </g>
        <g>
          <path
            fill='url(#paint18_linear_2758_27554)'
            d='M23.242 33.123a1.464 1.464 0 01-1.453-1.454V28.79c0-.794.659-1.453 1.453-1.453.795 0 1.453.659 1.453 1.453v2.88c0 .794-.64 1.453-1.453 1.453z'
          ></path>
          <path
            fill='url(#paint19_linear_2758_27554)'
            d='M23.242 33.123a1.464 1.464 0 01-1.453-1.454V28.79c0-.794.659-1.453 1.453-1.453.795 0 1.453.659 1.453 1.453v2.88c0 .794-.64 1.453-1.453 1.453z'
          ></path>
          <path
            fill='url(#paint20_radial_2758_27554)'
            d='M23.242 33.123a1.464 1.464 0 01-1.453-1.454V28.79c0-.794.659-1.453 1.453-1.453.795 0 1.453.659 1.453 1.453v2.88c0 .794-.64 1.453-1.453 1.453z'
          ></path>
        </g>
        <g>
          <path
            fill='url(#paint21_linear_2758_27554)'
            d='M38.742 33.123a1.464 1.464 0 01-1.453-1.454V28.79c0-.794.659-1.453 1.453-1.453.795 0 1.453.659 1.453 1.453v2.88c0 .794-.64 1.453-1.453 1.453z'
          ></path>
          <path
            fill='url(#paint22_linear_2758_27554)'
            d='M38.742 33.123a1.464 1.464 0 01-1.453-1.454V28.79c0-.794.659-1.453 1.453-1.453.795 0 1.453.659 1.453 1.453v2.88c0 .794-.64 1.453-1.453 1.453z'
          ></path>
          <path
            fill='url(#paint23_radial_2758_27554)'
            d='M38.742 33.123a1.464 1.464 0 01-1.453-1.454V28.79c0-.794.659-1.453 1.453-1.453.795 0 1.453.659 1.453 1.453v2.88c0 .794-.64 1.453-1.453 1.453z'
          ></path>
        </g>
        <g filter='url(#filter2_f_2758_27554)'>
          <path
            fill='url(#paint24_linear_2758_27554)'
            d='M30.378 35.28c-1.368-.067-3.687.094-6.088.977-2.755 1.013-4.037 1.766-4.037 6.247 0 9.869 5.634 11.645 9.808 10.232 3.936-1.332 8.174-3.428 9.87-8.9.868-2.804-.849-6.03-3.536-7.21-2.344-1.027-4.648-1.33-6.017-1.346z'
          ></path>
        </g>
        <g>
          <path
            fill='url(#paint25_linear_2758_27554)'
            d='M30.992 33.895c-1.42-.026-3.82.214-6.282 1.206-2.825 1.137-4.097 4.342-3.285 7.277 1.148 4.152 3.72 8.288 9.567 8.288 5.847 0 8.418-4.136 9.567-8.288.812-2.935-.46-6.14-3.285-7.277-2.462-.992-4.861-1.232-6.282-1.206z'
          ></path>
          <path
            fill='url(#paint26_radial_2758_27554)'
            d='M30.992 33.895c-1.42-.026-3.82.214-6.282 1.206-2.825 1.137-4.097 4.342-3.285 7.277 1.148 4.152 3.72 8.288 9.567 8.288 5.847 0 8.418-4.136 9.567-8.288.812-2.935-.46-6.14-3.285-7.277-2.462-.992-4.861-1.232-6.282-1.206z'
          ></path>
          <path
            fill='url(#paint27_radial_2758_27554)'
            d='M30.992 33.895c-1.42-.026-3.82.214-6.282 1.206-2.825 1.137-4.097 4.342-3.285 7.277 1.148 4.152 3.72 8.288 9.567 8.288 5.847 0 8.418-4.136 9.567-8.288.812-2.935-.46-6.14-3.285-7.277-2.462-.992-4.861-1.232-6.282-1.206z'
          ></path>
        </g>
        <g filter='url(#filter3_f_2758_27554)'>
          <path
            fill='#BAADCB'
            fillRule='evenodd'
            d='M25.328 42.206a.956.956 0 00-.943-.969.956.956 0 00-.944.97c0 2.48 1.82 4.638 4.227 4.638 1.358 0 2.529-.688 3.292-1.733.763 1.045 1.934 1.733 3.292 1.733 2.406 0 4.226-2.158 4.226-4.639a.956.956 0 00-.943-.969.956.956 0 00-.943.97c0 1.568-1.123 2.7-2.34 2.7-1.217 0-2.34-1.132-2.34-2.7a.956.956 0 00-.943-.97h-.019a.956.956 0 00-.943.97c0 1.568-1.123 2.7-2.34 2.7-1.216 0-2.339-1.132-2.339-2.7z'
            clipRule='evenodd'
          ></path>
        </g>
        <g strokeLinecap='round' strokeMiterlimit='10' strokeWidth='1.938'>
          <path
            stroke='url(#paint28_radial_2758_27554)'
            d='M30.985 41.934c0 2.025-1.512 3.67-3.372 3.67-1.86 0-3.37-1.645-3.37-3.67'
          ></path>
          <path
            stroke='url(#paint29_radial_2758_27554)'
            d='M30.985 41.934c0 2.025-1.512 3.67-3.372 3.67-1.86 0-3.37-1.645-3.37-3.67'
          ></path>
        </g>
        <path
          stroke='url(#paint30_radial_2758_27554)'
          strokeLinecap='round'
          strokeMiterlimit='10'
          strokeWidth='1.938'
          d='M37.742 41.934c0 2.025-1.51 3.67-3.37 3.67S31 43.958 31 41.933'
        ></path>
        <g filter='url(#filter4_f_2758_27554)'>
          <path
            stroke='url(#paint31_linear_2758_27554)'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='0.484'
            d='M24.242 41.934c.08 1.009 1.035 3.063 3.118 3.209 2.604.181 3.512-1.877 3.603-3.21-.05 1.07 1.12 3.361 3.572 3.361 2.107 0 3.098-2.29 3.21-3.36'
          ></path>
        </g>
        <g filter='url(#filter5_f_2758_27554)'>
          <path
            fill='#AEABB5'
            d='M28.493 35.777l3.662-.345c1.411-.133 2.24 1.157 1.612 2.28-.73 1.302-1.842 2.183-1.842 2.183-.365.299-.76.474-1.149.508-.388.04-.81-.059-1.224-.285 0 0-1.257-.657-2.217-1.8-.828-.986-.254-2.407 1.158-2.54z'
          ></path>
        </g>
        <g>
          <path
            fill='url(#paint32_linear_2758_27554)'
            d='M29.272 35.1h3.437c1.324 0 1.982 1.405 1.299 2.498-.792 1.267-1.904 2.064-1.904 2.064-.366.272-.749.413-1.114.41-.364.003-.747-.138-1.113-.41 0 0-1.112-.797-1.904-2.064-.684-1.093-.025-2.497 1.3-2.497z'
          ></path>
          <path
            fill='url(#paint33_linear_2758_27554)'
            d='M29.272 35.1h3.437c1.324 0 1.982 1.405 1.299 2.498-.792 1.267-1.904 2.064-1.904 2.064-.366.272-.749.413-1.114.41-.364.003-.747-.138-1.113-.41 0 0-1.112-.797-1.904-2.064-.684-1.093-.025-2.497 1.3-2.497z'
          ></path>
          <path
            fill='url(#paint34_linear_2758_27554)'
            d='M29.272 35.1h3.437c1.324 0 1.982 1.405 1.299 2.498-.792 1.267-1.904 2.064-1.904 2.064-.366.272-.749.413-1.114.41-.364.003-.747-.138-1.113-.41 0 0-1.112-.797-1.904-2.064-.684-1.093-.025-2.497 1.3-2.497z'
          ></path>
          <path
            fill='url(#paint35_radial_2758_27554)'
            d='M29.272 35.1h3.437c1.324 0 1.982 1.405 1.299 2.498-.792 1.267-1.904 2.064-1.904 2.064-.366.272-.749.413-1.114.41-.364.003-.747-.138-1.113-.41 0 0-1.112-.797-1.904-2.064-.684-1.093-.025-2.497 1.3-2.497z'
          ></path>
          <path
            fill='url(#paint36_radial_2758_27554)'
            d='M29.272 35.1h3.437c1.324 0 1.982 1.405 1.299 2.498-.792 1.267-1.904 2.064-1.904 2.064-.366.272-.749.413-1.114.41-.364.003-.747-.138-1.113-.41 0 0-1.112-.797-1.904-2.064-.684-1.093-.025-2.497 1.3-2.497z'
          ></path>
        </g>
        <g filter='url(#filter6_f_2758_27554)'>
          <path
            fill='url(#paint37_linear_2758_27554)'
            d='M55.901 38.33c-1.19-9.935-5.32-18.536-13.205-22.024-1.02-.452-2.18.03-2.707 1.012-.473.883-.317 1.965.35 2.712 4.168 4.66 6.85 9.813 7.13 18.137.102 3.063 3.163 5.34 5.998 4.176 1.613-.663 2.642-2.283 2.434-4.014z'
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_f_2758_27554'
          width='4.844'
          height='7.723'
          x='20.215'
          y='27.139'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_2758_27554'
            stdDeviation='0.484'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter1_f_2758_27554'
          width='4.844'
          height='7.723'
          x='35.715'
          y='26.974'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_2758_27554'
            stdDeviation='0.484'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter2_f_2758_27554'
          width='22.809'
          height='20.803'
          x='18.801'
          y='33.813'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_2758_27554'
            stdDeviation='0.727'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter3_f_2758_27554'
          width='16.585'
          height='7.157'
          x='22.666'
          y='40.462'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_2758_27554'
            stdDeviation='0.388'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter4_f_2758_27554'
          width='14.957'
          height='4.813'
          x='23.516'
          y='41.207'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_2758_27554'
            stdDeviation='0.242'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter5_f_2758_27554'
          width='9.74'
          height='7.701'
          x='25.597'
          y='34.066'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_2758_27554'
            stdDeviation='0.678'
          ></feGaussianBlur>
        </filter>
        <filter
          id='filter6_f_2758_27554'
          width='31.715'
          height='42.018'
          x='31.965'
          y='8.385'
          colorInterpolationFilters='sRGB'
          filterUnits='userSpaceOnUse'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix'></feFlood>
          <feBlend
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          ></feBlend>
          <feGaussianBlur
            result='effect1_foregroundBlur_2758_27554'
            stdDeviation='3.875'
          ></feGaussianBlur>
        </filter>
        <linearGradient
          id='paint0_linear_2758_27554'
          x1='48.712'
          x2='49.439'
          y1='25.974'
          y2='15.076'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C8C8CD'></stop>
          <stop offset='1' stopColor='#C8C8CD' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_2758_27554'
          x1='37.572'
          x2='45.14'
          y1='12.17'
          y2='13.32'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C8C8CD'></stop>
          <stop offset='1' stopColor='#C8C8CD' stopOpacity='0'></stop>
        </linearGradient>
        <radialGradient
          id='paint2_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-141.865 31.288 1.047) scale(17.5508 11.8328)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.909' stopColor='#C8C8CD' stopOpacity='0'></stop>
          <stop offset='0.999' stopColor='silver'></stop>
        </radialGradient>
        <radialGradient
          id='paint3_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(12.77538 17.67966 -11.986 8.66112 42.355 3.754)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.909' stopColor='#C8C8CD' stopOpacity='0'></stop>
          <stop offset='0.976' stopColor='silver'></stop>
        </radialGradient>
        <radialGradient
          id='paint4_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(142.716 25.155 15.19) scale(6.69666 7.00723)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff'></stop>
          <stop offset='1' stopColor='#fff' stopOpacity='0'></stop>
        </radialGradient>
        <linearGradient
          id='paint5_linear_2758_27554'
          x1='49.262'
          x2='46.234'
          y1='12.654'
          y2='16.922'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#B8B8BD'></stop>
          <stop offset='1' stopColor='#C6C7C9'></stop>
        </linearGradient>
        <linearGradient
          id='paint6_linear_2758_27554'
          x1='13.231'
          x2='13.11'
          y1='23.492'
          y2='9.809'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C8C8CD'></stop>
          <stop offset='1' stopColor='#C8C8CD' stopOpacity='0'></stop>
        </linearGradient>
        <radialGradient
          id='paint7_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-175.225 16.064 8.125) scale(26.1866 16.6074)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.913' stopColor='#C8C8CD' stopOpacity='0'></stop>
          <stop offset='0.976' stopColor='silver'></stop>
        </radialGradient>
        <radialGradient
          id='paint8_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(127.193 7.288 8.618) scale(7.41178 8.48392)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff'></stop>
          <stop offset='1' stopColor='#fff' stopOpacity='0'></stop>
        </radialGradient>
        <radialGradient
          id='paint9_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-23.834 44.922 -14.434) scale(8.4625 3.60081)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D2D2D5'></stop>
          <stop offset='1' stopColor='#B8B8BD'></stop>
        </radialGradient>
        <radialGradient
          id='paint10_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-51.21 25.588 -3.308) scale(8.3117 5.41418)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.755' stopColor='#ADADB2' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#98989C'></stop>
        </radialGradient>
        <radialGradient
          id='paint11_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(0 54.3711 -53.1459 0 31.642 15.016)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#EEEDF4'></stop>
          <stop offset='1' stopColor='#DFDAE7'></stop>
        </radialGradient>
        <radialGradient
          id='paint12_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(0 -38.8711 59.0229 0 30.994 77.016)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C4AFDD'></stop>
          <stop offset='1' stopColor='#B6A4CC' stopOpacity='0'></stop>
        </radialGradient>
        <radialGradient
          id='paint13_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(0 47.0265 -53.5293 0 30.994 11.583)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.882' stopColor='#B9A6D1' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#ADA0BD'></stop>
        </radialGradient>
        <radialGradient
          id='paint14_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(76.378 8.135 25.492) scale(52.6239 57.41)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.811' stopColor='#9A88AF' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#9A88AF'></stop>
        </radialGradient>
        <radialGradient
          id='paint15_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(-47.3477 0 0 -34.6328 48.926 37.406)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.877' stopColor='#DBDADD' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#DBDADD'></stop>
        </radialGradient>
        <radialGradient
          id='paint16_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-104.781 31.32 7.807) scale(30.8521 38.8854)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.848' stopColor='#D0D1D2' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#C9C9C9'></stop>
        </radialGradient>
        <radialGradient
          id='paint17_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(18.323 -95.067 68.218) scale(44.1557 38.5339)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.848' stopColor='#D0D1D2' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#C9C9C9'></stop>
        </radialGradient>
        <linearGradient
          id='paint18_linear_2758_27554'
          x1='24.695'
          x2='23.013'
          y1='29.868'
          y2='29.868'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.225' stopColor='#594C4F'></stop>
          <stop offset='1' stopColor='#392833'></stop>
        </linearGradient>
        <linearGradient
          id='paint19_linear_2758_27554'
          x1='25.149'
          x2='25.149'
          y1='32.851'
          y2='30.095'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#492847'></stop>
          <stop offset='1' stopColor='#552D52' stopOpacity='0'></stop>
        </linearGradient>
        <radialGradient
          id='paint20_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-104.85 23.582 6.281) scale(3.38669 3.65088)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.814' stopColor='#594C4F' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#594C4F'></stop>
        </radialGradient>
        <linearGradient
          id='paint21_linear_2758_27554'
          x1='40.195'
          x2='38.513'
          y1='29.868'
          y2='29.868'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.225' stopColor='#594C4F'></stop>
          <stop offset='1' stopColor='#392833'></stop>
        </linearGradient>
        <linearGradient
          id='paint22_linear_2758_27554'
          x1='40.649'
          x2='40.649'
          y1='32.851'
          y2='30.095'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#492847'></stop>
          <stop offset='1' stopColor='#552D52' stopOpacity='0'></stop>
        </linearGradient>
        <radialGradient
          id='paint23_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(-104.85 31.332 .318) scale(3.38669 3.65088)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.814' stopColor='#594C4F' stopOpacity='0'></stop>
          <stop offset='1' stopColor='#594C4F'></stop>
        </radialGradient>
        <linearGradient
          id='paint24_linear_2758_27554'
          x1='29.799'
          x2='23.038'
          y1='46.016'
          y2='54.008'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#C7BED5'></stop>
          <stop offset='1' stopColor='#B7A7D1' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient
          id='paint25_linear_2758_27554'
          x1='30.992'
          x2='30.992'
          y1='33.893'
          y2='50.666'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff'></stop>
          <stop offset='0' stopColor='#fff'></stop>
          <stop offset='1' stopColor='#E9DEFA'></stop>
        </linearGradient>
        <radialGradient
          id='paint26_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(0 -7.64072 20.5577 0 29.912 51.828)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#D3BEF1'></stop>
          <stop offset='1' stopColor='#DACBF0' stopOpacity='0'></stop>
        </radialGradient>
        <radialGradient
          id='paint27_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(0 -18.9999 19.594 0 30.992 52.24)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.837' stopColor='#D8D8DC' stopOpacity='0'></stop>
          <stop offset='0.97' stopColor='#D8D8DC'></stop>
        </radialGradient>
        <radialGradient
          id='paint28_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(0 3.33008 -4.23998 0 27.602 43.015)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.006' stopColor='#423539'></stop>
          <stop offset='1' stopColor='#3C223C'></stop>
        </radialGradient>
        <radialGradient
          id='paint29_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(1.87695 5.44921 -13.5763 4.67629 27.825 41.039)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.006' stopColor='#533530'></stop>
          <stop offset='1' stopColor='#543057'></stop>
        </radialGradient>
        <radialGradient
          id='paint30_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(96.859 -1.722 36.946) scale(3.30158 9.2638)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.006' stopColor='#533530'></stop>
          <stop offset='1' stopColor='#543057'></stop>
        </radialGradient>
        <linearGradient
          id='paint31_linear_2758_27554'
          x1='23.879'
          x2='38.44'
          y1='42.903'
          y2='42.903'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5F5356'></stop>
          <stop offset='0.289' stopColor='#5F5356' stopOpacity='0'></stop>
          <stop offset='0.445' stopColor='#5F5356' stopOpacity='0'></stop>
          <stop offset='0.534' stopColor='#5F5356'></stop>
          <stop offset='1' stopColor='#5F5356' stopOpacity='0'></stop>
        </linearGradient>
        <linearGradient
          id='paint32_linear_2758_27554'
          x1='30.183'
          x2='31.145'
          y1='35.711'
          y2='39.167'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5F5254'></stop>
          <stop offset='1' stopColor='#2E2127'></stop>
        </linearGradient>
        <linearGradient
          id='paint33_linear_2758_27554'
          x1='30.532'
          x2='30.532'
          y1='36.405'
          y2='34.072'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.147' stopColor='#34222C' stopOpacity='0'></stop>
          <stop offset='0.873' stopColor='#34222C'></stop>
        </linearGradient>
        <linearGradient
          id='paint34_linear_2758_27554'
          x1='29.364'
          x2='29.364'
          y1='40.318'
          y2='36.791'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#552D52'></stop>
          <stop offset='1' stopColor='#552D52' stopOpacity='0'></stop>
        </linearGradient>
        <radialGradient
          id='paint35_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='matrix(8.22408 -2.79202 1.25368 3.69281 26.843 38.583)'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.828' stopColor='#5C5458' stopOpacity='0'></stop>
          <stop offset='0.935' stopColor='#5C5458'></stop>
        </radialGradient>
        <radialGradient
          id='paint36_radial_2758_27554'
          cx='0'
          cy='0'
          r='1'
          gradientTransform='rotate(145.376 10.886 23.365) scale(1.54513 .93725)'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6A5C5E'></stop>
          <stop offset='1' stopColor='#6A5C5E' stopOpacity='0'></stop>
        </radialGradient>
        <linearGradient
          id='paint37_linear_2758_27554'
          x1='55.552'
          x2='39.635'
          y1='23.726'
          y2='32.541'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff'></stop>
          <stop offset='1' stopColor='#fff' stopOpacity='0'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PolarBear;
