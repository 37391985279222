import {
  ChangeFrequentQuestions,
  marketplaceActions,
} from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import React, { useCallback } from 'react';

import { SingleQuestion } from './single-question';

export const FrequentQuestions = () => {
  const { frequentQuestions } = useAppSelector(
    (state) => state.marketplace.courseForm
  );
  const dispatch = useAppDispatch();
  const onSwap = useCallback(
    (index: number, destIndex: number) => {
      dispatch(marketplaceActions.onSwapFrequentQuestion({ destIndex, index }));
    },
    [dispatch]
  );
  const onDelete = useCallback(
    (index: number) => {
      dispatch(marketplaceActions.deleteFrequentQuestion(index));
    },
    [dispatch]
  );
  const onChange = useCallback(
    (data: ChangeFrequentQuestions) => {
      dispatch(marketplaceActions.changeFrequentQuestions(data));
    },
    [dispatch]
  );

  return (
    <>
      <div className={'flex items-center justify-between'}>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Частые вопросы
        </h1>
      </div>
      {frequentQuestions.map((elem, i) => (
        <SingleQuestion
          {...elem}
          index={i}
          itemsCount={frequentQuestions.length}
          onSwap={onSwap}
          onChange={onChange}
          onDelete={onDelete}
          key={elem.id}
        />
      ))}
      <button
        data-testid={'button-add_question'}
        className={
          'width-content text-[18px] leading-[24px] text-blue200 transition-all duration-150 hover:text-blue300'
        }
        onClick={() => dispatch(marketplaceActions.addFrequentQuestion())}
      >
        Добавить еще
      </button>
    </>
  );
};
