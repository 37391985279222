export function formatDateAndTime(time: any, withNoTime?: boolean) {
  const date = new Date(time);

  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(2);

  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  if (withNoTime) {
    return `${day}.${month}.${year}`;
  }

  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}

export function convertDateFormat(originalDate: any) {
  const dateObject = new Date(originalDate);

  const year = dateObject.getUTCFullYear();
  const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = dateObject.getUTCDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}
