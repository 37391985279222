import { useAppSelector } from '@/shared/model';
import { useState } from 'react';

import BannersView from './Banners-View';

const BannersContainer = () => {
  const banners = useAppSelector((state) => state.banners.banners);
  const [editOpen, setEditOpen] = useState(false);
  return (
    <BannersView
      banners={banners}
      editOpen={editOpen}
      setEditOpen={setEditOpen}
    />
  );
};

export default BannersContainer;
