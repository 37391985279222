import { foodsActions as actions } from '@/entities/foods';
import { useCheckImagesSizes } from '@/shared/lib/hooks/useCheckImageSizes';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import DropInput from '@/shared/ui/DropInput/DropInput';

export const ImageInput = () => {
  const dispatch = useAppDispatch();
  const checkImages = useCheckImagesSizes();
  const { image } = useAppSelector((state) => state.foods.form.main);
  const onChange = async (files: File[]) => {
    const err = await checkImages({
      files: files,
      minHeight: 750,
      minWidth: 750,
    });
    if (err) {
      dispatch(
        setNotification({
          variant: 'error',
          value: 'Ошибка при загрузке (маленький размер)',
        })
      );
      return;
    }
    dispatch(actions.changeRecipesForm({ key: 'image', value: files }));
  };
  return (
    <DropInput
      setFiles={([files]: any) => onChange(files)}
      withIcon
      sizes={'(750x750 px)'}
      // onDelete={() => onClear('image')}
      files={image}
      label='image'
      htmlFor={'image'}
      format='dropzone'
      title={'Фото'}
      // setValue={setValue}
      required
      name='image'
      id='image'
    />
  );
};
