import { IAuth } from '@/entities/auth/types/IAuth';
import axios from 'axios';
import { getCookie, setCookie } from 'typescript-cookie';

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const apiWithoutTokens = axios.create({
  baseURL: BASE_URL,
});

export const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${getCookie('jwt')}`;
  return request;
});

api.interceptors.response.use(
  (request) => request,
  async (error) => {
    if (error.response.status === 401) {
      const originalRequest = error.config;
      const response = await apiWithoutTokens.get<IAuth>(
        '/auth/refresh/token',
        {
          params: {
            refreshToken: getCookie('refresh'),
          },
        }
      );
      setCookie('jwt', response.data.accessToken);
      return api.request(originalRequest);
    }
    throw error;
  }
);
