import { useEffect, useState } from 'react';

export const useIsScrolled = (height = 150) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > height && !isScrolled) {
        setIsScrolled(true);
      } else if (currentScrollY === 0 && isScrolled) {
        setIsScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [height, isScrolled]);
  return isScrolled;
};
