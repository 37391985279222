/* eslint-disable react-hooks/exhaustive-deps */
import { reviewsActions } from '@/entities/reviews/model/reviewsSlice';
import {
  deleteReview,
  fetchAllReviews,
  publicateReview,
  sendOnModerationReview,
} from '@/entities/reviews/model/reviewsThunk';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { memo, useEffect, useMemo, useState } from 'react';
import { Template } from 'src/widgets/Template';

import Loading from '../../views/Loading/Loading';
import Card from '../views/ReviewCard';
import NoContent from './NoContent';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const ReviewsCard = () => {
  const dispatch = useAppDispatch();
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const reviews = useAppSelector((state) => state.reviews.reviews);
  const isLoading = useAppSelector((state) => state.reviews.isLoading);
  const searchValue = useAppSelector((state) => state.reviews.searchValue);
  const limit = useAppSelector((state) => state.reviews.limit);
  const offset = useAppSelector((state) => state.reviews.currentPage);
  const activeFilters = useAppSelector((state) => state.reviews.filters);
  const activeSort = useAppSelector((state) => state.reviews.sort);
  const createdAd = useAppSelector((state) => state.reviews.createdAd);
  const rate = useAppSelector((state) => state.reviews.rate);
  const filterParam = activeFilters.map((item) => item);

  const paramsFinalObject = useMemo(() => {
    if (filterParam.length === 1) {
      return {
        limit,
        offset,
        filters: filterParam[0].param,
        createdAd: createdAd,
        rate: rate,
        search: searchValue,
      };
    }
    if (filterParam.length === 0 || filterParam.length === 2) {
      return {
        limit,
        offset,
        createdAd: createdAd,
        rate: rate,
        search: searchValue,
      };
    }
  }, [limit, offset, filterParam, createdAd, rate, searchValue]);

  useEffect(() => {
    if (searchValue || filterParam) {
      dispatch(reviewsActions.changeLimit());
    }
  }, [searchValue, filterParam]);

  const handleDeleteReview = (id: number) => {
    dispatch(deleteReview(id)).then(() => {
      dispatch(setNotification({ value: 'Отзыв удалён', variant: 'success' }));
      dispatch(reviewsActions.closeDeleteModal());
    });
  };

  const handleSendOnModeration = (id: number) => {
    dispatch(sendOnModerationReview(id)).then(() => {
      dispatch(
        setNotification({
          value: 'Отзыв отправлен на модерацию',
          variant: 'success',
        })
      );
    });
  };

  const handlePublicateReview = (id: number) => {
    dispatch(publicateReview(id)).then(() => {
      dispatch(
        setNotification({ value: 'Отзыв опубликован', variant: 'success' })
      );
    });
  };

  useEffect(() => {
    dispatch(fetchAllReviews(paramsFinalObject));
  }, [limit, offset, activeFilters, rate, createdAd, activeSort, searchValue]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(reviewsActions.clearReviews())
  //   }
  // }, [])

  if ((isLoading && offset === 0) || !isOnline) {
    return <Loading />;
  }

  return (
    <>
      {reviews.length !== 0 ? (
        <Template>
          {reviews.map((elem) => (
            <Card
              id={elem.id}
              isModerated={elem.isModerated}
              comment={elem.comment}
              key={elem.id}
              name={elem.name ? elem.name : '-'}
              date={elem.createdAt}
              company={elem.company}
              product={elem.product}
              onDelete={handleDeleteReview}
              onModeration={handleSendOnModeration}
              onPublicate={handlePublicateReview}
              rate={elem.rate}
            />
          ))}
        </Template>
      ) : (
        <NoContent
          text={
            searchValue.length > 0
              ? 'Такого отзыва у нас нет, попробуйте изменить запрос'
              : 'Отзывов не найдено'
          }
        />
      )}
      {isLoading && offset !== 0 && <Loading />}
      {limit === 18 && (
        <div
          data-testid={'button-load_more'}
          className='border-gray-[1px] m-auto flex h-[50px] w-[150px] cursor-pointer items-center justify-center rounded-[100px] border text-center text-base text-[#808080]'
          onClick={() => dispatch(reviewsActions.updateCurrentPage(offset + 1))}
        >
          Загрузить ещё
        </div>
      )}
    </>
  );
};

export default memo(ReviewsCard);
