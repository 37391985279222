import { useAppSelector } from '@/shared/model';
import { useState } from 'react';

import CategoriesView from './Categories-View';
import './Categories.css';

const CategoriesContainer = () => {
  const categories = useAppSelector((store) => store.categories.categories);
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <CategoriesView
      categories={categories}
      setModalVisible={setModalVisible}
      modalVisible={modalVisible}
    />
  );
};

export default CategoriesContainer;
