/* eslint-disable react-hooks/exhaustive-deps */
import { reviewsActions } from '@/entities/reviews/model/reviewsSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import sx from 'classnames';
import React, { FC, memo, useEffect, useState } from 'react';
import CheckCircle from 'src/assets/svg/checkCircle';
import DeleteRev from 'src/assets/svg/deleteRev';
import Restart from 'src/assets/svg/restart';

import ButtonRev from '../ButtonRev';
import './some.css';
import Comment from './Components/Comment';
import Company from './Components/Company';
import ModerationStatus from './Components/ModerationStatus';
import PostInfo from './Components/PostInfo';

interface Props {
  id: number;
  comment: string | null;
  date: Date;
  name: string;
  rate: 1 | 2 | 3 | 4 | 5;
  isModerated: boolean;
  product?: { title: string; id: number } | null;
  company?: { title: string; id: number } | null;
  onDelete: (id: number) => void;
  onModeration: (id: number) => void;
  onPublicate: (id: number) => void;
}

const Card: FC<Props> = ({
  comment,
  isModerated,
  id,
  date,
  name,
  product,
  company,
  onPublicate,
  onModeration,
  rate,
}) => {
  const [expanded, setExpanded] = useState(false);
  const truncatedComment = comment?.split('')?.slice(0, 120);
  const [isTrunc, setTrunc] = useState(comment?.split('').length > 130);
  const fadedWord = truncatedComment?.slice(-5).reverse().join('');
  const notFadedText = truncatedComment?.slice(0, -5).join('');
  const activeFilters = useAppSelector((state) => state.reviews.filters);
  const activeSort = useAppSelector((state) => state.reviews.sort);
  const searchValue = useAppSelector((state) => state.reviews.searchValue);

  const isShown = useAppSelector(
    (state) => state.reviews.isDeleteModal.isShown
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isTrunc && expanded) {
      setTrunc(false);
      setExpanded(false);
    }
  }, [activeFilters, activeSort, searchValue, comment]);

  // console.log(isTrunc, truncatedComment?.length > 120, truncatedComment.length, fullComment?.length)

  useEffect(() => {
    const container = document.getElementById(`expandable-container-${id}`);
    if (container) {
      container.style.height = expanded ? '100%' : isTrunc && '342px';
    }
  }, [expanded, isTrunc, id]);

  useEffect(() => {
    if (isShown) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isShown]);

  const [tooltip, setTooltip] = useState<{ trashTooltip: boolean }>({
    trashTooltip: false,
  });

  useEffect(() => {
    return () => {
      setTrunc(false);
      setExpanded(false);
    };
  }, []);

  return (
    <div
      id={isTrunc ? `expandable-container-${id}` : undefined}
      className={sx(
        'justify-star flex h-full max-w-[410.67px] flex-col items-start self-stretch overflow-visible rounded-[24px] border border-gray200 p-[24px]',
        'min-h-[270px]',
        'transition-height duration-300 ease-in-out'
      )}
    >
      <ModerationStatus isModerated={isModerated} />
      <PostInfo name={name && name} date={date} rate={rate} />
      <Company company={company} product={product} />
      <Comment
        expanded={expanded}
        truncatedComment={truncatedComment}
        fadedWord={fadedWord}
        comment={comment && comment}
        notFadedText={notFadedText}
        setExpanded={setExpanded}
        isTrunc={isTrunc}
      />

      <div className={`${sx('flex w-[100%] gap-2')} mt-auto`}>
        {isModerated ? (
          <div className='w-full md:w-[300px]'>
            <ButtonRev
              onClick={() => onModeration(id)}
              data-testid={`button-moderate-${id}`}
            >
              <Restart /> На модерацию
            </ButtonRev>
          </div>
        ) : (
          <ButtonRev
            onClick={() => onPublicate(id)}
            data-testid={`button-publish-${id}`}
          >
            <CheckCircle /> Опубликовать
          </ButtonRev>
        )}
        <div
          className='relative flex flex-col gap-1'
          onMouseEnter={() =>
            setTooltip((prevTooltip) => ({
              ...prevTooltip,
              trashTooltip: true,
            }))
          }
          onMouseLeave={() =>
            setTooltip((prevTooltip) => ({
              ...prevTooltip,
              trashTooltip: false,
            }))
          }
        >
          <ButtonRev
            data-testid={`button-delete-${id}`}
            onClick={() => dispatch(reviewsActions.openDeleteModal(id))}
            className='w-full md:w-[4vw]'
            isDelete
          >
            <DeleteRev />
          </ButtonRev>
          <Tooltip
            isSmaller
            isOpen={tooltip.trashTooltip}
            text={'Удалить отзыв'}
            tooltipClassName='top-[-80px] text-[13px] leading-[22px]'
          >
            <></>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default memo(Card);
