/* eslint-disable react-hooks/exhaustive-deps */

import {
  ChangeContents,
  marketplaceActions,
} from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useCallback } from 'react';

import { SingleContent } from './single-content';

export const Contents = () => {
  const dispatch = useAppDispatch();
  const { contents } = useAppSelector((state) => state.marketplace.courseForm);
  const onDelete = useCallback((id: number) => {
    dispatch(marketplaceActions.deleteContent(id));
  }, []);

  const onSwap = useCallback((index: number, destIndex: number) => {
    dispatch(marketplaceActions.onSwapContents({ index, destIndex }));
  }, []);

  const onChange = useCallback((data: ChangeContents) => {
    const { index, key, value } = data;
    dispatch(marketplaceActions.changeContents({ index, key, value }));
  }, []);
  return (
    <>
      <div className={'flex items-center justify-between'}>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Содержание
        </h1>
      </div>
      {contents.map((elem, i) => (
        <SingleContent
          itemsCount={contents.length}
          index={i}
          onChange={onChange}
          onDelete={onDelete}
          onSwap={onSwap}
          key={elem.id}
          {...elem}
        />
      ))}
      <button
        data-testid={'button-add-content'}
        className={
          'text-[18px] leading-[24px] text-blue200 transition-all duration-150 hover:text-blue300'
        }
        onClick={() => dispatch(marketplaceActions.addContents())}
      >
        Добавить еще
      </button>
    </>
  );
};
