import { api } from '../../../shared/api';

class StoriesService {
  createStorie(formData: FormData) {
    return api.post('/categories/story', formData);
  }

  editStory(id: number, formData: FormData) {
    return api.patch(`/categories/story/${id}`, formData);
  }

  deleteStory(id: number) {
    return api.delete(`/categories/story/${id}`);
  }
}
const storiesService = new StoriesService();
export default storiesService;
