import { unionTimePeriodType } from '@/entities/statistics/types/IStatistics';
import { api } from '@/shared/api';
import { apiPaginate, PaginateType } from '@/shared/lib/hooks/usePaginate';

class StatisticsService {
  getAllUsers(
    {
      lastActivityAt,
      search,
      regTypes,
    }: {
      lastActivityAt?: 'ASC' | 'DESC';
      search?: string;
      regTypes?: string[];
    },
    pagination?: { limit: number; page: number }
  ) {
    const params = new URLSearchParams({
      ['orderBy[lastActivityAt]']: lastActivityAt,
    });
    if (pagination?.limit && pagination?.page) {
      apiPaginate(pagination, params);
    }

    if (regTypes?.length) {
      params.append('filters[authTypes]', regTypes.toString());
    }
    if (search) {
      params.append('search', search);
    }
    return api.get(`/users?${params.toString()}`);
  }

  getCountUsers() {
    return api.get('/user/count');
  }

  getAllEmailUsers(createdAt: 'ASC' | 'DESC', pagination: PaginateType) {
    const params = new URLSearchParams({
      ['orderBy[createdAt]']: createdAt,
    });
    apiPaginate(pagination, params);
    return api.get(`/registrations?${params.toString()}`);
  }

  getAllBoughtProducts(
    search?: string,
    filters?: any,
    createdAt?: 'ASC' | 'DESC',
    pagination?: PaginateType
  ) {
    const params = new URLSearchParams({});

    if (search) {
      params.append('search', search);
    }

    if (filters[0].length !== 0) {
      const encodedFilters = filters[0]
        .map((filter: string | number | boolean) => encodeURIComponent(filter))
        .join(',');
      params.append('filters[requestSources]', encodedFilters);
    }
    apiPaginate(pagination, params);

    if (
      (filters[0].length === 0 && search) ||
      (filters[0].length !== 0 && !search) ||
      (filters[0].length === 0 && !search)
    ) {
      params.append('orderBy[createdAt]', createdAt);
    }

    return api.get(`/v2/orders?${params.toString()}`);
  }

  getAllStatistics(period: unionTimePeriodType) {
    return api.get(`/statistics?filters[period]=${period}`);
  }
}
const statisticsService = new StatisticsService();
export default statisticsService;
