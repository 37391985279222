import { setState } from '@/shared/interfaces/common/setState';
import * as UI from '@/shared/ui';
import NewButton from '@/shared/ui/NewButton/NewButton';
import { FC } from 'react';

interface Props {
  opened: boolean;
  setOpened: setState<boolean>;
  onPin: () => void;
}

export const PinArticle: FC<Props> = ({ opened, setOpened, onPin }) => {
  return (
    <UI.Modal opened={opened} onClose={() => setOpened(false)}>
      <div className='flex flex-col items-center'>
        <h2 className='mb-[16px] text-center text-[32px] font-medium not-italic leading-[40px]'>
          Закрепить статью?
        </h2>
        <p className='mb-[32px] text-center font-[Rubik] text-[20px] font-normal not-italic leading-[26px]'>
          В разделе может быть только одна закрепленная статья,
          <br />
          после публикации этой статьи текущий закреп
          <br />
          будет отменен
        </p>
        <div className='flex w-[360px] flex-col gap-[24px]'>
          <NewButton
            data-testid={'button-pin'}
            variant='primary'
            styles='w-full'
            onClick={() => {
              onPin();
              setOpened(false);
            }}
          >
            Закрепить
          </NewButton>
          <span className='flex h-[23px] flex-col self-center'>
            <NewButton
              data-testid={'button-cancel_pin'}
              variant='blue'
              onClick={() => setOpened(false)}
            >
              Отмена
            </NewButton>
          </span>
        </div>
      </div>
    </UI.Modal>
  );
};
