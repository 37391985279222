import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useCheckImagesSizes } from '@/shared/lib/hooks/useCheckImageSizes';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import DropInput from '@/shared/ui/DropInput/DropInput';

export const CoverInput = () => {
  const dispatch = useAppDispatch();
  const checkImages = useCheckImagesSizes();
  const { cover } = useAppSelector(
    (state) => state.marketplace.courseForm.form
  );
  const onChange = async ([files]: File[][]) => {
    const err = await checkImages({ files, minHeight: 1024, minWidth: 1024 });
    if (err) {
      dispatch(
        setNotification({
          variant: 'error',
          value: 'Ошибка при загрузке (маленький размер)',
        })
      );
      return;
    }
    dispatch(
      marketplaceActions.changeCoursesForm({ key: 'cover', value: files })
    );
  };
  const onDelete = () => {
    dispatch(
      marketplaceActions.changeCoursesForm({ key: 'cover', value: null })
    );
  };

  return (
    <DropInput
      data-testid={'dropzone-cover'}
      withIcon
      label='cover'
      htmlFor={'cover'}
      format='dropzone'
      title={'Обложка'}
      sizes='(1024x1024 px)'
      required
      name='cover'
      id='cover'
      files={cover}
      setFiles={onChange}
      onDelete={onDelete}
    />
  );
};
