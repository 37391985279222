import { useCreateCourse } from '@/pages/Marketplace/course/model/use-create-course';
import FormTabs from '@/shared/ui/FormTabs/FormTabs';
import FormWrapper from '@/shared/ui/FormWrapper/FormWrapper';
import Header from '@/widgets/Header/Header';

const CreateCourse = () => {
  const { activeTab, tabs, changeTab } = useCreateCourse();
  return (
    <div className={'bg-gray100'}>
      <div className={'mx-auto flex min-h-screen max-w-[1280px] flex-col'}>
        <Header />
        <FormWrapper className={'pt-[40px]'}>
          <FormTabs
            backLink={'/marketplace/courses'}
            tabs={tabs}
            active={activeTab.value}
            onClick={changeTab}
          />
          <div
            className={
              'mb-[80px] flex h-full w-full flex-col gap-[40px] rounded-[32px] bg-white p-[40px]'
            }
          >
            {activeTab?.tab}
          </div>
        </FormWrapper>
      </div>
    </div>
  );
};

export default CreateCourse;
