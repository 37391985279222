import { foodsActions as actions } from '@/entities/foods';
import { fetchStatic } from '@/entities/foods/model/thunk';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useEffect } from 'react';
export const useFilters = () => {
  const dispatch = useAppDispatch();
  const { categories, ages, measures } = useAppSelector((state) => state.foods);
  const { category, search, age, isMarked } = useAppSelector(
    (state) => state.foods.filters
  );

  const handleAddFilter = (e: any, i: any) => {
    dispatch(
      actions.changeFilters({
        key: 'category',
        value:
          i?.id === category?.id
            ? null
            : {
                id: i.id,
                title: i.label,
              },
      })
    );
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.changeFilters({ key: 'search', value: e.target.value }));
  };
  const toggleMark = () => {
    dispatch(actions.changeFilters({ key: 'isMarked', value: !isMarked }));
    dispatch(actions.changeFilters({ key: 'age', value: null }));
  };

  const toggleAge = () => {
    dispatch(actions.changeFilters({ key: 'age', value: !age }));
    dispatch(actions.changeFilters({ key: 'isMarked', value: null }));
  };
  const onClear = () =>
    dispatch(actions.changeFilters({ value: '', key: 'search' }));

  const handleSelectCategory = () => {};

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchStatic());
      } catch (e) {}
    })();
  }, []);

  return {
    handleAddFilter,
    categories: categories.map((c) => ({ value: c.id, label: c.title })),
    ages,
    measures,
    category,
    search,
    age,
    isMarked,
    onChange,
    toggleMark,
    toggleAge,
    onClear,
    handleSelectCategory,
  };
};
