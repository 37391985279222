import { foodsActions as actions } from '@/entities/foods';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Input } from '@/shared/ui/Input/Input';
import { TextArea } from '@/shared/ui/TextArea/TextArea';

export const TextBlock = () => {
  const dispatch = useAppDispatch();
  const { title, description } = useAppSelector(
    (state) => state.foods.form.main
  );
  return (
    <>
      <Input
        type='type'
        htmlFor={'title'}
        title={'Название'}
        required
        value={title}
        onChange={(e) =>
          dispatch(
            actions.changeRecipesForm({
              key: 'title',
              value: e.target.value,
            })
          )
        }
        // isClose={watch('title')?.length > 0}
        // onClear={() => onClear('title')}
      />
      <TextArea
        required
        data-testid={'textarea-description'}
        label={'Описание'}
        name={'description'}
        id={'description'}
        value={description}
        length={0}
        onChange={(e) =>
          dispatch(
            actions.changeRecipesForm({
              key: 'description',
              value: e.target.value,
            })
          )
        }
      />
    </>
  );
};
