import { IApiRule, IRecommendation } from '@/entities/foods/types';
import { api as defaultApi } from '@/shared/api';

class Api {
  private readonly baseUrl: string;

  private api: typeof defaultApi;

  constructor({
    baseUrl,
    api = defaultApi,
  }: {
    baseUrl?: string;
    api: typeof defaultApi;
  }) {
    this.baseUrl = baseUrl;
    this.api = api;
  }

  getAll({ data }: { data?: any }) {
    return this.api.get(this.baseUrl + 'foods/', {
      params: {
        ...data,
        limit: 10000,
      },
    });
  }

  getSingle(id: string) {
    return this.api.get(this.baseUrl + 'foods/' + id);
  }

  delete(id: string) {
    return this.api.delete(this.baseUrl + 'foods/' + id);
  }

  create(data: any) {
    return this.api.post(this.baseUrl + 'foods/', data);
  }

  update(id: string, data: any) {
    return this.api.patch(this.baseUrl + 'foods/' + id, data);
  }

  getCategories() {
    return this.api.get(this.baseUrl + 'foodCategories');
  }

  getAges() {
    return this.api.get(this.baseUrl + 'ages');
  }

  getMeasures() {
    return this.api.get(this.baseUrl + 'measures');
  }

  getRecommendations(productId: number) {
    return this.api.get(this.baseUrl + `foods/${productId}/recommendations`);
  }

  addRecommendations(data: IRecommendation[], productId: number) {
    return this.api.post(
      this.baseUrl + `foods/${productId}/recommendations:batchCreate`,
      {
        recommendations: data.map((recommendation) => ({
          text: recommendation.text,
          order: recommendation.order,
        })),
      }
    );
  }

  updateRecommendations(data: IRecommendation[], productId: number) {
    return defaultApi.post(
      this.baseUrl + `foods/${productId}/recommendations:batchUpdate`,
      {
        recommendations: data,
      }
    );
  }

  deleteRecommendations(data: number[], productId: number) {
    return defaultApi.post(
      this.baseUrl + `foods/${productId}/recommendations:batchDelete`,
      {
        ids: data,
      }
    );
  }

  getRules(productId: number) {
    return defaultApi.get(this.baseUrl + `foods/${productId}/servingRules`);
  }

  addRules(data: IApiRule[], productId: number) {
    return defaultApi.post(
      this.baseUrl + `foods/${productId}/servingRules:batchCreate`,
      {
        servingRules: data,
      }
    );
  }

  updateRules(data: IApiRule[], productId: number) {
    return defaultApi.post(
      this.baseUrl + `foods/${productId}/servingRules:batchUpdate`,
      {
        servingRules: data,
      }
    );
  }

  deleteRules(data: number[], productId: number) {
    return defaultApi.post(
      this.baseUrl + `foods/${productId}/servingRules:batchDelete`,
      {
        ids: data,
      }
    );
  }
}
export const api = new Api({ baseUrl: '/', api: defaultApi });
