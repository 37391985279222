import './Stories.css';
import { useAppSelector } from '@/shared/model';
import { useState } from 'react';

import StoriesView from './Stories-View';

const StoriesContainer = () => {
  const stories = useAppSelector((store) => store.categories.stories);
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <StoriesView
      modalOpen={modalOpen}
      stories={stories}
      setModalOpen={setModalOpen}
    />
  );
};

export default StoriesContainer;
