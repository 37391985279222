import { LabelHTMLAttributes } from 'react';

type SizeVariants = 'medium' | 'regular';
const sizes: Record<SizeVariants, Record<string, string>> = {
  regular: {
    text: '18px',
    leading: '24px',
    width: 'regular',
  },
  medium: {
    text: '20px',
    leading: '26px',
    width: 'medium',
  },
};

export const Label = ({
  label,
  required,
  id,
  size = 'medium',
  ...props
}: {
  label: string;
  required?: boolean;
  id?: string;
  size?: SizeVariants;
} & LabelHTMLAttributes<HTMLLabelElement>) => (
  <label
    htmlFor={id}
    className={`w-fit text-[${sizes[size].text}] font-${sizes[size].width} leading-[${sizes[size].leading}]`}
    {...props}
  >
    {label} {!!required && <span className={'text-red500'}>*</span>}
  </label>
);
