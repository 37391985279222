import { ISelectItem } from '@/shared/interfaces/common/ISelectItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { forwardRef } from 'react';
interface Props {
  label: string;
  value: number | string;
  onChange: (data: any) => void;
  items: ISelectItem[];
  width?: number;
  fullWidth?: boolean;
  margin?: 'dense' | 'none';
}

export const UISelect = forwardRef<any, Props>(
  (
    {
      label,
      value,
      onChange,
      items,
      width = 175,
      fullWidth = false,
      margin = 'none',
      ...props
    },
    ref
  ) => {
    return (
      <FormControl sx={{ minWidth: width }} fullWidth={fullWidth}>
        <InputLabel id={label}>{label}</InputLabel>
        <Select
          data-testid={'select'}
          labelId={label}
          id={label}
          value={value}
          label={label}
          margin={margin}
          onChange={onChange}
          {...props}
        >
          {items.map((elem) => (
            <MenuItem key={elem.value} value={elem.value}>
              {elem.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
);
