import { IContent } from '@/entities/marketplace/types/IContent';
import { IFormCardProps } from '@/entities/marketplace/types/IFormCardProps';
import { Change } from '@/shared/interfaces/types';
import { Input } from '@/shared/ui/Input/SecondInput';
import { TextArea } from '@/shared/ui/TextArea/TextArea';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import FormCard from 'src/widgets/FormCard';

export const SingleContent = ({
  onChange,
  onDelete,
  onSwap,
  description,
  id,
  index,
  itemsCount,
  title,
  place,
}: IFormCardProps<Change<any, string>> & IContent) => {
  return (
    <FormCard
      index={index}
      itemsCount={itemsCount}
      key={id}
      onDelete={() => onDelete(id)}
      onSwap={onSwap}
    >
      <div className={'grid grid-cols-3 gap-x-[32px]'}>
        <Input
          data-testid={`input-name-${index}`}
          id={'name'}
          containerClassName={'col-span-2'}
          name={'name'}
          label={'Название'}
          value={title}
          onChange={(e) =>
            onChange({
              value: e.target.value,
              index,
              key: 'title',
            })
          }
          withClear
        />

        <div className={'relative'}>
          <Input
            data-testid={`input-contents-${index}`}
            id={'contents'}
            containerClassName={'col-span-1'}
            name={'contents'}
            label={'Содержание'}
            value={place}
            onChange={(e) =>
              onChange({
                value: e.target.value,
                index,
                key: 'place',
              })
            }
          />
          <Tooltip
            className={'absolute top-[68px] right-[20px]'}
            text={`Сокращайте:
- страницы (1-5 с.)

- таймкод(1 ч. 15 мин.)

- урок (1 ур.)
- задание (1 зад.)`}
          >
            <div
              className={
                'flex aspect-square h-[21.5px] items-center justify-center rounded-[8px] bg-gray-300 text-[18px] leading-[20px] text-white hover:bg-black'
              }
            >
              i
            </div>
          </Tooltip>
        </div>
        <div></div>
      </div>
      <TextArea
        data-testid={`textarea-description-${index}`}
        label={'Описание'}
        name={'description'}
        id={'description'}
        value={description}
        length={description?.length}
        onChange={(e) =>
          onChange({
            value: e.target.value,
            index,
            key: 'description',
          })
        }
        errorMessage={'Слишком длинное описание'}
        maxLength={132}
      />
    </FormCard>
  );
};
