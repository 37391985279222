import { ReactComponent as Sort } from '@/assets/svg/sort.svg';
import Search from '@/pages/Review/components/SearchInput';
import useFilterVisibility from '@/shared/lib/hooks/useFilterVisibility';
import { useIsScrolled } from '@/shared/lib/hooks/useIsScrolled';
import { FilterSelect } from '@/shared/ui/Filters';
import Status from '@/shared/ui/Filters/Status';
import classNames from 'classnames';

import { useFilters } from '../model/use-filters';
export const Filters = ({ basicGrid }: { basicGrid?: string }) => {
  const {
    handleAddFilter,
    categories,
    category,
    search,
    age,
    isMarked,
    onChange,
    toggleMark,
    toggleAge,
    onClear,
    handleSelectCategory,
  } = useFilters();
  const {
    isFilterVisible,
    filterSelectOptions,
    handleMouseEnter,
    handleMouseLeave,
  } = useFilterVisibility();
  const isScrolled = useIsScrolled(150);
  return (
    <>
      <div className='relative flex w-[1280px] gap-1'>
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className='relative min-w-[150px] flex-col bg-transparent'
        >
          <Status
            ref={filterSelectOptions}
            currentFilter={category?.title}
            name='Категория'
            onClick={handleSelectCategory}
          />
          {isFilterVisible && (
            <FilterSelect
              data-testid={'select-filter'}
              data={categories}
              handleAddFilter={handleAddFilter}
            />
          )}
        </div>
        <div className='flex-1'>
          <Search
            onChange={onChange}
            value={search}
            isReview
            isLetter={true}
            onClear={onClear}
          />
        </div>
      </div>
      <div
        className={classNames(
          `sticky top-[12%] z-10 mt-6 mb-[4px] flex h-14 
  w-[1280px] items-start justify-start gap-8
  self-stretch rounded-[16px] border-[1px] border-[#F3F3F3] bg-[#FFF] px-6 py-4 text-lg font-normal not-italic leading-6 text-[color:var(--Simple-Gray-Midnight,#7D7D7D)] transition-all duration-75`,
          basicGrid,
          isScrolled && 'shadow-[0px_8px_20px_0px_rgba(0,0,0,0.08)]'
        )}
      >
        <div className='col-span-1 w-[40px]'></div>
        <div className='col-span-1 w-[496px]'>Название</div>
        <div className='col-span-1 w-[196px]'>Категория</div>
        <div
          className='col-span-1 flex w-[196px] cursor-pointer flex-row items-center gap-1'
          onClick={toggleMark}
        >
          Метка{' '}
          <Sort
            className={
              isMarked
                ? 'rotate-180 transition-all duration-200'
                : 'rotate-360 transition-all duration-200'
            }
          />
        </div>
        <div
          className='col-span-1 flex max-w-[96px]  cursor-pointer flex-row items-center gap-1'
          onClick={toggleAge}
        >
          Возраст{' '}
          <Sort
            className={
              age
                ? 'rotate-180 transition-all duration-200'
                : 'rotate-360 transition-all duration-200'
            }
          />
        </div>
      </div>
    </>
  );
};
