import { Trash } from '@/assets/svg/trashSvg';
import { Type } from '@/entities/promocodes/types/IPromocodes';
import { statisticsActions } from '@/entities/statistics/model/statisticsSlice';
import { routes } from '@/shared/consts/consts';
import { declension } from '@/shared/lib/declension';
import { formatDateAndTime } from '@/shared/lib/formatDateAndTime';
import { useAppDispatch } from '@/shared/model';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { DeletePromocode } from '../../modals/DeletePromocode';

type Props = {
  promocode: string;
  id: number;
  products: any[];
  access: Type;
  discount: number;
  discountCurrency: string | any;
  usageCounter: number;
  amountUsers: number;
  activity: string;
  index: number;
  onOpen: () => void;
  length: number;
};

export const Promocode = ({
  products,
  promocode,
  id,
  amountUsers,
  access,
  index,
  onOpen,
  length,
  discount,
  discountCurrency,
  usageCounter,
  activity,
}: Props) => {
  const [isDelete, setIsDelete] = React.useState(false);

  const product =
    products.length > 1
      ? `Выбрано: ${products.length}`
      : products.length === 1
      ? products[0].title
      : 'Любой';

  const discountCurrencyNode = discountCurrency === 'ruble' ? '₽' : '%';
  const accessTitle =
    access.id === 3 && amountUsers >= 1
      ? `${amountUsers} ${declension(amountUsers, ['человек', 'человека'])}`
      : access.title;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isVisible, setIsVisible] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);

  const refPromo = React.useRef(null);

  const showCopyTooltip = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleCopy = async (str: string) => {
    copy(str);
    showCopyTooltip();
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDelete(true);
  };

  const navigateWithText = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    str: string
  ) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/${routes.adminRoutes.statistics + '/orders'}`);
    dispatch(statisticsActions.onSearch(str));
  };

  return (
    <>
      {isDelete && (
        <DeletePromocode id={id} opened={isDelete} setOpened={setIsDelete} />
      )}
      <div
        onClick={(e) => {
          if (e.target === refPromo.current) {
            return;
          } else {
            onOpen();
          }
        }}
      >
        <div
          className={classNames(
            'relative flex h-14 items-start justify-start gap-8 self-stretch border-[#f3f3f3] bg-[#FFF] px-6 py-4 text-lg font-normal not-italic leading-6 text-[color:var(--Simple-Night,#000)] transition-all duration-75 hover:cursor-pointer hover:bg-[#F3F3F3]',
            index === 0
              ? 'rounded-t-[16px] border-l border-r border-t'
              : 'rounded-[0px] border-l border-r border-t',
            index === length - 1 ? 'rounded-b-[16px] border-b' : 'rounded-[0px]'
          )}
          onMouseEnter={() => setIsVisible(true)}
          onMouseLeave={() =>
            setTimeout(() => {
              setIsVisible(false);
            }, 50)
          }
        >
          <div
            className='col-span-1 w-[164px] truncate'
            onClick={() => handleCopy(promocode)}
          >
            <Tooltip
              isOpen={isCopied}
              text={'Скопировано'}
              tooltipClassName='left-[60px] bottom-[38px]'
            >
              <p id='promocode' ref={refPromo}>
                {promocode}
              </p>
            </Tooltip>
          </div>

          <div className='... col-span-1 w-[262px] truncate whitespace-nowrap'>
            {product}
          </div>
          <div className='col-span-1 w-[263px]'>{accessTitle}</div>
          <div className='col-span-1 w-[64px] whitespace-nowrap'>
            {discount} {discountCurrencyNode}
          </div>
          {usageCounter > 0 ? (
            <div
              className='col-span-1 w-[113px] self-center bg-none px-0 pb-0 text-lg font-normal not-italic leading-6 text-[#337CEC] hover:text-blue300'
              onClick={(e) => navigateWithText(e, promocode)}
            >
              {usageCounter}
            </div>
          ) : (
            <div className='col-span-1 flex w-[113px] cursor-pointer items-center gap-2'>
              {usageCounter}
            </div>
          )}
          <div className='col-span-1 flex max-w-[124px] cursor-pointer items-center gap-2'>
            {activity ? 'до ' + formatDateAndTime(activity, true) : 'Бессрочно'}
          </div>
          {isVisible && (
            <div className='absolute right-[26px] top-[25%] flex gap-[16px]'>
              <button
                data-testid={`button-delete-${index}`}
                className='h-[30px] w-[30px]'
                onClick={(e) => {
                  handleDelete(e);
                }}
              >
                <Tooltip text='Удалить' tooltipClassName='left-[9px]'>
                  <Trash className='text-red500 hover:text-red' />
                </Tooltip>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
