import React from 'react';
import { Template } from 'src/widgets/Template';

import LoadingCard from './LoadingCard';

type Props = {
  isSwiper?: boolean;
};

const Loading = ({ isSwiper }: Props) => {
  if (isSwiper) {
    return (
      <div className='flex items-center justify-center gap-[24px]'>
        <LoadingCard />
        <LoadingCard />
        <LoadingCard />
      </div>
    );
  }

  return (
    <Template>
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
    </Template>
  );
};

export default Loading;
