import { useInitAfterAuth } from '@/pages/Root/lib/useInitAfterAuth';
import { useInitBeforeAuth } from '@/pages/Root/lib/useInitBeforeAuth';
import { routes } from '@/shared/consts/consts';
import { useAppSelector } from '@/shared/model';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const Root = () => {
  const roles = useAppSelector((state) => state.auth.roles);
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const initStatus = useAppSelector((state) => state.auth.initStatus);
  const initAfterAuth = useInitAfterAuth();
  const navigate = useNavigate();
  const [loadingOpen, setLoadingOpen] = useState(true);
  const { pathname } = useLocation();
  // делаем первоначальные запросы чтобы понять авторизован пользователь или нет
  useInitBeforeAuth();
  useEffect(() => {
    // если первоначальные запросы не выполнились код ниже не выполнять
    if (initStatus === 'pending') return;
    // если пользователь не авторизован редирект на логин
    if (!isAuth) {
      !['pass-recovery', 'register'].includes(pathname) && navigate('/login');
      setLoadingOpen(false);
      return;
    }

    // если у пользователя слабая роль редиректим на соответствующий роут
    if (roles.includes('User') && !roles.includes('Admin')) {
      setLoadingOpen(false);
      !Object.values(routes.authRoutes).includes(pathname.slice(1)) &&
        navigate('/no-rights');
      return;
    }

    // все ок, можно фетчить остальные данные с сервака
    initAfterAuth();
    const adminRoutesValues = [
      ...Object.values(routes.adminRoutes).filter(
        (value) => typeof value === 'string'
      ),
      ...Object.values(routes.marketplace).filter(
        (value) => typeof value === 'string'
      ),
      ...Object.values(routes.articles).filter(
        (value) => typeof value === 'string'
      ),
      ...Object.values(routes.foods).filter(
        (value) => typeof value === 'string'
      ),
    ];
    if (
      !adminRoutesValues.some((route) => pathname.slice(1).startsWith(route))
    ) {
      navigate('/statistics');
    }
    setLoadingOpen(false);
  }, [initStatus]);

  // не позволяем пользователю зайти на защищенные роуты
  useEffect(() => {
    if (roles.includes('User') && !roles.includes('Admin')) {
      !Object.values(routes.authRoutes).includes(pathname.slice(1)) &&
        navigate('/no-rights');
    }
  }, [pathname, roles]);
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 10 }} open={loadingOpen}>
        <CircularProgress />
      </Backdrop>
      <Outlet />
    </>
  );
};
