import { IBanner } from '@/entities/banners/types/IBanner';
import { getImagePath } from '@/shared/lib/images';
import { UIImage } from '@/shared/ui/PreviewImage/UIImage';
import { TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BannerItem = ({
  id,
  title,
  image,
  showUntil,
  height,
  link,
  articleId,
  createdAt,
  updatedAt,
}: IBanner) => {
  const navigate = useNavigate();
  const showSingleBanner = () => {
    navigate(`${id}`);
  };
  return (
    <>
      <TableRow
        key={id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        onClick={showSingleBanner}
      >
        <TableCell
          component='th'
          scope='row'
          key={id}
          onClick={showSingleBanner}
        >
          {id}
        </TableCell>
        <TableCell align='right'>{title}</TableCell>
        <TableCell align='right'>
          {new Date(showUntil).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </TableCell>
        <TableCell align='right'>
          <UIImage rounded url={getImagePath(image)} />
        </TableCell>
        <TableCell align='right'>{height}</TableCell>
        <TableCell align='right'>{link}</TableCell>
        <TableCell align='right'>
          {new Date(createdAt).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </TableCell>
        <TableCell align='right'>
          {new Date(updatedAt).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </TableCell>
        <TableCell align='right'>{articleId}</TableCell>
      </TableRow>
    </>
  );
};

export default BannerItem;
