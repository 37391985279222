import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';

import { Input } from '../../../../../shared/ui/Input/SecondInput';

export const VideoLinkInput = () => {
  const videoLink = useAppSelector(
    (state) => state.marketplace.courseForm.form.videoLink
  );
  const dispatch = useAppDispatch();
  return (
    <Input
      value={videoLink}
      data-testid={'input-video_link'}
      onChange={(e) =>
        dispatch(
          marketplaceActions.changeCoursesForm({
            key: 'videoLink',
            value: e.target.value,
          })
        )
      }
      name={'videoLink'}
      //required
      label={'Видео'}
      withClear
    />
  );
};
