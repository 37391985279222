import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 22.0002C17.5228 22.0002 22 17.5231 22 12.0002C22 6.4774 17.5228 2.00024 12 2.00024C6.47715 2.00024 2 6.4774 2 12.0002C2 17.5231 6.47715 22.0002 12 22.0002ZM16.4808 9.66461C16.8477 9.25183 16.8105 8.61975 16.3977 8.25283C15.9849 7.88592 15.3529 7.9231 14.9859 8.33588L10.4137 13.4796L9.15928 12.0161C8.79986 11.5968 8.16856 11.5482 7.74923 11.9077C7.32991 12.2671 7.28135 12.8984 7.64077 13.3177L9.39225 15.3611C9.91807 15.9746 10.8643 15.9831 11.4011 15.3792L16.4808 9.66461Z'
      fill='#1BC47D'
    />
  </svg>
);

export default SvgComponent;
