import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const Error = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='h2'>404</Typography>
    </Box>
  );
};
