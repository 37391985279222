import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './app/App';
import store from './app/model';

import './index.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={2}
      preventDuplicate
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      autoHideDuration={4000}
    >
      <App />
    </SnackbarProvider>
  </Provider>
);
