import { setState } from '@/shared/interfaces/common/setState';
import { Modal } from '@/shared/ui';
import NewButton from '@/shared/ui/NewButton/NewButton';
import { FC, useEffect } from 'react';

interface Props {
  opened: boolean;
  setOpened: setState<boolean>;
  to: 'hide' | 'publish' | 'delete';
  handleSubmit?: () => void;
  setTo?: any;
  title: string;
  comment?: Partial<Record<'hide' | 'publish' | 'delete', string>>;
}

const toAsString = {
  hide: 'Скрыть',
  publish: 'Вернуть',
  delete: 'Удалить',
};

export const ChangeStatusPublication: FC<Props> = ({
  opened,
  comment,
  setOpened,
  to,
  title,
  handleSubmit,
  setTo,
}) => {
  useEffect(() => {
    return () => {
      setTo?.(null);
      setOpened(false);
    };
  }, []);

  return (
    <Modal opened={opened} onClose={() => setOpened(false)}>
      <div className='flex h-full min-h-[204px] w-[592px] flex-col items-center gap-[16px]'>
        <div className='mb-[16px] flex flex-col gap-[16px]'>
          <h2 className='text-center text-[32px] font-medium not-italic leading-[40px]'>
            {`${toAsString[to]} ${title}?`}
          </h2>
          {!!comment && (
            <p className='text-center text-[20px] leading-[26px]'>
              {comment[to]}
            </p>
          )}
        </div>
        <div className='flex flex-col'>
          <NewButton
            variant={to === 'delete' ? 'negative' : 'primary'}
            styles='w-[380px]'
            data-testid={`button-${to}`}
            onClick={() => {
              handleSubmit();
              setOpened(false);
            }}
          >
            {toAsString[to]}
          </NewButton>
          <span className='mt-[16px] flex  flex-col gap-[24px] self-center'>
            <NewButton
              data-testid={'button-cancel'}
              variant='blue'
              onClick={() => setOpened(false)}
            >
              Отмена
            </NewButton>
          </span>
        </div>
      </div>
    </Modal>
  );
};
