import { ReactComponent as Plus } from '@/assets/svg/Plus.svg';
import NoContent from '@/entities/marketplace/ui/NoContent/NoContent';
import * as UI from '@/shared/ui';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import { Template } from '@/widgets/Template';

import CompanyCard from '../../../../widgets/EditEntityCard';
import Loading from '../../../Review/views/Loading/Loading';
import { Areas } from '../../course/ui/Areas';
import { useCompanies } from '../model/use-companies';

const Companies = () => {
  const { companies, isEmpty, loading, handleSubmit, handleClickPlus } =
    useCompanies();
  return (
    <>
      <div className='align-center mb-12 flex h-[56px] justify-between'>
        <div className='flex h-full items-center gap-4'>
          <UI.SectionTitle title='Компании' />
          <div className={'relative'}>
            <Tooltip tooltipClassName='top-[-72px] ' text='Добавить компанию'>
              <div
                data-testid={'button-create-company'}
                className='200ms flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[50%] bg-[#337CEC] transition hover:bg-[#1B56B1]'
                onClick={handleClickPlus}
              >
                <Plus />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <Areas className={'mb-[24px]'} type={'companyFilters'} />
      {loading && <Loading />}
      {companies.length !== 0 && (
        <Template>
          {companies?.map((elem) => (
            <CompanyCard
              comment={{ hide: 'все материалы компании будут скрыты' }}
              handleSubmit={handleSubmit(elem.id)}
              publishedAt={elem.createdAt}
              actionTitle={'компанию'}
              status={elem.publicationStatus.id}
              title={elem.title}
              editLink={`/marketplace/edit-company/${elem.id}`}
              key={elem.id}
            />
          ))}
        </Template>
      )}
      {isEmpty && (
        <NoContent text='Пока нет сохраненных черновиков, попробуйте начать создавать компанию' />
      )}
    </>
  );
};

export default Companies;
