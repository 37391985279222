import { ChangeStaff } from '@/entities/marketplace/model/marketplaceSlice';
import DropInput from '@/shared/ui/DropInput/DropInput';
import { FC, memo } from 'react';
import FormCard from 'src/widgets/FormCard';

import { Input } from '../../../../../shared/ui/Input/SecondInput';

interface Props {
  index: number;
  speakersLength: number;
  onDelete: (index: number) => void;
  onChange: (data: ChangeStaff) => void;
  name: string;
  photo: File[] | string[];
  position: string;
  onSwap: (destIndex: number, index: number) => void;
  id: number;
}

const StaffBlock: FC<Props> = ({
  index,
  id,
  speakersLength,
  onDelete,
  onChange,
  name,
  photo,
  position,
  onSwap,
}) => {
  return (
    <FormCard
      index={index}
      itemsCount={speakersLength}
      onDelete={() => onDelete(id)}
      onSwap={onSwap}
    >
      <DropInput
        data-testid={`dropzone-photo-${index}`}
        withIcon
        label='photo'
        htmlFor={'photo'}
        format='dropzone'
        title={'Фото'}
        required
        name='photo'
        id='photo'
        config={{
          accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
          },
          multiple: false,
          maxFiles: 1,
        }}
        files={photo}
        setFiles={([files]: any) =>
          onChange({
            value: files,
            index,
            key: 'photo',
          })
        }
        onDelete={onDelete}
      />
      <div className='grid grid-cols-2 gap-x-[32px]'>
        <Input
          data-testid={`input-name-${index}`}
          required
          id={'name'}
          containerClassName={'col-span-1'}
          name={'name'}
          withClear
          label={'Имя Фамилия'}
          value={name}
          onChange={(e) =>
            onChange({
              value: e.target.value,
              index,
              key: 'name',
            })
          }
        />
        <Input
          data-testid={`input-position-${index}`}
          required
          withClear
          id={'position'}
          containerClassName={'col-span-1'}
          name={'position'}
          label={'Должность'}
          value={position}
          onChange={(e) =>
            onChange({
              value: e.target.value,
              index,
              key: 'position',
            })
          }
        />
      </div>
    </FormCard>
  );
};

export default memo(StaffBlock);
