import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Input } from '@/shared/ui/Input/SecondInput';
import Imask from 'imask';
import { ChangeEvent, useEffect, useState } from 'react';

const imask = Imask.createMask({
  mask: Number,
  thousandsSeparator: ' ',
});

export const PriceBlock = () => {
  const { price, discountPrice } = useAppSelector(
    (state) => state.marketplace.courseForm.form
  );

  const dispatch = useAppDispatch();
  const [discount, setDiscount] = useState('');
  const [discountPriceOwn, setDiscountPriceOwn] = useState<string>(
    discountPrice ?? price
      ? imask.resolve(
          (!isNaN(discountPrice) ? +discountPrice : price ?? '')?.toString()
        )
      : ''
  );
  const [priceOwn, setPriceOwn] = useState<string>(
    price ? imask.resolve(price?.toString()) : ''
  );
  useEffect(() => {
    if (discountPrice === null) {
      setDiscountPriceOwn('');
      return;
    }
    if (discountPrice !== parseInt(discountPriceOwn) && discountPrice >= 0) {
      setDiscountPriceOwn(imask.resolve((+discountPrice ?? '')?.toString()));
    }
  }, [discountPrice]);
  useEffect(() => {
    if (price !== parseInt(priceOwn) && price) {
      setPriceOwn(imask.resolve(price?.toString()));
    }
  }, [price]);
  useEffect(() => {
    if (discountPrice === price) {
      setDiscount(() => '');
      return;
    }
    if (discountPrice === 0 && price > 0) {
      setDiscount(() => '100%');
      return;
    }
    if (!price || discountPrice == null) {
      setDiscount(() => '');
      return;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _discount = +discountPrice / +price;
    setDiscount(() =>
      _discount ? `${Math.round((1 - _discount) * 100).toString()}%` : ''
    );
  }, [discountPrice, price]);
  const handleSetPrice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value === '' ? '' : null;
    imask.resolve(e.target.value);

    setPriceOwn(() => value ?? imask.value);

    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'price',
        value: +imask.unmaskedValue,
      })
    );
  };
  const handleSetDiscountPrice = (e: ChangeEvent<HTMLInputElement>) => {
    imask.resolve(e.target.value);
    setDiscountPriceOwn(() => imask.value);

    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'discountPrice',
        value: parseInt(imask.unmaskedValue),
      })
    );
  };
  return (
    <div className={'flex flex-col gap-[8px]'}>
      <div className={'grid grid-cols-3 gap-[32px]'}>
        <Input
          data-testid={'input-discount_price'}
          id={'discountPrice'}
          value={discountPriceOwn ? discountPriceOwn : ''}
          onChange={handleSetDiscountPrice}
          required
          name={'discountPrice'}
          label={'Стоимость, ₽'}
        />
        <Input
          data-testid={'input-price'}
          id={'price'}
          value={priceOwn ? priceOwn : ''}
          onChange={handleSetPrice}
          name={'price'}
          label={'Стоимость до скидки, ₽'}
        />
        <Input
          data-testid={'input-discount'}
          value={discount}
          onChange={(e) => setDiscount(e.target.value)}
          name={'discount'}
          label={'Процент скидки'}
          disabled
        />
      </div>
      {(isNaN(discountPrice) ||
        (discountPrice >= 0 && priceOwn !== '' && discountPrice >= price)) && (
        <p className={'text-sm text-red500'}>
          Стоимость до скидки должна быть больше стоимости после скидки{' '}
        </p>
      )}
    </div>
  );
};
