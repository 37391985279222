import { deleteStory } from '@/entities/categories/model/categoriesSlice';
import { setState } from '@/shared/interfaces/common/setState';
import { useAppDispatch } from '@/shared/model';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  modalOpen: boolean;
  setModalOpen: setState<boolean>;
  id: number;
  name: string;
}

export const DeleteStoryModal: FC<Props> = ({
  modalOpen,
  setModalOpen,
  id,
  name,
}) => {
  const [loading, setLoading] = useState(false);
  const dispach = useAppDispatch();
  const navigate = useNavigate();
  const onSubmit = async () => {
    setLoading(true);
    await dispach(deleteStory(id));
    setLoading(false);
    navigate('/stories', { replace: true });
  };
  return (
    <Dialog open={modalOpen} onClose={() => setModalOpen(false)} fullWidth>
      <DialogTitle>Точно удалить историю {name}?</DialogTitle>
      <DialogActions>
        <Button
          data-testid={`button-delete-${id}`}
          type='submit'
          variant='contained'
          color='warning'
          disabled={loading}
          onClick={onSubmit}
        >
          Удалить
        </Button>
        <Button
          data-testid={'button-cancel'}
          onClick={() => setModalOpen(false)}
          variant='outlined'
        >
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};
