import { ReactComponent as CloseEye } from '@/assets/svg/CloseEye.svg';
import DeleteRev from '@/assets/svg/deleteRev';
import { ReactComponent as Edit } from '@/assets/svg/Edit.svg';
import { ReactComponent as OpenEye } from '@/assets/svg/OpenEye.svg';
import { ReactComponent as Pin } from '@/assets/svg/Pin.svg';
import ButtonRev from '@/pages/Review/sub-pages/views/ButtonRev';
import { convertDateFormat } from '@/shared/lib/formatDateAndTime';
import * as UI from '@/shared/ui';
import sx from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ChangeStatusPublication } from './ChangeStatusPublication';
import Status from './Status';

type Props = {
  handleSubmit: (arg: 'hide' | 'publish' | 'delete', status: number) => void;
  status: number;
  title: string;
  isPinned?: boolean;
  actionTitle: string;
  editLink: string;
  publishedAt?: string;
  children?: React.ReactNode;
  text?: string | number; //todo: поменять название
  comment?: Partial<Record<'hide' | 'publish' | 'delete', string>>;
};
const tooltipPositionClassName =
  'absolute left-1/2 top-[-8px] z-[10] -translate-y-full -translate-x-1/2';
const EditEntityCard = ({
  handleSubmit,
  children,
  comment,
  actionTitle,
  isPinned,
  status,
  title,
  editLink,
  text,
  publishedAt,
}: Props) => {
  const [tooltip, setTooltip] = React.useState<{
    trashTooltip: boolean;
    hideTooltip: boolean;
  }>({
    trashTooltip: false,
    hideTooltip: false,
  });
  const [open, setOpen] = React.useState(false);
  const [to, setTo] = React.useState(null);
  const navigate = useNavigate();
  function dateFormat(date: string): string {
    const [year, month, day] = date.split('-');
    return `${day}.${month}.${year}`;
  }

  return (
    <>
      <ChangeStatusPublication
        opened={open}
        comment={comment}
        handleSubmit={() => handleSubmit(to, status)}
        title={actionTitle}
        setOpened={setOpen}
        to={to}
        setTo={setTo}
      />

      <div className='justify-star relative flex h-full w-[98.8%] flex-col items-start self-stretch overflow-visible rounded-[24px] border border-gray200 p-[24px]'>
        {!!isPinned && (
          <span className='absolute top-[16px] right-[16px]'>
            <Pin />
          </span>
        )}
        <Status status={{ id: status, title: '' }} className='mb-[16px]' />
        {(!!publishedAt || !!text) && (
          <span className={'align-center flex w-full justify-between'}>
            <span className='mb-[8px] text-base font-normal not-italic leading-[22px] text-[color:var(--Simple-Gray-Midnight,#7D7D7D)]'>
              {publishedAt ? dateFormat(convertDateFormat(publishedAt)) : ''}
            </span>

            <span className='mb-[8px] text-base font-normal not-italic leading-[22px] text-[color:var(--Simple-Gray-Midnight,#7D7D7D)]'>
              {text}
            </span>
          </span>
        )}

        <span
          data-testid={'link-edit'}
          className='max-w-[100%] cursor-pointer whitespace-pre-wrap break-words text-lg font-normal not-italic leading-6 text-[#337CEC]'
          onClick={() => navigate(editLink)}
        >
          {title}
        </span>
        <div className={`${sx('flex w-[100%] gap-2')} mt-auto pt-[24px]`}>
          <div className='w-full md:w-[300px]'>
            <ButtonRev
              data-testid={'button-edit'}
              onClick={() => navigate(editLink)}
            >
              <Edit /> Изменить
            </ButtonRev>
          </div>
          {status === 3 && (
            <div
              className='flex flex-col gap-1'
              style={{ position: 'relative' }}
            >
              <ButtonRev
                data-testid={'button-hide'}
                onClick={() => {
                  setTo('hide');
                  setOpen(true);
                }}
                className='w-full md:w-[4vw]'
                isDelete
                onMouseEnter={() =>
                  setTooltip((prevTooltip) => ({
                    ...prevTooltip,
                    hideTooltip: true,
                  }))
                }
                onMouseLeave={() =>
                  setTooltip((prevTooltip) => ({
                    ...prevTooltip,
                    hideTooltip: false,
                  }))
                }
              >
                <OpenEye />
              </ButtonRev>
              {tooltip.hideTooltip ? (
                <div className={tooltipPositionClassName}>
                  <UI.Tooltip text={`Скрыть ${actionTitle}`} />
                </div>
              ) : null}
            </div>
          )}
          {status === 2 && (
            <div
              className='flex flex-col gap-1'
              style={{ position: 'relative' }}
            >
              <ButtonRev
                data-testid={'button-publish'}
                onClick={() => {
                  setTo('publish');
                  setOpen(true);
                }}
                className='w-full md:w-[4vw]'
                isDelete
                onMouseEnter={() =>
                  setTooltip((prevTooltip) => ({
                    ...prevTooltip,
                    hideTooltip: true,
                  }))
                }
                onMouseLeave={() =>
                  setTooltip((prevTooltip) => ({
                    ...prevTooltip,
                    hideTooltip: false,
                  }))
                }
              >
                <CloseEye />
              </ButtonRev>
              {tooltip.hideTooltip ? (
                <div className={tooltipPositionClassName}>
                  <UI.Tooltip text={`Вернуть ${actionTitle}`} />
                </div>
              ) : null}
            </div>
          )}
          <div className='flex flex-col gap-1' style={{ position: 'relative' }}>
            <ButtonRev
              data-testid={'button-delete'}
              onClick={() => {
                setTo('delete');
                setOpen(true);
              }}
              className='w-full md:w-[4vw]'
              isDelete
              onMouseEnter={() =>
                setTooltip((prevTooltip) => ({
                  ...prevTooltip,
                  trashTooltip: true,
                }))
              }
              onMouseLeave={() =>
                setTooltip((prevTooltip) => ({
                  ...prevTooltip,
                  trashTooltip: false,
                }))
              }
            >
              <DeleteRev />
            </ButtonRev>
            {tooltip.trashTooltip ? (
              <div className={tooltipPositionClassName}>
                <UI.Tooltip text={`Удалить ${actionTitle}`} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {children}
    </>
  );
};

export default EditEntityCard;
