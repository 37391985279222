import React from 'react';

const useFilterVisibility = () => {
  const [isFilterVisible, setIsFilterVisible] = React.useState(false);
  const filterSelectOptions = React.useRef(null);
  let timer: NodeJS.Timeout;

  const handleMouseEnter = () => {
    clearTimeout(timer);
    setIsFilterVisible(true);
  };

  const handleMouseLeave = () => {
    timer = setTimeout(() => {
      if (
        filterSelectOptions.current &&
        filterSelectOptions.current.contains(document.activeElement)
      ) {
        return;
      }
      setIsFilterVisible(false);
    }, 200);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  return {
    isFilterVisible,
    filterSelectOptions,
    handleMouseEnter,
    handleMouseLeave,
    toggleFilterVisibility,
  };
};

export default useFilterVisibility;
