import { IBanner } from '@/entities/banners/types/IBanner';
import { setState } from '@/shared/interfaces/common/setState';
import { getImagePath } from '@/shared/lib/images';
import { UIImage } from '@/shared/ui/PreviewImage/UIImage';
import BasicTabs from '@/shared/ui/Tabs/Tabs';
import { Box, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';

import { DeleteBannerModal } from '../components/DeleteBannerModal';
import { EditBannerModal } from '../components/EditBannerModal';

interface Props {
  banner: IBanner;
  editModal: boolean;
  setEditModal: setState<boolean>;
  deleteModal: boolean;
  setDeleteModal: setState<boolean>;
}

const SingleBannerView: FC<Props> = ({
  banner,
  editModal,
  setDeleteModal,
  setEditModal,
  deleteModal,
}) => {
  return (
    <>
      <EditBannerModal
        modalVisible={editModal}
        setModalVisible={setEditModal}
        banner={banner}
      />
      <DeleteBannerModal
        modalOpen={deleteModal}
        setModalOpen={setDeleteModal}
        id={banner.id}
        name={banner.title}
      />
      <BasicTabs
        data-testid={'link-back'}
        label='Назад к баннерам'
        targetRoute='/banners'
      />
      <Box key={banner.id} sx={{ margin: '20px' }}>
        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
          <Button
            data-testid={`button-edit-${banner.id}`}
            variant='contained'
            onClick={() => setEditModal(true)}
          >
            Редактировать
          </Button>
          <Button
            variant='contained'
            color='warning'
            data-testid={`button-delete-${banner.id}`}
            onClick={() => setDeleteModal(true)}
          >
            Удалить
          </Button>
        </Box>

        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, cursor: 'pointer' }}
            size='medium'
            aria-label='a dense table'
          >
            <TableHead sx={{ background: '#5d8aa8' }}>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align='right'>Название</TableCell>
                <TableCell align='right'>Время жизни баннера</TableCell>
                <TableCell align='right'>Фото</TableCell>
                <TableCell align='right'>Высота</TableCell>
                <TableCell align='right'>Ссылка</TableCell>
                <TableCell align='right'>Дата создания</TableCell>
                <TableCell align='right'>Дата редактирования</TableCell>
                <TableCell align='right'>Id Статьи</TableCell>
                <TableCell align='right'>-</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {banner.id}
                </TableCell>
                <TableCell align='right'>{banner.title}</TableCell>
                <TableCell align='right'>
                  {new Date(banner.showUntil).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TableCell>
                <TableCell align='right'>
                  <UIImage url={getImagePath(banner.image)} rounded />
                </TableCell>
                <TableCell align='right'>{banner.height}</TableCell>
                <TableCell align='right'>{banner.link}</TableCell>
                <TableCell align='right'>
                  {new Date(banner.createdAt).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TableCell>
                <TableCell align='right'>
                  {new Date(banner.updatedAt).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TableCell>
                <TableCell align='right'>{banner.articleId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default SingleBannerView;
