import { api, apiWithoutTokens } from '@/shared/api';
import { getCookie } from 'typescript-cookie';

class AuthService {
  signUp(data: any) {
    return apiWithoutTokens.post('/auth/registration', data);
  }

  confirmEmail(data: any) {
    return apiWithoutTokens.put('/auth/registration/confirm-email', data);
  }

  signIn(data: any) {
    return apiWithoutTokens.put('/auth/signin', data);
  }

  checkIsRegistered(data: any) {
    return apiWithoutTokens.get(`/auth/email/check?email=${data}`);
  }

  restore(data: any) {
    return apiWithoutTokens.post('/auth/email/restore', data);
  }

  checkAuth() {
    return apiWithoutTokens.get('/auth/check', {
      headers: {
        Authorization: `Bearer ${getCookie('jwt')}`,
      },
    });
  }

  getUserRoles() {
    return apiWithoutTokens.get('/roles/user', {
      headers: {
        Authorization: `Bearer ${getCookie('jwt')}`,
      },
    });
  }

  getAllRoles() {
    return api.get('/roles');
  }

  giveRole(userId: number, roleId: number) {
    return api.put(`/roles/user/${userId}`, undefined, {
      params: { roleId },
    });
  }
}
const authService = new AuthService();
export default authService;
