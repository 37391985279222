import { articlesActions } from '@/entities/articles/model/articleSlice';
import Search from '@/pages/Review/components/SearchInput';
import useFilterVisibility from '@/shared/lib/hooks/useFilterVisibility';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { FilterSelect } from '@/shared/ui/Filters';
import Status from '@/shared/ui/Filters/Status';
import SortSelect from '@/shared/ui/Sort/SortSelect';
import { SortValues } from '@/shared/ui/Sort/SortValues';

export const Areas = () => {
  const {
    isFilterVisible,
    filterSelectOptions,
    handleMouseEnter,
    handleMouseLeave,
  } = useFilterVisibility();

  const {
    isFilterVisible: isSortVisible,
    filterSelectOptions: isFilterSelectOptions,
    handleMouseEnter: handleOptionsMouseEnter,
    handleMouseLeave: handleOptionsMouseLeave,
  } = useFilterVisibility();

  const filterValues = useAppSelector((state) => state.articles.filterValues);
  const sortValues = useAppSelector((state) => state.articles.sortTypeInit);
  const filter = useAppSelector((state) => state.articles.filter);
  const activeSort = useAppSelector((state) => state.articles.sort);
  const value = useAppSelector((state) => state.articles.searchValue);
  const dispatch = useAppDispatch();

  const handleAddFilter = (
    event: React.MouseEvent<HTMLDivElement>,
    v: { label: string; value: number; param: boolean }
  ) => {
    dispatch(articlesActions.addFilter(v));
    handleMouseLeave();
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(articlesActions.changeSearch(e.target.value));

  const onClear = (e: React.MouseEvent<HTMLDivElement>) =>
    dispatch(articlesActions.changeSearch(''));

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement>,
    { label, value }: { label: string; value: number | string }
  ) => {
    dispatch(articlesActions.changeSort({ label, value }));
    handleOptionsMouseLeave();
  };

  return (
    <div className='mb-[24px] flex-col gap-1'>
      <div className='flex w-[100%] gap-1'>
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className='relative flex-col bg-transparent'
        >
          <Status
            ref={filterSelectOptions}
            currentFilter={filter?.label}
            onClick={() => {}}
          />
          {isFilterVisible && (
            <FilterSelect
              data-testid={'select-filter'}
              ref={filterSelectOptions}
              data={filterValues}
              handleAddFilter={handleAddFilter}
            />
          )}
        </div>
        <div className='mr-[24px] flex-1'>
          <Search
            data-testid={'search'}
            onChange={onChange}
            value={value}
            isReview
            isLetter={value.length > 0}
            onClear={onClear}
          />
        </div>
        <div
          onMouseEnter={handleOptionsMouseEnter}
          onMouseLeave={handleOptionsMouseLeave}
          className='relative z-[3] flex select-none flex-col self-center'
        >
          <SortSelect
            onClick={() => {}}
            isSortVisible={isSortVisible}
            ref={isFilterSelectOptions}
            activeSort={activeSort}
          />
          {isSortVisible && (
            <SortValues
              data={sortValues}
              ref={isFilterSelectOptions}
              handleClick={handleClick}
              activeSort={activeSort}
            />
          )}
        </div>
      </div>
    </div>
  );
};
