import Basket from 'src/assets/svg/basket';

const NoContent = ({ text }: { text: string }) => {
  return (
    <div className='flex flex-col items-center justify-center rounded-xl pb-[40px] pt-[56px] text-base'>
      <Basket />
      <p className='mt-4'>Упс...</p>
      <p>{text}</p>
    </div>
  );
};
export default NoContent;
