import {
  deleteCompany,
  fetchAllCompanies,
  toggleCompanyVisibility,
} from '@/entities/marketplace/model/markeplaceThunks';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCompanies = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { companies, companyFilters } = useAppSelector(
    (store) => store.marketplace
  );
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      !companies?.length && setLoading(true);
      await dispatch(fetchAllCompanies());
      setLoading(false);
    })();
  }, [companies?.length, companyFilters, dispatch]);
  const handleSubmit =
    (id: number) => (to: 'delete' | 'hide' | 'publish', status: number) => {
      if (to === 'delete') {
        dispatch(deleteCompany(Number(id)));
        return;
      }
      dispatch(toggleCompanyVisibility({ companyId: id, status: status }));
    };
  const handleClickPlus = () => navigate('/marketplace/create-company');
  const isEmpty =
    companies.length === 0 &&
    !!Object.values(companyFilters).filter((v) => v)[0];
  return { companies, loading, handleSubmit, handleClickPlus, isEmpty };
};
