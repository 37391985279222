import { sharedApi } from '@/shared/api/shared.api';

export const uploadImg = async (
  files: File[] | string[]
): Promise<string[]> => {
  return Promise.all(
    files.map(async (v) => {
      if (typeof v === 'string') {
        return v;
      }
      const fd = new FormData();
      fd.append('file', v);
      const ans = await sharedApi.createFile(fd);
      return ans.data.file;
    })
  );
};
