import articleService from '@/entities/articles/api/articles-service';
import {
  AuthorData,
  EditArticleObject,
  IArticle,
  IFormat,
} from '@/entities/articles/types/IArticle';
import { tryCatch } from '@/shared/lib/tryCatch';
import { setNotification } from '@/shared/model/appSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { IArticleSource } from './articleSlice';

export const fetchAllArticles = createAsyncThunk(
  'article/fetchAllArticles',
  async (
    {
      search,
      sort,
      publicationStatusId,
    }: {
      search?: string;
      sort?: 'ASC' | 'DESC';
      publicationStatusId?: number;
    },
    { rejectWithValue }
  ) => {
    const [data, err] = await tryCatch<{ articles: IArticle[] }>(
      articleService.getAllArticles(search, sort, publicationStatusId)
    );
    if (err) throw rejectWithValue(`Ошибка в получении статей ${err.status}`);
    return data.articles;
  }
);

export const fetchSingleArticle = createAsyncThunk(
  'article/getSingleArticle',
  async (id: number, { rejectWithValue }) => {
    const [data, err] = await tryCatch<IArticle>(
      articleService.getSingleArticle(id)
    );
    if (err) throw rejectWithValue(`Ошибка в получении статьи ${err.status}`);
    return data;
  }
);

export interface ISourcesResponse {
  id: number;
  title: string;
  link: string;
  articleId: number;
  order: number;
}

export const getArticleSources = createAsyncThunk(
  'article/getArticleSources',
  async (id: number, { rejectWithValue }) => {
    const [data, err] = await tryCatch<{ sources: ISourcesResponse[] }>(
      articleService.getArticlesSources(id)
    );
    if (err)
      throw rejectWithValue(
        `Ошибка в получении источников статей ${err.status}`
      );
    return data;
  }
);

export const fetchTopics = createAsyncThunk(
  'article/getTopics',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch<IFormat[]>(articleService.getTopics());
    if (err) throw rejectWithValue(`Ошибка в получении статьи ${err.status}`);
    return data;
  }
);

export const fetchFormats = createAsyncThunk(
  'article/getForamts',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch<{ formats: { format: IFormat }[] }>(
      articleService.getFormats()
    );
    if (err) throw rejectWithValue(`Ошибка в получении статьи ${err.status}`);
    return data;
  }
);

export const deleteArticle = createAsyncThunk(
  'article/deleteArticle',
  async ({ id }: { id: number }, { rejectWithValue, dispatch }) => {
    const [data, err] = await tryCatch<IArticle>(
      articleService.deleteArticle(id)
    );

    if (err) {
      dispatch(
        setNotification({
          value: `Ошибка при удалении статьи [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }

    dispatch(
      setNotification({
        value: 'Статья удалена',
        variant: 'success',
      })
    );
    return id;
  }
);

export const editSingleArticle = createAsyncThunk(
  'article/egitSingleArticle',
  async (
    { id, formData }: { id: number; formData: EditArticleObject },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch<IArticle>(
      articleService.updateArticle(id, formData)
    );
    if (err) {
      throw rejectWithValue(`Ошибка в редактировании статьи ${err.status}`);
    }

    dispatch(setNotification({ value: 'Изменения сохранены' }));
    return data;
  }
);

export const createSingleArticle = createAsyncThunk(
  'article/createSingleArticle',
  async (
    { formData }: { formData: EditArticleObject },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch(
      articleService.createSingleArticle(formData)
    );
    if (err)
      throw rejectWithValue(`Ошибка в редактировании статьи ${err.status}`);
    dispatch(setNotification({ value: 'Статья создана' }));
    return data;
  }
);

export interface IArticleAction {
  sources: IArticleSource[];
}

export const addArticlesSources = createAsyncThunk(
  'article/addArticlesSources',
  async (
    { articleId, formData }: { articleId: number; formData: IArticleAction },
    { rejectWithValue, dispatch }
  ) => {
    const filteredSources = formData.sources
      .filter((source) => source.title.trim() && source.link.trim())
      .map((source, index) => ({ ...source, order: index }));
    const [data, err] = await tryCatch(
      articleService.addArticlesSources(articleId, filteredSources)
    );
    if (err)
      throw rejectWithValue(`Ошибка в добавлении источников ${err.status}`);
    return data;
  }
);

export const addTopics = createAsyncThunk(
  'article/addTopics',
  async (
    { articleId, data }: { articleId: number; data: { ids: number[] } },
    { rejectWithValue, dispatch }
  ) => {
    const [dataTopics, err] = await tryCatch(
      articleService.addTopicsToArticle(articleId, data)
    );
    if (err) throw rejectWithValue(`Ошибка в добавлении топиков ${err.status}`);
    return dataTopics;
  }
);

export const removeTopics = createAsyncThunk(
  'article/removeTopics',
  async (
    { articleId, data }: { articleId: number; data: { ids: number[] } },
    { rejectWithValue, dispatch }
  ) => {
    const [dataTopics, err] = await tryCatch(
      articleService.deleteTopicsFromArticle(articleId, data)
    );
    if (err) throw rejectWithValue(`Ошибка в удалении топиков ${err.status}`);
    return dataTopics;
  }
);

export const changePublicationType = createAsyncThunk(
  'article/changePublicationType',
  async (
    {
      articleId,
      type,
      status,
    }: { articleId: number; type: string; status?: 'first' },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch(
      articleService.changePublicationType(articleId, type)
    );

    if (err) {
      dispatch(
        setNotification({
          value: `Не удалось изменить статус: [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    if (status !== 'first') {
      //если статья изменяется с /edit-article
      dispatch(
        setNotification({
          value: type === 'publish' ? 'Статья возвращена' : 'Статья скрыта',
          variant: 'success',
        })
      );
    }
    return articleId;
  }
);

export const addSingleArticlesSources = createAsyncThunk(
  'article/addSingleArticlesSources',
  async (
    { id, formData }: { id: number; formData: IArticleSource },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch(
      articleService.addSingleArticlesSource(id, formData)
    );
    if (err)
      throw rejectWithValue(`Ошибка в добавлении источников ${err.status}`);
    return data;
  }
);

export const editArticleSources = createAsyncThunk(
  'article/editArticleSources',
  async (
    { id, formData }: { id: number; formData: any },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch(
      articleService.updateArticlesSources(id, formData)
    );
    if (err)
      throw rejectWithValue(`Ошибка в добавлении источников ${err.status}`);
    return data;
  }
);

export const deleteSources = createAsyncThunk(
  'article/deleteSources',
  async (
    { articleId, sources }: { articleId: number; sources: any },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch(
      articleService.deleteSources(articleId, sources)
    );
    if (err)
      throw rejectWithValue(`Ошибка в добавлении источников ${err.status}`);
    return data;
  }
);

export const editAuthor = createAsyncThunk(
  'article/editAuthor',
  async (
    { id, formData }: { id: number; formData: AuthorData },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch<{
      id: number;
      name: string;
      surname: string;
      avatar: string;
      positionAtWork: string;
    }>(articleService.updateAuthor(id, formData));
    if (err)
      throw rejectWithValue(`Ошибка в редактировании статьи ${err.status}`);
    return data;
  }
);

export const addAuthor = createAsyncThunk(
  'article/addAuthor',
  async (
    { formData }: { formData: AuthorData },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch<{
      id: number;
      name: string;
      surname: string;
      avatar: string;
      positionAtWork: string;
    }>(articleService.addAuthor(formData));
    if (err)
      throw rejectWithValue(`Ошибка в редактировании статьи ${err.status}`);
    return data;
  }
);
