import { api } from '@/shared/api';

class CategoriesService {
  async getAllCategories() {
    return api.get('/categories/all');
  }

  async getSingleCat(id: number) {
    return api.get(`/categories/category/${id}`);
  }

  async createCategory(formData: FormData) {
    return api.post('/categories/category', formData);
  }

  async editCategory(id: number, formData: FormData) {
    return api.patch(`/categories/category/${id}`, formData);
  }

  async deleteCategory(id: number) {
    return api.delete(`/categories/category/${id}`);
  }
}
const categoriesService = new CategoriesService();
export default categoriesService;
