import { IStory } from '@/entities/stories/types/IStory';
import { setState } from '@/shared/interfaces/common/setState';
import { Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';

import { AddStoryModal } from './components/AddStoryModal';
import StoryItem from './components/StoryItem';

interface Props {
  modalOpen: boolean;
  stories: IStory[];
  setModalOpen: setState<boolean>;
}

const StoriesView: FC<Props> = ({ stories, modalOpen, setModalOpen }) => {
  return (
    <>
      <AddStoryModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <div className='container'>
        <div className='other'>
          <div className='buttons-block'>
            <div className='topbar-icons'>
              <Button
                data-testid={'button-open_modal'}
                variant='contained'
                onClick={() => setModalOpen(true)}
              >
                Создать
              </Button>
            </div>
          </div>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, cursor: 'pointer' }}
              size='medium'
              aria-label='a dense table'
            >
              <TableHead sx={{ background: '#5d8aa8' }}>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align='right'>Название</TableCell>
                  <TableCell align='right'>Фото</TableCell>
                  <TableCell align='right'>Количество просмотров</TableCell>
                  <TableCell align='right'>Дата создания</TableCell>
                  <TableCell align='right'>Дата редактирования</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stories?.map((storie: IStory) => (
                  <StoryItem {...storie} key={storie.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
};

export default StoriesView;
