import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { IAttachment } from '@/entities/marketplace/types/IContent';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import DropInput from '@/shared/ui/DropInput/DropInput';

export const PreviewInput = () => {
  const dispatch = useAppDispatch();
  const { previews } = useAppSelector(
    (state) => state.marketplace.courseForm.form
  );
  const onChange = ([files]: File[][]) => {
    const newFiles: IAttachment[] = files.map((file, index) => ({
      file: file,
      order: index,
    }));

    const filesToAdd: IAttachment[] = [...(previews || []), ...newFiles]
      .reverse()
      .slice(0, 2)
      .reverse(); //pick 2 last files

    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'previews',
        value: filesToAdd,
      })
    );
  };
  const onDelete = (index: number) => {
    const sliced = previews
      ?.filter((_, i) => i !== index)
      .map((file, index) => ({
        file: file.file,
        order: index,
      }));
    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'previews',
        value: sliced,
      })
    );
  };

  return (
    <DropInput
      data-testid={'dropzone-preview'}
      label='preview'
      htmlFor={'preview'}
      format='dropzone'
      config={{
        accept: {
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
        },
        multiple: true,
        maxFiles: 2,
      }}
      title={'Превью'}
      sizes='(до 2 фото)'
      name='preview'
      id='preview'
      files={previews?.map((v) => v.file)}
      setFiles={onChange}
      withIcon
      onDelete={onDelete}
    />
  );
};
