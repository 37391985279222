/* eslint-disable react-hooks/exhaustive-deps */
import { statisticsActions } from '@/entities/statistics/model/statisticsSlice';
import { fetchAllUsers } from '@/entities/statistics/model/statisticsThunks';
import { useIsScrolled } from '@/shared/lib/hooks/useIsScrolled';
import { usePaginate } from '@/shared/lib/hooks/usePaginate';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import * as UI from '@/shared/ui';
import GoBack from '@/shared/ui/GoBack/GoBack';
import sx from 'classnames';
import React, { ChangeEvent, useEffect } from 'react';
import CloseSearch from 'src/assets/svg/closeSearch';
import SearchIcon from 'src/assets/svg/search';

import StatsHead from '../../components/StatsHead';
import Skeletons from '../../Sceletons/Skeletons';
import UserActive from './UserActive';

export const TopBarActiveUsers = () => {
  const dispatch = useAppDispatch();

  const userActive = useAppSelector((state) => state.statistics.users);
  const regTypes = useAppSelector((state) => state.statistics.currentRegTypes);
  const isLoading = useAppSelector((state) => state.statistics.loading);
  const isScrolled = useIsScrolled();
  const searchValue = useAppSelector((state) => state.statistics.search);

  const { ref, page } = usePaginate(
    () =>
      dispatch(
        fetchAllUsers({
          lastActivityAt: 'DESC',
          limit: 40,
          page: page,
          search: searchValue,
          regTypes,
        })
      ),
    {
      initialPage: 1,
      data: userActive,
      deps: [searchValue, regTypes],
    }
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(statisticsActions.onSearch(e.target.value));
  };

  useEffect(() => {
    return () => {
      dispatch(statisticsActions.clearDateSort());
      dispatch(statisticsActions.clearSearch());
    };
  }, []);

  return (
    <div className='container'>
      <GoBack />
      <div className='mb-[32px] flex h-[56px] items-center justify-between'>
        <div className='flex h-full items-center justify-center gap-8'>
          <UI.SectionTitle title='Пользователи' />
        </div>
      </div>
      <div className='relative'>
        <input
          data-testid={'input-search'}
          value={searchValue}
          type='text'
          className={sx(
            'mb-[24px] w-[100%] rounded-[100px] bg-[#f3f3f3] py-3 pl-[53px] pr-2 text-base',
            'placeholder:text-base focus:outline-none'
          )}
          placeholder='Поиск'
          onChange={handleSearch}
        />
        <div className='absolute top-[17%] left-[12px] pl-3'>
          <SearchIcon />
        </div>
        {searchValue.length > 0 && (
          <div
            data-testid={'button-close_search'}
            className='absolute top-[17%] right-[24px] cursor-pointer pl-3'
            onClick={() => dispatch(statisticsActions.onSearch(''))}
          >
            <CloseSearch />
          </div>
        )}
      </div>
      <StatsHead
        amountElements={6}
        isScrolled={isScrolled}
        className='border-[1px] border-[#F3F3F3]'
      />
      {isLoading && <Skeletons type={2} />}
      {userActive?.map((item, index) => (
        <UserActive
          index={index}
          length={userActive?.length}
          id={item?.id}
          createdAt={item?.createdAt}
          updatedAt={item?.updatedAt}
          authType={item?.authType}
          phone={item?.phone}
          isPhoneNumberConfirmed={item?.isPhoneNumberConfirmed}
          lastActivityAt={item?.lastActivityAt}
          email={item?.email}
          ip={item?.ip}
          key={`${item?.id} ${item?.createdAt} ${item?.updatedAt}`}
        />
      ))}
      {userActive?.length !== 0 && <div ref={ref}></div>}
    </div>
  );
};

export default TopBarActiveUsers;
