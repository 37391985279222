import { useAppSelector } from '@/shared/model';
import { useNavigate } from 'react-router-dom';

import StatCard from '../../components/StatCard';
import StatLoader from '../../Sceletons/StatLoader';

const StatCards = () => {
  const statistics = useAppSelector((state) => state.statistics.statistics);
  const activeSort = useAppSelector((state) => state.statistics.sort);
  const isLoading = useAppSelector((state) => state.statistics.loading);
  const navigate = useNavigate();

  if (isLoading) {
    return <StatLoader />;
  }

  return (
    <div className='flex max-w-[1280px] gap-6'>
      {statistics?.map((item) => (
        <StatCard
          key={item?.type}
          stat={`${
            item?.type === 'users'
              ? 'Пользователей'
              : item?.type === 'registrations'
              ? 'Регистраций'
              : 'Покупок'
          }`}
          amount={item?.amount}
          forTime={activeSort.label}
          data-testid={`link-${item?.type}`}
          change={item?.differenceForPreviousPeriod}
          onClick={() => navigate(`/statistics/${item?.type}`)}
        />
      ))}
    </div>
  );
};

export default StatCards;
