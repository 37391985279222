import { NotificationsVariant } from '@/shared/model/appSlice';
import sx from 'classnames';
import { SnackbarKey, SnackbarMessage } from 'notistack';
import { forwardRef } from 'react';
import ErrorNotif from 'src/assets/svg/errorNotif';
import SuccessIcon from 'src/assets/svg/success';

interface Props {
  id: SnackbarKey;
  message: SnackbarMessage;
  variant?: NotificationsVariant;
  style?: Object;
}

const SnackBar = forwardRef<HTMLDivElement, Props>(
  ({ message, variant = 'success', style, id, ...other }, ref) => {
    // const { closeSnackbar } = useSnackbar();
    // const onClick = () => closeSnackbar(id);
    return (
      <div
        style={style}
        ref={ref}
        {...other}
        className={sx(
          'z-[99999] h-[65px] rounded-[100px] bg-white px-[48px] pt-[24px] pb-[24px] text-center',
          '[box-shadow:_0px_0px_30px_rgba(0,_0,_0,_0.1)]',
          'flex items-center justify-center'
        )}
      >
        <div className='flex items-center gap-[12px]'>
          {variant === 'success' ? <SuccessIcon /> : <ErrorNotif />}
          <p className='max-w-[340px] select-none text-base'>{message}</p>
        </div>
        {/* <button onClick={onClick}>
          <SnackbarClose />
        </button> */}
      </div>
    );
  }
);
export default SnackBar;
