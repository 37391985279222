import { useEditFoods as useEdit } from '@/entities/foods';
import { routes } from '@/shared/consts/consts';
import FormTabs from '@/shared/ui/FormTabs/FormTabs';
import FormWrapper from '@/shared/ui/FormWrapper/FormWrapper';
import NewButton from '@/shared/ui/NewButton/NewButton';
import BaseScreen from '@/widgets/BaseScreen';
import Header from '@/widgets/Header/Header';
import React from 'react';

import { tabs } from './consts';

export const EditForm = () => {
  const { handleUpdate, activeTab, handleChangeTab, formState } = useEdit();

  // let blocker = useBlocker(
  //   ({ currentLocation, nextLocation }) =>
  //     (Object.keys(dirtyFields).length > 0 ||
  //       formDirty !== false ||
  //       (superOrder > 0 && isZakrep === false) ||
  //       (superOrder === 0 && isZakrep === true)) &&
  //     currentLocation.pathname !== nextLocation.pathname
  // );
  const tab = tabs.find((tab) => tab.value === activeTab);
  const Component = tab.tab;
  return (
    <BaseScreen exitScreen={false}>
      <Header />
      {/* {blocker && blocker?.state === 'blocked' && (
        <SaveChangesModal
          opened={blocker?.state === 'blocked'}
          redirectUrl={blocker?.location.pathname}
          setOpened={blocker?.reset}
          status={status}
          handleSubmit={handleSubmit}
          isValid={isValid}
          handleFinalSubmitForm={handleFinalSubmitEditArticleForm}
        />
      )} */}

      <FormWrapper>
        <FormTabs
          onClick={handleChangeTab}
          active={tab.value}
          backLink={'/' + routes.foods.foods}
          tabs={tabs}
          isValid={formState.isValid}
        >
          <NewButton
            data-testid={'button-update_article'}
            variant='primary'
            onClick={handleUpdate}
            styles={'mt-[16px]'}
            disabled={!formState.isValid || !formState.formDirty}
          >
            Обновить
          </NewButton>
        </FormTabs>

        <Component />
      </FormWrapper>
    </BaseScreen>
  );
};
