import { reviewsActions } from '@/entities/reviews/model/reviewsSlice';
import SearchInput from '@/pages/Review/components/SearchInput';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { ChangeEvent } from 'react';

export const Search = () => {
  const value = useAppSelector((state) => state.reviews.searchValue);
  const dispatch = useAppDispatch();
  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    dispatch(reviewsActions.changeSearch(e.target.value));

  const onClear = (e: React.MouseEvent<HTMLDivElement>) =>
    dispatch(reviewsActions.changeSearch(''));

  return (
    <SearchInput
      onChange={onChange}
      value={value}
      isReview
      isLetter={value.length > 0}
      onClear={onClear}
    />
  );
};
