import { api } from '@/shared/api';
import {
  CourseDto,
  CreationCourseAttachmentDto,
  CreationCourseContentDto,
  CreationCourseDto,
  CreationQuestionDto,
  ListCourseAttachmentDto,
  ListCourseContentDto,
  ListCourseDto,
  ListCourseFormatDto,
  ListQuestionDto,
  UpdationCourseDto,
} from '@/shared/interfaces/generated';

class CourseApi {
  getCourseFormats() {
    return api.get<ListCourseFormatDto>('/course-formats');
  }

  getAllCourses({
    status,
    search,
    createdAt,
  }: {
    status?: 1 | 2 | 3;
    search?: string;
    createdAt?: 'ASC' | 'DESC' | null;
  }) {
    const params = new URLSearchParams();
    params.append('limit', '10000');
    if (status) {
      params.append('filters[publicationStatusId]', String(status));
    }
    if (search != null) {
      params.append('search', search.trim());
    }
    if (createdAt) {
      params.append('orderBy[publishedAt', createdAt);
    }
    return api.get<ListCourseDto>('/v2/courses', {
      params,
    });
  }

  saveCourse(data: CreationCourseDto) {
    return api.post<CourseDto>('/courses', data);
  }

  addTopicsToCourse(data: number[], courseId: number) {
    return api.post(`/courses/${courseId}/topics:batchAdd`, { ids: data });
  }

  deleteTopicsFromCourse(data: number[], courseId: number) {
    return api.post(`/courses/${courseId}/topics:batchRemove`, { ids: data });
  }

  getCourseAttachments(courseId: number) {
    return api.get<ListCourseAttachmentDto>(`/courses/${courseId}/attachments`);
  }

  addAttachmentToCourse(data: CreationCourseAttachmentDto[], courseId: number) {
    return api.post(`/courses/${courseId}/attachments:batchCreate`, {
      attachments: data,
    });
  }

  deleteAttachmentsFromCourse(data: number[], courseId: number) {
    return api.post(`/courses/${courseId}/attachments:batchDelete`, {
      ids: data,
    });
  }

  addPreviewsToCourse(
    data: { file: string; order: number }[],
    courseId: number
  ) {
    return api.post(`/products/${courseId}/media:batchCreate`, {
      media: data,
    });
  }

  deletePreviewFromCourse(data: number[], courseId: number) {
    return api.post(`/products/${courseId}/media:batchDelete`, { ids: data });
  }

  getContentsForCourse(courseId: number) {
    return api.get<ListCourseContentDto>(`/courses/${courseId}/contents`);
  }

  addContentsToCourse(data: CreationCourseContentDto[], courseId: number) {
    return api.post(`/courses/${courseId}/contents:batchCreate`, {
      contents: data,
    });
  }

  updateCourseContents(data: CreationCourseContentDto[], courseId: number) {
    return api.post(`/courses/${courseId}/contents:batchUpdate`, {
      contents: data,
    });
  }

  deleteCourseContents(data: number[], courseId: number) {
    return api.post(`/courses/${courseId}/contents:batchDelete`, {
      ids: data,
    });
  }

  addStaffToProduct(staffIds: number[], productId: number) {
    return api.post(`/products/${productId}/staff:batchAdd`, {
      ids: staffIds,
    });
  }

  deleteStaffFromProduct(staffIds: number[], productId: number) {
    return api.post(`/products/${productId}/staff:batchRemove`, {
      ids: staffIds,
    });
  }

  getSingleCourse(courseId: number) {
    return api.get<CourseDto>(`/courses/${courseId}`);
  }

  getQuestionsForProduct(productId: number) {
    return api.get<ListQuestionDto>(`/products/${productId}/questions`);
  }

  addQuestionsToProduct(data: CreationQuestionDto[], productId: number) {
    return api.post(`/products/${productId}/questions:batchCreate`, {
      questions: data,
    });
  }

  updateQuestionsForProduct(data: CreationQuestionDto[], productId: number) {
    return api.post(`/products/${productId}/questions:batchUpdate`, {
      questions: data,
    });
  }

  deleteQuestionsFromProduct(data: number[], productId: number) {
    return api.post(`/products/${productId}/questions:batchDelete`, {
      ids: data,
    });
  }

  editCourse(courseId: number, formData: UpdationCourseDto) {
    return api.patch<CourseDto>(`/courses/${courseId}`, formData);
  }

  deleteCourse(id: number) {
    return api.delete(`/courses/${id}`);
  }

  publishCourse(id: string) {
    return api.post(`/products/${id}:publish`);
  }

  hideCourse(id: string) {
    return api.post(`/products/${id}:hide`);
  }
}
export const courseApi = new CourseApi();
