import { InputHTMLAttributes, SVGProps } from 'react';

export const ArrowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='10'
    viewBox='0 0 16 10'
    fill='none'
    {...props}
  >
    <path
      d='M9.01213 9.07366L15.0774 2.96484C15.2114 2.8309 15.3179 2.67155 15.3905 2.49598C15.4631 2.32041 15.5005 2.1321 15.5005 1.9419C15.5005 1.7517 15.4631 1.56339 15.3905 1.38782C15.3179 1.21225 15.2114 1.0529 15.0774 0.918962C14.8093 0.650619 14.4468 0.5 14.0689 0.5C13.691 0.5 13.3284 0.650619 13.0604 0.918962L7.99649 6.01925L2.9326 0.918962C2.66458 0.650619 2.30202 0.5 1.92411 0.5C1.5462 0.5 1.18364 0.650619 0.915625 0.918962C0.783047 1.05359 0.678157 1.21325 0.60697 1.38878C0.535783 1.56432 0.4997 1.75229 0.500788 1.9419C0.4997 2.13151 0.535783 2.31948 0.60697 2.49502C0.678157 2.67056 0.783047 2.83022 0.915625 2.96484L6.98085 9.07366C7.11383 9.2087 7.27205 9.31588 7.44636 9.38903C7.62068 9.46218 7.80765 9.49984 7.99649 9.49984C8.18533 9.49984 8.3723 9.46218 8.54662 9.38903C8.72094 9.31588 8.87915 9.2087 9.01213 9.07366Z'
      fill='black'
    />
  </svg>
);

export type SelectProps<
  TTitle extends string | number = string,
  TId extends string | number = string | number
> = {
  selected?: boolean;
  id: TId;
  title: TTitle;
};

export type BaseSelectProps = {
  containerClassName?: string;
  values: SelectProps[];
  label?: string;
  placeholder?: string;
  required?: boolean;
  placeholderClassName?: string;
  handleSelect?: (arg: SelectProps) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export type SingleSelectProps = {
  isSingle?: true;
  selected: SelectProps;
} & BaseSelectProps;

export type MultipleSelectProps = {
  isSingle?: false;
  selected: SelectProps[];
} & BaseSelectProps;
export type SelectInnerProps = SingleSelectProps | MultipleSelectProps;
