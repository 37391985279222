import { IPromocode } from '@/entities/promocodes/types/IPromocodes';
import { formatDateAndTime } from '@/shared/lib/formatDateAndTime';
import { formatPhoneNumber } from '@/shared/lib/formatPhone';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import sx from 'classnames';
import copy from 'copy-to-clipboard';
import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as FIcon } from 'src/assets/images/FIcon.svg';
import { ReactComponent as SICon } from 'src/assets/images/SuccessIcon.svg';

type Props = {
  id?: number;
  company?: string;
  phone?: string;
  email?: string;
  price?: number;
  length?: number;
  promocode?: IPromocode;
  date?: string;
  index?: number;
  isSuccess?: boolean;
};

const Purchase = ({
  id,
  email,
  company,
  length,
  phone,
  promocode,
  index,
  price,
  date,
  isSuccess,
}: Props) => {
  const navigate = useNavigate();
  const [isCopied, setIsCopied] = React.useState(false);
  const showCopyTooltip = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleCopy = async (str: string) => {
    copy(str);
    showCopyTooltip();
  };
  return (
    <div
      className={sx(
        'min-h-14 relative top-[12%] flex h-fit items-center justify-between gap-8 self-stretch border-[#F3F3F3] bg-[#FFF] px-6 py-4 text-lg font-normal not-italic leading-6 text-[color:black] transition-all duration-75 hover:bg-[#F3F3F3]',
        index === 0
          ? 'rounded-t-[16px] border-t border-r border-l'
          : 'rounded-[0px] border-t border-r border-l',
        index === length - 1 ? 'rounded-b-[16px] border-b' : 'rounded-[0px]'
      )}
      data-testid={`link-edit_course-${id}`}
    >
      <div
        onClick={() => navigate(`/marketplace/edit-course/${id}`)}
        className=' font-family: Rubik; whitespace-no-wrap w-[220px] truncate text-ellipsis text-lg font-normal not-italic leading-6 text-blue200 hover:cursor-pointer hover:cursor-pointer hover:text-blue300'
      >
        <span>
          {company ? (
            company
          ) : (
            <p className='text-black hover:cursor-auto'>—</p>
          )}
        </span>
      </div>
      <div className='w-[156px]'>{phone ? formatPhoneNumber(phone) : '—'}</div>
      <Tooltip
        isOpen={isCopied}
        text={'Скопировано'}
        tooltipClassName='bottom-10'
      >
        <div
          onClick={() => handleCopy(email)}
          className='w-[280px] cursor-pointer'
        >
          {email}
        </div>
      </Tooltip>

      <div className='w-[99px]'>{promocode?.title || '—'}</div>
      <div className='w-[77px] text-right'>{price}₽</div>
      <div className='w-[142px]'>{date ? formatDateAndTime(date) : '—'}</div>
      <div className=''>{isSuccess ? <SICon /> : <FIcon />}</div>
    </div>
  );
};

export default memo(Purchase);
