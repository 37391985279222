import { setState } from '@/shared/interfaces/common/setState';
import { useEscapeKey } from '@/shared/lib/hooks/useEscapeKey';
import * as UI from '@/shared/ui';
import NewButton from '@/shared/ui/NewButton/NewButton';
import { FC } from 'react';

interface Props {
  opened: boolean;
  setOpened: setState<boolean>;
  handleContine?: any;
  handleExit?: () => void;
}

export const ContinueModal: FC<Props> = ({
  opened,
  setOpened,
  handleContine,
  handleExit,
}) => {
  useEscapeKey(() => handleExit());
  return (
    <UI.Modal opened={opened} onClose={() => handleExit()}>
      <div className='flex w-[528px] flex-col items-center gap-[16px] text-center text-3xl font-medium'>
        <h2 className='mb-[16px] text-center text-[32px] font-medium not-italic leading-[40px]'>
          Продолжить заполнение?
        </h2>
        <div className='flex w-[360px] flex-col items-center'>
          <NewButton
            data-testid={'button-save_changes'}
            onClick={handleContine}
            variant='primary'
            type='submit'
            styles='w-[360px]'
          >
            Продолжить
          </NewButton>
          <div
            data-testid={'button-cancel_changes'}
            className='mt-[24px] flex h-[23px]  cursor-pointer flex-col gap-[24px] self-center text-center text-base font-normal not-italic leading-5 text-[red] hover:text-[#BD2929]'
            onClick={() => handleExit()}
          >
            Не сохранять
          </div>
        </div>
      </div>
    </UI.Modal>
  );
};
