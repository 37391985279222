import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    width={12}
    height={5}
    fill='none'
  >
    <path fill='#000' d='M0 0h12L7.28 3.933a2 2 0 0 1-2.56 0L0 0Z' />
  </svg>
);
export default SvgComponent;
