import { ReactComponent as Plus } from '@/assets/svg/Plus.svg';
import NoContent from '@/entities/marketplace/ui/NoContent/NoContent';
import { useCourses } from '@/pages/Marketplace/course/model/use-courses';
import * as UI from '@/shared/ui';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import CourseCard from '@/widgets/EditEntityCard';
import { Template } from '@/widgets/Template';

import Loading from '../../../Review/views/Loading/Loading';
import { Areas } from './Areas';

const Courses = () => {
  const { courses, loading, handleSubmit, isEmpty, handleClickPlus } =
    useCourses();
  return (
    <>
      <div className='align-center mb-12 flex h-[56px] justify-between'>
        <div className='flex h-full items-center gap-4'>
          <UI.SectionTitle title='Материалы' />
          <div className={'relative'}>
            <Tooltip tooltipClassName='top-[-72px]' text='Добавить материал'>
              <div
                data-testid={'button-create_course'}
                className='200ms z-0 flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[50%] bg-[#337CEC] transition hover:bg-[#1B56B1]'
                onClick={handleClickPlus}
              >
                <Plus />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <Areas className={'mb-[24px]'} type={'courseFilters'} />
      {loading && <Loading />}
      {courses.length !== 0 && (
        <Template>
          {courses.map((elem) => (
            <CourseCard
              handleSubmit={handleSubmit({
                productId: elem.product.id,
                status: elem.product.publicationStatusId,
                courseId: elem.id,
              })}
              publishedAt={elem.product.publishedAt}
              text={`${elem.product.discountPrice ?? elem.product.price} ₽`}
              actionTitle={'материал'}
              isPinned={elem.product.superOrder > 0}
              status={elem.product.publicationStatusId}
              title={elem.product.title}
              editLink={`/marketplace/edit-course/${elem.id}`}
              key={elem.id}
            />
          ))}
        </Template>
      )}
      {isEmpty && (
        <NoContent text='Здесь ничего нет.. попробуйте изменить параметры фильтрации или поиска' />
      )}
    </>
  );
};

export default Courses;
