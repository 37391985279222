import { FilterSelectType } from '@/shared/ui/Filters';
import Close from 'src/assets/svg/close';

interface ActiveFiltersProps {
  isPurchases?: boolean;
  handleRemoveFilter?: (
    event: React.MouseEvent<HTMLDivElement>,
    v: FilterSelectType['value']
  ) => void;
  handleRemoveAllFilters?: (event: React.MouseEvent<HTMLDivElement>) => void;
  handleRemoveFilterReqs?: (
    event: React.MouseEvent<HTMLDivElement>,
    v: FilterSelectType['value']
  ) => void;
  handleRemoveAllFiltersReqs?: (
    event: React.MouseEvent<HTMLDivElement>
  ) => void;
  activeFilters?: FilterSelectType[];
  activeReqs?: FilterSelectType[];
}

const ActiveFilters = ({
  isPurchases,
  handleRemoveAllFilters,
  handleRemoveAllFiltersReqs,
  handleRemoveFilter,
  handleRemoveFilterReqs,
  activeFilters,
  activeReqs,
}: ActiveFiltersProps) => {
  if ((!activeFilters && !isPurchases) || (isPurchases && !activeReqs)) {
    return <></>;
  }

  return (
    <>
      {!isPurchases &&
        activeFilters &&
        activeFilters?.map(({ label, value }) => (
          <div
            key={value}
            data-testid={'button-remove_filter'}
            className='flex cursor-pointer items-center justify-center gap-2 rounded-[1000px] border border-[black] bg-black py-[7px] pl-4 pr-3.5'
            onClick={(event) => handleRemoveFilter(event, value)}
          >
            <p className='text-base font-normal not-italic leading-[22px] text-[white]'>
              {label}
            </p>
            <Close />
          </div>
        ))}
      {activeFilters?.length > 0 && !isPurchases && (
        <div
          data-testid={'button-remove_all_filters'}
          className='flex cursor-pointer items-center justify-center gap-2 rounded-[1000px] border border-[black] bg-white py-[7px]  pl-4 pr-3.5 text-[black] transition hover:bg-black hover:text-white'
          onClick={(event) => handleRemoveAllFilters(event)}
        >
          <p className='text-base font-normal not-italic leading-[22px]'>
            Очистить всё
          </p>
        </div>
      )}
      {isPurchases &&
        activeReqs &&
        activeReqs.map(({ label, value }) => (
          <div
            data-testid={'button-remove_filter_reqs'}
            key={value}
            className='flex cursor-pointer items-center justify-center gap-2 rounded-[1000px] border border-[black] bg-black py-[7px] pl-4 pr-3.5'
            onClick={(event) => handleRemoveFilterReqs(event, value)}
          >
            <p className='text-base font-normal not-italic leading-[22px] text-[white]'>
              {label}
            </p>
            <Close />
          </div>
        ))}
      {activeReqs?.length > 0 && isPurchases && (
        <div
          data-testid={'button-remove_all_filters_reqs'}
          className='flex cursor-pointer items-center justify-center gap-2 rounded-[1000px] border border-[black] bg-white py-[7px]  pl-4 pr-3.5 text-[black] transition hover:bg-black hover:text-white'
          onClick={(event) => handleRemoveAllFiltersReqs(event)}
        >
          <p className='text-base font-normal not-italic leading-[22px]'>
            Очистить всё
          </p>
        </div>
      )}
    </>
  );
};

export default ActiveFilters;
