import CloseSearch from '@/assets/svg/closeSearch';
import SearchIcon from '@/assets/svg/search';
import { cn } from '@/shared/lib/cn';
import sx from 'classnames';
import { ChangeEvent, FC } from 'react';

export type InputType = 'default' | 'invisible';

interface Props {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear?: (e: React.MouseEvent<HTMLDivElement>) => void;
  isReview?: boolean;
  className?: string;
  isLetter?: boolean;
  type?: InputType;
}

const variantStyles: Record<InputType, string> = {
  default: sx(
    'w-[100%] rounded-full bg-[#F3F3F3] py-3 pl-[53px] pr-2 text-base',
    'placeholder:text-base focus:outline-none'
  ),
  invisible: sx(
    'w-[100%] bg-[white] h-[56px] py-2 pl-[51px] text-base text-xl font-normal leading-[26px]',
    'placeholder:text-[#ABABAB] focus:outline-none'
  ),
};

const Search: FC<Props> = ({
  value,
  onChange,
  isReview,
  className,
  type = 'default',
  isLetter,
  onClear,
}) => {
  return (
    <div className='relative'>
      <input
        data-testid={'input-search'}
        value={value}
        type='text'
        className={cn(variantStyles[type], className)}
        placeholder='Поиск'
        onChange={onChange}
      />
      <div
        className={sx(
          'absolute top-1/2 left-[12px] -translate-y-1/2',
          type === 'default' && 'pl-3',
          type === 'invisible' && 'pl-1'
        )}
      >
        <div className={sx(type === 'invisible' ? 'scale-85' : 'scale-100')}>
          <SearchIcon />
        </div>
      </div>
      {isReview && isLetter && value.length > 0 && (
        <div
          data-testid={'button-clear_input'}
          className='absolute top-1/2 right-[24px] ml-3 -translate-y-1/2 cursor-pointer'
          onClick={(e) => (onClear ? onClear(e) : () => {})}
        >
          <CloseSearch />
        </div>
      )}
    </div>
  );
};

export default Search;
