import { useNavigate } from 'react-router-dom';

type Props = {
  company?: any;
  product?: any;
};

const Company = ({ company, product }: Props) => {
  const navigate = useNavigate();
  return (
    <>
      {company && product && (
        <div
          data-testid={`link-edit_company_course-${product.id}`}
          className='max-w-[100%] cursor-pointer pb-[16px] pt-[8px] text-base font-normal not-italic leading-[22px] text-[#337CEC]'
          onClick={() => navigate(`/marketplace/edit-course/${product.id}`)}
        >
          {' '}
          {product?.title}
        </div>
      )}
      {company && !product && (
        <div
          data-testid={`link-edit_company-${company.id}`}
          className='max-w-[100%] cursor-pointer pb-[16px] pt-[8px] text-base font-normal not-italic leading-[22px] text-[#337CEC]'
          onClick={() =>
            navigate(`/marketplace/edit-company/description/${company.id}`)
          }
        >
          {' '}
          {company?.title}
        </div>
      )}
      {product && !company && (
        <div
          data-testid={`link-edit_course-${product.id}`}
          className='mb-[16px] mt-[8px] max-w-[100%] cursor-pointer truncate text-base font-normal not-italic leading-[22px] text-[#337CEC]'
          onClick={() => navigate(`/marketplace/edit-course/${product.id}`)}
        >
          {' '}
          {product?.title}
        </div>
      )}
      {!product && !company && (
        <div className='mb-[16px] mt-[8px] text-base font-normal not-italic leading-[22px] text-[gray]'>
          Без компании
        </div>
      )}
      {''}
    </>
  );
};

export default Company;
