import { api } from '@/shared/api';

class BannersService {
  getAllBanners() {
    return api.get('/banners');
  }

  createBanner(formData: FormData) {
    return api.post('/banners', formData);
  }

  editBanner(id: number, formData: FormData) {
    return api.patch(`/banners/banner/${id}`, formData);
  }

  getSingleBanner(id: number) {
    return api.get(`/banners/banner/${id}`);
  }

  getApplicationPlaces() {
    return api.get('/applicationPlaces');
  }

  deleteBanner(id: number) {
    return api.delete(`/banners/banner/${id}`);
  }
}
const bannersService = new BannersService();
export default bannersService;
