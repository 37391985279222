import React from 'react';

type Props = {};

const Restart = (props: Props) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.591 3.50828C18.8713 3.62437 19.054 3.89785 19.054 4.20119V8.44383C19.054 8.85805 18.7182 9.19383 18.304 9.19383H14.0614C13.758 9.19383 13.4846 9.0111 13.3685 8.73085C13.2524 8.45059 13.3165 8.128 13.531 7.9135L15.134 6.3105C12.5007 5.20912 9.34938 5.7309 7.2068 7.87348C4.3755 10.7048 4.3755 15.2952 7.2068 18.1265C10.0381 20.9578 14.6286 20.9578 17.4599 18.1265C19.1061 16.4803 19.7956 14.2401 19.5268 12.0937C19.4754 11.6827 19.7668 11.3078 20.1778 11.2563C20.5888 11.2049 20.9638 11.4963 21.0152 11.9073C21.339 14.4934 20.508 17.1997 18.5205 19.1872C15.1034 22.6043 9.56323 22.6043 6.14614 19.1872C2.72906 15.7701 2.72906 10.2299 6.14614 6.81282C8.88452 4.07444 12.9848 3.5312 16.2643 5.18028L17.7737 3.67086C17.9882 3.45637 18.3108 3.3922 18.591 3.50828Z'
        fill='black'
      />
    </svg>
  );
};

export default Restart;
