import { promocodesActions } from '@/entities/promocodes/model/promocodesSlice';
import Search from '@/pages/Review/components/SearchInput';
import { useAppDispatch } from '@/shared/model';

export const Areas = ({ search }: { search?: string }) => {
  const dispatch = useAppDispatch();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(promocodesActions.changeSearch(e.target.value));

  const onClear = (e: React.MouseEvent<HTMLDivElement>) =>
    dispatch(promocodesActions.changeSearch(''));
  return (
    <div className='flex w-[100%] flex-col gap-1'>
      <Search
        onChange={onChange}
        value={search}
        isReview
        isLetter={true}
        onClear={onClear}
      />
    </div>
  );
};
