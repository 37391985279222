const LoadingCard = () => {
  return (
    <svg
      width='411'
      height='304'
      viewBox='0 0 411 304'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='409.667'
        height='303'
        rx='23.5'
        fill='white'
      />
      <rect
        x='0.5'
        y='0.5'
        width='409.667'
        height='303'
        rx='23.5'
        stroke='#F3F3F3'
      />
      <rect x='24' y='24' width='122' height='32' rx='8' fill='#F3F3F3' />
      <rect x='24' y='72' width='140' height='24' rx='8' fill='#F3F3F3' />
      <rect x='24' y='104' width='288' height='24' rx='8' fill='#F3F3F3' />
      <rect x='24' y='144' width='362.667' height='24' rx='8' fill='#F3F3F3' />
      <rect x='24' y='176' width='240' height='24' rx='8' fill='#F3F3F3' />
      <rect x='24' y='224' width='298.667' height='56' rx='28' fill='#F3F3F3' />
      <rect x='330.667' y='224' width='56' height='56' rx='28' fill='#F3F3F3' />
    </svg>
  );
};

export default LoadingCard;
