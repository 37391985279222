import {
  articlesActions,
  ChangeSource,
} from '@/entities/articles/model/articleSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import * as UI from '@/shared/ui';
import NewButton from '@/shared/ui/NewButton/NewButton';
import React, { memo, useCallback } from 'react';

import SingleSource from './SingleSource';

const Source = () => {
  const sources = useAppSelector((state) => state.articles.articlesForm.source);
  const dispatch = useAppDispatch();
  const onSwap = useCallback((index: number, destIndex: number) => {
    dispatch(articlesActions.onSwapArticleSources({ destIndex, index }));
  }, []);
  const onDelete = useCallback((index: number) => {
    dispatch(articlesActions.deleteArticleSource(index));
  }, []);
  const onChange = useCallback((data: ChangeSource) => {
    const { index, key, value } = data;
    dispatch(articlesActions.changeArticleSources({ index, key, value }));
  }, []);

  const handleAddSource = () => {
    dispatch(articlesActions.addArticleSource());
  };
  return (
    <UI.Block>
      <fieldset className='mb-[40px] flex w-[100%] items-center justify-between'>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Источники
        </h1>
      </fieldset>
      <div className='flex w-[100%] flex-col items-center space-y-6'>
        {sources?.map((elem, i) => (
          <SingleSource
            {...elem}
            index={i}
            itemsCount={sources.length}
            onSwap={onSwap}
            onChange={onChange}
            onDelete={onDelete}
            key={elem.order}
            id={elem.order}
          />
        ))}
        <NewButton
          data-testid={'button-add-source'}
          variant='blue'
          onClick={handleAddSource}
        >
          Добавить ещё
        </NewButton>
      </div>
    </UI.Block>
  );
};

export default memo(Source);
