export function formatDateToDDMMYY(inputDate: any) {
  const date = new Date(inputDate);
  const day = date.getDate();
  const month = date.getMonth() + 1; // Месяцы начинаются с 0
  const year = date.getFullYear() % 100; // Получаем двузначный год

  // Функция padStart добавляет нули слева, если число состоит из одной цифры
  const formattedDay = String(day).padStart(2, '0');
  const formattedMonth = String(month).padStart(2, '0');
  const formattedYear = String(year).padStart(2, '0');

  return `${formattedDay}.${formattedMonth}.${formattedYear}`;
}
