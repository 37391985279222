import { useEscapeKey } from '@/shared/lib/hooks/useEscapeKey';
import { useOutsideClick } from '@/shared/lib/hooks/useOutsideClick';
import { Label } from '@/shared/ui/Label/Label';
import cn from 'classnames';
import { useState } from 'react';

import { ArrowIcon, SelectProps, SingleSelectProps } from './model';

export const SelectSingle = ({
  values,
  label,
  containerClassName,
  placeholder,
  placeholderClassName,
  required,
  selected,
  handleSelect,
  ...props
}: SingleSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const onSelect = (value: SelectProps) => {
    handleSelect(value);
    setIsOpen(false);
  };
  const ref = useOutsideClick(() => setIsOpen(false));
  useEscapeKey(() => setIsOpen(false));
  const isSelected = (value: SelectProps) => {
    return value?.id === selected?.id;
  };
  const isDisabled = props.disabled || !values?.length;
  const handleClick = () => {
    if (isDisabled) {
      return;
    }
    setIsOpen((v) => !v);
  };
  return (
    <div
      ref={ref}
      className={cn('flex flex-col gap-[16px]', containerClassName)}
    >
      <input {...props} type={'hidden'} value={selected?.id || ''} />
      <Label onClick={handleClick} label={label} required={required} />
      <div
        className={`relative ${
          isDisabled ? 'cursor-disabled' : 'cursor-pointer'
        }`}
      >
        <button
          className={cn(
            `flex w-full max-w-full text-start ${
              isDisabled && 'cursor-disabled bg-gray100'
            } gap-[16px] overflow-hidden overflow-ellipsis rounded-[16px] border-[1px] border-solid border-gray200 py-[23px] pl-[24px] pr-[48px] text-[20px] leading-[26px]`,
            placeholderClassName
          )}
          data-testid={'button-toggle_select'}
          onClick={handleClick}
        >
          {placeholder}
        </button>
        <div
          onClick={handleClick}
          data-testid={'button_arrow-toggle'}
          className={`absolute top-1/2 right-[22px] z-0 -translate-y-1/2 select-none transition-all duration-150 ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          <ArrowIcon />
        </div>
        <div
          className={`absolute z-10 w-full pt-[6px] ${!isOpen ? 'hidden' : ''}`}
        >
          <div
            className={
              'z-1 flex max-h-[450px] w-full flex-col gap-[8px] overflow-y-auto rounded-[16px] bg-white p-[8px] shadow-[0_10px_40px_-15px_rgba(0,0,0,0.3)]'
            }
          >
            {!!values?.length &&
              values?.map((value) => (
                <button
                  data-testid={`button-select-${value.id}`}
                  onClick={() => onSelect(value)}
                  key={value.id}
                  className={`flex h-full w-full flex-1 cursor-pointer gap-[12px] rounded-[16px] py-[15px] px-[16px] text-start text-[20px] leading-[26px] hover:bg-gray100 ${
                    isSelected(value) && 'bg-blue-100 hover:bg-blue100'
                  }`}
                >
                  {value?.title || (
                    <div className={'text-red500'}>Название не указано</div>
                  )}
                </button>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
