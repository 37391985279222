import { ISelectItem } from '@/shared/interfaces/common/ISelectItem';
import { paginate } from '@/shared/lib/hooks/usePaginate';
import { FilterSelectType } from '@/shared/ui/Filters';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AppOrSite,
  IBoughtProduct,
  IEmailUsers,
  IStatistics,
  IUser,
  requestSourceTypeStatisticsInit,
  sortTypeStatisticsInit,
  unionTimePeriodType,
} from '../types/IStatistics';
import {
  fetchAllBoughtProducts,
  fetchAllEmailUsers,
  fetchAllRoles,
  fetchAllStatistics,
  fetchAllUsers,
} from './statisticsThunks';

const authTypes = ['yandex', 'apple', 'google', 'email', 'vkontakte'];

interface IState {
  users: IUser[];
  emailUsers: IEmailUsers[];
  products: IBoughtProduct[];
  selectUsers: ISelectItem[];
  selectRoles: ISelectItem[];
  statistics: IStatistics[];
  requestSource: typeof requestSourceTypeStatisticsInit;
  loading: boolean;
  sortInit: typeof sortTypeStatisticsInit;
  sort?: {
    label: string;
    value: number;
    type: unionTimePeriodType;
  };
  sortOrder: any;
  activeTypes: string[];
  activeReqSource: FilterSelectType[];
  currentRegTypes: string[];
  search: string;
  dateSort: boolean;
  isLoading: boolean;
}

const initialState: IState = {
  users: null,
  loading: false,
  sortOrder: 'asc',
  emailUsers: [],
  requestSource: requestSourceTypeStatisticsInit,
  activeReqSource: [],
  search: '',
  products: [],
  statistics: [],
  activeTypes: authTypes,
  currentRegTypes: [],
  selectUsers: [],
  selectRoles: [],
  isLoading: false,
  sortInit: sortTypeStatisticsInit,
  sort: { label: 'За сегодня', value: 5, type: 'day' },
  dateSort: false,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    changeSort: (
      state,
      action: PayloadAction<{
        label: string;
        value: number;
        type: unionTimePeriodType;
      }>
    ) => {
      state.sort = action.payload;
    },
    changePriceSort: (state) => {
      state.sortOrder = state.sortOrder === 'asc' ? 'desc' : 'asc';

      state.products.sort((a, b) => {
        const orderFactor = state.sortOrder === 'asc' ? 1 : -1;
        return orderFactor * (a.totalPrice - b.totalPrice);
      });
    },
    changeReqSourceOrder: (state, action: PayloadAction<FilterSelectType>) => {
      const isAlreadyAdded = state.activeReqSource.some(
        (item) => item.value === action.payload.value
      );
      if (!isAlreadyAdded) {
        state.activeReqSource = [...state.activeReqSource, action.payload];
      } else {
        state.activeReqSource = state.activeReqSource.filter(
          (item) => item.value !== action.payload.value
        );
      }
    },
    setActiveTypes: (state, action: PayloadAction<string>) => {
      const index = state.currentRegTypes.indexOf(action.payload);

      if (index !== -1) {
        state.currentRegTypes.splice(index, 1);
      } else {
        state.currentRegTypes.push(action.payload);
      }
    },
    onSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    clearSearch: (state) => {
      state.search = '';
    },
    changeDateSort: (state) => {
      state.dateSort = !state.dateSort;
    },
    removeFilter: (state, action: PayloadAction<{ type: AppOrSite }>) => {
      state.activeReqSource = state.activeReqSource.filter(
        (item) => item.value !== action.payload.type
      );
    },
    removeAllFilter: (state) => {
      state.activeReqSource = [];
    },
    clearDateSort: (state) => {
      state.dateSort = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.users = paginate({
        page: action.payload.page,
        data: action.payload.data.users,
        state: state.users,
      });
      state.loading = false;
    });
    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      console.error(action.payload);
      state.loading = false;
    });

    builder.addCase(fetchAllRoles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllRoles.fulfilled, (state, action) => {
      state.selectRoles = action.payload.map((elem) => ({
        label: elem.description,
        value: elem.id,
      }));
      state.loading = false;
    });

    builder.addCase(fetchAllEmailUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStatistics.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllStatistics.fulfilled, (state, action) => {
      state.statistics = [...action.payload.statistics];
      state.loading = false;
    });
    builder.addCase(fetchAllEmailUsers.fulfilled, (state, action) => {
      state.emailUsers = paginate({
        page: action.payload.page,
        data: action.payload.data.registrations,
        state: state.emailUsers,
      });
      state.loading = false;
    });

    builder.addCase(fetchAllBoughtProducts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAllBoughtProducts.fulfilled, (state, action) => {
      state.products = paginate({
        page: action.payload.page,
        data: action.payload.data.orders.filter(
          (item) => item?.totalPrice !== undefined && item?.items?.[0]?.title
        ),
        state: state.products,
      });
      state.loading = false;
    });
  },
});

export const { reducer: statisticsReducer, actions: statisticsActions } =
  statisticsSlice;
