import sx from 'classnames';

const SectionTitle = ({
  title,
  styles = '',
}: {
  title: string;
  styles?: string;
}) => {
  return <h1 className={sx('text-[42px] font-medium', styles)}>{title}</h1>;
};
export default SectionTitle;
