import { ISelectItem } from '@/shared/interfaces/common/ISelectItem';
import { tryCatch } from '@/shared/lib/tryCatch';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import bannersService from '../api/banners-service';
import { IBanner } from '../types/IBanner';
import { IPlace } from '../types/IPlace';

interface IBannerStore {
  banners: IBanner[];
  places: IPlace[];
  selectPlaces: ISelectItem[];
  banner: IBanner;
}

export const fetchBanners = createAsyncThunk(
  'banners/fetchBanners',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch<{ banners: IBanner[] }>(
      bannersService.getAllBanners()
    );
    if (err) {
      throw rejectWithValue(`ERROR in fetchBanners >>> ${err.message}`);
    }
    return data.banners;
  }
);

export const createBanner = createAsyncThunk(
  'banners/createBanner',
  async (formData: FormData, { rejectWithValue }) => {
    const [data, err] = await tryCatch<IBanner>(
      bannersService.createBanner(formData)
    );
    if (err) {
      throw rejectWithValue(`ERROR in createBanner >>> ${err.message}`);
    }
    return data;
  }
);

export const editBanner = createAsyncThunk(
  'banners/editBanner',
  async (
    { id, formData }: { id: number; formData: FormData },
    { rejectWithValue }
  ) => {
    const [data, err] = await tryCatch<IBanner>(
      bannersService.editBanner(id, formData)
    );
    if (err) {
      throw rejectWithValue(`ERROR in editBanner >>> ${err.message}`);
    }
    return data;
  }
);

export const deleteBanner = createAsyncThunk(
  'banners/deleteBanner',
  async (id: number, { rejectWithValue }) => {
    const [, err] = await tryCatch(bannersService.deleteBanner(id));
    if (err) {
      throw rejectWithValue(`ERROR in deleteBanner >>> ${err.message}`);
    }
    return id;
  }
);

export const fetchBannerById = createAsyncThunk(
  'banners/fetchBannerById',
  async (id: number, { rejectWithValue }) => {
    const [data, err] = await tryCatch<IBanner>(
      bannersService.getSingleBanner(id)
    );
    if (err) {
      throw rejectWithValue(`ERROR in fetchBannerById >>> ${err.message}`);
    }
    return data;
  }
);

export const fetchAppPlaces = createAsyncThunk(
  'banners/fetchAppPlaces',
  async (_, { rejectWithValue }) => {
    const [data, err] = await tryCatch<{ applicationPlaces: IPlace[] }>(
      bannersService.getApplicationPlaces()
    );
    if (err) {
      throw rejectWithValue(`ERROR in fetchAppPlaces >>> ${err.message}`);
    }
    return data.applicationPlaces;
  }
);

const initialState = {
  banners: [],
  places: [],
  selectPlaces: [],
  banner: {},
} as IBannerStore;

const appSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBanners.fulfilled, (state, action) => {
      state.banners = action.payload;
    });
    builder.addCase(fetchBanners.rejected, (_, action) => {
      console.error(action.payload);
    });

    builder.addCase(createBanner.fulfilled, (state, action) => {
      state.banners.unshift(action.payload);
    });
    builder.addCase(createBanner.rejected, (_, action) => {
      console.error(action.payload);
    });

    builder.addCase(fetchBannerById.fulfilled, (state, action) => {
      state.banner = action.payload;
    });
    builder.addCase(fetchBannerById.rejected, (_, action) => {
      console.error(action.payload);
    });

    builder.addCase(deleteBanner.fulfilled, (state, action) => {
      state.banners = state.banners.filter(
        (elem) => elem.id !== action.payload
      );
    });
    builder.addCase(deleteBanner.rejected, (state, action) => {
      console.error(action.payload);
    });

    builder.addCase(editBanner.fulfilled, (state, action) => {
      state.banner = action.payload;
      state.banners = state.banners.map((elem) =>
        elem.id === action.payload.id ? action.payload : elem
      );
    });
    builder.addCase(editBanner.rejected, (_, action) => {
      console.error(action.payload);
    });

    /**
     * places
     */

    builder.addCase(fetchAppPlaces.fulfilled, (state, action) => {
      state.places = action.payload;
      state.selectPlaces = state.places.map(({ id, title }) => ({
        value: id,
        label: title,
      }));
    });
    builder.addCase(fetchAppPlaces.rejected, (_, action) => {
      console.error(action.payload);
    });
  },
});

export const { actions: bannersActions, reducer: bannerReducer } = appSlice;

export default appSlice.reducer;
