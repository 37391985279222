import React from 'react';

type Props = {
  fill?: string | boolean;
};

const SortUp = ({ fill }: Props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Small/Sort/Up'>
        <path
          id='Icon'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3 5.25C3 4.83579 3.33579 4.5 3.75 4.5H12.25C12.6642 4.5 13 4.83579 13 5.25C13 5.66421 12.6642 6 12.25 6H3.75C3.33579 6 3 5.66421 3 5.25ZM3 8.75C3 8.33579 3.33579 8 3.75 8H9.25C9.66421 8 10 8.33579 10 8.75C10 9.16421 9.66421 9.5 9.25 9.5H3.75C3.33579 9.5 3 9.16421 3 8.75ZM3.5 11.5C3.22386 11.5 3 11.8358 3 12.25C3 12.6642 3.22386 13 3.5 13H6.5C6.77614 13 7 12.6642 7 12.25C7 11.8358 6.77614 11.5 6.5 11.5H3.5Z'
          fill={fill ? '#ABABAB' : 'black'}
        />
      </g>
    </svg>
  );
};

export default SortUp;
