import {
  fetchAllCompanies,
  fetchCourseForEdit,
  getCourseFormats,
  getTopics,
  publishCourse,
  saveCourseContents,
  saveCourseDescription,
  saveCourseFrequentQuestions,
} from '@/entities/marketplace/model/markeplaceThunks';
import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { CreateCourseTabs } from '@/entities/marketplace/types/tabs';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { useEffect, useState } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';

import { tabs } from '../consts';

export const useEditCourse = () => {
  const { courseId } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const navigate = useNavigate();
  const activeTab = useAppSelector(
    (store) => store.marketplace.activeCreateCourseTab
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        await dispatch(fetchCourseForEdit(+courseId));
        await dispatch(getTopics());
        await dispatch(fetchAllCompanies());
        await dispatch(getCourseFormats());
      } catch (e) {
        navigate('/marketplace/courses');
      }
    })();
    return () => {
      dispatch(marketplaceActions.resetCourseForm());
    };
  }, [courseId, dispatch]);

  const { form, productId, publicationStatusId, unsavedChanges, canSubmit } =
    useAppSelector((store) => store.marketplace.courseForm);
  useEffect(() => {
    dispatch(marketplaceActions.checkCanSubmitCourse());
  }, [dispatch, form]);

  const handleClick = () => {
    if (publicationStatusId !== 3) {
      setModalOpen(() => true);

      return;
    }
    setUpdateModalOpen(() => true);
  };

  const handleSubmit = async ({
    withRedirect,
    withNotification = true,
  }: {
    withRedirect?: boolean;
    withNotification?: boolean;
  }) => {
    try {
      await dispatch(saveCourseDescription({ isEditing: true }));
      await dispatch(saveCourseContents());
      await dispatch(saveCourseFrequentQuestions());

      if (withRedirect) {
        navigate('/marketplace/courses/');
      }
      if (withNotification) {
        dispatch(
          setNotification({
            value: 'Материал обновлен',
            variant: 'success',
          })
        );
      }
    } finally {
      setUpdateModalOpen(() => false);
    }
  };

  const handlePublic = async () => {
    try {
      await handleSubmit({ withNotification: false });
      await dispatch(publishCourse(+productId));

      dispatch(
        setNotification({
          value: 'Материал опубликован',
          variant: 'success',
        })
      );
      navigate('/marketplace/courses/');
    } catch (e) {
      dispatch(
        setNotification({
          value: 'Ошибка при публикации',
          variant: 'error',
        })
      );
    } finally {
      setModalOpen(() => false);
    }
  };
  const unsavedChangesAll =
    unsavedChanges.description ||
    unsavedChanges.frequentQuestions ||
    unsavedChanges.contents;

  const handleChangeTab = (tab: CreateCourseTabs) => {
    dispatch(marketplaceActions.changeCreateCourseTab(tab));
  };
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !updateModalOpen &&
      !modalOpen &&
      unsavedChangesAll &&
      canSubmit &&
      currentLocation.pathname !== nextLocation.pathname
  );
  return {
    handleClick,
    handleSubmit,
    handlePublic,
    handleChangeTab,
    modalOpen,
    updateModalOpen,
    setUpdateModalOpen,
    setModalOpen,
    unsavedChangesAll,
    publicationStatusId,
    blocker,
    canSubmit,
    activeTab: tabs.find((tab) => tab.value === activeTab),
  };
};
