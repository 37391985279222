import Close from '@/assets/svg/close';
import { useOutsideClick } from '@/shared/lib/hooks/useOutsideClick';
import cn from 'classnames';
import { addYears } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { InputHTMLAttributes, SVGProps, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { Label } from '../Label/Label';
const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='20'
    height='21'
    viewBox='0 0 20 21'
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4 1.75V3.10149C2.69989 3.22937 1.82475 3.51839 1.17157 4.17157C0.518391 4.82475 0.229375 5.69989 0.101492 7H19.8985C19.7706 5.69989 19.4816 4.82475 18.8284 4.17157C18.1752 3.51839 17.3001 3.22938 16 3.10149V1.75C16 1.19772 15.5523 0.75 15 0.75C14.4477 0.75 14 1.19772 14 1.75V3.00869C13.3993 3 12.7355 3 12 3H8C7.26451 3 6.60074 3 6 3.00869V1.75C6 1.19772 5.55228 0.75 5 0.75C4.44772 0.75 4 1.19772 4 1.75ZM0.00869056 9C0 9.60074 0 10.2645 0 11V13C0 16.7712 0 18.6569 1.17157 19.8284C2.34315 21 4.22876 21 8 21H12C15.7712 21 17.6569 21 18.8284 19.8284C20 18.6569 20 16.7712 20 13V11C20 10.2645 20 9.60074 19.9913 9H0.00869056ZM16 12C16 12.5523 15.5523 13 15 13C14.4477 13 14 12.5523 14 12C14 11.4477 14.4477 11 15 11C15.5523 11 16 11.4477 16 12ZM16 16C16 16.5523 15.5523 17 15 17C14.4477 17 14 16.5523 14 16C14 15.4477 14.4477 15 15 15C15.5523 15 16 15.4477 16 16ZM10 13C10.5523 13 11 12.5523 11 12C11 11.4477 10.5523 11 10 11C9.44771 11 9 11.4477 9 12C9 12.5523 9.44771 13 10 13ZM10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44771 15 9 15.4477 9 16C9 16.5523 9.44771 17 10 17ZM6 12C6 12.5523 5.55228 13 5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11C5.55228 11 6 11.4477 6 12ZM6 16C6 16.5523 5.55228 17 5 17C4.44772 17 4 16.5523 4 16C4 15.4477 4.44772 15 5 15C5.55228 15 6 15.4477 6 16Z'
      fill='black'
    />
  </svg>
);

export const DateInput = ({
  label,
  onChange,
  placeholder,
  initValue,
  onClear,
  dateFormat = 'dd.MM.yy',
  minDate = new Date(Date.now()),
  maxDate = addYears(new Date(Date.now()), 100),
  ...props
}: Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'value'> & {
  onChange: (date: Date | null) => void;
  label?: string;
  onClear?: () => void;
  minDate?: Date;
  maxDate?: Date;
  dateFormat?: string;
  initValue?: Date;
  selectsStart?: boolean;
  selectsEnd?: boolean;
} & ReactDatePickerProps) => {
  const [, setDate] = useState(initValue);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const ref = useOutsideClick(() => setDatePickerOpen(false));
  console.log(initValue, onClear);
  return (
    <span className={cn('relative flex w-full flex-col gap-[16px]')}>
      <Label required={props.required} id={props.name} label={label} />
      <div ref={ref} className={'relative flex h-full w-full'}>
        <DatePicker
          data-testid={'date_picker'}
          name={props.name}
          id={props.name}
          locale={ru}
          minDate={minDate}
          maxDate={maxDate}
          selected={initValue}
          selectsEnd={props?.selectsEnd}
          selectsStart={props?.selectsStart}
          onChange={(newDate: any) => {
            setDate(newDate);
            onChange(newDate);
          }}
          placeholderText={placeholder}
          open={datePickerOpen}
          onCalendarClose={() => setDatePickerOpen(false)}
          onInputClick={() => setDatePickerOpen(true)}
          dateFormat={dateFormat}
          className={
            'flex w-full gap-[16px] rounded-[16px] border-[1px] border-solid border-gray200 px-[24px] py-[23px] text-[20px] leading-[26px] placeholder:text-gray-300 focus:outline-0 active:outline-0 disabled:bg-gray100'
          }
          shouldCloseOnSelect={true}
          {...props}
        />
        {initValue && onClear && (
          <div
            className='absolute right-[52px] top-1/2 -translate-y-1/2 cursor-pointer'
            onClick={() => onClear()}
          >
            <Close color='#ABABAB' size={24} />
          </div>
        )}
        <div
          className='absolute right-[24px] top-1/2 -translate-y-1/2 cursor-pointer'
          onClick={() => setDatePickerOpen(!datePickerOpen)}
        >
          <CalendarIcon />
        </div>
      </div>
    </span>
  );
};
