import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { useEffect } from 'react';

import { deleteSingle, fetchAll } from '../model/thunk';

export const useList = () => {
  const dispatch = useAppDispatch();
  const { foods } = useAppSelector((state) => state.foods);
  const { search, category, age, isMarked } = useAppSelector(
    (state) => state.foods.filters
  );

  const handleDelete = async (id: string) => {
    try {
      await dispatch(deleteSingle(id));
      dispatch(
        setNotification({
          value: 'Продукт удален',
          variant: 'success',
        })
      );
    } catch (e) {
      dispatch(
        setNotification({
          value: 'Ошибка при удалении продукта',
          variant: 'error',
        })
      );
    }
  };
  const foodsFilters = {
    search: search,
    'filters[categoryId]': category?.id,
    'orderBy[fromWhatAge]': age === null ? undefined : age ? 'ASC' : 'DESC',
    'orderBy[possibleAllergy]':
      isMarked === null ? undefined : isMarked ? 'ASC' : 'DESC',
  };
  useEffect(() => {
    (async () => {
      try {
        await dispatch(
          fetchAll({
            filters: foodsFilters,
          })
        );
      } catch (e) {}
    })();
  }, [search, category, age, isMarked]);
  const isEmpty =
    foods.length === 0 && !!Object.values(foodsFilters).filter((v) => v)[0];
  return { foods: foods || [], isEmpty, handleDelete };
};
