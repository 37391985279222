import { getImagePath } from '@/shared/lib/images';
import { UIImage } from '@/shared/ui/PreviewImage/UIImage';
import { TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
interface Props {
  id: number;
  name: string;
  image: string;
  createdAt: string;
  updatedAt: string;
}

const CategoryItem: FC<Props> = ({ id, name, image, createdAt, updatedAt }) => {
  const navigate = useNavigate();
  const getSingleCategory = async () => {
    navigate(`${id}`);
  };
  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        data-testid={`link-${id}`}
        onClick={getSingleCategory}
      >
        <TableCell component='th' scope='row'>
          {id}
        </TableCell>
        <TableCell align='right'>{name}</TableCell>
        <TableCell align='right'>
          <UIImage url={getImagePath(image)} rounded />
        </TableCell>
        <TableCell align='right'>
          {new Date(createdAt).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </TableCell>
        <TableCell align='right'>
          {new Date(updatedAt).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </TableCell>
      </TableRow>
    </>
  );
};

export default CategoryItem;
