import { useCreateCompany } from '@/pages/Marketplace/company/model/use-create-company';
import FormTabs from '@/shared/ui/FormTabs/FormTabs';
import FormWrapper from '@/shared/ui/FormWrapper/FormWrapper';
import Header from '@/widgets/Header/Header';
import React from 'react';

const CreateCompany = () => {
  const { onTabClick, tabs, activeTab } = useCreateCompany();
  return (
    <div className={'bg-gray100'}>
      <div className={'mx-auto flex min-h-screen max-w-[1280px] flex-col'}>
        <Header />
        <FormWrapper className={'pt-[40px]'}>
          <FormTabs
            tabs={tabs}
            backLink={'/marketplace/companies'}
            active={activeTab.value}
            onClick={onTabClick}
          />
          <div
            className={
              'mb-[80px] flex h-full w-full flex-col gap-[40px] rounded-[32px] bg-white p-[40px]'
            }
          >
            {activeTab.tab}
          </div>
        </FormWrapper>
      </div>
    </div>
  );
};

export default CreateCompany;
