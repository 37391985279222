import { useEffect, useState } from 'react';

export const useScrollToBottom = (
  condition: boolean,
  elementToScroll: HTMLElement | null = null
) => {
  const [scrollToBottom, setScrollToBottom] = useState(false);

  useEffect(() => {
    if (condition) {
      setScrollToBottom(true);
    }
  }, [condition]);

  useEffect(() => {
    if (scrollToBottom) {
      if (elementToScroll) {
        elementToScroll.scrollTop = elementToScroll.scrollHeight;
      } else {
        window.scrollTo(0, document.body.scrollHeight);
      }
      setScrollToBottom(false);
    }
  }, [scrollToBottom, elementToScroll]);

  return;
};
