import { foodsTabs, FoodsTabVariant as TabVariant } from '@/entities/foods';

import { Main } from '../ui/Main/Main';
import { Recommendations } from '../ui/Recommendations/Recommendations';
import { Rules } from '../ui/Rules/Rules';

const tabsMapper: Record<TabVariant, () => JSX.Element> = {
  main: Main,
  recs: Recommendations,
  rules: Rules,
};

export const tabs = foodsTabs.map((tab) => ({
  ...tab,
  tab: tabsMapper[tab.value],
}));
