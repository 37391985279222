import { IFormPromocode } from '@/entities/promocodes/types/IPromocodeForm';
import {
  IPromocodes,
  PromocodesAccessTypes,
  PromocodesTypes,
} from '@/entities/promocodes/types/IPromocodes';
import { api } from '@/shared/api';

class PromocodesService {
  getAllPromocodes(
    search?: string,
    endDate?: 'ASC' | 'DESC',
    usageCounter?: typeof endDate,
    limit?: number,
    page?: number
  ) {
    const params = new URLSearchParams();
    params.append('search', search);

    if (endDate) {
      params.append('orderBy[endDate]', endDate);
    }
    if ('DESC' === usageCounter) {
      params.append('orderBy[usageCounter]', usageCounter);
    }

    params.append('limit', String(limit) || '10000');
    if (page) {
      params.append('offset', String((page - 1) * limit));
    }
    return api.get<IPromocodes>(`/promocodes?${params}`);
  }

  getAllPromocodeTypes() {
    return api.get<PromocodesTypes>('/promocodeTypes');
  }

  getAllPromocodeAccessTypes() {
    return api.get<PromocodesAccessTypes>('/promocodeAccessTypes');
  }

  createPromocode(data: IFormPromocode) {
    return api.post('/promocodes', data);
  }

  editPromococode(data: IFormPromocode, id: number) {
    return api.patch(`/promocodes/${id}`, data);
  }

  deletePromococode(id: number) {
    return api.delete(`/promocodes/${id}`);
  }
}
const promocodesService = new PromocodesService();
export default promocodesService;
