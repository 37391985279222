import {
  fetchCompanyForEdit,
  publishCompany,
  submitCompany,
} from '@/entities/marketplace/model/markeplaceThunks';
import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { CreateCompanyTabs } from '@/entities/marketplace/types/tabs';
import { tabs } from '@/pages/Marketplace/company/consts';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { useEffect, useState } from 'react';
import { useBlocker, useNavigate, useParams } from 'react-router-dom';

export const useEditCompany = () => {
  const {
    activeEditCompanyTab: activeTab,
    companyForm: { publicationStatus, unsavedChanges, canSubmit },
  } = useAppSelector((state) => state.marketplace);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const onTabClick = (to: CreateCompanyTabs) => {
    dispatch(marketplaceActions.changeEditCompanyTab(to));
    navigate(`/marketplace/edit-company/${id}`, { replace: true });
  };
  useEffect(() => {
    (async () => {
      await dispatch(fetchCompanyForEdit(+id));
    })();
    return () => {
      dispatch(marketplaceActions.resetCompanyForm());
    };
  }, []);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !modalOpen &&
      unsavedChanges &&
      canSubmit &&
      currentLocation.pathname !== nextLocation.pathname
  );

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleSave = async () => {
    await dispatch(submitCompany({ isEditing: true }));
    dispatch(
      setNotification({ value: 'Изменения сохранены', variant: 'success' })
    );
  };
  const handlePublish = async () => {
    if (unsavedChanges) {
      await handleSave();
    }
    await dispatch(publishCompany(+id));
    dispatch(
      setNotification({
        value: 'Компания опубликована',
        variant: 'success',
      })
    );
    navigate('/marketplace/companies');
  };
  return {
    activeTab: tabs.find((tab) => tab.value === activeTab),
    blocker,
    modalOpen,
    setModalOpen,
    onTabClick,
    handleOpenModal,
    handleSave,
    handlePublish,
    publicationStatus,
    canSubmit,
    unsavedChanges,
  };
};
