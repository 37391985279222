import { foodsActions as actions } from '@/entities/foods';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Select } from '@/shared/ui/Select/Select';

export const SelectMeasure = () => {
  const dispatch = useAppDispatch();
  const { measure } = useAppSelector((state) => state.foods.form.main);

  const { measures } = useAppSelector((state) => state.foods);
  return (
    <Select
      name={'measure'}
      data-testid={'select-measure'}
      id={'measure'}
      selected={measure}
      required
      values={measures}
      handleSelect={(value) =>
        dispatch(
          actions.changeRecipesForm({
            key: 'measure',
            value: value,
          })
        )
      }
      label={'Мера'}
      placeholderClassName={measure ? '' : 'text-gray-300'}
      containerClassName='w-[428px]'
      placeholder={measure ? measure.title : 'Выберите'}
    />
  );
};
