import * as UI from '@/shared/ui';

import { ImageInput } from './image-input';
import { SelectAge } from './select-age';
import { SelectCategory } from './select-category';
import { SelectColor } from './select-color';
import { SelectMeasure } from './select-measure';
import { TextBlock } from './text-block';
import { ToggleMark } from './toggle-mark';

export const Main = () => {
  return (
    <UI.Block>
      <fieldset className='mb-[40px] flex w-[100%] items-center justify-between'>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Описание
        </h1>
        <ToggleMark />
      </fieldset>
      <div
        className='flex flex-col gap-[32px]'
        // onSubmit={handleSubmit(onSubmitCreateArticle)}
      >
        <ImageInput />
        <div className='flex flex-col gap-[32px]'>
          <div className='flex gap-[32px]'>
            <SelectColor />
            <SelectCategory />
          </div>

          <div className='flex gap-[32px]'>
            <SelectAge />
            <SelectMeasure />
          </div>
        </div>
        <TextBlock />
      </div>
    </UI.Block>
  );
};
