import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';

import { Input } from '../../../../../shared/ui/Input/SecondInput';

export const TitleInput = () => {
  const title = useAppSelector(
    (state) => state.marketplace.courseForm.form.title
  );
  const dispatch = useAppDispatch();
  return (
    <Input
      data-testid={'input-title'}
      value={title}
      onChange={(e) =>
        dispatch(
          marketplaceActions.changeCoursesForm({
            key: 'title',
            value: e.target.value,
          })
        )
      }
      name={'title'}
      required
      label={'Название'}
      withClear
    />
  );
};
