import { useAppSelector } from '@/shared/model';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SingleStoryPageView from './SingleStoryPage-View';

const SingleStoryPageContainer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const story = useAppSelector((store) =>
    store.categories.stories.find((elem) => elem.id === +id)
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  useEffect(() => {
    if (!story) {
      navigate('/stories');
    }
  }, [story]);
  if (!story) return;
  return (
    <SingleStoryPageView
      story={story}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      deleteOpen={deleteOpen}
      setDeleteOpen={setDeleteOpen}
    />
  );
};

export default SingleStoryPageContainer;
