import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { getDate } from '@/pages/Marketplace/course/ui/DescriptionCourse/date-input';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Modal } from '@/shared/ui';
import { addYears, format, subYears } from 'date-fns';
import { useState } from 'react';

import { Button } from '../../../../shared/ui/Button/Button';
import { DateInput } from '../../../../shared/ui/DatePicker/DatePicker';

export const PublishModal = ({
  isOpen,
  onClose,
  onSubmit,
  withDate = true,
}: {
  isOpen?: boolean;
  withDate?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const date = useAppSelector(
    (state) => state.marketplace.courseForm.form.date
  );
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };
  const fixedDate = getDate(date);
  const handleSetDate = (v: Date) => {
    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'date',
        value: v,
      })
    );
  };
  const { form } = useAppSelector((state) => state.marketplace.courseForm);
  return (
    <Modal opened={isOpen} onClose={onClose}>
      <div
        className={
          'flex w-[522px] flex-col items-center justify-center gap-[32px]'
        }
      >
        <div className={'flex flex-col items-center justify-center gap-[16px]'}>
          <h2 className={'text-[32px] font-medium leading-[40px] text-black'}>
            Опубликовать материал?
          </h2>
          {withDate && (
            <p className={'text-[20px] leading-[26px] text-black'}>
              {form?.isAdvertising
                ? 'Укажите срок проведения рекламной кампании'
                : 'Укажите дату публикации'}
            </p>
          )}
        </div>
        {withDate &&
          (form?.isAdvertising ? (
            <div
              className={'relative flex items-center justify-center gap-[32px]'}
            >
              <DateInput
                minDate={new Date(Date.now())}
                selectsStart
                maxDate={addYears(new Date(Date.now()), 100)}
                initValue={new Date(Date.now())}
                label={'Дата начала'}
                required
                data-testid={'input-date-start'}
                onChange={handleStartDateChange}
                startDate={startDate}
                endDate={endDate}
                selected={startDate}
              />
              <div
                className={
                  'absolute bottom-[28px] right-1/2 flex h-[16px] w-[16px] translate-x-1/2 items-center justify-center'
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='12'
                  height='2'
                  viewBox='0 0 12 2'
                  fill='none'
                >
                  <path
                    d='M11 0H1C0.447715 0 0 0.447715 0 1C0 1.55229 0.447716 2 1 2H11C11.5523 2 12 1.55229 12 1C12 0.447715 11.5523 0 11 0Z'
                    fill='#ABABAB'
                  />
                </svg>
              </div>
              <DateInput
                selectsEnd
                onChange={handleEndDateChange}
                minDate={new Date(Date.now())}
                maxDate={addYears(new Date(Date.now()), 100)}
                label={'Дата окончания'}
                data-testid={'input-date-end'}
                placeholder={'ДД.ММ.ГГ'}
                startDate={startDate}
                endDate={endDate}
                selected={endDate}
              />
            </div>
          ) : (
            <DateInput
              name={'date'}
              data-testid={'input-date'}
              label={'Дата публикации'}
              minDate={subYears(new Date(), 100)}
              maxDate={addYears(new Date(), 100)}
              value={format(fixedDate, 'dd.MM.yyyy')}
              initValue={fixedDate}
              onChange={handleSetDate}
            />
          ))}

        <div className={'flex w-[360px] flex-col gap-[16px]'}>
          <Button onClick={onSubmit} data-testid={'button-publish'}>
            Опубликовать
          </Button>
          <Button
            onClick={onClose}
            data-testid={'button-cancel'}
            variant={'link'}
          >
            Отмена
          </Button>
        </div>
      </div>
    </Modal>
  );
};
