import cn from 'classnames';
import React from 'react';

import { ReactComponent as SkeletonPurchases } from './assets/Field.svg';
import { ReactComponent as SkeletonUsers } from './assets/Field2.svg';
import { ReactComponent as SkeletonRegistrations } from './assets/Field3.svg';

type Props = {
  type: 1 | 2 | 3;
  className?: string;
};

const Skeletons = (props: Props) => {
  let SkeletonComponent: any;

  switch (props.type) {
    case 1:
      SkeletonComponent = SkeletonPurchases;
      break;
    case 2:
      SkeletonComponent = SkeletonUsers;
      break;
    case 3:
      SkeletonComponent = SkeletonRegistrations;
      break;
    default:
      SkeletonComponent = SkeletonPurchases;
  }

  const skeletonsArray = Array.from({ length: 11 }, (_, index) => (
    <div
      className={cn(
        'pb-1 ',
        props?.className,
        'w-max',
        'border-[1px] border-[#F3F3F3]',
        index === 0
          ? 'rounded-t-[16px] border-t border-r border-l'
          : 'rounded-[0px] border-t border-r border-l',
        index === 10 ? 'rounded-b-[16px] border-b' : 'rounded-[0px]'
      )}
      key={index}
    >
      <SkeletonComponent />
    </div>
  ));

  return <>{skeletonsArray}</>;
};

export default Skeletons;
