import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ChevronLeft } from 'src/assets/images/goBack.svg';

type Props = {
  isPurchases?: boolean;
  form?: boolean;
  onClick?: any;
};

const GoBack = ({ isPurchases, form, onClick }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      data-testid={'button-back'}
      className={cn(
        'flex translate-x-[-0.5%] cursor-pointer items-center justify-start gap-[2px] text-gray-300 hover:text-gray400',
        isPurchases ? 'pt-[24px]' : 'mb-[24px]'
      )}
      onClick={onClick ? () => onClick() : () => navigate(-1)}
    >
      <ChevronLeft />
      <p className='text-base font-normal not-italic leading-[22px]'>Назад</p>
    </div>
  );
};

export default GoBack;
