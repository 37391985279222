import { IStory } from '@/entities/stories/types/IStory';
import { routes } from '@/shared/consts/consts';
import { getImagePath } from '@/shared/lib/images';
import { UIImage } from '@/shared/ui/PreviewImage/UIImage';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';

const StoryItem = ({
  id,
  title,
  image,
  createdAt,
  updatedAt,
  viewsCount,
}: IStory) => {
  const navigate = useNavigate();
  const getSingleStory = () => {
    navigate(`/${routes.adminRoutes.stories}/${id}`);
  };

  return (
    <>
      <TableRow
        key={id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        onClick={getSingleStory}
      >
        <TableCell component='th' scope='row'>
          {id}
        </TableCell>
        <TableCell align='right'>{title}</TableCell>
        <TableCell align='right'>
          <UIImage url={getImagePath(image)} rounded />
        </TableCell>
        <TableCell align='right'>{viewsCount}</TableCell>
        <TableCell align='right'>
          {new Date(createdAt).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </TableCell>
        <TableCell align='right'>
          {new Date(updatedAt).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })}
        </TableCell>
      </TableRow>
    </>
  );
};

export default StoryItem;
