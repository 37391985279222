import { routes } from '@/shared/consts/consts';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import React from 'react';
import { Link } from 'react-router-dom';

const SignInPageView = ({
  validationError,
  handleSubmit,
  handleFormSubmit,
  register,
  setPassword,
  errors,
  setEmail,
  handleSignUp,
}: any) => {
  return (
    <>
      <div className='login'>Войти</div>
      <div className='login-container wrapper'>
        <Container maxWidth='sm'>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Box mb={2}>
              <TextField
                data-testid={'input-email'}
                helperText={
                  validationError === 'User not found' &&
                  'Пользователь с таким email не найден'
                }
                className='input-value'
                variant='outlined'
                label='Email'
                fullWidth
                autoFocus
                autoComplete='Email'
                {...register('email', {
                  onChange: (e: any) => {
                    setEmail(e.target.value);
                  },
                  require: 'Введите Email',
                })}
                error={validationError === 'User not found'}
              />
            </Box>
            <Box mb={2}>
              <TextField
                data-testid={'input-password'}
                helperText={
                  validationError === 'Wrong password' && 'Неверный пароль'
                }
                className='input-value'
                variant='outlined'
                label='Пароль'
                fullWidth
                type='password'
                autoFocus
                autoComplete='Пароль'
                {...register('password', {
                  onChange: (e: any) => {
                    setPassword(e.target.value);
                  },
                  required: 'Введите пароль',
                })}
                error={validationError === 'Wrong password'}
              />
            </Box>
            <Button
              data-testid={'button-login'}
              type='submit'
              variant='contained'
              fullWidth
              color='primary'
            >
              Войти
            </Button>
            <div className='auth-user'>Еще не зарегистрированы?</div>
            <Button
              data-testid={'button-register'}
              type='submit'
              variant='contained'
              fullWidth
              color='primary'
              onClick={handleSignUp}
              className='button-auth'
            >
              Зарегистрироваться
            </Button>
          </form>
          <Link
            data-testid={'link-forgot-password'}
            to={`/${routes.authRoutes.restore}`}
            replace={true}
          >
            <Button
              variant='contained'
              fullWidth
              color='primary'
              className='button-auth p-3'
            >
              Восстановить пароль
            </Button>
          </Link>
        </Container>
      </div>
    </>
  );
};

export default SignInPageView;
