import classNames from 'classnames';

type Values = 'ruble' | 'percentage';

type Props = {
  setActive: (value: Values) => void;
  isActiveType: Values;
};

const variants: Record<Values, { value: string }> = {
  ruble: {
    value: '₽',
  },
  percentage: {
    value: '%',
  },
};

const DiscountType = ({ setActive, isActiveType }: Props) => {
  return (
    <div className='mr-2 flex items-center gap-2 '>
      {Object.entries(variants).map(([key, value]) => (
        <button
          key={key}
          className={classNames(
            'h-[36px] w-[43px] gap-[4px] rounded-[1000px] border-[1px] border-[#E1E1E1] px-4 text-left text-sm font-normal leading-5 text-[#7D7D7D]',
            isActiveType === key && 'border-[black] bg-[black] text-[#FFFFFF]'
          )}
          onClick={() => setActive(key as Values)}
        >
          {value.value}
        </button>
      ))}
    </div>
  );
};

export default DiscountType;
