import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { BASE_URL } from '@/shared/api';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Select, SelectProps } from '@/shared/ui/Select';
import { useEffect, useState } from 'react';
import { getCookie } from 'typescript-cookie';

export const CompanyBlock = () => {
  const companies = useAppSelector((state) => state.marketplace.companies);
  const [staff, setStaff] = useState<SelectProps[]>();

  const { company: selectedCompany, staff: selectedStaff } = useAppSelector(
    (state) => state.marketplace.courseForm.form
  );
  const selectedCompanyId = selectedCompany?.id;
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!selectedCompanyId) {
      return;
    }
    fetch(BASE_URL + `/companies/${selectedCompanyId}/staff`, {
      headers: {
        Authorization: `Bearer ${getCookie('jwt')}`,
      },
    })
      .then((v) => v.json())
      .then((v) =>
        setStaff(v?.staff?.map((v: any) => ({ ...v, title: v.name })))
      );
  }, [selectedCompanyId]);
  const handleSelectCompany = (value: SelectProps) => {
    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'company',
        value: value,
      })
    );
    dispatch(marketplaceActions.changeCoursesForm({ key: 'staff', value: [] }));
  };
  const handleSelectStaff = (value: SelectProps) => {
    const selected = selectedStaff.find((v) => v.id === value.id);
    if (selected) {
      dispatch(
        marketplaceActions.changeCoursesForm({
          key: 'staff',
          value: selectedStaff.filter((v) => v.id !== value.id),
        })
      );
      return;
    }
    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'staff',
        value: [...selectedStaff, value],
      })
    );
  };
  return (
    <div className={'grid grid-cols-2 gap-[32px]'}>
      <Select
        data-testid={'select-company'}
        name={'company'}
        id={'company'}
        selected={selectedCompany}
        required
        values={companies}
        handleSelect={(value) => handleSelectCompany(value)}
        label={'Компания'}
        placeholderClassName={selectedCompany?.title ? '' : 'text-gray-300'}
        placeholder={
          selectedCompany
            ? companies?.find((v) => v.id === selectedCompany?.id)?.title
            : 'Выберите'
        }
      />
      <Select
        data-testid={'select-staff'}
        name={'staff'}
        id={'staff'}
        selected={selectedStaff}
        isSingle={false}
        values={staff}
        disabled={!staff?.length}
        handleSelect={handleSelectStaff}
        label={'Команда'}
        placeholderClassName={selectedStaff?.[0]?.title ? '' : 'text-gray-300'}
        placeholder={
          selectedCompanyId
            ? selectedStaff
                ?.map((v) => v?.title || 'Название не указано')
                .join(', ') || 'Выберите'
            : 'Компания не выбрана'
        }
      />
    </div>
  );
};
