import {
  fetchAllCompanies,
  getCourseFormats,
  getTopics,
} from '@/entities/marketplace/model/markeplaceThunks';
import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { CreateCourseTabs } from '@/entities/marketplace/types/tabs';
import { tabs } from '@/pages/Marketplace/course/consts';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useEffect } from 'react';

export const useCreateCourse = () => {
  const activeTab = useAppSelector(
    (store) => store.marketplace.activeCreateCourseTab
  );
  const dispatch = useAppDispatch();
  const changeTab = (value: CreateCourseTabs) => {
    dispatch(marketplaceActions.changeCreateCourseTab(value));
  };
  const { form } = useAppSelector((store) => store.marketplace.courseForm);

  useEffect(() => {
    dispatch(marketplaceActions.checkCanSubmitCourse());
  }, [dispatch, form]);
  useEffect(() => {
    dispatch(getTopics());
    dispatch(fetchAllCompanies());
    dispatch(getCourseFormats());
  }, [dispatch]);

  return {
    activeTab: tabs.find((tab) => tab.value === activeTab),
    changeTab,
    tabs: tabs.map((tab, index) =>
      index === 0 ? tab : { ...tab, disabled: true }
    ),
  };
};
