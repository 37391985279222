import { ICategory } from '@/entities/categories/types/ICategory';
import { DeleteCategoryModal } from '@/pages/Categories/components/DeleteCategoryModal';
import { EditCategoryModal } from '@/pages/Categories/components/EditCategoryModal';
import { setState } from '@/shared/interfaces/common/setState';
import BasicTabs from '@/shared/ui/Tabs/Tabs';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface Props {
  category: ICategory;
  editOpen: boolean;
  setEditOpen: setState<boolean>;
  setDeleteOpen: setState<boolean>;
  deleteOpen: boolean;
}

const SingleCategoryView: FC<Props> = ({
  category,
  editOpen,
  setEditOpen,
  setDeleteOpen,
  deleteOpen,
}) => {
  return (
    <>
      <EditCategoryModal
        category={category}
        modalVisible={editOpen}
        setModalVisible={setEditOpen}
      />
      <DeleteCategoryModal
        modalOpen={deleteOpen}
        setModalOpen={setDeleteOpen}
        id={category.id}
        name={category.name}
      />
      <BasicTabs label='Назад к категориям' targetRoute='/categories' />
      <Box key={category.id} sx={{ margin: '20px' }}>
        <Box sx={{ display: 'flex', gap: 1.5, mb: 3 }}>
          <Button
            data-testid={'button-open_edit'}
            variant='contained'
            onClick={() => setEditOpen(true)}
          >
            Редактировать
          </Button>
          <Button
            data-testid={'button-open_delete'}
            variant='contained'
            color='warning'
            onClick={() => setDeleteOpen(true)}
          >
            Удалить
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, cursor: 'pointer' }}
            size='medium'
            aria-label='a dense table'
          >
            <TableHead sx={{ background: '#5d8aa8' }}>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align='right'>Название</TableCell>
                <TableCell align='right'>Фото</TableCell>
                <TableCell align='right'>Создано</TableCell>
                <TableCell align='right'>Редактировано</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {category.id}
                </TableCell>
                <TableCell align='right'>{category.name}</TableCell>
                <TableCell align='right'>
                  <img
                    className='categories-image'
                    src={`${BASE_URL}/storage/${category.image}`}
                    alt='image'
                  />
                </TableCell>
                <TableCell align='right'>
                  {new Date(category.createdAt).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TableCell>
                <TableCell align='right'>
                  {new Date(category.updatedAt).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Typography
          sx={{ marginTop: '50px', textAlign: 'center', fontSize: '1.2rem' }}
        >
          Cторис
        </Typography>
        <div className='stories-wrapper'>
          {category.stories.map((storie) => (
            <Card
              sx={{ maxWidth: 345, marginRight: '30px', cursor: 'pointer' }}
              key={storie.id}
            >
              <CardMedia
                component='img'
                alt='card'
                height='140'
                width='140'
                image={`https://trybaby.fmake.ru/storage/${storie.image}`}
                title='Сторис'
              />
              <CardContent>
                <Typography gutterBottom variant='h5' component='div'>
                  Название: {storie.title}
                </Typography>
                <Typography variant='body2' color='text.secondary'>
                  Сторис Id: {storie.id}
                </Typography>
                <Typography>Сторис Id категории: {category.id}</Typography>
                <Typography>
                  Количество просмотров: {storie.viewsCount}
                </Typography>
                <Typography>
                  Дата создания:{' '}
                  {new Date(storie.createdAt).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      </Box>
    </>
  );
};

export default SingleCategoryView;
