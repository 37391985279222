import { setState } from '@/shared/interfaces/common/setState';
import * as UI from '@/shared/ui';
import NewButton from '@/shared/ui/NewButton/NewButton';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  opened: boolean;
  setOpened: setState<boolean>;
  status: any;
  isValid: boolean;
  handleFinalSubmitForm?: any;
  handleSubmit?: any;
  onSaveClick?: () => void;
  redirectUrl: any;
  handleLeave?: () => void;
  isNavigaton?: boolean;
}

export const SaveChangesModal: FC<Props> = ({
  opened,
  setOpened,
  status,
  isValid,
  redirectUrl,
  handleLeave,
  onSaveClick,
  handleFinalSubmitForm,
  handleSubmit,
  isNavigaton,
}) => {
  const navigation = useNavigate();

  const [url, setUrl] = useState();
  useEffect(() => {
    if (redirectUrl) {
      setUrl(redirectUrl);
    }
    return () => setUrl(undefined);
  }, [redirectUrl]);

  return (
    <UI.Modal opened={opened} onClose={() => setOpened(false)}>
      <form
        onSubmit={(e) => {
          isNavigaton && e.preventDefault();
          handleSubmit &&
            handleSubmit((data: any) => {
              handleFinalSubmitForm(data, status.id).then(() => {
                isNavigaton ? navigation(url) : (window.location.href = url);
              });
            });
        }}
      >
        <div className='flex w-[528px] flex-col items-center gap-[16px] text-center text-3xl font-medium'>
          <h2 className='mb-[16px] text-center text-[32px] font-medium not-italic leading-[40px]'>
            Сохранить изменения?
          </h2>
          <div className='flex w-[360px] flex-col'>
            <NewButton
              data-testid={'button-save_changes'}
              onClick={onSaveClick}
              variant='primary'
              type='submit'
              styles='w-full'
            >
              Сохранить
            </NewButton>
            <div
              data-testid={'button-cancel_changes'}
              className='mt-[24px] flex h-[23px]  cursor-pointer flex-col gap-[24px] self-center text-center text-base font-normal not-italic leading-5 text-[red] hover:text-[#BD2929]'
              onClick={() => {
                !isNavigaton && setOpened(false);
                handleLeave ? handleLeave() : (window.location.href = url);
              }}
            >
              Не сохранять
            </div>
          </div>
        </div>
      </form>
    </UI.Modal>
  );
};
