import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Select, SelectProps } from '@/shared/ui/Select';

export const FiltersBlock = () => {
  const { topics, courseFormats: formats } = useAppSelector(
    (state) => state.marketplace
  );
  const dispatch = useAppDispatch();

  const { topics: selectedTopics, formats: selectedFormats } = useAppSelector(
    (state) => state.marketplace.courseForm.form
  );

  const handleSelectTopics = (value: SelectProps) => {
    const selected = selectedTopics.find((v) => v.id === value.id);
    if (selected) {
      dispatch(
        marketplaceActions.changeCoursesForm({
          key: 'topics',
          value: selectedTopics.filter((v) => v.id !== value.id),
        })
      );
      return;
    }
    dispatch(
      marketplaceActions.changeCoursesForm({
        key: 'topics',
        value: [...selectedTopics, value],
      })
    );
  };
  return (
    <div className={'grid grid-cols-2 gap-[32px]'}>
      <Select
        name={'formats'}
        data-testid={'select-formats'}
        id={'formats'}
        selected={selectedFormats}
        required
        values={formats}
        handleSelect={(value) =>
          dispatch(
            marketplaceActions.changeCoursesForm({
              key: 'formats',
              value: [value],
            })
          )
        }
        label={'Формат'}
        placeholderClassName={
          selectedFormats?.[0]?.title ? '' : 'text-gray-300'
        }
        placeholder={selectedFormats?.[0]?.title || 'Выберите'}
      />
      <Select
        data-testid={'select-topics'}
        name={'topics'}
        id={'topics'}
        selected={selectedTopics}
        required
        isSingle={false}
        values={topics}
        handleSelect={handleSelectTopics}
        label={'Темы'}
        placeholderClassName={selectedTopics?.[0]?.title ? '' : 'text-gray-300'}
        placeholder={
          selectedTopics
            ?.map((v) => v?.title || 'Название не указано')
            .join(', ') || 'Выберите'
        }
      />
    </div>
  );
};
