import sx from 'classnames';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  styles?: string;
}

const Block: FC<Props> = ({ children, styles }) => {
  return (
    <div className={sx('w-full rounded-[32px] bg-white p-[40px]', styles)}>
      {children}
    </div>
  );
};

export default Block;
