import { articleReducer } from '@/entities/articles/model/articleSlice';
import { authReducer } from '@/entities/auth/model/authSlice';
import { bannerReducer } from '@/entities/banners/model/bannerSlice';
import { categoriesReducer } from '@/entities/categories/model/categoriesSlice';
import { foodsReducer } from '@/entities/foods';
import { marketplaceReducer } from '@/entities/marketplace/model/marketplaceSlice';
import { promocodesReducer } from '@/entities/promocodes/model/promocodesSlice';
import { reviewsReducer } from '@/entities/reviews/model/reviewsSlice';
import { statisticsReducer } from '@/entities/statistics/model/statisticsSlice';
import { appReducer } from '@/shared/model/appSlice';
import { configureStore } from '@reduxjs/toolkit';

const store = configureStore({
  reducer: {
    categories: categoriesReducer,
    app: appReducer,
    articles: articleReducer,
    foods: foodsReducer,
    reviews: reviewsReducer,
    auth: authReducer,
    promocodes: promocodesReducer,
    banners: bannerReducer,
    statistics: statisticsReducer,
    marketplace: marketplaceReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
