import { useAppSelector } from '@/shared/model';
import { Outlet } from 'react-router-dom';

import ReviewsCard from './sub-pages/Cards';
import DeleteModal from './sub-pages/views/DeleteModal/DeleteModal';
import { TopBar } from './views/TopBar';

const Review = () => {
  const isShown = useAppSelector((state) => state.reviews.isDeleteModal);
  return (
    <div className='container pt-12'>
      <div className='mb-10'>
        <TopBar />
        <ReviewsCard />
      </div>
      <Outlet />
      {isShown.isShown && <DeleteModal />}
    </div>
  );
};
export default Review;
