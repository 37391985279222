import Close from '@/assets/svg/close';
import Divider from '@/assets/svg/Divider';
import Search from '@/pages/Review/components/SearchInput';
import { useOutsideClick } from '@/shared/lib/hooks/useOutsideClick';
import { IOption } from '@/shared/lib/normalizer';
import FormLabel from '@/shared/ui/FormLabel/FormLabel';
import cn from 'classnames';
import React from 'react';
import './Scrollbar.css';

import { Checkbox } from '../Checkbox/Checkbox';

const ArrowIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='10'
    viewBox='0 0 16 10'
    fill='none'
    {...props}
  >
    <path
      d='M9.01213 9.07366L15.0774 2.96484C15.2114 2.8309 15.3179 2.67155 15.3905 2.49598C15.4631 2.32041 15.5005 2.1321 15.5005 1.9419C15.5005 1.7517 15.4631 1.56339 15.3905 1.38782C15.3179 1.21225 15.2114 1.0529 15.0774 0.918962C14.8093 0.650619 14.4468 0.5 14.0689 0.5C13.691 0.5 13.3284 0.650619 13.0604 0.918962L7.99649 6.01925L2.9326 0.918962C2.66458 0.650619 2.30202 0.5 1.92411 0.5C1.5462 0.5 1.18364 0.650619 0.915625 0.918962C0.783047 1.05359 0.678157 1.21325 0.60697 1.38878C0.535783 1.56432 0.4997 1.75229 0.500788 1.9419C0.4997 2.13151 0.535783 2.31948 0.60697 2.49502C0.678157 2.67056 0.783047 2.83022 0.915625 2.96484L6.98085 9.07366C7.11383 9.2087 7.27205 9.31588 7.44636 9.38903C7.62068 9.46218 7.80765 9.49984 7.99649 9.49984C8.18533 9.49984 8.3723 9.46218 8.54662 9.38903C8.72094 9.31588 8.87915 9.2087 9.01213 9.07366Z'
      fill='black'
    />
  </svg>
);
type Props = {
  innerRef?: (node?: Element | null) => void;
  options?: IOption[];
  currentOptions?: number[];
  activeExternal?: number;
  defaultOption: string;
  externalOptions?: IOption[];
  onChooseExternal?: (id: number) => void;
  onClear?: () => void;
  opened: boolean;
  setIsOpened: (a: boolean) => void;
  singleName?: string;
  onChoose: (id: number) => void;
  search?: string | '';
  isOpened: boolean;
  isUpside?: boolean;
  title: string;
  onClearOptions: () => void;
  onClose?: () => void;
  onChange?: (search: React.ChangeEvent<HTMLInputElement>) => void;
};

export const DropdownInput = ({
  options,
  currentOptions,
  opened,
  onClose,
  isUpside = false,
  onClearOptions,
  onChoose,
  onChooseExternal,
  onClear,
  isOpened,
  activeExternal,
  setIsOpened,
  singleName,
  title,
  defaultOption = 'Любой',
  externalOptions,
  search,
  onChange,
  innerRef,
}: Props) => {
  const ref = useOutsideClick(() => onClose());

  const defualtDrop =
    'border-[1px solid] z-[10] mb-[-32px] flex w-[100%] flex-col items-center justify-center rounded-2xl bg-[white] p-2 pt-0 shadow-[0_10px_40px_-15px_rgba(0,0,0,0.3)]';

  const position = isUpside
    ? `${defualtDrop} absolute top-[100%] mt-[6px]`
    : `${defualtDrop} absolute bottom-[100%]`;

  return (
    <div
      className='relative flex w-[100%] cursor-pointer flex-col gap-[16px]'
      ref={ref}
    >
      <FormLabel title={title} htmlFor={`dropdown-input-${title}`} />
      <div
        className={cn(
          'flex h-[72px] items-center gap-4 self-stretch truncate px-6 py-[23px] pr-[42px] text-xl font-normal not-italic leading-[26px] outline-none',
          'rounded-2xl border border-solid border-[color:var(--Simple-Gray-Day,#E1E1E1)]',
          'relative',
          'select-none'
        )}
        data-testid={'button-open_dropdown'}
        onClick={() => setIsOpened(!isOpened)}
      >
        {currentOptions?.length > 1 && `Выбрано: ${currentOptions?.length}`}
        {currentOptions?.length === 1 && `${singleName}`}
        {currentOptions?.length === 0 && `${defaultOption}`}
        {currentOptions?.length > 0 && (
          <button
            className={'absolute right-[44px] top-[32%] transition'}
            onClick={onClearOptions}
          >
            <Close color='#ABABAB' size={24} />
          </button>
        )}

        <button
          className={`absolute right-[20px] top-[42%] transition ${
            opened ? 'rotate-180' : ''
          }`}
        >
          <ArrowIcon />
        </button>
      </div>
      {opened && (
        <div className={position}>
          <div className='flex w-[434px] flex-col pl-2 pr-2'>
            <Search
              data-testid={'dropdown-search'}
              onChange={onChange}
              value={search}
              type='invisible'
              onClear={onClear}
            />
            <span className='ml-[-8px]'>
              <Divider width={434} />
            </span>
            <div
              className={cn(
                'flex h-[160px] flex-col gap-[4px] overflow-auto',
                'scrollbar'
              )}
            >
              {options?.map((item, index) => {
                return (
                  <button
                    key={item.value}
                    className={cn(
                      index === 0 && 'mt-2',
                      index === options?.length - 1 && 'mb-2'
                    )}
                    onClick={() => onChoose(Number(item.value))}
                  >
                    <p
                      className={cn(
                        'flex cursor-pointer items-center gap-[12px] rounded-[16px] p-[16px] text-left text-xl font-normal leading-[26px]',
                        currentOptions?.includes(Number(item.value)) &&
                          'bg-[#EBF1FC]',
                        !currentOptions?.includes(Number(item.value)) &&
                          'hover:bg-[#F3F3F3]'
                      )}
                    >
                      <Checkbox
                        className='m-[0px] h-[24px] w-[24px] p-[0px]'
                        onClick={() => {}}
                        isOpen={currentOptions?.includes(Number(item.value))}
                      />
                      {item.label}
                    </p>
                  </button>
                );
              })}
              {options.length > 0 && <div ref={innerRef} />}
            </div>
          </div>
          <span className='mb-[8px]'>
            <Divider width={386} />
          </span>
          <div className='flex w-[100%] cursor-pointer flex-col gap-[4px] text-left text-xl font-normal leading-[26px]'>
            {externalOptions ? (
              externalOptions?.map((item) => {
                return (
                  <button
                    key={item.value}
                    onClick={() => onChooseExternal(Number(item.value))}
                  >
                    <p
                      className={cn(
                        'flex cursor-pointer items-center gap-[24px] rounded-[16px] p-[16px] text-left text-xl font-normal leading-[26px]',
                        activeExternal === Number(item.value) && 'bg-[#EBF1FC]',
                        activeExternal !== Number(item.value) &&
                          'hover:bg-[#F3F3F3]'
                      )}
                    >
                      {item.label}
                    </p>
                  </button>
                );
              })
            ) : (
              <button onClick={onClearOptions}>
                <p
                  className={cn(
                    'flex cursor-pointer items-center gap-[24px] rounded-[16px] p-[16px] text-left text-xl font-normal leading-[26px]',
                    !externalOptions?.length &&
                      !currentOptions?.length &&
                      'bg-[#EBF1FC]'
                  )}
                >
                  Любой
                </p>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
