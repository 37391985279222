import { IStory } from '@/entities/stories/types/IStory';
import { setState } from '@/shared/interfaces/common/setState';
import { getImagePath } from '@/shared/lib/images';
import { UIImage } from '@/shared/ui/PreviewImage/UIImage';
import BasicTabs from '@/shared/ui/Tabs/Tabs';
import { Box, Button } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC } from 'react';

import { routes } from '../../../shared/consts/consts';
import { DeleteStoryModal } from '../components/DeleteStoryModal';
import { EditStoryModal } from '../components/EditStoryModal';

interface Props {
  story: IStory;
  modalOpen: boolean;
  setModalOpen: setState<boolean>;
  deleteOpen: boolean;
  setDeleteOpen: setState<boolean>;
}

const SingleStoryPageView: FC<Props> = ({
  story,
  modalOpen,
  setModalOpen,
  deleteOpen,
  setDeleteOpen,
}) => {
  return (
    <>
      <EditStoryModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        story={story}
      />
      <DeleteStoryModal
        modalOpen={deleteOpen}
        setModalOpen={setDeleteOpen}
        id={story.id}
        name={story.title}
      />
      <BasicTabs
        label='Назад к сторис'
        targetRoute={`/${routes.adminRoutes.stories}`}
      />
      <Box key={story.id} sx={{ margin: '20px' }}>
        <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
          <Button variant='contained' onClick={() => setModalOpen(true)}>
            Редактировать
          </Button>
          <Button
            variant='contained'
            color='warning'
            onClick={() => setDeleteOpen(true)}
          >
            Удалить
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, cursor: 'pointer' }}
            size='medium'
            aria-label='a dense table'
          >
            <TableHead sx={{ background: '#5d8aa8' }}>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell align='right'>Название</TableCell>
                <TableCell align='right'>Фото</TableCell>
                <TableCell align='right'>Дата создания</TableCell>
                <TableCell align='right'>Дата редактирования</TableCell>
                <TableCell align='right'>Id Категории</TableCell>
                <TableCell align='right'>Id Статьи</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {story.id}
                </TableCell>
                <TableCell align='right'>{story.title}</TableCell>
                <TableCell align='right'>
                  <UIImage url={getImagePath(story.image)} rounded />
                </TableCell>
                <TableCell align='right'>
                  {new Date(story.createdAt).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TableCell>
                <TableCell align='right'>
                  {new Date(story.updatedAt).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                  })}
                </TableCell>
                <TableCell align='right'>{story.categoryId}</TableCell>
                <TableCell align='right'>{story.articleId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default SingleStoryPageView;
