import { submitCompany } from '@/entities/marketplace/model/markeplaceThunks';
import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { Button } from '@/shared/ui/Button/Button';
import { Input } from '@/shared/ui/Input/SecondInput';
import { TextArea } from '@/shared/ui/TextArea/TextArea';
import { useNavigate } from 'react-router-dom';

import { LogoInput } from './LogoInput';

const FormDescription = () => {
  const { title, description, logo } = useAppSelector(
    (state) => state.marketplace.companyForm.form
  );
  const { publicationStatus, canSubmit } = useAppSelector(
    (state) => state.marketplace.companyForm
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSave = async () => {
    const res = await dispatch(submitCompany({ isEditing: false }));
    // @ts-ignore
    const id = res?.payload?.id;
    if (id) {
      dispatch(
        setNotification({ value: 'Изменения сохранены', variant: 'success' })
      );
      navigate(('/marketplace/edit-company/' + id) as string);
    }
  };

  return (
    <>
      <div className={'flex items-center justify-between'}>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Описание
        </h1>
      </div>
      <LogoInput />

      <Input
        data-testid={'input-title'}
        value={title}
        onChange={(e) =>
          dispatch(
            marketplaceActions.changeCompanyForm({
              key: 'title',
              value: e.target.value,
            })
          )
        }
        name={'title'}
        required
        label={'Название'}
        withClear
      />
      <TextArea
        data-testid={'textarea-description'}
        label={'Описание'}
        name={'description'}
        id={'description'}
        value={description}
        length={description?.length}
        onChange={(e) =>
          dispatch(
            marketplaceActions.changeCompanyForm({
              key: 'description',
              value: e.target.value,
            })
          )
        }
      />

      {publicationStatus === undefined && (
        <Button
          data-testid={'button-save'}
          variant={'secondary'}
          onClick={handleSave}
          disabled={logo?.length === 0 || !title.trim()}
        >
          Сохранить
        </Button>
      )}
    </>
  );
};

export default FormDescription;
