import { useCheckImagesSizes } from '@/shared/lib/hooks/useCheckImageSizes';
import { uploadImg } from '@/shared/lib/uploadImg';
import { useAppDispatch } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import DropInput from '@/shared/ui/DropInput/DropInput';
import { useState } from 'react';

const minHeight = 720;
const minWidth = 1280;
export const ImageUpload = () => {
  const dispatch = useAppDispatch();
  const [images, setImages] = useState<string[]>([]);
  const checkImages = useCheckImagesSizes();
  const onChange = async ([files]: File[][]) => {
    const result: string[] = [];
    for (let i = 0; i < files.length; i++) {
      const err = await checkImages({ files, minHeight, minWidth });
      if (err) {
        dispatch(
          setNotification({
            variant: 'error',
            value: 'Ошибка при загрузке (маленький размер)',
          })
        );
        continue;
      }
      const response = await uploadImg([files[i]]);
      result.push(response[0]);
    }
    setImages(() => [...images, ...result]);
  };
  const onDelete = (index: number) => {
    const sliced = images?.filter((_, i) => i !== index);
    setImages(() => sliced);
  };

  return (
    <DropInput
      label='upload'
      htmlFor={'upload'}
      format='dropzone'
      title={'Изображения'}
      sizes={`(${minWidth}x${minHeight} px)`}
      name='upload'
      id='upload'
      data-testid={'dropzone-images'}
      config={{
        accept: {
          'image/png': ['.png'],
          'image/jpg': ['.jpg'],
          'image/jpeg': ['.jpeg'],
        },
        multiple: true,
      }}
      files={images}
      setFiles={onChange}
      withIcon
      withLinkCopy
      onDelete={onDelete}
    />
  );
};
