import { foodsActions as actions } from '@/entities/foods';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { ToggleSwitch } from '@/shared/ui/ToggleSwitch/ToggleSwitch';

export const ToggleMark = () => {
  const dispatch = useAppDispatch();
  const { isMarked } = useAppSelector((state) => state.foods.form.main);
  return (
    <ToggleSwitch
      data-testid={'toggle-pin-article'}
      checked={isMarked}
      label={'Возможна аллергия'}
      onChange={() =>
        dispatch(
          actions.changeRecipesForm({ key: 'isMarked', value: !isMarked })
        )
      }
    />
  );
};
