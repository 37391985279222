type Props = {};

const DeleteRev = (props: Props) => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Medium/Delete'>
        <g id='Icon'>
          <path
            d='M3.41675 5.66667C3.41675 5.20644 3.76213 4.83335 4.18818 4.83335L6.85242 4.8329C7.38177 4.81841 7.84876 4.45482 8.02889 3.91691C8.03362 3.90277 8.03907 3.88532 8.0586 3.82203L8.1734 3.44993C8.24365 3.22179 8.30485 3.02303 8.39049 2.84536C8.72884 2.14349 9.35482 1.6561 10.0782 1.53132C10.2613 1.49973 10.4552 1.49987 10.6778 1.50002H14.1559C14.3784 1.49987 14.5723 1.49973 14.7555 1.53132C15.4788 1.6561 16.1048 2.14349 16.4432 2.84536C16.5288 3.02303 16.59 3.22179 16.6603 3.44993L16.7751 3.82203C16.7946 3.88532 16.8 3.90277 16.8048 3.91691C16.9849 4.45482 17.5445 4.81886 18.0739 4.83335H20.6453C21.0714 4.83335 21.4167 5.20644 21.4167 5.66667C21.4167 6.12691 21.0714 6.5 20.6453 6.5H4.18818C3.76213 6.5 3.41675 6.12691 3.41675 5.66667Z'
            fill='#EC3333'
          />
          <path
            d='M12.2735 21.4998H13.0604C15.7679 21.4998 17.1217 21.4998 18.0019 20.6366C18.8821 19.7734 18.9721 18.3575 19.1522 15.5256L19.4117 11.445C19.5094 9.90848 19.5583 9.1402 19.1167 8.65335C18.6752 8.1665 17.9295 8.1665 16.4382 8.1665H8.8958C7.40445 8.1665 6.65878 8.1665 6.21722 8.65335C5.77566 9.1402 5.82452 9.90848 5.92224 11.445L6.18175 15.5256C6.36185 18.3575 6.4519 19.7734 7.33209 20.6366C8.21227 21.4998 9.56602 21.4998 12.2735 21.4998Z'
            fill='#EC3333'
          />
        </g>
      </g>
    </svg>
  );
};

export default DeleteRev;
