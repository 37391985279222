import {
  ChangeStaff,
  marketplaceActions,
} from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import React, { useCallback } from 'react';

import StaffBlock from './staff-block';

const FormStaff = () => {
  const { staff } = useAppSelector((state) => state.marketplace.companyForm);
  const dispatch = useAppDispatch();
  const onChange = useCallback((data: ChangeStaff) => {
    const { index, key, value } = data;

    dispatch(marketplaceActions.changeStaff({ index, key, value }));
  }, []);
  const onDelete = useCallback((id: number) => {
    dispatch(marketplaceActions.deleteStaff(id));
  }, []);
  const onSwap = useCallback((destIndex: number, index: number) => {
    dispatch(marketplaceActions.swapStaff({ destIndex, index }));
  }, []);

  return (
    <>
      <div className={'flex items-center justify-between'}>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Команда
        </h1>
      </div>
      {staff.map((elem, index) => (
        <StaffBlock
          {...elem}
          key={elem.id}
          onSwap={onSwap}
          index={index}
          onChange={onChange}
          onDelete={onDelete}
          speakersLength={staff.length}
        />
      ))}
      <button
        data-testid={'button-add_staff'}
        className={
          'text-[18px] leading-[24px] text-blue200 transition-all duration-150 hover:text-blue300'
        }
        onClick={() => dispatch(marketplaceActions.addStaff())}
      >
        Добавить еще
      </button>
    </>
  );
};
export default FormStaff;
