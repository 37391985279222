import React, { FC } from 'react';

export const UIImage: FC<{ url: string; rounded?: boolean }> = ({
  url,
  rounded = false,
}) => {
  return (
    <img
      src={url}
      className={`h-[50px] w-[50px] object-cover ${
        rounded ? 'rounded-full' : 'rounded-[2px]'
      }`}
      alt='default'
    />
  );
};
