import { fetchIsAuth, fetchUserRoles } from '@/entities/auth/model/authSlice';
import { useAppDispatch } from '@/shared/model';
import { useEffect } from 'react';
export const useInitBeforeAuth = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      await dispatch(fetchIsAuth());
      await dispatch(fetchUserRoles());
    })();
  }, [dispatch]);
};
