import { statisticsActions } from '@/entities/statistics/model/statisticsSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { FilterSelect, FilterSelectType } from '@/shared/ui/Filters';
import Status from '@/shared/ui/Filters/Status';

export const Filters = ({
  isFilterVisible,
  filterSelectOptions,
  handleMouseEnter,
  handleMouseLeave,
  isPurchases,
}: {
  isPurchases?: boolean;
  isFilterVisible: any;
  filterSelectOptions: any;
  handleMouseEnter: any;
  handleMouseLeave: any;
}) => {
  const reqTypeValues = useAppSelector(
    (state) => state.statistics.requestSource
  );
  const dispatch = useAppDispatch();

  const handleAddFilter = (_: any, filter: FilterSelectType) => {
    dispatch(statisticsActions.changeReqSourceOrder(filter));
  };

  return (
    <div className='flex flex-col'>
      <div
        className='relative flex flex-col'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Status onClick={() => {}} ref={filterSelectOptions} />
        {isFilterVisible && (
          <FilterSelect
            ref={filterSelectOptions}
            data={reqTypeValues}
            handleAddFilter={handleAddFilter}
          />
        )}
      </div>
    </div>
  );
};
