import { Button } from '@/shared/ui/Button/Button';
import FormTabs from '@/shared/ui/FormTabs/FormTabs';
import FormWrapper from '@/shared/ui/FormWrapper/FormWrapper';
import Header from '@/widgets/Header/Header';
import { SaveChangesModal } from '@/widgets/SaveChangesModal/SaveChangesModal';
import React from 'react';

import { tabs } from '../consts';
import { useEditCompany } from '../model/use-edit-company';
import { PublishModal } from './PublishModal';

const EditCompany = () => {
  const {
    activeTab,
    blocker,
    modalOpen,
    setModalOpen,
    onTabClick,
    handleOpenModal,
    handleSave,
    handlePublish,
    publicationStatus,
    canSubmit,
    unsavedChanges,
  } = useEditCompany();
  return (
    <div className={'bg-gray100'}>
      <div className={'mx-auto flex min-h-screen max-w-[1280px] flex-col'}>
        <Header />
        <FormWrapper className={'pt-[40px]'}>
          <FormTabs
            tabs={tabs}
            backLink={'/marketplace/companies'}
            active={activeTab.value}
            onClick={onTabClick}
          >
            {publicationStatus === 3 && (
              <Button
                variant={'primary'}
                disabled={!canSubmit || !unsavedChanges}
                onClick={handleSave}
              >
                Обновить
              </Button>
            )}
            {publicationStatus !== 3 && (
              <Button
                variant={'primary'}
                disabled={!canSubmit}
                onClick={handleOpenModal}
              >
                Опубликовать
              </Button>
            )}

            {(publicationStatus === 2 || publicationStatus === 1) && (
              <Button
                variant={'secondary'}
                disabled={!unsavedChanges || !canSubmit}
                onClick={() => handleSave()}
                className={'mt-[16px] h-[80px] w-full'}
              >
                Сохранить
              </Button>
            )}
          </FormTabs>
          <div
            className={
              'mb-[80px] flex h-full w-full flex-col gap-[40px] rounded-[32px] bg-white p-[40px]'
            }
          >
            {activeTab?.tab}
          </div>
        </FormWrapper>
        <PublishModal
          isOpen={modalOpen}
          onSubmit={handlePublish}
          onClose={() => setModalOpen(false)}
        />
        {blocker && unsavedChanges && blocker?.state === 'blocked' && (
          <SaveChangesModal
            isNavigaton={true}
            opened={blocker?.state === 'blocked'}
            redirectUrl={blocker?.location.pathname}
            setOpened={blocker?.reset}
            status={publicationStatus}
            onSaveClick={() => handleSave()}
            isValid={canSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default EditCompany;
