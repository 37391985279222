export interface IOption {
  value: string | number;
  label: string;
}

export function normalizeData<T, U extends IOption>(
  options: T[],
  valueKey: keyof T,
  labelKey: keyof T,
  externalKey?: keyof T
): U[] {
  return options?.map((item) => ({
    value: item[valueKey],
    label: item[labelKey] || item[externalKey],
  })) as unknown as U[];
}
