import storiesService from '@/entities/stories/api/stories-service';
import { IStory } from '@/entities/stories/types/IStory';
import { ISelectItem } from '@/shared/interfaces/common/ISelectItem';
import { tryCatch } from '@/shared/lib/tryCatch';
import { setNotification } from '@/shared/model/appSlice';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import categoriesService from '../api/categories-service';
import { ICategory } from '../types/ICategory';

interface ICategoriesSlice {
  categories: ICategory[];
  selectCategories: ISelectItem[];
  stories: IStory[];
  category: ICategory;
}

const initialState = {
  categories: [],
  selectCategories: [],
  stories: [],
  category: {},
} as ICategoriesSlice;

/**
 * categories thunks
 */
export const fetchAllCategories = createAsyncThunk(
  'categories/getCategories',
  async (_, { rejectWithValue }) => {
    const [data, error] = await tryCatch<{ categories: ICategory[] }>(
      categoriesService.getAllCategories()
    );
    if (error) {
      throw rejectWithValue(
        `Ошибка в получении категорий (${error.response.status})`
      );
    }
    return data.categories;
  }
);

export const addNewCategory = createAsyncThunk(
  'categories/addNewCategory',
  async (formData: FormData, { rejectWithValue, dispatch }) => {
    const [data, err] = await tryCatch<ICategory>(
      categoriesService.createCategory(formData)
    );
    if (err) {
      throw rejectWithValue(`ERROR in addNewCategory >>> ${err.message}`);
    }
    dispatch(setNotification({ value: 'Операция прошла успешно' }));

    return data;
  }
);

export const editCategory = createAsyncThunk(
  'categories/editCategory',
  async (
    { id, formData }: { id: number; formData: FormData },
    { rejectWithValue, dispatch }
  ) => {
    const [data, err] = await tryCatch<ICategory>(
      categoriesService.editCategory(id, formData)
    );
    if (err) {
      throw rejectWithValue(`ERROR in editCategory >>> ${err.message}`);
    }
    dispatch(setNotification({ value: 'Операция прошла успешно' }));

    return data;
  }
);

export const fetchCategoryById = createAsyncThunk(
  'categories/fetchCategoryById',
  async (id: number, { rejectWithValue }) => {
    const [data, err] = await tryCatch<{ category: ICategory }>(
      categoriesService.getSingleCat(id)
    );
    if (err) {
      throw rejectWithValue(`ERROR in fetchCategoryById >>> ${err.message}`);
    }
    return data.category;
  }
);

export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [, err] = await tryCatch(categoriesService.deleteCategory(id));
    if (err) {
      throw rejectWithValue(`ERROR in deleteCategory >>> ${err.message}`);
    }
    dispatch(setNotification({ value: 'Операция прошла успешно' }));

    return id;
  }
);

/**
 * stories thunks
 */
export const addNewStory = createAsyncThunk(
  'categories/addNewStory',
  async (formData: FormData, { rejectWithValue, dispatch }) => {
    const [data, err] = await tryCatch<IStory>(
      storiesService.createStorie(formData)
    );
    if (err) {
      throw rejectWithValue(`ERROR in addNewStory >>> ${err.message}`);
    }
    dispatch(setNotification({ value: 'Операция прошла успешно' }));

    return data;
  }
);

export const editStory = createAsyncThunk(
  'categories/editStory',
  async (
    { id, formData }: { id: number; formData: FormData },
    { rejectWithValue }
  ) => {
    const [data, err] = await tryCatch<IStory>(
      storiesService.editStory(id, formData)
    );
    if (err) {
      throw rejectWithValue(`ERROR in editStory >>> ${err.message}`);
    }
    return data;
  }
);

export const deleteStory = createAsyncThunk(
  'categories/deleteStory',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [, err] = await tryCatch(storiesService.deleteStory(id));
    if (err) {
      throw rejectWithValue(`ERROR in deleteStory >>> ${err.message}`);
    }
    dispatch(setNotification({ value: 'Операция прошла успешно' }));
    return id;
  }
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    /**
     * categories reducers
     */

    builder.addCase(fetchAllCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.selectCategories = action.payload.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      state.stories = state.categories
        .map((elem) => elem.stories)
        .flat()
        .sort((a, b) => b.id - a.id);
    });
    builder.addCase(fetchAllCategories.rejected, (_, action) => {
      console.error('ERROR IN fetchAllCategories >>> ', action.payload);
    });

    builder.addCase(addNewCategory.fulfilled, (state, action) => {
      state.categories.unshift(action.payload);
      state.selectCategories = state.categories.map(({ id, name }) => ({
        value: id,
        label: name,
      }));
    });
    builder.addCase(addNewCategory.rejected, (_, action) => {
      console.error(action.payload);
    });

    builder.addCase(editCategory.fulfilled, (state, action) => {
      state.categories = state.categories.map((elem) =>
        elem.id === action.payload.id ? action.payload : elem
      );
      state.category = action.payload;
    });
    builder.addCase(editCategory.rejected, (_, action) => {
      console.error(action.payload);
    });

    builder.addCase(fetchCategoryById.fulfilled, (state, action) => {
      state.category = action.payload;
    });
    builder.addCase(fetchCategoryById.rejected, (_, action) => {
      console.error(action.payload);
    });

    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.categories = state.categories.filter(
        (elem) => elem.id !== action.payload
      );
    });
    builder.addCase(deleteCategory.rejected, (_, action) => {
      console.error(action.payload);
    });
    /**
     * stories reducers
     */

    builder.addCase(addNewStory.fulfilled, (state, action) => {
      state.stories.unshift(action.payload);
    });
    builder.addCase(addNewStory.rejected, (_, action) => {
      console.error(action.payload);
    });

    builder.addCase(editStory.fulfilled, (state, action) => {
      state.stories = state.stories.map((elem) =>
        elem.id === action.payload.id ? action.payload : elem
      );
    });
    builder.addCase(editStory.rejected, (_, action) => {
      console.error(action.payload);
    });

    builder.addCase(deleteStory.fulfilled, (state, action) => {
      state.stories = state.stories.filter(
        (elem) => elem.id !== action.payload
      );
    });
    builder.addCase(deleteStory.rejected, (_, action) => {
      console.error(action.payload);
    });
  },
});

export const { reducer: categoriesReducer } = categoriesSlice;
