import { SVGProps } from 'react';

export const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='21'
    viewBox='0 0 19 21'
    fill='none'
    {...props}
  >
    <path
      d='M0.75 4.66667C0.75 4.20644 1.09538 3.83335 1.52143 3.83335L4.18567 3.8329C4.71502 3.81841 5.18202 3.45482 5.36214 2.91691C5.36688 2.90277 5.37232 2.88532 5.39185 2.82203L5.50665 2.44993C5.5769 2.22179 5.6381 2.02303 5.72375 1.84536C6.06209 1.14349 6.68808 0.656103 7.41147 0.53132C7.59457 0.499734 7.78848 0.499868 8.01106 0.500021H11.4891C11.7117 0.499868 11.9056 0.499734 12.0887 0.53132C12.8121 0.656103 13.4381 1.14349 13.7764 1.84536C13.8621 2.02303 13.9233 2.22179 13.9935 2.44993L14.1083 2.82203C14.1279 2.88532 14.1333 2.90277 14.138 2.91691C14.3182 3.45482 14.8778 3.81886 15.4071 3.83335H17.9786C18.4046 3.83335 18.75 4.20644 18.75 4.66667C18.75 5.12691 18.4046 5.5 17.9786 5.5H1.52143C1.09538 5.5 0.75 5.12691 0.75 4.66667Z'
      fill='currentColor'
    />
    <path
      d='M9.60677 20.4998H10.3937C13.1012 20.4998 14.4549 20.4998 15.3351 19.6366C16.2153 18.7734 16.3054 17.3575 16.4855 14.5256L16.745 10.445C16.8427 8.90848 16.8916 8.1402 16.45 7.65335C16.0084 7.1665 15.2628 7.1665 13.7714 7.1665H6.22905C4.73771 7.1665 3.99204 7.1665 3.55047 7.65335C3.10891 8.1402 3.15777 8.90848 3.25549 10.445L3.515 14.5256C3.6951 17.3575 3.78515 18.7734 4.66534 19.6366C5.54553 20.4998 6.89927 20.4998 9.60677 20.4998Z'
      fill='currentColor'
    />
  </svg>
);
