import {
  filterTypeInit,
  IReview,
  IState,
  sortTypeInit,
} from '@/entities/reviews/types/reviews';
import { FilterSelectType } from '@/shared/ui/Filters';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  deleteReview,
  fetchAllReviews,
  publicateReview,
  sendOnModerationReview,
} from './reviewsThunk';

const initialState: IState = {
  reviews: [],
  searchValue: '',
  limit: 10000,
  isLoading: false,
  isError: false,
  filters: [],
  isCardLoading: false,
  filtersInit: filterTypeInit,
  sortInit: sortTypeInit,
  createdAd: 'DESC',
  rate: 'DESC',
  sort: { label: 'По дате', value: 1 },
  isDeleteModal: { isShown: false, id: undefined },
  currentPage: 0,
};

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    changeSearch(state, action: PayloadAction<string>) {
      state.currentPage = 0;
      state.searchValue = action.payload;
    },

    changeLimit(state) {
      state.limit = 10001;
    },

    updateCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },

    openDeleteModal(state, action) {
      state.isDeleteModal.isShown = true;
      state.isDeleteModal.id = action.payload;
    },

    closeDeleteModal(state) {
      state.isDeleteModal.isShown = false;
      state.isDeleteModal.id = undefined;
    },

    addFilter(
      state,
      action: PayloadAction<{ label: string; value: number; param: boolean }>
    ) {
      state.isLoading = true;
      const existingFilter = state.filters.find(
        (filter) => filter.value === action.payload.value
      );

      if (!existingFilter) {
        state.filters = [...state.filters, action.payload];
      }
      state.isLoading = false;
    },

    removeFilter(state, action: PayloadAction<FilterSelectType['value']>) {
      state.isLoading = true;
      state.filters = state.filters.filter(
        (filter) => filter.value !== action.payload
      );
      state.isLoading = false;
    },

    removeAllFilter(state) {
      state.isLoading = true;
      state.filters = [];
      state.isLoading = false;
    },

    clearReviews(state) {
      state.reviews = [];
    },

    changeSort: (
      state,
      action: PayloadAction<{ label: string; value: number | string }>
    ) => {
      state.sort = action.payload;
      if (action.payload.value === 1) {
        if (state.createdAd === 'DESC') {
          state.createdAd = 'ASC';
          state.rate = undefined;
        } else {
          state.createdAd = 'DESC';
          state.rate = undefined;
        }
      } else if (action.payload.value === 2) {
        if (state.rate === 'DESC') {
          state.rate = 'ASC';
          state.createdAd = undefined;
        } else {
          state.rate = 'DESC';
          state.createdAd = undefined;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllReviews.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(fetchAllReviews.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReview.pending, (state) => {
        state.isCardLoading = true;
      })
      .addCase(sendOnModerationReview.pending, (state) => {
        state.isCardLoading = true;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.reviews = state.reviews.filter(
          (item) => item.id !== action.meta.arg
        );
        state.isCardLoading = false;
      })
      .addCase(sendOnModerationReview.fulfilled, (state, action) => {
        const updatedReviews = state.reviews.map((item) => {
          if (item.id === action.meta.arg) {
            return { ...item, isModerated: false };
          }
          return item;
        });
        const replacedIndex = state.reviews.findIndex(
          (item) => item.id === action.meta.arg
        );

        if (replacedIndex !== -1) {
          state.reviews[replacedIndex] = updatedReviews[replacedIndex];
        }

        if (state.filters.length === 1) {
          state.reviews.splice(replacedIndex, 1);
        }

        state.isCardLoading = false;
      })
      .addCase(publicateReview.fulfilled, (state, action) => {
        const updatedReviews = state.reviews.map((item) => {
          if (item.id === action.meta.arg) {
            return { ...item, isModerated: true };
          }
          return item;
        });

        const replacedIndex = state.reviews.findIndex(
          (item) => item.id === action.meta.arg
        );

        if (replacedIndex !== -1) {
          state.reviews[replacedIndex] = updatedReviews[replacedIndex];
        }

        if (state.filters.length === 1) {
          state.reviews.splice(replacedIndex, 1);
        }

        state.isCardLoading = false;
      })
      .addCase(
        fetchAllReviews.fulfilled,
        (
          state,
          action: PayloadAction<IReview[], string, { arg: { offset: number } }>
        ) => {
          const { payload, meta } = action;

          if (meta.arg.offset === 0) {
            state.reviews = payload;
          } else {
            state.reviews = [...state.reviews, ...payload];
          }

          state.isLoading = false;
          state.isError = false;
        }
      );
  },
});

export const { actions: reviewsActions, reducer: reviewsReducer } =
  reviewsSlice;
