import Basket from '@/assets/svg/basket';

const NoContent = ({
  text,
  icon = <Basket />,
}: {
  text: string;
  icon?: any;
}) => {
  return (
    <div className='flex flex-col items-center justify-center rounded-xl py-10 text-base'>
      {icon}
      <p className='mt-4'>Упс...</p>
      <p>{text}</p>
    </div>
  );
};
export default NoContent;
