import { ReactComponent as Calendar } from '@/assets/svg/Calendar.svg';
import { setState } from '@/shared/interfaces/common/setState';
import * as UI from '@/shared/ui';
import NewButton from '@/shared/ui/NewButton/NewButton';
import sx from 'classnames';
import { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

interface Props {
  opened: boolean;
  setOpened: setState<boolean>;
  control: any;
  status: any;
  isValid: boolean;
  handleFinalSubmitForm: any;
  handleSubmit: any;
}

export const ChangeDateModal: FC<Props> = ({
  opened,
  setOpened,
  control,
  handleSubmit,
  handleFinalSubmitForm,
}) => {
  return (
    <UI.Modal opened={opened} onClose={() => setOpened(false)}>
      <form
        onSubmit={handleSubmit((data: any) => {
          handleFinalSubmitForm(data, true);
        })}
      >
        <div className='flex flex-col justify-center'>
          <h2 className='mb-2 w-[528px] text-center text-3xl font-medium'>
            Опубликовать статью?
          </h2>
          <p className='font-small mb-2 text-center text-[20px]'>
            Проверьте дату публикации
          </p>
          <Controller
            name='publishedAt'
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <div className='relative flex w-[428px] flex-col items-start gap-[16px]'>
                <ReactDatePicker
                  selected={field.value ? new Date(field.value) : null}
                  onChange={field?.onChange}
                  dateFormat='dd.MM.yyyy'
                  data-testid={'date_picker'}
                  className={sx(
                    'my-[8px] flex h-[72px] w-[528px] cursor-pointer items-center gap-4 self-stretch truncate px-6 py-[23px] pr-[42px] text-xl font-normal not-italic leading-[26px] outline-none',
                    'rounded-2xl border border-solid border-[color:var(--Simple-Gray-Day,#E1E1E1)]'
                  )}
                  onBlur={field?.onBlur}
                />
                <span className='absolute right-[-76px] top-[35%]'>
                  <Calendar />
                </span>
              </div>
            )}
          />
          <div className='flex w-[100%] flex-col items-center gap-2'>
            <NewButton
              variant='primary'
              type='submit'
              data-testid={'button-publish_article'}
              styles='w-[360px] mt-[16px]'
            >
              Опубликовать
            </NewButton>
          </div>
          <NewButton
            variant='blue'
            type='button'
            data-testid={'button-cancel'}
            styles='w-[100px] max-h-[20px] mt-[16px]'
            onClick={() => setOpened(false)}
          >
            Отмена
          </NewButton>
        </div>
      </form>
    </UI.Modal>
  );
};
