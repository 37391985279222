import { cn } from '@/shared/lib/cn';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';

type Variant = 'primary' | 'submit' | 'blue' | 'red' | 'negative';

const variantStyles: Record<Variant, string> = {
  primary:
    'bg-yellow100 px-[48px] min-h-[80px] text-black hover:bg-yellow200 disabled:hover:bg-yellow100',
  submit: 'bg-[#337CEC] px-[48px] min-h-[80px] text-white',
  blue: 'bg-none text-[#337CEC] text-lg not-italic font-normal leading-6 self-center pb-0 px-0 hover:text-blue300',
  red: 'text-base px-[48px] min-h-[80px] font-normal leading-[22px] tracking-normal text-center text-[red] hover:text-[#BD2929]',
  negative:
    'bg-[#EC3333]  px-[48px] min-h-[80px] hover:bg-[#BD2929] rounded-[100px] text-white',
};

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  styles?: string;
  onClick?: (...args: any) => void;
  children: ReactNode;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  variant: Variant;
}

const NewButton: FC<Props> = ({
  styles = '',
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
  disabled = false,
  variant,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick?.();
  };
  return (
    <button
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      data-testid='button'
      className={cn(
        '700ms flex h-fit w-full items-center justify-center gap-[10px] transition-all',
        {
          'text-center text-xl font-medium not-italic leading-[26px]':
            variant !== 'blue',
        },
        variantStyles[variant],
        'rounded-[100px]',
        'background-[#337CEC] disabled:cursor-not-allowed',
        { 'opacity-50': disabled },
        styles
      )}
    >
      {children}
    </button>
  );
};

export default NewButton;
