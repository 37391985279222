import * as UI from '@/shared/ui';

import { Areas } from './Areas';

export const TopBar = () => {
  return (
    <>
      <div className='align-center mb-12 flex h-[56px] justify-between'>
        <div className='flex h-full items-center gap-8'>
          <UI.SectionTitle title='Отзывы' />
        </div>
      </div>
      <Areas />
    </>
  );
};

export default TopBar;
