import { routes } from '@/shared/consts/consts';
import { useOutsideClick } from '@/shared/lib/hooks/useOutsideClick';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PolarBear from 'src/assets/svg/polarBear';

import logo from '../../assets/images/logo.svg';
import Menu from './Menu';

const lure = Object.freeze([
  { label: 'Категории', link: `/${routes.adminRoutes.categories}` },
  { label: 'Баннеры', link: `/${routes.adminRoutes.banners}` },
]);

const study = Object.freeze([
  { label: 'Компании', link: '/marketplace/companies/' },
  { label: 'Материалы', link: `/${routes.marketplace.courses}` },
  { label: 'Промокоды', link: `/${routes.adminRoutes.promocodes}` },
  { label: 'Отзывы', link: `/${routes.adminRoutes.reviews}` },
  // { label: 'Промокоды', link: `/${routes.adminRoutes.promocodes}` },
]);

const articles = Object.freeze([
  { label: 'Сторис', link: `/${routes.adminRoutes.stories}` },
  { label: 'Статьи', link: `/${routes.adminRoutes.articles}` },
]);

export default function Header() {
  const [open, setOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const navRefLure = useOutsideClick(() => setActiveCategory(null));
  const navRefStudy = useOutsideClick(() => setActiveCategory(null));
  const navRefArticles = useOutsideClick(() => setActiveCategory(null));
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrolled(scrollTop > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setActiveCategory(null);
  }, [location.pathname]);

  const navigate = useNavigate();

  const navItem = cn(
    'cursor-pointer transition text-[20px] mb-[5px] hover:text-[#337CEC] hover:transition-all duration-100'
  );
  const linkItem = cn(
    'flex cursor-pointer items-center self-stretch rounded-[16px] p-[16px] text-[20px] font-normal not-italic leading-5 hover:bg-[#EBF1FC]'
  );
  const absoluteItem = cn(
    'absolute flex h-min flex-col items-start justify-center gap-[4px] rounded-[32px] bg-white p-[20px] shadow-lg translate-x-[-20%]'
  );

  const handleOnMenu = (category: string) => {
    setTimeout(() => setActiveCategory(category), 100);
  };

  const handleOutMenu = (withTimer?: boolean) => {
    if (!withTimer) {
      setActiveCategory(null);
    } else {
      setTimeout(() => {
        setActiveCategory(null);
      }, 500);
    }
  };
  // margin-top: 92px;

  return (
    <header
      className={`h-30 fixed top-0 left-0 right-0 z-[99] w-full bg-white ${
        isScrolled
          ? 'border-solid} border-b border-b-[color:var(--simple-gray-morning,#F3F3F3)]'
          : ''
      }`}
    >
      <div className='container'>
        <div className='relative flex h-[90px] items-center justify-between '>
          <img
            src={logo}
            alt='logo'
            className='hover: cursor-pointer'
            onClick={() => navigate(`/${routes.adminRoutes.statistics}`)}
          />
          <div className='flex items-center gap-8'>
            <div
              className='cursor-pointer text-base'
              onMouseLeave={() => handleOutMenu(false)}
            >
              <p
                className={cn(navItem)}
                onClick={() => navigate(`/${routes.foods.foods}`)}
              >
                {' '}
                Продукты
              </p>
            </div>
            <div
              className='cursor-pointer text-base'
              onMouseLeave={() => handleOutMenu(false)}
            >
              <p
                className={cn(
                  navItem,
                  activeCategory === 'lure' && 'text-[#337CEC]'
                )}
                onMouseEnter={() =>
                  (!activeCategory || activeCategory !== 'lure') &&
                  handleOnMenu('lure')
                }
              >
                {' '}
                Прикорм
              </p>
              {activeCategory === 'lure' && (
                <div
                  className={absoluteItem}
                  ref={navRefLure}
                  onMouseLeave={() => handleOutMenu(false)}
                >
                  {lure.map(({ label, link }) => (
                    <Link key={label} to={link} className={linkItem}>
                      {label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <div
              className='cursor-pointer text-base'
              onMouseLeave={() => handleOutMenu(false)}
            >
              <p
                className={cn(
                  navItem,
                  activeCategory === 'study' && 'text-[#337CEC]'
                )}
                onMouseEnter={() =>
                  (!activeCategory || activeCategory !== 'study') &&
                  handleOnMenu('study')
                }
              >
                {' '}
                Учимся
              </p>
              {activeCategory === 'study' && (
                <div
                  className={absoluteItem}
                  ref={navRefStudy}
                  onMouseLeave={() => handleOutMenu(false)}
                >
                  {study.map(({ label, link }) => (
                    <Link key={label} to={link} className={linkItem}>
                      {label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <div
              className='cursor-pointer text-base'
              onMouseLeave={() => handleOutMenu(false)}
            >
              <p
                className={cn(
                  navItem,
                  activeCategory === 'articles' && 'text-[#337CEC]'
                )}
                onMouseEnter={() =>
                  (!activeCategory || activeCategory !== 'articles') &&
                  handleOnMenu('articles')
                }
              >
                {' '}
                Статьи{' '}
              </p>
              {activeCategory === 'articles' && (
                <div
                  className={absoluteItem}
                  ref={navRefArticles}
                  onMouseLeave={() => handleOutMenu(false)}
                >
                  {articles.map(({ label, link }) => (
                    <Link key={label} to={link} className={linkItem}>
                      {label}
                    </Link>
                  ))}
                </div>
              )}
            </div>
            <div
              onMouseEnter={() => setOpen(true)}
              onMouseLeave={() => setOpen(false)}
            >
              <div className='flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[100px] bg-[#FFEBEF]'>
                <PolarBear />
              </div>
              <Menu open={open} setOpen={setOpen} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
