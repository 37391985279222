import React from 'react';
import ArrowDown from 'src/assets/svg/arrowDown';

type Props = {
  onClick: () => void;
  ref?: any;
  currentFilter?: string;
  name?: string;
};

const Status = ({ onClick, currentFilter, ref, name = 'Статус' }: Props) => {
  return (
    <div
      ref={ref}
      className='g-2 flex cursor-pointer items-center justify-center rounded-[100px] border border-[#E1E1E1] py-[13px]  px-[24px] text-gray400'
      data-testid={'button-status'}
      onClick={onClick}
    >
      <p className='text-gray-600 mr-[5px] text-base font-normal not-italic leading-5'>
        {currentFilter ? currentFilter : name}
      </p>
      <ArrowDown />
    </div>
  );
};

export default Status;
