import { companyApi } from '@/entities/marketplace/api/company.api';
import { courseApi } from '@/entities/marketplace/api/course.api';
import { IAttachment, IContent } from '@/entities/marketplace/types/IContent';
import { IReview } from '@/entities/marketplace/types/IReview';
import { IStaff } from '@/entities/marketplace/types/IStaff';
import { sharedApi } from '@/shared/api/shared.api';
import { env } from '@/shared/consts/env';
import {
  CompanyDto,
  CourseAttachment,
  CourseDto,
  CreationStaffDto,
  QuestionAnswer,
  UpdationCourseDto,
} from '@/shared/interfaces/generated';
import { getTouchedElemsFromFirstArray } from '@/shared/lib/getTouchedElemsFromFirstArray';
import { getUniqueElemsFromFirstArray } from '@/shared/lib/getUniqueElemsFromFirstArray';
import { tryCatch, tryCatchAnyPromise } from '@/shared/lib/tryCatch';
import { uploadImg } from '@/shared/lib/uploadImg';
import { RootState } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { formatISO, isDate } from 'date-fns';

export const fetchAllCompanies = createAsyncThunk(
  'marketplace/fetchAllCompanies',
  async (_, { rejectWithValue, getState }) => {
    const { marketplace } = getState() as RootState;
    const filters = marketplace.companyFilters;
    const [data, err] = await tryCatch(
      companyApi.getAllCompanies({
        status: filters.status,
        search: filters.search,
        createdAt: filters.createdAt,
      })
    );
    if (err) {
      throw rejectWithValue(`ERROR in fetchAllCompanies >>> ${err.message}`);
    }
    return data;
  }
);

export const fetchAllCourses = createAsyncThunk(
  'marketplace/fetchAllCourses',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { marketplace } = getState() as RootState;
    const filters = marketplace.courseFilters;
    const [data, err] = await tryCatch(
      courseApi.getAllCourses({
        status: filters.status,
        search: filters.search,
        createdAt: filters.createdAt,
      })
    );
    if (err) {
      dispatch(
        setNotification({
          value: 'Ошибка в получении курсов',
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    return data;
  }
);

export const submitCompany = createAsyncThunk(
  'marketplace/submitCompany',
  async (
    { isEditing }: { isEditing?: boolean },
    { rejectWithValue, getState, dispatch }
  ) => {
    const handleErr = (err: AxiosError, text?: string) => {
      console.log(err);
      dispatch(
        setNotification({
          value: `${text ?? 'Упс... Что-то пошло не так'}`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.response.status);
    };
    const { marketplace } = getState() as RootState;
    const { companyForm } = marketplace;
    interface CompanyFormData {
      title: string;
      description: string;
      logo: string;
    }

    const logoUrl = (await uploadImg(companyForm.form.logo))[0];

    const companyFormData: CompanyFormData = {
      title: companyForm.form.title,
      description: companyForm.form.description,
      logo: logoUrl,
    };

    const [company, companyErr] = await tryCatch(
      isEditing
        ? companyApi.updateCompany(marketplace.companyId, companyFormData)
        : companyApi.createCompany(companyFormData)
    );
    if (companyErr) {
      handleErr(
        companyErr,
        `Ошибка при ${isEditing ? 'редактировании' : 'создании'} компании [${
          companyErr.response.status
        }]`
      );
    }

    if (!isEditing) {
      return { clearForm: !isEditing, company };
    }
    const initStaffIds = marketplace.editInitialSpeakers.map(({ id }) => id);
    const allStaff = companyForm.staff
      .filter((v) => v.name.trim() !== '' && v.position.trim() !== '')
      .map((v, index) => ({
        ...v,
        order: index,
      }));

    const deletedStaff = companyForm.staff
      .filter((v) => {
        return !allStaff.map((q) => q.id).includes(v.id);
      })
      .map((v) => v.id);

    if (deletedStaff?.length) {
      const [, err] = await tryCatch(
        companyApi.deleteStaffBatch(company.id, deletedStaff)
      );
      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при удалении команды [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }
    const staffToAdd = getUniqueElemsFromFirstArray(allStaff, initStaffIds);

    if (staffToAdd?.length) {
      const fixedStaffToAdd = staffToAdd?.map(
        async ({ position, name, photo }, i) => {
          const avatar =
            typeof photo[0] === 'string'
              ? photo[0]
              : await (async () => {
                  const fd = new FormData();
                  fd.append('file', photo[0]);
                  const ans = await sharedApi.createFile(fd);
                  return ans.data.file;
                })();
          return {
            name,
            positionAtWork: position,
            avatar,
            order: i,
          };
        }
      );
      const awaitedStaff = await Promise.all(fixedStaffToAdd);
      const [, err] = await tryCatch(
        companyApi.createStaffBatch(
          company.id,
          awaitedStaff as unknown as CreationStaffDto[]
        )
      );
      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при добавлении команды [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }
    const staffToEdit = getTouchedElemsFromFirstArray(
      allStaff.map((elem) => ({
        value: elem,
      })),
      marketplace.editInitialSpeakers,
      initStaffIds
    ).map((elem) => elem.value);
    if (staffToEdit?.length) {
      const fixedStaffToEdit = staffToEdit?.map(
        async ({ position, name, photo, id }, i) => {
          const avatar =
            typeof photo[0] === 'string'
              ? photo[0]
              : await (async () => {
                  const fd = new FormData();
                  fd.append('file', photo[0]);
                  const ans = await sharedApi.createFile(fd);
                  return ans.data.file;
                })();
          return {
            id,
            name,
            positionAtWork: position,
            avatar,
            order: i,
          };
        }
      );
      const awaitedStaff = await Promise.all(fixedStaffToEdit);
      const [, err] = await tryCatch(
        companyApi.editStaffBatch(
          company.id,
          awaitedStaff as unknown as CreationStaffDto[]
        )
      );

      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при изменении вопросов [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }

    const allStaffToSave = allStaff
      .sort((a, b) => a.order - b.order)
      .filter((v) => !deletedStaff.includes(v.id));

    return { clearForm: !isEditing, company, allStaffToSave };
  }
);

export const fetchCompanyForEdit = createAsyncThunk(
  'marketplace/fetchCompany',
  async (id: number, { rejectWithValue, dispatch }) => {
    const result: {
      staff: IStaff[];
      reviews: IReview[];
      company: CompanyDto;
    } = {
      staff: [],
      reviews: [],
      company: {} as CompanyDto,
    };

    const [company, companyErr] = await tryCatch(
      companyApi.getSingleCompany(id)
    );
    if (companyErr) {
      dispatch(
        setNotification({ value: companyErr.message, variant: 'error' })
      );
      throw rejectWithValue(companyErr.message);
    }

    const [staff, staffErr] = await tryCatch(companyApi.getStaffById(id));

    if (staffErr) {
      dispatch(setNotification({ value: staffErr.message, variant: 'error' }));
      throw rejectWithValue(staffErr.message);
    }

    result.company = company;
    result.staff = staff.staff
      .sort((a, b) => a.order - b.order)
      .map((elem) => ({
        id: elem.id,
        name: elem.name as unknown as string,
        photo: [elem.avatar],
        position: elem.positionAtWork as unknown as string,
      }));

    return result;
  }
);

export const saveCourseDescription = createAsyncThunk(
  'marketplace/saveCourseDescription',
  async (
    { isEditing }: { isEditing: boolean },
    { rejectWithValue, getState, dispatch }
  ) => {
    const state = getState() as RootState;

    const {
      marketplace: { courseForm },
    } = state;
    const {
      courseId,
      initTopics,
      initAttachments,
      initPreviews,
      initStaff,
      form: {
        description,
        cover,
        topics,
        attachments,
        previews,
        company,
        price,
        title,
        date,
        discountPrice,
        videoLink,
        formats,
        staff,
        isPinned,
      },
    } = courseForm;
    const coverUrl =
      typeof cover[0] === 'string'
        ? cover[0]
        : await (async () => {
            const fd = new FormData();
            fd.append('file', cover[0]);
            const ans = await sharedApi.createFile(fd);
            return ans.data.file;
          })();

    const courseData: UpdationCourseDto = {
      formatId: +formats?.[0].id,
      videoLink: null,
      product: {
        title: title,
        description: description,
        // @ts-ignore
        publishedAt: isDate(date) ? formatISO(date) : date,
        image: coverUrl,
        companyId: +company?.id,
        superOrder: isPinned ? 5 : 0,
        price: null,
        discountPrice: null,
      },
    };
    const prices: { _price: number; _discountPrice?: number | null } = (() => {
      if (!price && !isNaN(discountPrice)) {
        return { _price: Number(discountPrice) };
      }
      if (discountPrice && !isNaN(price) && price > 0) {
        return { _price: Number(price), _discountPrice: Number(discountPrice) };
      }
      if (price > 0 && +discountPrice === 0) {
        return { _price: Number(price), _discountPrice: 0 };
      }
      if (!price && +discountPrice === 0) {
        return { _price: 0 };
      }
      return { _price: Number(price), _discountPrice: Number(discountPrice) };
    })();
    courseData.product.price = prices?._price;
    courseData.product.discountPrice = prices?._discountPrice ?? null;
    if (videoLink) {
      courseData.videoLink = videoLink;
    }

    const [course, courseErr] = await tryCatch(
      isEditing
        ? courseApi.editCourse(courseId, courseData)
        : courseApi.saveCourse(courseData)
    );

    const handleErr = (err: AxiosError, text?: string) => {
      dispatch(
        setNotification({
          value: `${text ?? 'Упс... Что-то пошло не так'}`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    };

    if (courseErr) {
      handleErr(
        courseErr,
        `Ошибка при ${isEditing ? 'редактировании' : 'создании'} курса [${
          courseErr.response.status
        }]`
      );
    }

    const promises: Promise<any>[] = [];

    {
      //TOPICS
      const topicsToDelete = initTopics?.filter(
        (topic) => !topics?.map((v) => v.id)?.includes(topic.id)
      );
      if (topicsToDelete?.length) {
        promises.push(
          courseApi.deleteTopicsFromCourse(
            topicsToDelete?.map((v) => +v.id),
            course.id
          )
        );
      }

      const topicsToAdd = topics?.filter(
        (topic) => !initTopics?.map((v) => v.id)?.includes(topic.id)
      );

      if (topicsToAdd?.length) {
        promises.push(
          courseApi.addTopicsToCourse(
            topicsToAdd?.map((v) => +v.id),
            course.id
          )
        );
      }
    }

    {
      //STAFF
      const staffToDelete = initStaff?.filter(
        (_staff) => !staff?.map((v) => v.id)?.includes(_staff.id)
      );
      if (staffToDelete?.length) {
        promises.push(
          courseApi.deleteStaffFromProduct(
            staffToDelete?.map((v) => +v.id),
            course.product.id
          )
        );
      }
      const staffToAdd = staff?.filter(
        (_staff) => !initStaff?.map((v) => v.id)?.includes(_staff.id)
      );

      if (staffToAdd?.length) {
        promises.push(
          courseApi.addStaffToProduct(
            staffToAdd?.map((v) => +v.id),
            course.product.id
          )
        );
      }
    }

    {
      //ATTACHMENTS

      const attachmentsToAdd = attachments?.filter(
        (attachment) =>
          !initAttachments?.map((v) => v.id)?.includes(attachment.id) &&
          typeof attachment.file !== 'string'
      );
      const attachmentsToDelete = initAttachments?.filter(
        (attachment) => !attachments.map((v) => v.id)?.includes(attachment.id)
      );

      if (attachmentsToAdd?.length) {
        const attachmentFiles: { file: string; name: string }[] =
          await Promise.all(
            attachmentsToAdd.map(async (v) => {
              const fd = new FormData();
              fd.append('file', v.file);
              const ans = await sharedApi.createFile(fd);
              return { file: ans.data.file, name: v.name };
            })
          );
        promises.push(
          courseApi.addAttachmentToCourse(
            attachmentFiles?.map(({ file, name }, index) => ({
              file: file,
              name: name,
              order: index,
            })),
            course.id
          )
        );
      }
      if (attachmentsToDelete?.length) {
        promises.push(
          courseApi.deleteAttachmentsFromCourse(
            attachmentsToDelete?.map((v) => +v.id),
            course.id
          )
        );
      }
    }

    {
      //PREVIEWS

      const previewsToAdd = previews?.filter(
        (preview) =>
          !initPreviews?.map((v) => v.id).includes(preview.id) &&
          typeof preview.file !== 'string'
      );
      const previewsToDelete = initPreviews?.filter(
        (preview) => !previews.map((v) => v.id).includes(preview.id)
      );

      if (previewsToAdd?.length) {
        const previewFiles: { file: string; name: string }[] =
          await Promise.all(
            previewsToAdd?.map(async (v) => {
              const fd = new FormData();
              fd.append('file', v.file);
              const ans = await sharedApi.createFile(fd);
              return { file: ans.data.file, name: v.name };
            })
          );
        promises.push(
          courseApi.addPreviewsToCourse(
            previewFiles?.map(({ file, name }, index) => ({
              file: file,
              name: name,
              order: index,
            })),
            course.product.id
          )
        );
      }
      if (previewsToDelete?.length) {
        promises.push(
          courseApi.deletePreviewFromCourse(
            previewsToDelete?.map((v) => +v.id),
            course.product.id
          )
        );
      }
    }

    const [, err] = await tryCatchAnyPromise(Promise.all(promises));

    if (err) {
      handleErr(err);
      throw rejectWithValue(err.message);
    }

    return { course, previews, attachments, topics, staff };
  }
);

export const saveCourseFrequentQuestions = createAsyncThunk(
  'saveCourseQuestions',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const {
      marketplace: { courseForm },
    } = state;
    const { frequentQuestions, productId } = courseForm;

    const initQuestionIds = courseForm.initQuestions.map((elem) => elem.id);
    const allQuestions = [...frequentQuestions]
      .filter((v) => v.question.trim() !== '' && v.answer.trim() !== '')
      .map((v, index) => ({
        ...v,
        order: index,
      }));

    const deletedQuestions = courseForm.initQuestions
      .filter((v) => {
        return !frequentQuestions.map((q) => q.id).includes(v.id);
      })
      .map((v) => v.id);

    if (deletedQuestions?.length) {
      const [, err] = await tryCatch(
        courseApi.deleteQuestionsFromProduct(deletedQuestions, productId)
      );
      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при удалении вопросов [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }
    const questionsToAdd = getUniqueElemsFromFirstArray(
      allQuestions,
      initQuestionIds
    );

    if (questionsToAdd?.length) {
      const [, err] = await tryCatch(
        courseApi.addQuestionsToProduct(
          questionsToAdd?.map(({ answer, order, question }) => ({
            answer,
            order,
            questionType: 'frequent',
            question,
          })),
          productId
        )
      );
      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при добавлении вопросов [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }
    const questionsToEdit = getTouchedElemsFromFirstArray(
      allQuestions.map((elem) => ({
        value: elem,
      })),
      courseForm.initQuestions,
      initQuestionIds
    ).map((elem) => elem.value);
    if (questionsToEdit?.length) {
      const [, err] = await tryCatch(
        courseApi.updateQuestionsForProduct(
          questionsToEdit.map(({ answer, order, id, question }) => ({
            answer,
            order,
            id,
            question,
            questionType: 'frequent',
          })),
          productId
        )
      );

      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при изменении вопросов [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }

    return allQuestions
      .sort((a, b) => a.order - b.order)
      .filter((v) => !deletedQuestions.includes(v.id));
  }
);
export const saveCourseContents = createAsyncThunk(
  'saveCourseContents',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const state = getState() as RootState;
    const {
      marketplace: { courseForm },
    } = state;
    const { contents, courseId } = courseForm;

    const initContentsIds = courseForm.initContents.map((elem) => elem.id);
    const allContents = [...contents]
      .filter(
        (v) =>
          v.title.trim() !== '' &&
          v.place.trim() !== '' &&
          v.description.trim() !== ''
      )
      .map((v, index) => ({
        ...v,
        order: index,
      }));

    const deletedContents = courseForm.initContents
      .filter((v) => {
        return !contents.map((content) => content.id).includes(v.id);
      })
      .map((v) => v.id);

    if (deletedContents?.length) {
      const [, err] = await tryCatch(
        courseApi.deleteCourseContents(deletedContents, courseId)
      );
      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при удалении содержания [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }
    const contentsToAdd = getUniqueElemsFromFirstArray(
      allContents,
      initContentsIds
    );

    if (contentsToAdd?.length) {
      const [, err] = await tryCatch(
        courseApi.addContentsToCourse(
          contentsToAdd.map(({ place, title, description, order }) => ({
            place,
            title,
            description,
            order,
          })),
          courseId
        )
      );
      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при добавлении содержания [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }
    const contentsToEdit = getTouchedElemsFromFirstArray(
      allContents.map((elem) => ({
        value: elem,
      })),
      courseForm.initContents,
      initContentsIds
    ).map((elem) => elem.value);
    if (contentsToEdit?.length) {
      const [, err] = await tryCatch(
        courseApi.updateCourseContents(
          contentsToEdit.map(({ place, id, title, description, order }) => ({
            place,
            title,
            description,
            id,
            order,
          })),
          courseId
        )
      );

      if (err) {
        dispatch(
          setNotification({
            value: `Ошибка при изменении содержания [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue(err.message);
      }
    }
    return allContents
      .sort((a, b) => a.order - b.order)
      .filter((v) => !deletedContents.includes(v.id));
  }
);
export const fetchCourseForEdit = createAsyncThunk(
  'marketplace/fetchCourseForEdit',
  async (id: number, { rejectWithValue, dispatch }) => {
    const fetchData: {
      course: CourseDto;
      questions: QuestionAnswer[];
      attachments: IAttachment[];
      contents: IContent[];
    } = {
      course: {} as CourseDto,
      questions: [],
      attachments: [],
      contents: [],
    };

    const [course, courseErr] = await tryCatch(courseApi.getSingleCourse(id));
    const handleErr = (err: AxiosError, text?: string) => {
      dispatch(
        setNotification({
          value: text ?? 'Упс... Что-то пошло не так',
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    };
    if (courseErr) {
      handleErr(
        courseErr,
        `Ошибка при получении курса ${courseErr.response.status}`
      );
    }

    const [{ questions }, questionErr] = await tryCatch(
      courseApi.getQuestionsForProduct(course.product.id)
    );

    if (questionErr) {
      handleErr(
        questionErr,
        `Ошибка в получении вопросов [${questionErr.response.status}]`
      );
    }

    const [attachments, attachmentsErr] = await tryCatch<{
      attachments: CourseAttachment[];
    }>(courseApi.getCourseAttachments(id));

    if (attachmentsErr) {
      handleErr(
        attachmentsErr,
        `Ошибка в получении программы курса [${attachmentsErr.response.status}]`
      );
    }

    const [{ contents }, contentsErr] = await tryCatch<{
      contents: IContent[];
    }>(courseApi.getContentsForCourse(id));

    if (contentsErr) {
      handleErr(
        contentsErr,
        `Ошибка в получении программы курса [${contentsErr.response.status}]`
      );
    }

    fetchData.course = course;
    fetchData.attachments = attachments.attachments;
    const sortEnvs = (a: any, b: any) => {
      return !env.isProd ? a.order - b.order : b.order - a.order;
    };
    fetchData.contents = [...contents].sort(sortEnvs);
    fetchData.questions = [...questions].sort(sortEnvs);
    return fetchData;
  }
);

export const fetchSingleCompany = createAsyncThunk(
  'marketplace/fetchSingleCompany',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [company, companyErr] = await tryCatch(
      companyApi.getSingleCompany(id)
    );

    if (companyErr) {
      dispatch(
        setNotification({
          value: `Ошибка в получении компании [${companyErr.response.status}]`,
        })
      );

      throw rejectWithValue(companyErr.message);
    }

    return company;
  }
);

export const deleteCompany = createAsyncThunk(
  'marketplace/deleteCompany',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [, err] = await tryCatch(companyApi.softDelete(id));

    if (err) {
      dispatch(
        setNotification({
          value: `Ошибка в удалении компании [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    return id;
  }
);

export const getTopics = createAsyncThunk(
  'marketplace/getTopics',
  async (_, { rejectWithValue, dispatch }) => {
    const [val, err] = await tryCatch(sharedApi.getTopics());

    if (err) {
      dispatch(
        setNotification({
          value: `Ошибка при получении тем [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    return val.topics;
  }
);
export const getCourseFormats = createAsyncThunk(
  'marketplace/getCourseFormats',
  async (_, { rejectWithValue, dispatch }) => {
    const [val, err] = await tryCatch(courseApi.getCourseFormats());

    if (err) {
      dispatch(
        setNotification({
          value: `Ошибка при получении форматов [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    return val.formats;
  }
);
export const publishCourse = createAsyncThunk(
  'marketplace/publishCourse',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [val, err] = await tryCatch(courseApi.publishCourse(String(id)));

    if (err) {
      dispatch(
        setNotification({
          value: `Ошибка при публикации [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    return val.formats;
  }
);

export const deleteCourse = createAsyncThunk(
  'marketplace/deleteCourse',
  async (id: number, { rejectWithValue, dispatch }) => {
    const [, err] = await tryCatch(courseApi.deleteCourse(id));

    if (err) {
      dispatch(
        setNotification({
          value: `Ошибка в удалении курса [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    dispatch(
      setNotification({
        value: 'Материал удален',
        variant: 'success',
      })
    );
    return id;
  }
);

export const toggleCompanyVisibility = createAsyncThunk(
  'marketplace/hideCompany',
  async (
    { companyId, status }: { companyId: number | string; status: number },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const [, err] = await tryCatch(
        status === 3
          ? companyApi.hideCompany(companyId)
          : companyApi.publishCompany(companyId)
      );
      if (err) {
        dispatch(
          setNotification({
            value: `${err.message} [${err.response.status}]`,
            variant: 'error',
          })
        );
        throw rejectWithValue({ message: err.message });
      }
      return { companyId, publicationStatus: +status };
    } catch (e) {
      console.log(e);
    }
  }
);
export const publishCompany = createAsyncThunk(
  'marketplace/publishCompany',
  async (action: number, { rejectWithValue, dispatch }) => {
    const [val, err] = await tryCatch(companyApi.publishCompany(action));

    if (err) {
      dispatch(
        setNotification({
          value: `Ошибка при публикации [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    return val.formats;
  }
);

export const toggleCourseVisibility = createAsyncThunk(
  'marketplace/toggleCourseVisibility',
  async (
    { productId, status }: { productId: number | string; status: number },
    { rejectWithValue, dispatch }
  ) => {
    const [, err] = await tryCatch(
      status === 3
        ? courseApi.hideCourse(String(productId))
        : courseApi.publishCourse(String(productId))
    );
    if (err) {
      dispatch(
        setNotification({
          value: `Ошибка при обновлении материала: [${err.response.status}]`,
          variant: 'error',
        })
      );
      throw rejectWithValue(err.message);
    }
    dispatch(
      setNotification({
        value: status !== 3 ? 'Материал возвращен' : 'Материал скрыт',
        variant: 'success',
      })
    );
    return { id: productId, publicationStatus: +status };
  }
);
