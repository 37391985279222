/* eslint-disable import/no-duplicates */
import Articles from '@/pages/Articles';
import EditArticleFormCard from '@/pages/Articles/ui';
import CreateArticleFormCard from '@/pages/Articles/ui';
import AuthCodePage from '@/pages/AuthCodePage';
import Banners from '@/pages/Banners';
import SingleBannerPage from '@/pages/Banners/SingleBannerPage';
import Categories from '@/pages/Categories';
import SingleCategoryPage from '@/pages/Categories/SingleCategoryPage';
import { CreateFoodsForm, EditFoodsForm, Foods } from '@/pages/Foods';
import Main from '@/pages/Main';
import Marketplace from '@/pages/Marketplace';
import Companies from '@/pages/Marketplace/company/ui/Companies';
import CreateCompany from '@/pages/Marketplace/company/ui/CreateCompany';
import EditCompany from '@/pages/Marketplace/company/ui/EditCompany';
import Courses from '@/pages/Marketplace/course/ui/Courses';
import CreateCourse from '@/pages/Marketplace/course/ui/CreateCourse';
import EditCourse from '@/pages/Marketplace/course/ui/EditCourse';
import { NoRights } from '@/pages/NoRights/NoRights';
import PromocodeForm from '@/pages/Promocodes/modals/PromocodeForm';
import { Promocodes } from '@/pages/Promocodes/Promocodes';
import RestorePage from '@/pages/RestorePage';
import Review from '@/pages/Review';
import Root from '@/pages/Root';
import SignInPage from '@/pages/SignInPage';
import SignUpPage from '@/pages/SignUpPage';
import Statistics from '@/pages/Statistics';
import ActiveUsers from '@/pages/Statistics/ActiveUsers';
import Purchases from '@/pages/Statistics/Purchases';
import Users from '@/pages/Statistics/Users';
import Stories from '@/pages/Stories';
import SingleStoryPage from '@/pages/Stories/SingleStoryPage';
import { routes } from '@/shared/consts/consts';
import { useAppSelector } from '@/shared/model';
import SnackBar from '@/shared/ui/DefautlSnackbar';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

import Error from '../pages/Error';

function App() {
  const roles = useAppSelector((state) => state.auth.roles);
  const { enqueueSnackbar } = useSnackbar();
  const { index, notification, variant } = useAppSelector((state) => state.app);

  useEffect(() => {
    if (notification)
      enqueueSnackbar(notification, {
        content: (key, message) => (
          <SnackBar id={key} message={message} variant={variant} />
        ),
      });
  }, [notification, enqueueSnackbar, index, variant]);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path='/' element={<Root />}>
          {roles.includes('Admin') && (
            <>
              <Route element={<Main />}>
                <Route
                  path={routes.adminRoutes.statistics}
                  index
                  element={<Statistics />}
                />
                <Route
                  path={routes.adminRoutes.statistics + '/registrations'}
                  element={<Users />}
                />
                <Route
                  path={routes.adminRoutes.statistics + '/users'}
                  element={<ActiveUsers />}
                />
                <Route
                  path={routes.adminRoutes.statistics + '/orders'}
                  element={<Purchases />}
                />
                <Route
                  path={routes.adminRoutes.categories}
                  element={<Categories />}
                />
                <Route
                  path={routes.adminRoutes.articles}
                  element={<Articles />}
                />
                <Route
                  path={routes.adminRoutes.promocodes}
                  element={<Promocodes />}
                >
                  <Route
                    path='/promocodes/create-promocode'
                    element={<PromocodeForm />}
                  />
                  <Route
                    path='/promocodes/edit-promocode/:id'
                    element={<PromocodeForm />}
                  />
                </Route>
                <Route
                  path={routes.adminRoutes.stories}
                  element={<Stories />}
                />
                <Route
                  path={routes.adminRoutes.banners}
                  element={<Banners />}
                />
                <Route path={routes.foods.foods} element={<Foods />} />
                <Route path={routes.adminRoutes.reviews} element={<Review />} />
                <Route element={<Marketplace />}>
                  <Route
                    path={routes.marketplace.companies}
                    element={<Companies />}
                  />
                  <Route
                    path={routes.marketplace.courses}
                    element={<Courses />}
                  />
                </Route>
              </Route>
              <Route
                path={routes.marketplace.createCourse}
                element={<CreateCourse />}
              />
              <Route
                path={routes.articles.editArticle}
                element={<EditArticleFormCard />}
              />
              <Route
                path={routes.articles.createArticle}
                element={<CreateArticleFormCard />}
              />
              <Route
                path={routes.foods.createFoods}
                element={<CreateFoodsForm />}
              />
              <Route
                path={routes.foods.editFoods}
                element={<EditFoodsForm />}
              />
              <Route
                path={routes.marketplace.createCompany}
                element={<CreateCompany />}
              />
              <Route
                path={routes.marketplace.editCompany}
                element={<EditCompany />}
              />
              <Route
                path={routes.marketplace.editCourse}
                element={<EditCourse />}
              />

              <Route
                path={routes.adminRoutes.category}
                element={<SingleCategoryPage />}
              />

              <Route
                path={routes.adminRoutes.story}
                element={<SingleStoryPage />}
              />
              <Route
                path={routes.adminRoutes.banner}
                element={<SingleBannerPage />}
              />
            </>
          )}

          {roles.includes('User') && (
            <>
              <Route path={routes.userRoutes.noRights} element={<NoRights />} />
            </>
          )}

          <Route path={routes.authRoutes.login} element={<SignInPage />} />
          <Route path={routes.authRoutes.code} element={<AuthCodePage />} />
          <Route path={routes.authRoutes.register} element={<SignUpPage />} />
          <Route path={routes.authRoutes.restore} element={<RestorePage />} />
          <Route path={routes.errorRoutes.notFound} element={<Error />} />

          {/* этот роут нужен чтобы приложение матчило любой урл вбиваемый в адрессную строку и отрабатывал компонент Root */}
          <Route path='*' />
        </Route>
      </>
    )
  );

  return <RouterProvider router={router} />;
}

export default App;
