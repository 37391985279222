import { foodsActions as actions, ChangeRules } from '@/entities/foods';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import * as UI from '@/shared/ui';
import React, { useCallback } from 'react';

import { SingleRule } from './SingleRule';

export const Rules = () => {
  const dispatch = useAppDispatch();
  const rules = useAppSelector((state) => state.foods.form.rules);
  const onDelete = useCallback((id: number) => {
    dispatch(actions.deleteRule(id));
  }, []);

  const onSwap = useCallback((index: number, destIndex: number) => {
    dispatch(actions.onSwapRules({ index, destIndex }));
  }, []);

  const onChange = useCallback((data: ChangeRules) => {
    const { index, key, value } = data;
    dispatch(actions.changeRules({ index, key, value }));
  }, []);
  return (
    <UI.Block>
      <fieldset className='mb-[40px] flex w-[100%] items-center justify-between'>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Правила подачи
        </h1>
      </fieldset>
      <div className='flex flex-col items-center gap-[32px]'>
        {rules.map((elem: any, i) => (
          <SingleRule
            {...elem}
            index={i}
            itemsCount={rules.length}
            onSwap={onSwap}
            onChange={onChange}
            onDelete={onDelete}
            key={elem.id}
          />
        ))}
        <button
          data-testid={'button-add_rule'}
          className={
            'self-center text-[18px] leading-[24px] text-blue200 transition-all duration-150 hover:text-blue300'
          }
          type='button'
          onClick={() => dispatch(actions.addRules())}
        >
          Добавить еще
        </button>
      </div>
    </UI.Block>
  );
};
