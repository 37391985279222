import { useEditCourse } from '@/pages/Marketplace/course/model/use-edit-course';
import { Button } from '@/shared/ui/Button/Button';
import FormTabs from '@/shared/ui/FormTabs/FormTabs';
import FormWrapper from '@/shared/ui/FormWrapper/FormWrapper';
import Header from '@/widgets/Header/Header';
import { SaveChangesModal } from '@/widgets/SaveChangesModal/SaveChangesModal';
import React from 'react';

import { tabs } from '../consts';
import { PublishModal } from './PublishModal';
import { UpdateModal } from './UpdateModal';

const EditCourse = () => {
  const {
    modalOpen,
    updateModalOpen,
    setUpdateModalOpen,
    setModalOpen,
    handleChangeTab,
    publicationStatusId,
    handleClick,
    handleSubmit,
    handlePublic,
    blocker,
    unsavedChangesAll,
    canSubmit,
    activeTab,
  } = useEditCourse();
  return (
    <div className={'bg-gray100'}>
      <div className={'mx-auto flex min-h-screen max-w-[1280px] flex-col'}>
        <Header />
        <FormWrapper className={'pt-[40px]'}>
          <FormTabs
            backLink={'/marketplace/courses'}
            tabs={tabs}
            active={activeTab.value}
            onClick={handleChangeTab}
          >
            <Button
              data-testid={'button-submit'}
              variant={'primary'}
              disabled={
                !canSubmit || (publicationStatusId === 3 && !unsavedChangesAll)
              }
              onClick={() => handleClick()}
            >
              {publicationStatusId === 2 || publicationStatusId === 1
                ? 'Опубликовать'
                : 'Обновить'}
            </Button>
            {(publicationStatusId === 2 || publicationStatusId === 1) && (
              <Button
                data-testid={'button-save'}
                variant={'secondary'}
                disabled={!canSubmit || !unsavedChangesAll}
                onClick={() => handleSubmit({ withRedirect: false })}
                className={'mt-[16px] h-[80px] w-full'}
              >
                Сохранить
              </Button>
            )}
          </FormTabs>
          <div
            className={
              'mb-[80px] flex h-full w-full flex-col gap-[40px] rounded-[32px] bg-white p-[40px]'
            }
          >
            {activeTab.tab}
          </div>
        </FormWrapper>
        <PublishModal
          isOpen={modalOpen}
          onSubmit={handlePublic}
          onClose={() => setModalOpen(false)}
        />
        <UpdateModal
          isOpen={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
          onSave={() => handleSubmit({ withRedirect: true })}
        />
        {blocker && unsavedChangesAll && blocker?.state === 'blocked' && (
          <SaveChangesModal
            isNavigaton={true}
            opened={blocker?.state === 'blocked'}
            redirectUrl={blocker?.location.pathname}
            setOpened={blocker?.reset}
            status={publicationStatusId}
            onSaveClick={() => handleSubmit({ withRedirect: true })}
            isValid={canSubmit}
          />
        )}
      </div>
    </div>
  );
};

export default EditCourse;
