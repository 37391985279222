import { Outlet } from 'react-router-dom';

import TopBar from './components/TopBar';

export const Promocodes = () => {
  return (
    <div className='container pt-12'>
      <div className='mb-10'>
        <TopBar />
      </div>
      <Outlet />
    </div>
  );
};
