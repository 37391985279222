import { memo } from 'react';

type Props = {
  stat: string;
  amount: number;
  change?: number;
  forTime?: string;
  onClick: () => void;
};

const StatCard = ({ stat, amount, change, forTime, onClick }: Props) => {
  const changeStyle =
    change > 0
      ? 'text-[color:var(--Bright-Green,#1BC47D)] text-lg not-italic font-normal leading-6'
      : 'text-[color:var(--Simple-Red-Day,#EC3333)] text-lg not-italic font-normal leading-6';
  return (
    <div
      className='background: var(--Simple-Morning, #FFF) flex w-[28.5%] flex-[1_0_0] cursor-pointer flex-col items-start gap-4
        rounded-3xl px-6 py-5 shadow-[0px_8px_20px_0px_rgba(0,0,0,0.08)]'
      onClick={onClick}
    >
      <h4 className='text-lg font-normal not-italic leading-6'>{stat}</h4>
      <div className='flex-col'>
        <h2 className='overflow-hidden text-ellipsis text-[42px] font-normal not-italic leading-[50px] text-[color:var(--Simple-Night,#000)]'>
          {amount}
        </h2>
        <div className='flex gap-1 lowercase'>
          <p className={changeStyle}>{change > 0 ? '+' + change : change}</p>
          <p className='text-lg font-normal not-italic leading-6 text-[color:var(--Simple-Gray-Evening,#ABABAB)]'>
            {forTime}
          </p>
        </div>
      </div>
    </div>
  );
};

export default memo(StatCard);
