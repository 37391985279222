import React from 'react';

import {
  MultipleSelectProps,
  SelectInnerProps,
  SingleSelectProps,
} from './model';
import { SelectMultiple } from './SelectMultiple';
import { SelectSingle } from './SelectSingle';

export const Select = ({ isSingle = true, ...props }: SelectInnerProps) => {
  if (isSingle) {
    const singleProps = props as SingleSelectProps;
    return <SelectSingle {...singleProps} />;
  }
  const multipleProps = props as MultipleSelectProps;
  return <SelectMultiple {...multipleProps} />;
};
