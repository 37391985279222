import { FilterSelectType } from '@/shared/ui/Filters';

export interface IState {
  reviews?: IReview[];
  limit: number;
  searchValue: string | '';
  createdAd: 'DESC' | 'ASC' | undefined;
  rate: 'DESC' | 'ASC' | undefined;
  filters: (FilterSelectType & { param: boolean })[];
  filtersInit: typeof initFilterValues;
  sort?: { label: string; value: number | string };
  sortInit: typeof initSortValues;
  isLoading: boolean;
  isError: boolean;
  isDeleteModal: {
    isShown: boolean;
    id: number | undefined;
  };
  currentPage: number;
  isCardLoading: boolean;
}

export const filterTypeInit: Array<{
  label: string;
  value: number;
  param: boolean;
}> = [
  {
    label: 'Опубликовано',
    value: 1,
    param: true,
  },
  {
    label: 'На модерации',
    value: 2,
    param: false,
  },
];

export const sortTypeInit: Array<{ label: string; value: number | string }> = [
  {
    label: 'По дате',
    value: 1,
  },
  {
    label: 'По оценке',
    value: 2,
  },
];

export interface IRevParams {
  limit: any;
  offset: any;
  filters?: any;
  createdAd: any;
  search: any;
  rate: any;
}

export const initSortValues: typeof sortTypeInit = sortTypeInit;
export const initFilterValues: typeof filterTypeInit = filterTypeInit;

export interface IReview {
  id: number;
  comment: string | null;
  createdAt: Date;
  name: string;
  rate: 1 | 2 | 3 | 4 | 5;
  isModerated: boolean;
  product: { title: string; id: number } | null;
  company: { title: string; id: number } | null;
}
