export function formatAuthType(authType: string) {
  switch (authType) {
    case 'google':
      return 'Google';
    case 'apple':
      return 'Apple';
    case 'yandex':
      return 'Яндекс';
    case 'email':
      return 'Почта';
    default:
      return authType;
  }
}
