import { statisticsActions } from '@/entities/statistics/model/statisticsSlice';
import { formatAuthType } from '@/shared/lib/formatEmail';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import cn from 'classnames';
import React, { useState } from 'react';
import ChevronDown from 'src/assets/svg/ChevronDown';
import SortUp from 'src/assets/svg/sortUp';

import { ReactComponent as Check } from '../../../assets/images/CheckType.svg';

type Props = {
  amountElements: number;
  isScrolled?: boolean;
  className?: string;
};

const StatsHead = ({ amountElements, isScrolled, className }: Props) => {
  const dispatch = useAppDispatch();
  const [isSortVisible, setIsSortVisible] = useState(false);
  const sortValues = useAppSelector((state) => state.statistics.activeTypes);
  const currentRegTypes = useAppSelector(
    (state) => state.statistics.currentRegTypes
  );

  const flex = `w-[1280px] justify-between flex border-[1px] border-[#F3F3F3] gap-8 text-[color:var(--Simple-Gray-Midnight,#7D7D7D)] 
  text-lg not-italic font-normal 
  leading-6 h-14 justify-start items-start self-stretch px-6 py-4 bg-[#FFF] rounded-[16px] mb-[4px] sticky top-[12%] transition-all duration-75`;
  const itemStyle =
    'flex justify-between items-center text-black text-base not-italic font-normal leading-[22px] rounded-[12px] py-[13px] px-[16px] cursor-pointer hover:bg-[#EBF1FC]';

  if (amountElements === 3) {
    return (
      <div
        className={cn(
          flex,
          isScrolled && 'shadow-[0px_8px_20px_0px_rgba(0,0,0,0.08)]'
        )}
      >
        <div className='w-[3.7%]'>ID</div>
        <div className='w-[77%]'>Почта</div>
        <div
          className='flex w-[14%] cursor-pointer items-center gap-1'
          onClick={() => {
            dispatch(statisticsActions.changeDateSort());
          }}
        >
          Регистрация <SortUp />
        </div>
      </div>
    );
  }

  if (amountElements === 6) {
    return (
      <div
        className={cn(
          flex,
          isScrolled && 'shadow-[0px_8px_20px_0px_rgba(0,0,0,0.08)]'
        )}
      >
        <div className='col-span-1 w-[3.7%]'>ID</div>
        <div
          className='col-span-1 flex w-[7.5%] cursor-pointer items-center gap-1'
          onMouseEnter={() => setIsSortVisible(true)}
          onMouseLeave={() => setIsSortVisible(false)}
        >
          Способ <ChevronDown />
          {isSortVisible && (
            <div className='absolute top-[70%] left-20 flex h-auto w-[149px] flex-col justify-center gap-1 rounded-2xl bg-white p-1 shadow-lg'>
              {sortValues.map((item, index) => (
                <div
                  data-testid={`button-set_active-${index}`}
                  onClick={(event) =>
                    dispatch(statisticsActions.setActiveTypes(item))
                  }
                  key={index}
                  className={cn(
                    itemStyle,
                    currentRegTypes.includes(item) && 'bg-[#EBF1FC]'
                  )}
                >
                  {formatAuthType(item)}{' '}
                  {currentRegTypes.includes(item) && <Check />}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='col-span-1 w-[12.18%]'>Телефон</div>
        <div className='col-span-1 w-[40.46%]'>Почта</div>
        <div
          data-testid={'button-register'}
          className='col-span-1 flex w-[11.5%] cursor-pointer items-center gap-1'
          onClick={() => {
            dispatch(statisticsActions.changeDateSort());
          }}
        >
          Регистрация <SortUp />{' '}
        </div>
        <div
          data-testid={'button-activity'}
          className='col-span-1 flex w-[10.5%] cursor-pointer items-center gap-1'
          onClick={() => {
            dispatch(statisticsActions.changeDateSort());
          }}
        >
          Активность <SortUp />{' '}
        </div>
      </div>
    );
  }

  if (amountElements === 5) {
    return (
      <div
        className={cn(
          // className,
          flex,
          isScrolled && 'shadow-[0px_8px_20px_0px_rgba(0,0,0,0.08)]'
        )}
      >
        <div className='col-span-1 w-[220px]'>Материал</div>
        <div className='col-span-1 w-[156px]'>Телефон</div>
        <div className='col-span-1 w-[280px]'>Почта</div>
        <div className='col-span-1 w-[99px]'>Промокод</div>
        <div
          className='col-span-1 flex w-[77px] cursor-pointer items-center gap-1'
          onClick={() => {
            dispatch(statisticsActions.changePriceSort());
          }}
        >
          Сумма <SortUp fill={'#ABABAB'} />
        </div>
        <div
          className='col-span-1 flex w-[142px] cursor-pointer items-center gap-1'
          onClick={() => {
            dispatch(statisticsActions.changeDateSort());
          }}
        >
          Дата и время <SortUp />
        </div>
        <div className='col-span-1 h-full w-[24px]'> </div>
      </div>
    );
  }
};

export default StatsHead;
