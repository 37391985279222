import { InputHTMLAttributes, SVGProps } from 'react';

import { Label } from '../Label/Label';

const CheckboxIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={10}
    height={9}
    fill='none'
    {...props}
  >
    <path
      fill='#000'
      fillRule='evenodd'
      d='M9.665.92a1 1 0 0 1 .083 1.41l-5.08 5.715a1.333 1.333 0 0 1-2.009-.018L.908 5.984a1 1 0 1 1 1.518-1.302l1.255 1.464 4.572-5.144A1 1 0 0 1 9.665.92Z'
      clipRule='evenodd'
    />
  </svg>
);

export const Checkbox = ({
  isOpen,
  onClick,
  label,
  id,
  ...props
}: {
  isOpen?: boolean;
  onClick: (arg: boolean) => void;
  label?: string;
  id?: string;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>) => {
  return (
    <span className={`relative flex ${props.disabled ? 'opacity-50' : ''}`}>
      <div className='relative flex h-fit w-fit flex-col items-center justify-center'>
        <input
          data-testid={'checkbox'}
          name={id}
          id={id}
          {...props}
          checked={isOpen}
          onChange={() => onClick?.(!isOpen)}
          className={`unset pointer box-border h-[24px] w-[24px] rounded-[6px] border-[1px] border-solid border-gray200 bg-white text-gray400 disabled:cursor-not-allowed disabled:bg-gray100  ${props.className}`}
          type='checkbox'
        />
        {isOpen && <CheckboxIcon className='t-1/2 r-1/2 absolute' />}
      </div>
      <Label id={id} size={'regular'} label={label} />
    </span>
  );
};
