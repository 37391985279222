import './RestorePage.css';

import authService from '@/entities/auth/api/auth-service';
import { routes } from '@/shared/consts/consts';
import { useAppDispatch } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { AxiosResponse } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import RestorePageView from './RestorePage-View';

export type Inputs = {
  email: string;
};

const RestorePageContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    authService
      .checkIsRegistered(data.email.toString())
      .then(
        (
          res: AxiosResponse<
            {
              doesUserExist: boolean;
            },
            any
          >
        ) => {
          if (res.data.doesUserExist === false) {
            dispatch(
              setNotification({
                value: `Пользователь с почтой ${data.email} не найден`,
                variant: 'error',
              })
            );
          } else {
            const encodedEmailData = {
              email: `${data.email}`,
            };
            authService
              .restore(encodedEmailData)
              .then((res) => {})
              .then(() => {
                dispatch(
                  setNotification({
                    value: `На ${data.email} выслан новый пароль`,
                    variant: 'success',
                  })
                );
                navigate(`/${routes.authRoutes.login}`, { replace: true });
              });
          }
        }
      )
      .catch((err) => {
        dispatch(
          setNotification({
            value: 'Проверьте правильность написания email',
            variant: 'error',
          })
        );
      });
  };

  return (
    <RestorePageView
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      handleFormSubmit={onSubmit}
    />
  );
};

export default RestorePageContainer;
