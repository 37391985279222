import React from 'react';

const Close = ({
  color = 'white',
  size = 16,
}: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='Medium/Close'>
        <path
          id='Icon'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.95952 3.9594C4.35004 3.56887 4.98321 3.56887 5.37373 3.9594L7.99996 6.58562L10.6262 3.9594C11.0167 3.56887 11.6499 3.56887 12.0404 3.9594C12.4309 4.34992 12.4309 4.98309 12.0404 5.37361L9.41417 7.99984L12.0404 10.6261C12.4309 11.0166 12.4309 11.6498 12.0404 12.0403C11.6499 12.4308 11.0167 12.4308 10.6262 12.0403L7.99996 9.41405L5.37373 12.0403C4.98321 12.4308 4.35004 12.4308 3.95952 12.0403C3.56899 11.6498 3.56899 11.0166 3.95952 10.6261L6.58575 7.99984L3.95952 5.37361C3.56899 4.98309 3.56899 4.34992 3.95952 3.9594Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default Close;
