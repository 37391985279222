import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useCheckImagesSizes } from '@/shared/lib/hooks/useCheckImageSizes';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import DropInput from '@/shared/ui/DropInput/DropInput';

export const LogoInput = () => {
  const dispatch = useAppDispatch();
  const checkImages = useCheckImagesSizes();
  const { logo } = useAppSelector(
    (state) => state.marketplace.companyForm.form
  );
  const onChange = async ([files]: File[][]) => {
    const err = await checkImages({ files, minHeight: 1024, minWidth: 1024 });
    if (err) {
      dispatch(
        setNotification({
          variant: 'error',
          value: 'Ошибка при загрузке (маленький размер)',
        })
      );
      return;
    }
    dispatch(
      marketplaceActions.changeCompanyForm({ key: 'logo', value: files })
    );
  };
  const onDelete = () => {
    dispatch(
      marketplaceActions.changeCompanyForm({ key: 'logo', value: null })
    );
  };

  return (
    <DropInput
      withIcon
      label='logo'
      htmlFor={'logo'}
      format='dropzone'
      title={'Логотип'}
      sizes='(1024x1024 px)'
      required
      name='logo'
      id='logo'
      files={logo}
      setFiles={onChange}
      onDelete={onDelete}
    />
  );
};
