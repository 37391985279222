const starRev = ({
  selected = false,
  styles = '',
  ...props
}: {
  styles?: string;
  selected?: boolean;
}) => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g id='Star'>
      <path
        id='Star 2'
        d='M8.78549 2.03754C9.28229 0.987487 10.7177 0.987486 11.2145 2.03754L12.8692 5.53492C13.0665 5.9519 13.4478 6.24091 13.8889 6.30778L17.5889 6.86861C18.6998 7.03699 19.1434 8.46105 18.3395 9.27841L15.6622 12.0007C15.343 12.3253 15.1973 12.7929 15.2727 13.2512L15.9047 17.0952C16.0945 18.2494 14.9332 19.1295 13.9396 18.5846L10.6302 16.7697C10.2357 16.5533 9.76432 16.5533 9.36976 16.7697L6.06041 18.5846C5.06681 19.1295 3.90553 18.2494 4.09529 17.0952L4.72732 13.2512C4.80267 12.7929 4.65701 12.3253 4.33781 12.0007L1.66048 9.27841C0.856644 8.46105 1.30021 7.03699 2.41109 6.86861L6.11106 6.30778C6.55219 6.24091 6.93353 5.9519 7.13081 5.53492L8.78549 2.03754Z'
        fill={selected ? '#FFD662' : '#E1E1E1'}
      />
    </g>
  </svg>
);

export default starRev;
