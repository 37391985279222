import { CreateCompanyTabs } from '@/entities/marketplace/types/tabs';
import React, { ReactNode, SVGProps } from 'react';
import FormDescription from 'src/pages/Marketplace/company/ui/FormDescription';
import FormStaff from 'src/pages/Marketplace/company/ui/FormStaff';

const DescriptionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={18}
    height={20}
    fill='none'
    {...props}
  >
    <path
      fill='#000'
      fillRule='evenodd'
      d='M1.172 1.172C0 2.343 0 4.229 0 8v4c0 3.771 0 5.657 1.172 6.828C2.343 20 4.229 20 8 20h2c3.771 0 5.657 0 6.828-1.172C18 17.657 18 15.771 18 12V8c0-3.771 0-5.657-1.172-6.828C15.657 0 13.771 0 10 0H8C4.229 0 2.343 0 1.172 1.172ZM5 9a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H5ZM4 6a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Zm1 7a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2H5Z'
      clipRule='evenodd'
    />
  </svg>
);

const StaffIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={19}
    height={19}
    fill='none'
    {...props}
  >
    <path
      fill='#000'
      fillRule='evenodd'
      d='M11.001 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm3 11.001c0 2.21-3.134 4-7 4s-7-1.79-7-4 3.134-4 7-4 7 1.79 7 4Zm-1.001-8a3 3 0 1 0-1.028-5.82c.473.832.742 1.794.742 2.819s-.27 1.988-.743 2.82c.321.117.667.18 1.029.18Zm1.479 11c2.485 0 4.52-1.344 4.52-3 0-1.657-2.037-3-4.523-3 .734.8 1.238 1.806 1.238 3s-.503 2.2-1.235 3Z'
      clipRule='evenodd'
    />
  </svg>
);

export const tabs: {
  label: ReactNode;
  value: CreateCompanyTabs;
  tab?: ReactNode;
}[] = [
  {
    label: (
      <>
        <DescriptionIcon /> Описание
      </>
    ),
    value: 'description',
    tab: <FormDescription />,
  },
  {
    label: (
      <>
        <StaffIcon /> Команда
      </>
    ),
    value: 'staff',
    tab: <FormStaff />,
  },
];
