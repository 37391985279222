import sx from 'classnames';
import { ReactNode } from 'react';

const BaseScreen = ({
  children,
  exitScreen = false,
  bg = 'gray100',
  isSmallPT,
}: {
  children: ReactNode;
  exitScreen?: boolean;
  bg?: string;
  isSmallPT?: boolean;
}) => {
  return (
    <div
      className={sx(
        `flex min-h-screen flex-col bg-${bg} ${
          isSmallPT ? 'pt-6' : 'pt-10'
        } pb-8 transition`,
        exitScreen ? 'animate-destroy-screen' : 'animate-load-screen'
      )}
    >
      {children}
    </div>
  );
};

export default BaseScreen;
