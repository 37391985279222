import './Tooltip.css';
import SvgComponent from './rect';

type Props = {
  text: string;
};

const Tooltip = ({ text }: Props) => {
  return (
    <div className='tooltipContainer transition-all duration-200'>
      <div className='tooltip'>{text ? text : 'tooltip'}</div>
      <SvgComponent className='svgTool' />
    </div>
  );
};

export default Tooltip;
