import { Modal } from '@/shared/ui';

import { Button } from '../../../../shared/ui/Button/Button';

export const UpdateModal = ({
  isOpen,
  onClose,
  onSave,
}: {
  isOpen?: boolean;
  onClose: () => void;
  onSave: () => void;
}) => {
  const handleSubmit = () => {
    onSave();
  };
  return (
    <Modal opened={isOpen} onClose={onClose}>
      <div
        className={
          'flex w-[522px] flex-col items-center justify-center gap-[32px]'
        }
      >
        <div className={'flex flex-col items-center justify-center gap-[16px]'}>
          <h2 className={'text-[32px] font-medium leading-[40px] text-black'}>
            Обновить материал?
          </h2>
        </div>

        <div className={'flex w-[360px] flex-col gap-[16px]'}>
          <Button data-testid={'button-update'} onClick={handleSubmit}>
            Обновить
          </Button>
          <Button
            onClick={onClose}
            data-testid={'button-cancel'}
            variant={'link'}
          >
            Отмена
          </Button>
        </div>
      </div>
    </Modal>
  );
};
