import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NotificationsVariant = 'error' | 'success';
interface IAppStore {
  notification: string;
  //index needed for showing new notification when notification text doesnt change
  index: number;
  variant: NotificationsVariant;
}

const initialState = {
  notification: '',
  index: 0,
  variant: 'success',
} as IAppStore;

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setNotification(
      state,
      action: PayloadAction<{
        value: string;
        variant?: NotificationsVariant;
      }>
    ) {
      const { value, variant } = action.payload;
      variant ? (state.variant = variant) : (state.variant = 'success');
      state.notification = value;
      state.index += 1;
    },
  },
});

export const {
  actions: { setNotification },
  reducer: appReducer,
} = appSlice;
