type Props = {
  width?: number;
};

const Divider = ({ width = 474 }: Props) => {
  return (
    <svg
      width={width}
      height='1'
      viewBox='0 0 474 1'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='474' height='1' fill='#F3F3F3' />
    </svg>
  );
};

export default Divider;
