import { ChangeSource } from '@/entities/articles/model/articleSlice';
import { IFormCardProps } from '@/entities/marketplace/types/IFormCardProps';
import { Input } from '@/shared/ui/Input/Input';
import FormCard from '@/widgets/FormCard';
import { FC, memo } from 'react';

interface Props extends IFormCardProps<ChangeSource> {
  title: string;
  link: string;
}

const SingleQuestion: FC<Props> = ({
  onChange,
  onDelete,
  onSwap,
  title,
  id,
  index,
  itemsCount,
  link,
}) => {
  return (
    <FormCard
      index={index}
      itemsCount={itemsCount}
      key={id}
      onDelete={() => onDelete(id)}
      onSwap={onSwap}
    >
      <Input
        label='Название'
        placeholder=''
        title='Название'
        htmlFor='title'
        value={title}
        data-testid={`input-title-${id}`}
        onChange={(e) =>
          onChange({
            value: e.target.value,
            index,
            key: 'title',
          })
        }
      />
      <Input
        data-testid={`input-source-${id}`}
        label='Ссылка'
        placeholder=''
        htmlFor='link'
        title='Ссылка'
        value={link}
        onChange={(e) =>
          onChange({
            value: e.target.value,
            index,
            key: 'link',
          })
        }
      />
    </FormCard>
  );
};

export default memo(SingleQuestion);
