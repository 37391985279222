import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

interface Props {
  label: string;
  //register props from react-hook-form
  registerProps: any;
}

export const ShowUntil: FC<Props> = ({ label, registerProps }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography width={155}>{label}</Typography>
      <TextField
        data-testid={'text-field'}
        fullWidth
        type='datetime-local'
        {...registerProps}
      />
    </Box>
  );
};
