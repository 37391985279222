import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { CreateCompanyTabs } from '@/entities/marketplace/types/tabs';
import { tabs } from '@/pages/Marketplace/company/consts';
import { useAppDispatch, useAppSelector } from '@/shared/model';

export const useCreateCompany = () => {
  const { activeCreateCompanyTab: activeTab } = useAppSelector(
    (state) => state.marketplace
  );
  const dispatch = useAppDispatch();
  const onTabClick = (to: CreateCompanyTabs) => {
    dispatch(marketplaceActions.changeCreateCompanyTab(to));
  };
  return {
    onTabClick,
    tabs: tabs.map((tab, index) =>
      index === 0 ? tab : { ...tab, disabled: true }
    ),
    activeTab: tabs.find((tab) => tab.value === activeTab),
  };
};
