import { cn } from '@/shared/lib/cn';
import { ButtonHTMLAttributes } from 'react';

const variants = {
  primary:
    'active:outline-0 focus:outline-0 disabled:bg-yellow100 disabled:hover:bg-yellow100disabled disabled:opacity-80 mt-[16px] h-[80px] w-full rounded-full bg-yellow100 px-[48px] pt-[26px] pb-[28px] text-[20px] font-medium leading-[26px] transition-all duration-200 ease-in  hover:bg-yellow200',
  secondary:
    'active:outline-0 focus:outline-0 mx-auto flex items-center justify-center rounded-full bg-blue200 py-[15px] px-[56px] text-white transition-all duration-150 hover:bg-blue300 disabled:bg-blue100 text-[20px] font-medium leading-[26px]',
  link: 'width-content text-blue200 text-[16px] leading-[22px] hover:text-blue300 p-0 my-0 mx-auto',
  danger:
    'width-content text-red500 text-[16px] leading-[22px] hover:text-red p-0 my-0 mx-auto',
};
export const Button = ({
  children,
  className,
  variant = 'primary',
  ...props
}: {
  variant?: 'primary' | 'secondary' | 'link' | 'danger';
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button
      data-testid={'button'}
      {...props}
      className={cn(variants[variant], className)}
    >
      {children}
    </button>
  );
};
