import { saveCourseDescription } from '@/entities/marketplace/model/markeplaceThunks';
import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import { Button } from '@/shared/ui/Button/Button';
import { ToggleSwitch } from '@/shared/ui/ToggleSwitch/ToggleSwitch';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AdvertisementBlock } from './advertising-block';
import { AttachmentInput } from './attachment-input';
import { CompanyBlock } from './company-block';
import { CoverInput } from './cover-input';
import { DateBlock } from './date-input';
import { DescriptionInput } from './description-input';
import { FiltersBlock } from './filters-block';
import { PreviewInput } from './preview-input';
import { PriceBlock } from './price-block';
import { TitleInput } from './title-input';
import { VideoLinkInput } from './video-link-input';

export const DescriptionCourse = () => {
  const {
    canSubmit,
    unsavedChanges,
    publicationStatusId,
    form: { isPinned },
  } = useAppSelector((store) => store.marketplace.courseForm);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const [loading, setLoading] = useState(false);
  const handleSave = async () => {
    setLoading(() => true);
    try {
      const res = await dispatch(
        saveCourseDescription({ isEditing: !!courseId })
      );
      // @ts-ignore
      const id = res?.payload?.course?.id;
      if (id) {
        dispatch(
          setNotification({ value: 'Изменения сохранены', variant: 'success' })
        );
        navigate(('/marketplace/edit-course/' + id) as string);
      }
    } catch (e) {
      dispatch(
        setNotification({
          value: `Ошибка при сохранении: ${e.response.status}`,
          variant: 'error',
        })
      );
    }
    setLoading(false);
  };
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: 10 }} open={loading}>
        <CircularProgress />
      </Backdrop>
      <div className={'flex items-center justify-between'}>
        <h1 className={'text-[42px] font-medium leading-[48px] text-black'}>
          Описание
        </h1>
        <ToggleSwitch
          data-testid={'toggle-pin'}
          label={'Закрепить'}
          name={'superOrder'}
          id={'superOrder'}
          type='checkbox'
          checked={isPinned}
          onChange={(e) =>
            dispatch(
              marketplaceActions.changeCoursesForm({
                key: 'isPinned',
                value: !!e.target.checked,
              })
            )
          }
        />
      </div>
      <DateBlock />
      <FiltersBlock />
      <CoverInput />
      <TitleInput />
      <div className='mb-8 flex w-full items-end justify-between gap-10'>
        <PriceBlock />
      </div>
      <DescriptionInput />
      <PreviewInput />
      <VideoLinkInput />
      <CompanyBlock />
      <AttachmentInput />
      <hr className={'h-[1px] w-full text-gray100'} />
      <AdvertisementBlock />
      {publicationStatusId === undefined && (
        <Button
          variant={'secondary'}
          onClick={handleSave}
          disabled={!canSubmit || !unsavedChanges.description}
        >
          Сохранить
        </Button>
      )}
    </>
  );
};
