import { api } from '@/shared/api/index';

class SharedApi {
  createFile(data: FormData) {
    return api.post('/files', data);
  }

  getTopics() {
    return api.get<{ topics: any }>('/topics');
  }
}

export const sharedApi = new SharedApi();
