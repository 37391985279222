import { AxiosError, AxiosResponse } from 'axios';

//https://www.youtube.com/watch?v=ITogH7lJTyE

export const tryCatch = async <T, K = any>(
  promise: Promise<AxiosResponse<T>>
): Promise<[T | null, null | AxiosError<K>]> => {
  try {
    const response = await promise;
    return [response.data, null];
  } catch (e) {
    const err = e as AxiosError<K>;
    return [null, err];
  }
};

export const tryCatchAnyPromise = async <T>(
  promise: Promise<T>
): Promise<[T | null, null | AxiosError]> => {
  try {
    const result = await promise;
    return [result, null];
  } catch (e) {
    const err = e as AxiosError;
    return [null, err];
  }
};
