import {
  deleteCourse,
  fetchAllCourses,
  toggleCourseVisibility,
} from '@/entities/marketplace/model/markeplaceThunks';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCourses = () => {
  const [loading, setLoading] = useState(true);
  const { courses, courseFilters } = useAppSelector(
    (state) => state.marketplace
  );
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      !courses?.length && setLoading(true);
      await dispatch(fetchAllCourses());
      setLoading(false);
    })();
  }, [courseFilters, courses?.length, dispatch]);

  const handleSubmit =
    ({
      courseId,
      productId,
      status,
    }: {
      courseId?: number;
      productId?: number;
      status: number;
    }) =>
    (to: 'delete' | 'hide' | 'publish') => {
      if (to === 'delete') {
        dispatch(deleteCourse(Number(courseId)));
        return;
      }
      dispatch(
        toggleCourseVisibility({ productId: productId, status: status })
      );
    };

  const handleClickPlus = () => navigate('/marketplace/create-course');
  const isEmpty =
    courses.length === 0 && !!Object.values(courseFilters).filter((v) => v)[0];
  return {
    courses,
    loading,
    handleSubmit,
    handleClickPlus,
    isEmpty,
  };
};
