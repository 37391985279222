import { ReactComponent as Desc } from '@/assets/svg/Description.svg';
import { ReactComponent as Sources } from '@/assets/svg/Sources.svg';
import { ReactComponent as Union } from '@/assets/svg/Union.svg';
import { ArticleTabVariants } from '@/entities/articles/types/IArticle';

import Author from '../ui/Author/Author';
import Main from '../ui/Description/Main';
import Source from '../ui/Sources/Source';

export const tabs: {
  label: string;
  value: ArticleTabVariants;
  logo?: any;
  tab?: any;
}[] = [
  {
    label: 'Описание',
    value: 'main',
    logo: <Desc />,
    tab: Main,
  },
  {
    label: 'Автор',
    value: 'author',
    logo: <Union />,
    tab: Author,
  },
  {
    label: 'Источники',
    value: 'source',
    logo: <Sources />,
    tab: Source,
  },
];
