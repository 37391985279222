import { marketplaceActions } from '@/entities/marketplace/model/marketplaceSlice';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import { Input } from '@/shared/ui/Input/SecondInput';

export const AdvertisementBlock = () => {
  const { isAdvertising, ooo, erid, inn } = useAppSelector(
    (state) => state.marketplace.courseForm.form
  );
  const dispatch = useAppDispatch();
  return (
    <>
      <Checkbox
        id={'advert'}
        label={'Рекламный материал'}
        disabled
        className='mr-[12px]'
        data-testid={'checkbox-is_advertising'}
        onClick={() =>
          dispatch(
            marketplaceActions.changeCoursesForm({
              key: 'isAdvertising',
              value: !isAdvertising,
            })
          )
        }
        isOpen={isAdvertising}
      />
      <div
        className={`grid  grid-cols-2 grid-rows-2 gap-[32px] overflow-hidden transition-all duration-150 ${
          isAdvertising ? 'max-h-[700px]' : 'max-h-0'
        }`}
      >
        <Input
          data-testid={'input-ooo'}
          value={ooo}
          onChange={(e) =>
            dispatch(
              marketplaceActions.changeCoursesForm({
                key: 'ooo',
                value: e.target.value,
              })
            )
          }
          required
          label={'ООО/ИП'}
          name={'ooo'}
        />
        <Input
          data-testid={'input-inn'}
          value={inn}
          onChange={(e) =>
            dispatch(
              marketplaceActions.changeCoursesForm({
                key: 'inn',
                value: e.target.value,
              })
            )
          }
          required
          label={'ИНН'}
          name={'inn'}
        />
        <Input
          data-testid={'input-erid'}
          value={erid}
          onChange={(e) =>
            dispatch(
              marketplaceActions.changeCoursesForm({
                key: 'erid',
                value: e.target.value,
              })
            )
          }
          required
          label={'Erid'}
          name={'erid'}
        />
      </div>
    </>
  );
};
