import { FC, memo, ReactNode, SVGProps } from 'react';
interface Props {
  index: number;
  onSwap: (index: number, destIndex: number) => void;
  onDelete: (...args: any[]) => void;
  itemsCount: number;
  children: ReactNode;
}
const Arrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='10'
    viewBox='0 0 16 10'
    fill='none'
    {...props}
  >
    <path
      d='M9.01213 9.07366L15.0774 2.96484C15.2114 2.8309 15.3179 2.67155 15.3905 2.49598C15.4631 2.32041 15.5005 2.1321 15.5005 1.9419C15.5005 1.7517 15.4631 1.56339 15.3905 1.38782C15.3179 1.21225 15.2114 1.0529 15.0774 0.918962C14.8093 0.650619 14.4468 0.5 14.0689 0.5C13.691 0.5 13.3284 0.650619 13.0604 0.918962L7.99649 6.01925L2.9326 0.918962C2.66458 0.650619 2.30202 0.5 1.92411 0.5C1.5462 0.5 1.18364 0.650619 0.915625 0.918962C0.783046 1.05359 0.678157 1.21325 0.60697 1.38878C0.535783 1.56432 0.4997 1.75229 0.500788 1.9419C0.4997 2.13151 0.535783 2.31948 0.60697 2.49502C0.678157 2.67056 0.783046 2.83022 0.915625 2.96484L6.98085 9.07366C7.11383 9.2087 7.27205 9.31588 7.44636 9.38903C7.62068 9.46218 7.80765 9.49984 7.99649 9.49984C8.18533 9.49984 8.3723 9.46218 8.54662 9.38903C8.72094 9.31588 8.87915 9.2087 9.01213 9.07366Z'
      fill='currentColor'
    />
  </svg>
);

const Trash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19'
    height='21'
    viewBox='0 0 19 21'
    fill='none'
    {...props}
  >
    <path
      d='M0.75 4.66667C0.75 4.20644 1.09538 3.83335 1.52143 3.83335L4.18567 3.8329C4.71502 3.81841 5.18202 3.45482 5.36214 2.91691C5.36688 2.90277 5.37232 2.88532 5.39185 2.82203L5.50665 2.44993C5.5769 2.22179 5.6381 2.02303 5.72375 1.84536C6.06209 1.14349 6.68808 0.656103 7.41147 0.53132C7.59457 0.499734 7.78848 0.499868 8.01106 0.500021H11.4891C11.7117 0.499868 11.9056 0.499734 12.0887 0.53132C12.8121 0.656103 13.4381 1.14349 13.7764 1.84536C13.8621 2.02303 13.9233 2.22179 13.9935 2.44993L14.1083 2.82203C14.1279 2.88532 14.1333 2.90277 14.138 2.91691C14.3182 3.45482 14.8778 3.81886 15.4071 3.83335H17.9786C18.4046 3.83335 18.75 4.20644 18.75 4.66667C18.75 5.12691 18.4046 5.5 17.9786 5.5H1.52143C1.09538 5.5 0.75 5.12691 0.75 4.66667Z'
      fill='currentColor'
    />
    <path
      d='M9.60677 20.4998H10.3937C13.1012 20.4998 14.4549 20.4998 15.3351 19.6366C16.2153 18.7734 16.3054 17.3575 16.4855 14.5256L16.745 10.445C16.8427 8.90848 16.8916 8.1402 16.45 7.65335C16.0084 7.1665 15.2628 7.1665 13.7714 7.1665H6.22905C4.73771 7.1665 3.99204 7.1665 3.55047 7.65335C3.10891 8.1402 3.15777 8.90848 3.25549 10.445L3.515 14.5256C3.6951 17.3575 3.78515 18.7734 4.66534 19.6366C5.54553 20.4998 6.89927 20.4998 9.60677 20.4998Z'
      fill='currentColor'
    />
  </svg>
);
const FormCard: FC<Props> = ({
  index,
  onSwap,
  onDelete,
  itemsCount,
  children,
}) => {
  return (
    <div
      className={`flex w-[100%] flex-col gap-[32px] ${
        itemsCount > 1 &&
        index !== itemsCount - 1 &&
        'border-b border-solid border-b-[color:var(--Simple-Gray-Morning,#F3F3F3)] pb-[40px]'
      }`}
    >
      {itemsCount > 1 && (
        <div className={'flex justify-between gap-5'}>
          <button
            data-testid={`button-delete-${index}`}
            disabled={index === 0 && itemsCount === 1}
            onClick={onDelete}
          >
            <Trash className='text-red500 hover:text-red' />
          </button>
          <div className={'flex gap-[12px]'}>
            <button
              data-testid={`button-swap_down-${index}`}
              onClick={() => onSwap(index, index - 1)}
              disabled={index === 0}
              className={
                'rotate-180 text-black hover:text-black900 disabled:opacity-30 disabled:hover:text-black'
              }
            >
              <Arrow />
            </button>
            <button
              data-testid={`button-swap_up-${index}`}
              onClick={() => onSwap(index, index + 1)}
              disabled={index === itemsCount - 1}
              className={
                'text-black hover:text-black900 disabled:opacity-30 disabled:hover:text-black'
              }
            >
              <Arrow />
            </button>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default memo(FormCard);
