import { tryCatch } from '@/shared/lib/tryCatch';
import { useAppDispatch } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { setCookie } from 'typescript-cookie';

import authService from '../../entities/auth/api/auth-service';
import { SignUpTypes } from '../SignUpPage/SignUp-Types';
import AuthCodePageView from './AuthCodePage-View';

const AuthCodePageContainer = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SignUpTypes>({ mode: 'onChange' });
  const onSubmit = async () => {
    const [{ accessToken, refreshToken }, err] = await tryCatch(
      authService.confirmEmail({
        email,
        code,
      })
    );
    navigate('/no-rights');
    setCookie('jwt', accessToken);
    setCookie('refresh', refreshToken);
    reset();
    err && dispatch(setNotification({ value: 'Ошибка', variant: 'error' }));
  };
  return (
    <AuthCodePageView
      handleSubmit={handleSubmit}
      handleFormSubmit={onSubmit}
      register={register}
      setEmail={setEmail}
      setCode={setCode}
      errors={errors}
    />
  );
};

export default AuthCodePageContainer;
