import { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react';

interface Props
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  styles?: string;
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isDelete?: boolean;
}

const ButtonRev: FC<Props> = ({
  styles = '',
  onClick,
  onMouseEnter,
  onMouseLeave,
  children,
  isDelete,
  disabled = false,
}) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };
  // disabled:cursor-not-allowed ${!disabled ? 'hover:scale-95' : ''}
  return (
    <button
      data-testid={'button-rev'}
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      disabled={disabled}
      className={`
      flex justify-center gap-2 rounded-[100px] bg-[#F3F3F3] text-base opacity-100
      transition hover:bg-[#E1E1E1] ${
        !isDelete ? 'px-6 py-4 md:w-[100%]' : 'px-4 py-4'
      }
    `}
    >
      {children}
    </button>
  );
};

export default ButtonRev;
