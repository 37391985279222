import { TextareaHTMLAttributes, useRef, useState } from 'react';

import { Label } from '../Label/Label';

export const TextArea = ({
  errorMessage,
  id,
  maxLength,
  label,
  length,
  ...props
}: {
  errorMessage?: string;
  id?: string | number;
  label?: string;
  length?: number;
  maxLength?: number;
} & TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const [value, setValue] = useState('');
  const ref = useRef<HTMLTextAreaElement>(null);
  const isLonger = maxLength < (value?.length || length);
  const currentLength = maxLength - (value?.length || length);
  return (
    <div className={'flex flex-col gap-[16px]'}>
      <Label id={id} label={label} required={props.required} />
      <div className={'flex flex-col gap-[8px]'}>
        <div className='relative h-[168px]'>
          <textarea
            data-testid={'textarea'}
            value={value}
            ref={ref}
            onChange={(e) => setValue(e.target.value)}
            className={`hZ-[168px] w-full resize-none rounded-[16px] border-[1px] ${
              isLonger ? 'border-red500' : 'border-gray200'
            } overflow-y-scroll p-[24px] text-[20px] leading-[30px] focus:outline-0 active:outline-0`}
            name={id}
            id={id}
            cols={30}
            rows={4}
            {...props}
          ></textarea>
          {maxLength && (
            <div
              className={`absolute right-[22px] bottom-[18px] text-[14px] leading-[20px] ${
                isLonger ? 'text-red500' : 'text-gray-300'
              }`}
            >
              {currentLength}
            </div>
          )}
        </div>
        <label
          onClick={() => ref?.current?.focus()}
          htmlFor={id}
          className={'text-[16px] leading-[22px] text-red500'}
        >
          {!!errorMessage && !!isLonger && errorMessage}
        </label>
      </div>
    </div>
  );
};
