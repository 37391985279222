import { ReactComponent as Desc } from '@/assets/svg/Description.svg';
import { ReactComponent as Sources } from '@/assets/svg/Sources.svg';
import { ReactComponent as Union } from '@/assets/svg/Union.svg';
import React from 'react';

import { TabVariants } from '../types';

export const tabs: {
  label: string;
  value: TabVariants;
  logo?: any;
  tab?: any;
}[] = [
  {
    label: 'Описание',
    value: 'main',
    logo: <Desc />,
  },
  {
    label: 'Рекомендации',
    value: 'recs',
    logo: <Union />,
  },
  {
    label: 'Правила подачи',
    value: 'rules',
    logo: <Sources />,
  },
];
