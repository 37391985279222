import { Config } from 'jodit/types/config';
import { InsertMode } from 'jodit/types/types';
import { useMemo } from 'react';

export const useJoditConfig = (
  placeholder = 'Введите текст',
  config?: Partial<Config>,
  fromEmptyConfig?: boolean
) => {
  const defaultConfig = useMemo(
    () => ({
      readonly: false,
      defaultFontSize: 20,
      height: '450px',
      width: '100%',
      pastePlain: true,
      defaultActionOnPaste: 'insert_only_text' as InsertMode,
      buttons: [
        {
          name: 'bold',
          exec: (editor: any) => {
            editor.selection.applyStyle({
              fontWeight: '500',
            });
          },
        },
        'italic',
        {
          name: 'fontsize',
          tooltip: 'Toggle title',
          exec: (editor: any) => {
            const selection = editor.selection;
            if (selection.isCollapsed()) {
              return;
            }
            editor.execCommand('formatBlock', selection, 'h2');
          },
        },
        'link',
        'ul',
        'ol',
        'paragraph',
        '|',
        'image',
        {
          name: 'video',
          tooltip: 'Insert Video',
          exec: (editor: any) => {
            const videoURL = prompt('Enter the video URL:');
            if (videoURL) {
              const videoIdMatch = videoURL.match(
                /(?:youtu\.be\/|youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|\/(?:[cs]\/)?watch(?:er)?(?:\/.*?[&?])?v=)([^"&?/\s]{11})/
              );
              if (videoIdMatch) {
                const videoId = videoIdMatch[1];
                const embedCode = `<iframe width="400" height="345" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen loading="lazy" />`;

                editor.selection.insertHTML(embedCode);
                return;
              }
              editor.selection.insertHTML(
                `<iframe src="${videoURL}" width="400" height="345"  loading="lazy"/>`
              );
            }
          },
        },
      ],
      askBeforePasteHTML: false,
      removeButtons: [
        'hr',
        'fullsize',
        'brush',
        'file',
        'brush',
        'paragraph',
        'left',
        'height',
        'center',
        'right',
        'table',
        'print',
        'underline',
        'eraser',
        'redo',
        'source',
        'fullsize',
        'format',
        'spellcheck',
        'indent',
        'preview',
        'undo',
        'justify',
        'source',
        'font-style',
        'script',
        'list',
        'indent',
        'font',
        'color',
        'media',
        'state',
        'clipboard',
        'insert',
        'history',
        'search',
        'other',
        'info',
        'audio',
        'charmap',
        'showall',
      ],
      image: {
        useImageEditor: true,
        editStyle: true,
      },

      toolbarAdaptive: false,
      font: {
        Rubik: 'Rubik',
      },
      style: {
        fontSize: '20px',
        lineHeight: '1.25',
        fontFamily: 'Rubik',
      },
      ...config,
    }),
    [placeholder]
  );
  if (fromEmptyConfig) {
    return config;
  }
  return defaultConfig;
};
