import { ReactComponent as Plus } from '@/assets/svg/Plus.svg';
import { useFoodsList as useList } from '@/entities/foods';
import NoContent from '@/entities/marketplace/ui/NoContent/NoContent';
import { routes } from '@/shared/consts/consts';
import * as UI from '@/shared/ui';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import { useNavigate } from 'react-router-dom';

import { Filters } from './ui/Filters';
import { Item } from './ui/Item';

export const List = () => {
  const navigate = useNavigate();
  const { foods, isEmpty, handleDelete } = useList();
  const basicGrid =
    'flex h-14 items-center justify-start gap-8 self-stretch rounded-[16px] bg-[#FFF] px-6 py-4 border';
  return (
    <div className='container pt-12'>
      <div className='mb-10'>
        <div className='align-center mb-12 flex h-[56px] justify-between'>
          <div className='flex h-full items-center gap-4'>
            <UI.SectionTitle title='Продукты' />
            <div className={'relative'}>
              <Tooltip tooltipClassName='top-[-72px]' text='Добавить продукт'>
                <div
                  className='200ms flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[50%] bg-[#337CEC] transition hover:bg-[#1B56B1]'
                  onClick={() => navigate(`/${routes.foods.createFoods}`)}
                >
                  <Plus />
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <Filters basicGrid={basicGrid} />
        {foods.map(({ img, id, title, category, age, isMark }, index, arr) => (
          <Item
            id={foods?.[index]?.id || String(id)}
            img={foods?.[index]?.image || foods?.[0]?.image || img || ''}
            key={index}
            title={title}
            category={category.title}
            age={age}
            handleDelete={() => handleDelete(id)}
            isMark={isMark}
            basicGrid={basicGrid}
            isLast={index === arr.length - 1}
            isFirst={index === 0}
          />
        ))}
        {isEmpty && (
          <NoContent text='Здесь ничего нет.. попробуйте изменить параметры фильтрации или поиска' />
        )}
      </div>
    </div>
  );
};
