import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import { routes } from '../../shared/consts/consts';

export const NoRights = () => {
  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant='h5'>
        Пока что для вас в админке ничего не доступно 😔
      </Typography>
      <Typography variant='h5'>
        <Link to={`/${routes.authRoutes.login}`}>Войдите</Link> под другим
        аккаунтом или попросите выдать вам роль
      </Typography>
    </Box>
  );
};
