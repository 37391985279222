import { foodsActions as actions } from '@/entities/foods';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Select } from '@/shared/ui/Select/Select';

export const SelectCategory = () => {
  const dispatch = useAppDispatch();
  const { category } = useAppSelector((state) => state.foods.form.main);

  const { categories } = useAppSelector((state) => state.foods);
  return (
    <Select
      name={'category'}
      data-testid={'select-category'}
      id={'category'}
      selected={category}
      required
      values={categories}
      handleSelect={(value) =>
        dispatch(
          actions.changeRecipesForm({
            key: 'category',
            value: value,
          })
        )
      }
      label={'Категория'}
      placeholderClassName={category ? '' : 'text-gray-300'}
      containerClassName='w-[428px]'
      placeholder={category ? category.title : 'Выберите'}
    />
  );
};
