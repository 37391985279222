import { deletePromocode } from '@/entities/promocodes/model/promocodesThunks';
import { setState } from '@/shared/interfaces/common/setState';
import { useAppDispatch } from '@/shared/model';
import { setNotification } from '@/shared/model/appSlice';
import * as UI from '@/shared/ui';
import NewButton from '@/shared/ui/NewButton/NewButton';
import { FC } from 'react';

interface Props {
  opened: boolean;
  setOpened: setState<boolean>;
  id: number;
}

export const DeletePromocode: FC<Props> = ({ opened, setOpened, id }) => {
  const dispatch = useAppDispatch();
  const handleDelete = () => {
    dispatch(deletePromocode({ id })).then(() => {
      dispatch(
        setNotification({ value: 'Промокод удалён', variant: 'success' })
      );
    });
    setOpened(false);
  };
  return (
    <UI.Modal opened={opened} onClose={() => setOpened(false)}>
      <div className='flex flex-col justify-center'>
        <h2 className='mb-2 w-[528px] text-center text-3xl font-medium'>
          Удалить промокод?
        </h2>
        <div className='flex w-[100%] flex-col items-center gap-2'>
          <NewButton
            variant='negative'
            type='submit'
            data-testid={'button-publish_article'}
            styles='max-w-[360px] max-h-[56px] mt-[16px]'
            onClick={() => handleDelete()}
          >
            Удалить
          </NewButton>
        </div>
        <NewButton
          variant='blue'
          type='button'
          data-testid={'button-cancel'}
          styles='w-[100px] max-h-[20px] mt-[24px]'
          onClick={() => setOpened(false)}
        >
          Отмена
        </NewButton>
      </div>
    </UI.Modal>
  );
};
