import { IFormPromocode } from '@/entities/promocodes/types/IPromocodeForm';
import { IPromocode } from '@/entities/promocodes/types/IPromocodes';

export function normalizePromocode(promocode: IPromocode): IFormPromocode {
  const formPromocode: IFormPromocode = {
    title: promocode?.title || null,
    endDate: promocode?.endDate === null ? null : new Date(promocode?.endDate),
    discountCurrency:
      promocode?.discountCurrency === 'ruble' ||
      promocode?.discountCurrency === 'percentage'
        ? promocode?.discountCurrency
        : null,
    discount:
      typeof promocode?.discount === 'number' ? promocode?.discount : null,
    productsId: promocode?.products.map((product) => product.id) || null,
    usersId: promocode?.users.map((user) => user.id) || null,
    accessTypeId: promocode?.accessType.id || null,
    typeId: promocode?.type.id || null,
  };

  return formPromocode;
}
